import {Backdrop, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getFormattedDateTime} from "../../../../../utils/getFormattedDateTime";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyle = makeStyles({
    backdrop: {
        zIndex: 1299
    },
    container: {
        backgroundColor: "#fff",
        maxWidth: 800,
        padding: 30,
        paddingBottom: 20
    },
    titleBar: {
        marginBottom: 40
    },
    titleItem: {
        display: "flex",
        alignItems: "center"
    },
    description: {
        minHeight: 80,
        marginBottom: 40
    },
    bottomItem: {
        display: "flex",
        alignItems: "center",
    },
    actionButtons: {
        justifyContent: "flex-end"
    }
});

const DetailPopup = ({logData, close, stations, organizationUsers}) => {
    const classes = useStyle();
    const {t} = useTranslation();

    return (
        <Backdrop open={!!logData} className={classes.backdrop}>
            {logData && (
                <Grid container className={classes.container} data-testid="log-detail-popup">
                    <Grid item container xs={12} rowSpacing={3} columnSpacing={1} className={classes.titleBar}>
                        <Grid item xs={3} className={classes.titleItem} data-testid="detail-date">
                            <Typography variant="subtitle2">
                                {getFormattedDateTime(new Date(logData.date))}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent="center" className={classes.titleItem}
                              data-testid="detail-station">
                            <Typography variant="subtitle1">
                                {stations.find(station => station.id === logData.station)?.alias}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} justifyContent="flex-end" className={classes.titleItem}
                              data-testid="detail-user">
                            <Typography variant="subtitle2">
                                {organizationUsers.find(user => user.id === logData.user)?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.description} data-testid="detail-description">
                        <Typography>{logData.description}</Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.bottomItem} data-testid="detail-created">
                        <Typography variant="caption">
                            {t("common.created_at_date", {date: getFormattedDateTime(new Date(logData.created))})}
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} className={clsx(classes.bottomItem, classes.actionButtons)}>
                        <Button onClick={close} color="primary" data-testid="detail-close-button">
                            {t("action.close")}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Backdrop>
    );
};

export default DetailPopup;
