import React from 'react';
import {makeStyles} from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx";

const useStyles = makeStyles({
    box: {
        background: "#f18484",
        color: "#fff",
        borderRadius: 6,
        width: 240,
        padding: 16,
        position: "absolute",
        top: 80,
        zIndex: 2000,
        boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
        cursor: "default",
        transition: "opacity 0.5s ease-in-out, left 0.5s ease-in-out, right 0.5s ease-in-out"
    }
});

const ToastContainer = ({ className, children, onClick, onCloseButton }) => {

    const classes = useStyles();

    const handleOnClose = (event) => {
        event.stopPropagation();
        onCloseButton();
    };

    return <div className={clsx(className, classes.box)} onClick={onClick}>
        <CloseIcon
            onClick={handleOnClose}
            style={{position: "absolute", right: 10, top: 9, cursor: "pointer"}}/>
        {children}
    </div>;
};

export default ToastContainer;
