import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingAction, stopLoadingAction } from "../reducers/requestUIReducer";

const useLoading = () => {
    const { loading } = useSelector(state => state.requestUI);
    const dispatch = useDispatch();

    const setLoading = useCallback((loadingState) => {
        if (loadingState) {
            dispatch(startLoadingAction());
        } else {
            dispatch(stopLoadingAction());
        }
    }, [dispatch]);

    return { loading, setLoading };
};

export default useLoading;
