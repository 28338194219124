import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {drawerWidth} from "../dashboard/Dashboard";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import ReactDOM from "react-dom";
import NodeDetail from "./NodeDetail";
import {useHistory, useLocation} from "react-router-dom";
import MapLegend from "./legend/MapLegend";
import mapBoxGL from 'mapbox-gl';
import {isStationRedrawRequired} from "./util/stationDiffUtil";
import {
    HOME_ROUTE,
    MAPBOX_ACCESS_TOKEN,
    MAPBOX_LIGHT_STYLE,
    MAPBOX_SATELLITE_STYLE,
    stationState
} from "../../../../constants";
import {SATELLITE_MAP, STREET_MAP} from "./panel/BaseMapPanel";
import styles from './HomeScreen.module.scss';
import {isEqual} from "lodash";
import applyMarkerStyle from "../../../../utils/applyMarkerStyle";
import {LinearProgress} from "@mui/material";
import MainPanel from "./panel/MainPanel";
import {useMapContext} from "./MapContext";
import "./map_style.css";
import usePollutantRoses from "./pollutant-roses/usePollutantRoses";
import useStationsData from "../../../../hooks/useStationsData";
import {handleSentryError} from "../../../../handleSentryError";
import useTryWebGL from "../../../../hooks/useTryWebGL";
import {updateMapSelectedStationAction, updateWebGlFail} from "../../../../reducers/dashboardUIReducer";
import NoLocationDialog from "./no-location-dialog/NoLocationDialog";

export const mapboxMapStyles = {
    [STREET_MAP]: MAPBOX_LIGHT_STYLE,
    [SATELLITE_MAP]: MAPBOX_SATELLITE_STYLE
};

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
    rootHeight: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        '@media (min-width:0px) and (orientation: landscape)': {
            height: `calc(100vh - 48px)`
        },
        '@media (min-width:600px)': {
            height: `calc(100vh - 64px)`
        }
    },
    rootWidth: {
        width: `calc(100vw - ${theme.spacing(7)})`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100vw - ${theme.spacing(7)})`
        }
    },
    rootWidthOpen: {
        width: `calc(100vw - ${drawerWidth}px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100vw - ${drawerWidth}px)`
        }
    }
}));

const HomeScreen = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const drawerOpen = useSelector(state => state.dashboardUI.drawerOpen);
    const stations = useSelector(state => state.dashboardUI.stations, isEqual);
    const {units, organization, timeZone: dataTimeZone} = useSelector(state => state.auth);
    const {setCurrentMap, mapLoading, stationLayerActive, pollutantRosesLayerActive, mapType, showOfflineStationsOn} =
        useMapContext();
    const [stationMarkers, setStationMarkers] = useState([]);
    const [unknownStation, setOpenUnknownStation] = useState(null);
    const [offlineHiddenClue, setOfflineHiddenClue] = useState(false);
    const {tryWebGL} = useTryWebGL();

    const mapboxMapStyle = mapboxMapStyles[mapType];

    const previousStation = useRef("");

    const navigateToAnalyticsCallback = useCallback((deviveId) => {
        history.push(`/analytics/${deviveId}`);
    }, [history]);

    const usePopUp = useRef(makeStyles(({
        popUp: {
            zIndex: 1,
        }
    })));

    const classes = useStyles();
    const popUpClass = useRef(usePopUp.current());
    const mapRef = useRef(null);
    const detailRef = useRef(null);

    const {stationsAqiData, updateStationsAqiData, loading: loadingStationsAqiData} = useStationsData();

    const aqiStations = useMemo(() => (
        (stationLayerActive || pollutantRosesLayerActive) ? stations.map(station => {
            const stationAqiData = stationsAqiData.find(item => item.id === station.id);
            return {
                ...station,
                ...stationAqiData
            };
        }) : []
    ), [stations, stationLayerActive, pollutantRosesLayerActive, stationsAqiData]);

    const aqiLayerStations = useMemo(() => {
                return aqiStations.filter(station => {
                    return station.state === "offline" ? showOfflineStationsOn : station;
                });
            },
            [aqiStations, showOfflineStationsOn]);

    const updateDetailScreen = useCallback(({deviceId, domElement}) => {
        const device = aqiLayerStations.find(element => element.id === deviceId);
        ReactDOM.render(
            <NodeDetail index={units.index} device={device} deviceSelectedCallback={navigateToAnalyticsCallback}
                        dataTimeZone={dataTimeZone}/>,
            domElement
        );
    }, [units.index, aqiLayerStations, navigateToAnalyticsCallback, dataTimeZone]);

    const showDetail = useCallback((deviceId, position) => {


        let markerHeight = 20, markerRadius = 12, linearOffset = 25;
        let popupOffsets = {
            'top': [0, markerHeight],
            'top-left': [0, 0],
            'top-right': [0, 0],
            'bottom': [0, -markerHeight],
            'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'left': [markerRadius, (markerHeight - markerRadius) * -1],
            'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };

        const detailScreen = document.createElement('div');
        detailScreen.id = 'detailScreen';
        updateDetailScreen({deviceId, domElement: detailScreen});

        detailRef.current?.remove();
        detailRef.current = new mapBoxGL.Popup({
            className: popUpClass.current.popUp, maxWidth: 600,
            closeOnClick: false, offset: popupOffsets, focusAfterOpen: false
        })
            .setLngLat([position.long, position.lat])
            .setDOMContent(detailScreen)
            .addTo(mapRef.current);
    }, [updateDetailScreen, popUpClass]);

    const onPollutantRoseClick = useCallback((stationId, position) => {
        showDetail(stationId, position);
    }, [showDetail]);

    useEffect(() => {
        updateStationsAqiData();
    }, [updateStationsAqiData]);

    useEffect(() => {
        return () => {
            dispatch(updateMapSelectedStationAction(null));
        };
    }, [dispatch]);


    const {loading: loadingPollutantRoses, rosesLayerStations} = usePollutantRoses(onPollutantRoseClick);
    const loading = loadingPollutantRoses || loadingStationsAqiData;

    const visibleAqiStations = useMemo(() => (
        aqiLayerStations.filter(station => {

            return !rosesLayerStations?.find(roseStation => roseStation.id === station.id);
        })
    ), [aqiLayerStations, rosesLayerStations]);


    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setStyle(mapboxMapStyle);
        }
    }, [mapboxMapStyle]);

    useEffect(() => {
        const parsedValues = queryString.parse(location.search);

        const lastPosition = localStorage.getItem('lastPosition');
        if (lastPosition) {
            const [lat, long, zoom] = lastPosition.split(';');
            parsedValues.lat ??= lat;
            parsedValues.long ??= long;
            parsedValues.zoom ??= zoom;
        }

        // Reset location
        history.push(HOME_ROUTE);

        mapBoxGL.accessToken = MAPBOX_ACCESS_TOKEN;

        const failWebGL = tryWebGL(() => {
            mapRef.current = new mapBoxGL.Map({
                container: 'map',
                style: mapboxMapStyle,
                boxZoom: true
            });
        });
        dispatch(updateWebGlFail(failWebGL));

        const zoom = parsedValues.zoom !== undefined ? parsedValues.zoom : organization.map.zoom;
        try {
            mapRef.current?.setZoom(zoom);
        } catch {
            handleSentryError(`Invalid zoom value: ${JSON.stringify(zoom)}`);
            mapRef.current?.setZoom(1);
        }

        const center = (parsedValues.long !== undefined && parsedValues.lat !== undefined) ?
            [parsedValues.long, parsedValues.lat] : [organization.map.center.lon, organization.map.center.lat];
        try {
            mapRef.current?.setCenter(center);
        } catch {
            handleSentryError(`Invalid position value: ${JSON.stringify(center)}`);
            mapRef.current?.setZoom(1);
        }

        mapRef.current?.addControl(new mapBoxGL.NavigationControl());
        mapRef.current?.dragRotate.disable();

        mapRef.current?.on('moveend', () => {
            if (mapRef.current) {
                const {lat, lng} = mapRef.current.getCenter();
                const zoom = mapRef.current.getZoom();
                const position = [lat, lng, zoom];
                localStorage.setItem('lastPosition', position.join(';'));
                history.push(`${HOME_ROUTE}?lat=${lat}&long=${lng}&zoom=${zoom}`);
            }
        });

        mapRef.current?.on('load', () => {
            setCurrentMap(mapRef.current);
        });

        return () => {
            mapRef.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const url = location.search;
        const parsedValues = queryString.parse(url);
        const newLat = parsedValues.lat;
        const newLong = parsedValues.long;
        const newZoom = parsedValues.zoom;
        const open = parsedValues.open;
        const unknownStation = parsedValues.unknown;

        if (mapRef.current && (newLat !== undefined && newLong !== undefined && newZoom !== undefined)) {
            const {lat, lng} = mapRef.current.getCenter();
            const zoom = mapRef.current.getZoom();

            if (newLat * 1 !== lat || newLong * 1 !== lng || newZoom * 1 !== zoom) {
                mapRef.current.setCenter([newLong, newLat]);
                mapRef.current.setZoom(newZoom);
            }
        }

        const showStationInfoDialog = (stationId, offLineHiddenClue = false)=>{
            setOfflineHiddenClue(offLineHiddenClue);
            detailRef.current?.remove();
            dispatch(updateMapSelectedStationAction(null));
            const station = aqiStations.find(element => element.id === stationId);
            setOpenUnknownStation(station);
        };

        if (unknownStation) {
            showStationInfoDialog(unknownStation);
        }

        if (open){
            const station = aqiStations.find(element => element.id === open);
            if(!showOfflineStationsOn && station.state === stationState.offline)
                showStationInfoDialog(open, true);
            else
                showDetail(open, {lat: newLat, long: newLong});
        }


    }, [aqiStations, dispatch, location, showDetail, showOfflineStationsOn]);

    useEffect(() => {
        mapRef.current?.resize();
    }, [drawerOpen]);

    useEffect(() => {
        if (isStationRedrawRequired(previousStation.current, visibleAqiStations)) {
            const stationsWithLocation = visibleAqiStations.filter(station => station.position !== undefined);
            stationMarkers?.forEach(value => {
                if (value !== undefined) {
                    value.remove();
                }
            });

            setStationMarkers(stationsWithLocation.map(value => {
                let el = document.createElement('div');
                el.id = value.id;
                el.alias = value.alias;
                el.position = value.position;
                applyMarkerStyle(el, value, units.index);

                el.addEventListener('click', function () {
                    showDetail(el.id, el.position);
                });

                const marker = new mapBoxGL.Marker(el);
                try {
                    return marker.setLngLat({
                        lng: value.position.long,
                        lat: value.position.lat
                    }).addTo(mapRef.current);
                } catch {
                    handleSentryError(`Invalid position value in station ${value.id}: ${JSON.stringify(value?.position)}`);
                    return null;
                }
            }).filter(item => !!item)); // Marker is not null
        }
        previousStation.current = JSON.stringify(visibleAqiStations);
    }, [showDetail, visibleAqiStations, stationMarkers, setStationMarkers, units.index]);

    const handleCloseUnknownLocationDialog = useCallback(() => {
        history.push(`/home`);
        setOpenUnknownStation(null);
        setOfflineHiddenClue(false);
    }, [history]);

    const handleNoPositionStationClick = useCallback((unknownStation) => {
        detailRef.current?.remove();
        const station = aqiStations.find(element => element.id === unknownStation);
        setOpenUnknownStation(station);
    }, [aqiStations]);

    return (
        <div>
            <div className={"no-border-map"}>
                <div id="map" className={
                    clsx(styles.root, classes.rootHeight, drawerOpen ? classes.rootWidthOpen : classes.rootWidth)
                }/>
            </div>
            <div className={clsx(styles.controls, drawerOpen ? classes.rootWidthOpen : classes.rootWidth)}>
                <MapLegend indexType={units.index}/>
                <div className={clsx(styles.loadingLinear, mapLoading || loading && styles.visible)}>
                    <LinearProgress variant={mapLoading || loading ? "indeterminate" : "determinate"} value={0}/>
                </div>
                <MainPanel handleNoPositionStationsClick={handleNoPositionStationClick}/>
            </div>
            {unknownStation && <NoLocationDialog index={units.index}
                                                 offlineHiddenClue={offlineHiddenClue}
                                                 handleClose={handleCloseUnknownLocationDialog}
                                                 device={unknownStation}
                                                 deviceSelectedCallback={navigateToAnalyticsCallback}
                                                 dataTimeZone={dataTimeZone}/>}
        </div>
    );
};

export default HomeScreen;
