import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import ListCustomItemText from "../../../common/multipleSelector/ListCustomItemText";
import {getStationOptions, stationSelectorFilter} from "../../../../utils/stationUtil";
import ListCustomItem from "../../../common/multipleSelector/ListCustomItem";

const getValue = (options, selectedStation) => {
    return options.find(value => {
        return value.id === selectedStation;
    });
};

const useStyles = makeStyles({
    select: {
        width: 140,
        color: "white",
        marginRight: 20,
        '&:before': {
            borderColor: "white",
        },
        '&:after': {
            borderColor: "white",
        }
    },
    icon: {
        fill: "white",
    },
    root: {
        "& .MuiFormLabel-root": {
            color: "white",
        },
        "& .MuiInput-input": {
            color: "white"
        },
        "& .MuiButtonBase-root": {
            color: "white"
        },
        "& .MuiInputBase-root.MuiInput-root:after": {
            borderBottom: "2px solid #ffffff"
        },
        "& .MuiInputBase-root.MuiInput-root:before": {
            borderBottom: "1px solid rgba(256, 256, 256, 0.72)"
        }
    },
    label: {
        alignSelf: "stretch",
        lineHeight: 1.25,
        fontWeight: 500,
        marginBottom: 2
    },
    subLabel: {
        alignSelf: "stretch",
        lineHeight: 1.25
    }
});

const AnalyticsStationSelector = ({hideStationsWithLocation = false, dark = false}) => {

    const {t} = useTranslation();
    const options = useSelector(state => getStationOptions(state.dashboardUI.stations).filter(item => !hideStationsWithLocation || !item.position), isEqual);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const mapSelectedStation = useSelector(state => state.dashboardUI.mapSelectedStation);
    const webGlFail = useSelector(state => state.dashboardUI.webGlFail);
    const history = useHistory();
    const location = useLocation();
    const [value, setValue] = useState(null);

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const val = getValue(options, selectedStation);
        setValue(val);
    }, [options, selectedStation]);

    useEffect(() => {
        const val = getValue(options, mapSelectedStation);
        setValue(val);
    }, [options, mapSelectedStation]);

    const onSensorChange = (newValue) => {

        if (newValue !== null) {


            let sensorId = newValue.id;

            if (newValue.id !== value?.id && location.pathname.startsWith("/analytics")) {
                history.push(`/analytics/${sensorId}`);
            }
            if (location.pathname.startsWith("/home")) {
                if (newValue.position) {
                    const {lat, long} = newValue.position;
                    history.push(`/home/?lat=${lat}&long=${long}&zoom=16&open=${sensorId}`);
                } else
                    history.push(`/home/?unknown=${sensorId}`);
            }
        }
        setMenuOpen(false);
    };

    const classes = useStyles();

    return (
        <Autocomplete
            disabled={webGlFail && location.pathname.startsWith("/home")}
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            className={classes.select}
            value={value !== undefined ? value : null}
            disablePortal
            options={options}
            style={{width: 200, marginRight: 50}}
            filterOptions={stationSelectorFilter}
            renderOption={(props, option) => (
                <ListCustomItem {...props} option={option} onClick={() => onSensorChange(option)}>
                    <ListCustomItemText option={option} isStation={true} />
                </ListCustomItem>
            )}
            renderInput={(params) =>
                <TextField
                    variant="standard" className={dark ? {} : classes.root} {...params} label={t("dashboard.select_station")}/>}
        />
    );
};

export default AnalyticsStationSelector;
