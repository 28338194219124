import React from "react";
import {Card, Grid, IconButton} from "@mui/material";
import CardLoading from "./CardLoading";
import Warning from "../Warning";
import {useAnchorEl} from "../../../hooks/useAnchorEl";
import CardTitle from "./CardTitle";
import RefreshIcon from "@mui/icons-material/Refresh";
import {makeStyles} from "@mui/styles";
import HelpPopup from "../HelpPopup";

const useStyles = makeStyles({
    refreshButton: {
        marginLeft: "auto",
        color: "gray"
    }
});

const CardLayout = ({ className, loading, error, title, helpText, refreshButtonEvent, refreshButtonDisabled,
                        children }) => {
    const classes = useStyles();
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();

    return (
        <Card className={className}>
            {error
                ? <Warning message={error}/>
                : loading ? <CardLoading/> : (
                    <Grid container flexDirection="column" spacing={1}>
                        {(title || helpText) && (
                            <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                                {title && (helpText
                                    ? <CardTitle title={title} setAnchorEl={setAnchorEl}/>
                                    : <CardTitle title={title}/>)}
                                {refreshButtonEvent && (
                                    <IconButton className={classes.refreshButton} aria-label="refresh"
                                                disabled={loading || refreshButtonDisabled}
                                                onClick={refreshButtonEvent}>
                                        <RefreshIcon fontSize={"large"}/>
                                    </IconButton>
                                )}
                                {helpText && <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                                                        message={helpText}/>}
                            </Grid>
                        )}
                        {children}
                    </Grid>
                )}
        </Card>
    );
};

export default CardLayout;