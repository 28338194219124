import {periodTypes} from "bettairplaformutil/src/validationUtil";

export const
    MINIMUN_PASSWORD_LENGHT = 15,
    LOGIN_FORM_MINIMUN_PASSWORD_LENGHT = 8,

    PROFILE_ROUTE = "/profile",
    LOGIN_ROUTE = "/login",
    REGISTER_ROUTE = "/register",
    RESET_ROUTE = "/reset",
    ANALYTICS_ROUTE = "/analytics/:id",
    COMPARE_ROUTE = "/compare",
    DOWNLOAD_ROUTE = "/download",
    REPORT_ROUTE = "/reports",
    ALARM_ROUTE = "/alarm",
    LOGS_ROUTE = "/logs",
    VALIDATION_ROUTE = "/validation",
    ORGANIZATION_ROUTE = "/organization",
    HOME_ROUTE = "/home",

    DEFAULT_PERIOD = 7,

    sensor = {
        no2: "NO2",
        no: "NO",
        o3: "O3",
        co2: "CO2",
        co: "CO",
        so2: "SO2",
        h2s: "H2S",
        nh3: "NH3",
        pm1: "PM1",
        pm2p5: "PM2P5",
        pm4: "PM4",
        pm10: "PM10",
        hcl: "HCL",
        voc: "VOC",
        voc_iaq: "VOC_IAQ",
        noise: "noise",
        temperature: "temperature",
        rh: "relativeHumidity",
        ah: "absoluteHumidity",
        temperature_meteo: "temperatureMeteo",
        rh_meteo: "relativeHumidityMeteo",
        pressure: "pressure",
        equivalent_pressure: "equivalentPressure",
        rain_rate: "rainRate",
        solar_radiation: "solarRadiation",
        uv_index: "uvIndex",
        wind: "wind",
        wind_speed: "windSpeed",
        wind_gust: "windGust",
        wind_direction: "wdirection",
        battery: "battery",
        aqi: "aqi",
    },

    pollutants = [
        sensor.no2,
        sensor.no,
        sensor.o3,
        sensor.co2,
        sensor.co,
        sensor.so2,
        sensor.h2s,
        sensor.nh3,
        sensor.pm1,
        sensor.pm2p5,
        sensor.pm4,
        sensor.pm10,
        sensor.hcl,
        sensor.voc
    ],

    meteoSensors = [
        sensor.temperature,
        sensor.rh,
        sensor.ah,
        sensor.temperature_meteo,
        sensor.rh_meteo,
        sensor.pressure,
        sensor.equivalent_pressure,
        sensor.rain_rate,
        sensor.solar_radiation,
        sensor.uv_index,
        sensor.wind,
        sensor.wind_speed,
        sensor.wind_gust,
        sensor.wind_direction
    ],

    meteoDataSource = {
        internal: "internal",
        external: "external"
    },

    meteoDataSourceMap = {
        [meteoDataSource.internal]: [sensor.temperature, sensor.rh],
        [meteoDataSource.external]: [sensor.temperature_meteo, sensor.rh_meteo]
    },

    unit = {
        ppb: "ppb",
        ppm: "ppm",
        ug_m3: "ug-m3",
        mg_m3: "mg-m3",
        celsius: "celsius",
        percentage: "percentage",
        fahrenheit: "fahrenheit",
        mps: "mps",
        mph: "mph",
        knots: "knots",
        degrees: "degrees",
        hpa: "hPa",
        dba: "dBA",
        iaq: "IAQ",
        mm_h: "mm/h",
        w_m2: "W/m²",
        uv: "UV"
    },

    indexTypes = {
        AQI: 'AQI',
        EAQI: 'EAQI',
        ICQA: 'ICQA',
        DIBA: 'DIBA',
        ISPU: 'ISPU',
        AQHI: 'AQHI',
        APIMS: 'APIMS',
        "AQHI-HK": 'AQHI-HK',
        "AQI-QB": 'AQI-QB',
        ICA: 'ICA'
    },

    NO_INDEX_TYPE = "noIndex",

    STATION_DEFAULT_COLOR = "#61addc",
    STATION_SELECTED_COLOR = "#3333FF",

    airQualityIndexColors = {
        AQI: ["#52B946", "#F3EC18", "#F67D1E", "#ED1C25",
            "#7D287C", "#480D27"],
        EAQI: ["#73e7df", "#4fc5a5", "#e7de00", "#f55050",
            "#920434", "#7b227e"],
        ISPU: ["#31c535", "#026ebb", "#f5b900", "#f70000",
            "#030303"],
        ICQA: ["#399dc7", "#319c5c", "#e9dc4a", "#c23442",
            "#6b181f", "#9f5aa0"],
        DIBA: ["#05aae7", "#8dc950", "#f5f513", "#f50309"],
        AQHI: ["#3dc4ee", "#f9c931", "#e8202b", "#600f11"],
        APIMS: ["#0000FF", "#428500", "#FFF833", "#FD8800",
            "#D41413"],
        "AQHI-HK": ["#45AF35", "#F6AB00",
            "#E50010", "#AA4514","#231716"],
        "AQI-QB": ["#00E400", "#FFFF00", "#FF0000"],
        ICA: ["#52B946", "#F3EC18", "#F67D1E", "#ED1C25",
            "#7D287C", "#694434"]
    },

    stationState = {
        active: "active",
        online: "online",
        offline: "offline"
    },

    rdConstants = {
        mdmm: "mdmm",
        vlh: "vlh",
        vld: "vld",
        vla: "vla",
    },

    timeZoneOptions = {
        UTC: "UTC",
        StationLocalTime: "StationLocalTime"
    },

    legalThresholdsOptions = {
        RD: "RD",
        WHO: "WHO",
        EUROPE: "EUROPE",
        ITALY: "ITALY",
        URUGUAY: "URUGUAY",
        COLOMBIA: "COLOMBIA"
    },

    VALIDATION_TABLE = "validationTable",
    VALIDATION_CHART = "validationChart",
    periodTypeColor = {
        [periodTypes.generated]: "#FFFFFF",
        [periodTypes.valid]: "#90C418",
        [periodTypes.notValid]: "#FC4850",
        [periodTypes.maintenance]: "#F8C822",
        [periodTypes.recalculated]: "#59ADFF",
        [periodTypes.stop]: "#686868"
    },

    MODEL_EXTERNAL_PREFIX = "EXT-",

    MAP_API_KEY = 'pk.eyJ1Ijoiam1zYWJpbjEzMjciLCJhIjoiY2ttZWx0dHZhMndmbDJ4a25sazFiOWs5ZiJ9.bm1M1koxEnJI_jwx5OCbyQ',

    DEFAULT_POPUP_WIDTH = 625,

    CONNECTION_MAX_RETRIES = 5,
    CONNECTION_RETRY_DELAY = 2000,
    CONNECTION_DEFAULT_TIMEOUT = 10000,
    CONNECTION_COMPARE_DATA_TIMEOUT = 30000,

    ERROR_500 = {id: "status.500"},
    ERROR_INVALID_REQUEST = {id: "invalidRequest"},
    ERROR_INVALID_VERIFICATION_CODE = {id: "invalidCode"},
    ERROR_INVALID_CREDENTIALS = {id: "invalidCredentials"},
    ERROR_TRY_LATER = {id: "tryLater"},
    ERROR_WEBSOCKET_FAIL_TO_CONNECT = {id: "websocket.failToConnect"},

    MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX,
    MAPBOX_LIGHT_STYLE = "mapbox://styles/mapbox/light-v10",
    MAPBOX_SATELLITE_STYLE = "mapbox://styles/mapbox/satellite-streets-v10",
    DEFAULT_MAP_POSITION = {"lat": 14, "lon": 0, "zoom": 2},

    appLanguages = ["en", "es", "ca", "fr", "it", "hr", "id", "ms", "nl", "pt", "tr", "zh", "el", "ja"],

    appModules = {
        compare: 'compare',
        alarms: 'alarms',
        downloads: 'downloads',
        reports: 'reports',
        cmms: 'cmms',
        dataValidation: 'dataValidation'
   },

   imageErrorTypes = ["TooLargeFileError", "FileTypeError"];


