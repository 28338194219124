import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import "../../translate/momentLocales";
import {
    abbreviatedWeekDaysTranslation
} from "../routes/private/analytics/temporal_variation_card/viewTextTranslateKeys";

const DatePickerField = ({size, variant, value, onChange, ...props}) => {
    const {t, i18n} = useTranslation();
    const [inputValue, setInputValue] = useState(moment.utc(value));

    useEffect(() => {
        const abbreviatedWeekDays = abbreviatedWeekDaysTranslation.map(day => ({
            // Create an object with a charAt method that returns the translated day
            // This is necessary because the DatePicker component uses the charAt function to get the abbreviated week days
            charAt: () => t(day)
        }));

        moment.updateLocale(i18n.language, {
            weekdaysShort: abbreviatedWeekDays,
        });

        moment.locale(i18n.language);
    }, [i18n.language, t]);

    const handleChange = (newValue) => {
        setInputValue(newValue);
        // Check if the value is a valid object
        if (newValue && newValue.isValid()) {
            const newDate = new Date(newValue.toDate());
            newDate.setUTCHours(0, 0, 0, 0);
            onChange(newDate);
        } else {
            onChange(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} adapterLocale={i18n.language}>
            <DatePicker
                value={inputValue}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField size={size} variant={variant} {...params}  />
                )}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default DatePickerField;