import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const createReportRequest = (body,callback) => {
    webClient.post('/report', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};