import {timeZoneOptions} from "../../../../constants";
import {getTzUtcOffset, localToUTCDate} from "../../../../utils/dateUtil";

export const getStationTimezones = (stations, date, dataTimeZone) => (
    stations.map(stationData => {
        const timezone = stationData?.position?.locationInfo?.timeZone;
        if (dataTimeZone === timeZoneOptions.UTC || !timezone) return 0;
        const utcDate = localToUTCDate(new Date(date), timezone);
        const utcOffset = getTzUtcOffset(timezone, utcDate);
        return utcOffset / 60;
    })
);

export const areAllSameTimezone = (timezones) => timezones.every((val, i, arr) => val === arr[0]);

export const getCommonTimezone = (stations, date, dataTimeZone) => {
    const timezones = getStationTimezones(stations, date, dataTimeZone);
    const timezone = areAllSameTimezone(timezones) ? timezones[0] : null;
    return timezone !== null ? ` (UTC${timezone > 0 ? `+${timezone}` : timezone || ""})` : "";
};
