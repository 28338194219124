import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {useDispatch} from "react-redux";
import {Backdrop, CircularProgress, Grid} from "@mui/material";
import validator from 'validator';
import CookiesDialog from "./CookiesDialog";
import {loginAction} from "../../../../reducers/authReducer";
import ForgotDialog from "./ForgotDialog";
import {
    ERROR_500,
    ERROR_INVALID_CREDENTIALS,
    ERROR_INVALID_REQUEST, ERROR_INVALID_VERIFICATION_CODE, ERROR_TRY_LATER,
    LOGIN_FORM_MINIMUN_PASSWORD_LENGHT
} from "../../../../constants";
import {hashGenerator} from "../../../../utils/hashGenerator";
import {otpRequest, resendOpt, updateExpiredPasswordRequest, verifyOpt} from "../../../../requests/auth/authRequests";
import useMessage from "../../../../hooks/useMessage";
import Credentials from "./Credentials";
import OTP from "./OTP";
import ExpiredPassword from "./ExpiredPassword";
import {useMessageDialog} from "../../../../hooks/useMessageDialog";
import {useTranslation} from "react-i18next";
import KeepAlive from "./KeepAlive";

const useStyles = makeStyles({
    button: {
        color: '#ffffff',
        marginBottom: 12,
        marginTop: 12
    },
    img: {
        width: 220,
        paddingBottom: 30
    },
    forgot: {
        display: 'flex',
        justifyContent: "center"
    },
    loginContainer: {
        minWidth: 400,
        maxWidth: 600,
        background: 'white',
        padding: 40,
        borderRadius: 6
    },
    container: {
        background: "#eaeaea",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    },
    form: {
        width: '100%'
    }
});

const credentialsInitialState = {
    email: '',
    password: '',
    newPassword: '',
    repeatPassword: '',
    validCredentialForm: false,
    showForgotDialog: false
};

const otpInitialState = {
    validOtpForm: false,
    otp: '',
    otpId: '',
    otpEmail: ''
};

const LoginScreen = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {t, language} = useTranslation();
    const {setError, setSuccess, setLargeInfo} = useMessage();
    const {showDialog} = useMessageDialog();
    const [otpState, setOptState] = useState(false);
    const [expiredPassword, setExpiredPassword] = useState(false);
    const [{validOtpForm, otp, otpId, otpEmail}, setOtpInfo] = useState(otpInitialState);
    const persistedEmail = localStorage.getItem('user_email');
    const initialEmail = persistedEmail ? JSON.parse(persistedEmail) : '';
    const [state, setState] = useState({
        ...credentialsInitialState,
        email: initialEmail
    });
    const [logging, setLogging] = useState(false);
    const [openKeepAlive, setOpenKeepAlive] = useState(false);
    const [loginData, setLoginData] = useState(null);

    const {email, password, validCredentialForm, showForgotDialog} = state;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has("next")) {
            const next = params.get("next");
            const decodedUrl = decodeURIComponent(next);
            localStorage.setItem("lastPath", decodedUrl);
        }
    }, []);

    const validateForm = useCallback((formEmail = email, formPassword = password) => {
        if (!validator.isEmail(formEmail)) {
            return false;
        }
        return formPassword.length >= LOGIN_FORM_MINIMUN_PASSWORD_LENGHT;
    }, [email, password]);

    const handleEmail = useCallback((event) => {
        const value = event.target.value;
        const validCredentialForm = validateForm(value, undefined);
        setState({...state, email: value, validCredentialForm});
    }, [state, validateForm]);

    const handlePassword = useCallback((event) => {
        const value = event.target.value;
        const validCredentialForm = validateForm(undefined, value);
        setState({...state, password: value, validCredentialForm});
    }, [state, validateForm]);


    const requestOtp = useCallback(async (email, password) => {
        setLoading(true);
        try {
            setLoading(false);
            const {data} = await otpRequest({mail: email, pass: password});
            setOtpInfo(state => ({...state, otpId: data.otpId, otpEmail: email}));
            setOptState(true);
        } catch (e) {
            setLoading(false);
            if (e?.response?.status)
                switch (e?.response?.status) {
                    case 401:
                        setError(ERROR_INVALID_CREDENTIALS);
                        break;
                    case 400:
                        setError(ERROR_INVALID_REQUEST);
                        break;
                    case 423:
                        await showDialog({
                            message: t("requestErrors.attemptsExceeded")
                        });
                        break;
                    case 500:
                        setError(ERROR_500);
                        break;
                    default:
                        setError(ERROR_TRY_LATER);
                        break;
                }
            else
                setError(ERROR_500);
        }
    }, [t, setError, setLoading, showDialog]);

    const handleLogin = useCallback(async () => {
        setLogging(true);
        let digestHex = await hashGenerator(password);
        await requestOtp(email, digestHex);
        setLogging(false);
    }, [email, requestOtp, password]);

    const onCloseKeepAlive = useCallback((keepAliveData = undefined) => {
        setOpenKeepAlive(false);

        if (keepAliveData)
            localStorage.setItem('user_email', JSON.stringify(email));
        else
            localStorage.removeItem('user_email');

        const lastPath = localStorage.getItem("lastPath");

        if (!lastPath || lastPath?.includes("cloud.bettair.city")) {
            const termsUrl = ["es", "ca"].includes(language)
                ? "https://bettaircities.com/es/aviso-legal/"
                : "https://bettaircities.com/legal-advice/";
            setLargeInfo(
                <>
                    {t("invitationAndResetScreen.using_this_app_you_accept")}&nbsp;
                    <a href={termsUrl} target="_blank" rel="noreferrer"
                       style={{color: "#ffffff", fontWeight: "bold"}}>
                        {t("invitationAndResetScreen.terms_and_conditions")}
                    </a>
                </>
            );
        }

        dispatch(loginAction(keepAliveData ?? loginData));
    }, [dispatch, email, language, loginData, setLargeInfo, t]);

    const handleValidateOtp = useCallback(async () => {
        setLoading(true);
        try {
            const resp = await verifyOpt({mail: otpEmail, otpId, otp});
            setLoading(false);
            setOpenKeepAlive(true);
            setLoginData(resp.data);

        } catch (e) {
            setLoading(false);

            if (e?.request?.status === 412) {
                setExpiredPassword(true);
            } else {
                setError(ERROR_INVALID_VERIFICATION_CODE);
            }
        }
    }, [otpEmail, otpId, otp, setError]);

    const handleEnter = useCallback((e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            if (!validCredentialForm && !otpState || !validOtpForm && otpState) return;

            if (!otpState) {
                handleLogin();
            } else {
                handleValidateOtp();
            }
        }
    }, [handleLogin, handleValidateOtp, otpState, validCredentialForm, validOtpForm]);

    const handlerForgot = useCallback(() => {
        setState({...state, showForgotDialog: true});
    }, [state]);

    const handleResendOtp = useCallback(async () => {
        setLoading(true);
        try {
            setLoading(false);
            const {data} = await resendOpt({mail: otpEmail, otpId});
            setOtpInfo(state => ({...state, otpId: data.otpId}));
            setSuccess("loginScreen.otp.resendSuccess");
        } catch (e) {
            setLoading(false);
            setState({
                ...credentialsInitialState,
                email: initialEmail
            });
            setOtpInfo(otpInitialState);
            setOptState(false);
            setError("loginScreen.otp.expiredLogin");
        }
    }, [initialEmail, otpEmail, otpId, setError, setSuccess]);

    const handleOtp = useCallback((event) => {
        const value = event.target.value.toUpperCase();
        if (value.length <= 6) {
            setOtpInfo(state => ({...state, otp: value, validOtpForm: value.length === 6}));
        }
    }, []);

    const handleUpdatePassword = useCallback(async (newPassword) => {
        setLoading(true);
        try {
            const passHash = await hashGenerator(newPassword);
            const {data} = await updateExpiredPasswordRequest({mail: otpEmail, otpId, otp, pass: passHash});
            setLoading(false);
            setSuccess("changePassword.success_changed");

            localStorage.setItem('user_email', JSON.stringify(email));

            dispatch(loginAction(data));
        } catch (e) {
            setLoading(false);
            setState({
                ...credentialsInitialState,
                email: initialEmail
            });
            setOtpInfo(otpInitialState);
            setOptState(false);
            setExpiredPassword(false);

            switch (e?.response?.status) {
                case 401:
                    setError(ERROR_INVALID_CREDENTIALS);
                    break;
                case 500:
                    setError(ERROR_500);
                    break;
                default:
                    setError(ERROR_TRY_LATER);
                    break;
            }
        }
    }, [otpEmail, otpId, otp, email, dispatch, setError, setSuccess, initialEmail]);

    return <div>
        {openKeepAlive && <KeepAlive onCloseKeepAlive={onCloseKeepAlive}/>}
        {loading && <Backdrop style={{zIndex: 1999, color: '#fff'}} open={loading} data-testid={"loading-screen"}>
            <CircularProgress color="inherit"/>
        </Backdrop>}
        <Grid className={classes.container} container justifyContent={"center"} alignContent={"center"}>
            {expiredPassword ? <ExpiredPassword loading={loading} handleUpdatePassword={handleUpdatePassword}/> : (
                otpState ? <OTP
                    loading={loading}
                    handleValidateOtp={handleValidateOtp}
                    handleResendOtp={handleResendOtp}
                    otp={otp}
                    handleOtp={handleOtp}
                    validForm={validOtpForm}
                    handleEnter={handleEnter}
                /> : <Credentials
                    loading={loading}
                    logging={logging}
                    email={email}
                    handleEmail={handleEmail}
                    handleEnter={handleEnter}
                    password={password}
                    handlePassword={handlePassword}
                    handleLogin={handleLogin}
                    handlerForgot={handlerForgot}
                    validForm={validCredentialForm}
                />
            )}
        </Grid>
        <CookiesDialog/>
        <ForgotDialog showForgotDialog={showForgotDialog} setForgotState={setState}/>
    </div>;
};

export default LoginScreen;
