import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import validator from 'validator';
import {forgotRequest} from "../../../../requests/auth/authRequests";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../hooks/useLoading";
import useMessage from "../../../../hooks/useMessage";
import {ERROR_500} from "../../../../constants";

export default function ForgotDialog({showForgotDialog,setForgotState}) {

    const initialState = {
        email:'',
        validCredentials:false
    };

    const { setLoading } = useLoading();
    const { setError, setSuccess } = useMessage();
    const [state, setState] = useState(initialState);
    const {email,validCredentials} = state;
    const { t } = useTranslation();

    const handleRecoverPassword = () => {
        setLoading(true);
        forgotRequest({mail:email},(err,data)=>{
            setLoading(false);
            if(!err){
                setSuccess('forgot.success');
            }else{
                switch (data.status) {
                    case 400:
                        setError({message: t('forgot.invalid_email')});
                        break;
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        setError({message: t('forgot.unknown')});
                        break;
                }
            }
            setState(initialState);
            setForgotState( oldState => ({...oldState,showForgotDialog:false}));
        });
    };

    const handleEmail =( event)=>{
        const value = event.target.value;
        setState({email: value,validCredentials:validator.isEmail(value)});
    };

    const handleClose = ()=>{
        setState(initialState);
        setForgotState( oldState => ({...oldState,showForgotDialog:false}));
    };

    return (
        <Dialog open={showForgotDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('forgot.forgot')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('forgot.enter_email')}
                </DialogContentText>
                <TextField
                    label={t('forgot.email')} variant="outlined"
                    required autoComplete="email" autoFocus fullWidth  margin="normal"
                    onChange={handleEmail} value={email}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!validCredentials}
                    onClick={handleRecoverPassword} color="primary">
                    {t('forgot.reset_password')}
                </Button>
            </DialogActions>
        </Dialog>

    );
}
