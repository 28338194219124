import {makeStyles} from "@mui/styles";
import {IconButton} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpPopup from "../HelpPopup";
import {useAnchorEl} from "../../../hooks/useAnchorEl";

const useStyles = makeStyles({
    helpButton:{
        color:"black"
    }
});

const HelpButton = ({ helpText }) => {
    const classes = useStyles();
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();

    return (
        <>
            <IconButton className={classes.helpButton} aria-label="help"
                        onClick={e => setAnchorEl(e.currentTarget)}>
                <HelpOutlineIcon />
            </IconButton>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={helpText}/>
        </>
    );
};

export default HelpButton;
