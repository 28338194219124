import {sensor, unit} from "../constants";

const getPollutantUnit = (pollutants, isPPM) => {
    return pollutants === "usa" ? (isPPM ? unit.ppm : unit.ppb) : (isPPM ? unit.mg_m3 : unit.ug_m3);
};

const getTemperatureUnit = (temperature) => {
    return temperature === "celsius" ? unit.celsius : unit.fahrenheit;
};

export const getVariableUnit = (variable, units) => {
    const {pollutants, temperature, windSpeed} = units;

    const unitMap = {
        [sensor.pressure]: unit.hpa,
        [sensor.equivalent_pressure]: unit.hpa,
        [sensor.pm1]: unit.ug_m3,
        [sensor.pm2p5]: unit.ug_m3,
        [sensor.pm4]: unit.ug_m3,
        [sensor.pm10]: unit.ug_m3,
        [sensor.rh]: unit.percentage,
        [sensor.ah]: unit.percentage,
        [sensor.rh_meteo]: unit.percentage,
        [sensor.battery]: unit.percentage,
        [sensor.noise]: unit.dba,
        [sensor.temperature]: () => getTemperatureUnit(temperature),
        [sensor.temperature_meteo]: () => getTemperatureUnit(temperature),
        [sensor.no2]: () => getPollutantUnit(pollutants, false),
        [sensor.o3]: () => getPollutantUnit(pollutants, false),
        [sensor.no]: () => getPollutantUnit(pollutants, false),
        [sensor.h2s]: () => getPollutantUnit(pollutants, false),
        [sensor.hcl]: () => getPollutantUnit(pollutants, false),
        [sensor.nh3]: () => getPollutantUnit(pollutants, false),
        [sensor.so2]: () => getPollutantUnit(pollutants, false),
        [sensor.co]: () => getPollutantUnit(pollutants, true),
        [sensor.co2]: () => getPollutantUnit(pollutants, true),
        [sensor.wind_speed]: windSpeed,
        [sensor.wind_gust]: windSpeed,
        [sensor.voc_iaq]: unit.iaq,
        [sensor.voc]: unit.mg_m3,
        [sensor.rain_rate]: unit.mm_h,
        [sensor.uv_index]: unit.uv,
        [sensor.solar_radiation]: unit.w_m2,
    };

    const unitOrFunction = unitMap[variable];
    if (typeof unitOrFunction === 'function') {
        return unitOrFunction();
    }
    return unitOrFunction;
};