import React from 'react';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {useTranslation} from "react-i18next";
import {webClient} from "../../../config/axios";

const DownloadButton = ({route, fileName}) => {

    const {t} = useTranslation();

    const handleDownload = async () => {
        try {
            const response = await webClient.get(route, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Download Error:', error);
        }
    };

    return (
        <Button
            variant="text"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={handleDownload}
            style={{fontSize: '0.8rem'}}
        >
            {t("common.download")}
        </Button>
    );
};

export default DownloadButton;
