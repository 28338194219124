import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Checkbox, Divider, List, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import ListCustomItemText from "./ListCustomItemText";
import getItemSelectorFilter from "../../../utils/getItemSelectorFilter";
import ListCustomItem from "./ListCustomItem";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    hidden: {
        display: "none"
    },
    cardHeader: {
        background: "#e5e5e5",
        "& .MuiCardHeader-subheader": {
            whiteSpace: "nowrap"
        }
    },
    divider: {
        borderColor: "white",
        borderWidth: 1
    }
});

const ListSelector = ({disabled, items, selectedItems, maxItems, searchableFields, hidden, handleToggle,
                          handleToggleAll, isStation = undefined}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const [filter, setFilter] = useState("");
    const [filteredItems, setFilteredItems] = useState(items);

    const itemSelectorFilter = useMemo(() => (
        getItemSelectorFilter(searchableFields ?? ["label"])
    ), [searchableFields]);

    useEffect(() => {
        // Use timeout to avoid filtering on each keystroke
        const timerId = setTimeout(() => {
            setFilteredItems(
                itemSelectorFilter(items, {inputValue: filter})
            );
        }, 300);
        return () => clearTimeout(timerId);
    }, [items, filter, itemSelectorFilter]);

    const filterCallback = useCallback((event) => {
        setFilter(event.target.value);
    }, []);

    const selectionList = useMemo(() => (
        filteredItems.map(option => {
            const labelId = `transfer-list-all-item-${option.id}-label`;
            const isChecked = selectedItems.indexOf(option.id) !== -1;
            const isDisabled = (!isChecked && selectedItems?.length >= maxItems) || disabled;
            const onClick = () => handleToggle(option);
            return (
                <ListCustomItem key={option.id} onClick={onClick} disabled={isDisabled} withCheckbox={true}
                                checked={isChecked} labelId={labelId}>
                    <ListCustomItemText option={option} isStation={isStation} />
                </ListCustomItem>
            );
        })
    ), [filteredItems, selectedItems, maxItems, disabled, isStation, handleToggle]);

    const selectedCount = selectedItems?.length;

    return (
        <Card className={clsx(classes.container, hidden && classes.hidden)}>
            <CardHeader className={classes.cardHeader}
                        sx={{px: 2, py: 1 , color: disabled ? "#636363" :"#242424"}}
                        avatar={!maxItems ? (
                            <Checkbox
                                onClick={handleToggleAll}
                                checked={selectedCount === items.length && items.length !== 0}
                                indeterminate={
                                    selectedCount !== items.length && selectedCount !== 0
                                }
                                disabled={items.length === 0 || disabled}
                                inputProps={{
                                    'aria-label': 'all items selected',
                                    "data-testid": "select-all"
                                }}
                            />
                        ) : <></>}
                        title={t("selector.available_selection")}
                        subheader={t("selector.selected_elements", {
                            count: maxItems ? selectedCount :
                                `${selectedCount}/${items.length}`
                        })}
            />
            <Divider
                classes={ {root:classes.divider}}  />
            <TextField
                value={filter}
                onChange={filterCallback}
                variant={"filled"}
                fullWidth
                label={t("selector.filter_by_name")}
                inputProps={{
                    'aria-label': 'filter by name',
                    "data-testid": "filter-by-name"
                }}
            />
            <List
                sx={{
                    bgcolor: '#f1f1f1',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
                data-testid="list-selector"
            >
                {selectionList}
            </List>
        </Card>
    );
};

export default ListSelector;
