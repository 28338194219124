import {useState} from "react";
import LegendItem from "./LegendItem";
import {Chip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyle = makeStyles({
    firstItem: {
        marginTop: 5
    },
    lastItem: {
        marginBottom: 5
    },
    chipContainer: {
        display: "flex",
        marginLeft: -12,
        marginTop: -4,
        marginBottom: -4
    }
});

const LegendList = ({items, onItemDelete}) => {
    const classes = useStyle();
    const [mouseOver, setMouseOver] = useState(null);
    return items.map((item, index) => (
        <div key={index} className={clsx(index === 0 && classes.firstItem,
            index === items.length - 1 && classes.lastItem)} onMouseEnter={() => setMouseOver(index)}
             onMouseLeave={() => setMouseOver(null)}>
            {mouseOver === index
                ? (
                    <div className={classes.chipContainer}>
                        <Chip onDelete={items.length === 1 ? null : () => onItemDelete(item.id)} label={
                            <LegendItem key={index} {...item} />
                        } />
                    </div>
                )
                : <LegendItem key={index} {...item} />}
        </div>
    ));
};

export default LegendList;