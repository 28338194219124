import React from 'react';
import {
    Backdrop,
    Button, Grid, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";

const MessageDialog = ({title, message, confirmText, onClose}) => {
    const {t} = useTranslation();
    return (
        <Backdrop data-testid={"confirm-delete-pop-up"} open={true} style={{zIndex:1999}}>
            <Grid container style={{backgroundColor:"#fff", maxWidth:680, padding:30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:40,marginBottom:40}}>
                    <Typography>{message}</Typography>
                </Grid>
                <Grid item container xs={12} style={{justifyContent:"flex-end"}}>
                    <Button data-testid={"confirm-button"}
                            onClick={onClose} color="primary">
                        {confirmText || t("ok")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default MessageDialog;
