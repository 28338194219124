export const isStationRedrawRequired = (previosStationsJson, stationsArray) => {
    if (previosStationsJson === "") {
        return true;
    }
    const oldStationArray = JSON.parse(previosStationsJson);
    if (oldStationArray.length !== stationsArray.length) {
        return true;
    }
    let paramChange = false;
    stationsArray.forEach(newStationData => {
        const oldStationData = oldStationArray.find(oldStation => {
            return oldStation.id === newStationData.id;
        });
        if (oldStationData === undefined) {
            return true;
        } else {
            const stateChanged = oldStationData.state !== newStationData.state;
            let positionChanged = false;
            if (oldStationData.position !== undefined && newStationData.position !== undefined) {
                positionChanged = oldStationData.position?.lat !== newStationData.position?.lat ||
                    oldStationData.position?.long !== newStationData.position?.long;
            }
            const indexChanged = oldStationData.index !== newStationData.index;
            if (stateChanged || positionChanged || indexChanged) {
                paramChange = true;
            }
        }
    });
    return paramChange;
};