import {webClient} from "../../config/axios";
import {dateToUTCServerFormatString} from "../../utils/dateToUtcStringConverter";

export const predominantPollutantRequest = (stationId, date, index, hourly = undefined) => {
    const time =  dateToUTCServerFormatString(date);
    const url = `/station-data/${stationId}/predominant-pollutant`;
    return webClient.get(url, {params: {time, index, hourly}})
        .then(response => {
            return response.data;
        });
};