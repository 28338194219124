import {useSelector} from "react-redux";
import {useCallback} from "react";

const useModules = () => {

    const {id, organization} = useSelector(state => state.auth);
    const modules = organization?.modules;

    const hasModule = useCallback((module)=> {
        if (!id) return true; // if user is not logged in, return true to avoid showing 404 page
        return modules ? modules.includes(module) : false;
    },[id, modules]);

    return { hasModule };
};

export default useModules;
