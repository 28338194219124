import {webClient} from "../../config/axios";

export const getStationValidationData = ({station, ...params}) => {
    return webClient.get(`/validate/periods/${station}`, {params})
        .then(response => {
            return response.data;
        });
};

export const postStationValidationData = (body) => {
    return webClient.post("/validate", body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};