import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMessage from "../../../../../hooks/useMessage";
import {useSelector} from "react-redux";
import {DEFAULT_PERIOD, timeZoneOptions} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {pollutantNames} from "../../pollutantNames";
import {useTranslation} from "react-i18next";
import {vocIaqDataRequest} from "../../../../../requests/analytics/vocIaqRequest";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import useRequest from "../../../../../hooks/useRequest";
import {AUTO_RESOLUTION} from "../common/ResolutionSelector";

const baseSensorData = {
    type: 'scatter'
};


const initialPlotLayout = {
    legend: {x: -0.25, y: 0.9},
    yaxis: {
        title: "IAQ",
    },
    xaxis: {showgrid: false},
    hovermode: "x unified"
};

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo: false
};

const initialState = {
    data: [],
    error: "",
    loading: true
};

const VocIaqCardView = ({className}) => {

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const [{data, loading}, updateState] = useState(initialState);
    const {setError, setInfo} = useMessage();
    const isMounted = useMountComponent();
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const [timeZone, setTimeZone] = useState(selectedStationData.position?.locationInfo ? dataTimeZone : timeZoneOptions.UTC);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);


    const updateData = useCallback(() => {

        updateState(state => {
            return {...state, loading: true, data: [], error: ""};
        });

        vocIaqDataRequest({
            stationId: selectedStation,
            init: selectedRange[0],
            end: selectedRange[1],
            dataTimeZone:timeZone,
            resolution:selectedResolution
        }, (err, data) => {
                if (!isMounted.current) {
                    return;
                }
                if (!err) {
                    let dataArray = Object.entries(data).filter(item => item[0] === "VOC_IAQ");
                    let newData = dataArray.map((value) => {
                        let y = value[1].y;
                        let adaptedData = {...value[1], y};
                        return {
                            name: ` ${pollutantNames.get(value[0])} - IAQ`,
                            ...adaptedData,
                            ...baseSensorData,
                            hoverlabel: {namelength: 0},
                            hovertemplate: `<b>${pollutantNames.get(value[0])}</b>: %{y}`,
                            marker: {color: "#D01A55"},
                            yaxis: "y1"
                        };
                    });
                    if (newData.length > 0) {
                        updateState(state => {
                            return {
                                ...state, data: newData,
                                loading: false,
                            };
                        });
                    } else {
                        updateState(state => {
                            return {...state, data: [], loading: false,};
                        });
                    }
                } else {
                    if (data.status === 404) {
                        updateState(state => {
                            return {...state, loading: false};
                        });
                        setInfo("analyticScreen.voc_iaq.data_not_found");
                    } else {
                        updateState(state => {
                            return {...state, loading: false, error: t("analyticScreen.voc_iaq.could_not_update_data")};
                        });
                        handleErrorResponse(data, response => {
                            setError(response, false, "analyticScreen.voc_iaq.could_not_update_data");
                        });
                    }
                }
            });
    }, [handleErrorResponse, isMounted, selectedRange, selectedResolution, selectedStation, t, setError, setInfo, timeZone]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const handleRefresh = () => {
        updateData();
    };

    const handleTimeZoneChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setTimeZone(newAlignment);
        }
    };

    const plotLayout = useMemo(() => {
        const locationInfo = selectedStationData.position?.locationInfo;
        return {
            ...initialPlotLayout,
            xaxis: {
                ...initialPlotLayout.xaxis,
                hoverformat: `%-d %b, %Y %H:%M:%S${locationInfo ? '' : ` (${timeZoneOptions.UTC})`}`
            }
        };
    }, [selectedStationData]);

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("analyticScreen.voc_iaq.title")}
                    helpText={t("analyticScreen.voc_iaq.en_voc_iaq")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout loading={loading} emptyData={data.length === 0} height={420}
                         chartStyles={{ "& .modebar": { left: "40%" } }} timeZone={timeZone}
                         onTimeZoneChange={selectedStationData.position?.locationInfo && handleTimeZoneChange}
                         period={period} onPeriodChange={setPeriod} dateRange={dateRange}
                         onResolutionChange={setSelectedResolution} resolution={selectedResolution}
                         onDateRangeChange={setDateRange}>
                <ChartView
                    layout={plotLayout}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default VocIaqCardView;
