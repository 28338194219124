import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const getPasswordListRequest = (callback, isOrganization = false) => {

    const url = isOrganization ? '/organization/app-password' : '/user/app-password';

    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

