import React from 'react';
import { IconButton, Typography} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return {
        cardTittle:{
            marginLeft:20,
            color:"gray"
        },
        helpButton:{
            color:"black"
        }
    };
});

const CardTitle = ({ title, setAnchorEl }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.cardTittle} variant={"h6"}>{title}</Typography>
            {setAnchorEl && <IconButton className={classes.helpButton} aria-label="help"
                        onClick={e => setAnchorEl(e.currentTarget)}>
                <HelpOutlineIcon />
            </IconButton>}
        </>
    );
};

export default CardTitle;
