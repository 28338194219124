import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";

const useAuth = () => {
    const { id, role } = useSelector((state) => state.auth);

    const {permissions, roles} = useMemo(() => {
        const newPermissions = [];
        const newRoles = (id ?? null) && role.map((item) => {
            newPermissions.push(...item.permissions);
            return item.id;
        });
        return {permissions: newPermissions, roles: newRoles};
    }, [id, role]);

    const hasPermission = useCallback((permission) => permissions.includes(permission), [permissions]);

    return { id, roles, permissions, hasPermission };
};

export default useAuth;
