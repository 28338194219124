const getIconStyle = ({ iconSize, backgroundUrl, backgroundColor, borderRadius, borderColor, borderSize, textColor,
                          opacity }) => ({
    width: `${iconSize}px`,
    height: `${iconSize}px`,
    backgroundSize: `100%`,
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundColor: backgroundColor || 'none',
    borderRadius: backgroundColor ? borderRadius : 0,
    boxSizing: 'border-box',
    border: backgroundColor ? `solid ${borderSize ?? '0.1'}px ${borderColor || backgroundColor}` : 'none',
    boxShadow: backgroundColor
        ? `0px 2px 2px 0px rgba(96,96,96,0.6),inset 0px ${iconSize}px ${iconSize}px -${iconSize/4*3}px rgba(255,255,255,0.4)`
        : 'none',
    fontSize: `${iconSize * 0.8}px`,
    color: textColor || 'inherit',
    opacity: opacity,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export default getIconStyle;