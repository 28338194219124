import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {usePeriodContext} from "../../common/PeriodContext";
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import {pollutantNames} from "../../../pollutantNames";
import {timeZoneOptions, VALIDATION_TABLE} from "../../../../../../constants";
import {useTranslation} from "react-i18next";
import {useVirtualizer} from "@tanstack/react-virtual";
import DataTableCell from "./DataTableCell";
import {isEqual} from "lodash";
import {getVariableUnitName} from "../../../unitsNames";
import useTableData, {LOAD_LIMIT, VALUE_TAG} from "./useTableData";
import {FLAG_TAG} from "./useTableDataFlags";
import moment from "moment-timezone";
import {MAGIC_TOOL, SELECTION_TOOL} from "../EditorControls";
import CardViewLoading from "../../../../../common/chart/ChartLoading";
import DataNotFound from "../../../../../common/DataNotFound";

const CELL_HEIGHT = 28;

const useStyles = makeStyles({
    tableContainer: {
        position: "relative",
        height: 600,
        display: "flex",
        flexDirection: "column",
        userSelect: "none"
    },
    loadingOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        background: "rgba(255, 255, 255, 0.9)",
        zIndex: 3
    },
    tableScroll: {
        overflow: "auto"
    },
    table: {
        position: "relative",
        borderRight: "1px solid #666666",
        borderBottom: "1px solid #666666",
        borderLeft: "1px solid #666666"
    },
    tableRow: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: "flex",
    },
    tableHeader: {
        position: "sticky",
        zIndex: 2,
        background: "#ffffff",
        borderTop: "1px solid #666666",
        borderBottom: "1px solid #666666"
    },
    tableRowCursor: {
        position: "absolute",
        left: 0,
        right: 0,
        height: CELL_HEIGHT + 2,
        boxSizing: "border-box",
        border: "2px solid #FF0000",
        pointerEvents: "none",
        zIndex: 1
    },
    tableCell: {
        border: "1px solid #666666",
        display: "block",
        flex: 1,
        width: "auto",
        height: CELL_HEIGHT,
        lineHeight: `${CELL_HEIGHT}px`,
        padding: "0 8px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: "right"
    },
    tableHeaderCell: {
        height: CELL_HEIGHT * 2,
        cursor: "default"
    },
    tableHeaderCellSelectionActive: {
        cursor: "s-resize"
    },
    firstRowCell: {
        flex: "none",
        width: 200,
        textAlign: "left",
        cursor: "default"
    },
    firstRowCellSelectionActive: {
        cursor: "e-resize"
    },
    firstHeaderCellSelectionActive: {
        cursor: "se-resize"
    }
});

const DataTable = ({ selectedTool, selectionMatrix, handleSelectionClick, editorMatrix, handleMagicSelectionClick}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {selectedStation, stationSensors, loading, dataMatrix: matrix, dataParams, selectedPositionDate,
        cursorPosition, setCursorPosition, lastSelectedCell} = usePeriodContext();
    const selectedStationData = useSelector(state => (
        state.dashboardUI.stations.find(({id}) => selectedStation === id)
    ));
    const {timeZone: dataTimeZone, units} = useSelector(state => state.auth);
    const {loadMoreElements, rowIndexToTimeRange, rowIndexToDate, dateToRowIndex, getTableData, loadingData} =
        useTableData();

    const containerRef = useRef(null);
    const headerRef = useRef(null);

    const headerHeight = headerRef.current?.clientHeight || 0;

    const virtualizer = useVirtualizer({
        count: matrix.length,
        getScrollElement: () => containerRef.current,
        estimateSize: () => CELL_HEIGHT,
        overscan: 20,
        paddingStart: headerHeight
    });

    useEffect(() => {
        getTableData();
    }, [getTableData]);

    const [nextRequestData, setNextRequestData] = useState(null);
    const updateNextRequestData = useCallback((data) => {
        setNextRequestData((prevData) => {
            return isEqual(prevData, data) ? prevData : data;
        });
    }, []);

    useEffect(() => {
        let timeoutId;
        if (nextRequestData) {
            timeoutId = setTimeout(() => {
                loadMoreElements(nextRequestData.startIndex, nextRequestData.limit);
            }, 500);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [nextRequestData, loadMoreElements]);

    const virtualItems = virtualizer.getVirtualItems();

    // This useEffect is responsible for loading data into the virtualized table as the user scrolls through it.
    useEffect(() => {
        if (!loadingData && matrix.length && matrix[0].length && virtualItems.length) {
            const checkIfRowIsLoaded = (index) => matrix[index][0]?.[VALUE_TAG] !== undefined;
            // Maps the virtual elements (rows in the virtualized table) to their indices in the data matrix.
            let virtualItemIndexes = virtualItems.map((virtualRow) => virtualRow.index);
            // Checks if the first and last row in the current view of the table are empty.
            const isFirstIndexEmpty = !checkIfRowIsLoaded(virtualItemIndexes[0]);
            const isLastIndexEmpty = !checkIfRowIsLoaded(virtualItemIndexes[virtualItemIndexes.length - 1]);
            let scrollingToTop = false;
            // If the first row is empty and the last one is not, it reverses the order of the indices and sets that the scroll is upwards.
            if (isFirstIndexEmpty && !isLastIndexEmpty) {
                virtualItemIndexes = virtualItemIndexes.reverse();
                scrollingToTop = true;
            }
            // Looks for the first row index that does not have loaded data.
            const firstItemToLoad = virtualItemIndexes.find((index) => !checkIfRowIsLoaded(index));
            if (firstItemToLoad !== undefined) {
                // If it finds such an index, it calculates the range of rows that it needs to load.
                let lastItemToLoad = scrollingToTop ? firstItemToLoad - LOAD_LIMIT + 1 : firstItemToLoad + LOAD_LIMIT - 1;
                for (let i = 1; i < LOAD_LIMIT; i++) {
                    // If the scroll is upwards, it loads the rows from the found empty row. If a loaded row is found, it stops the loop.
                    if (scrollingToTop && (firstItemToLoad - i === 0 || checkIfRowIsLoaded(firstItemToLoad - i - 1))) {
                        lastItemToLoad = firstItemToLoad - i;
                        break;
                    }
                    // If the scroll is downwards, it loads the rows after the found empty row. If a loaded row is found, it stops the loop.
                    if (!scrollingToTop && (firstItemToLoad + i === matrix.length - 1 || checkIfRowIsLoaded(firstItemToLoad + i + 1))) {
                        lastItemToLoad = firstItemToLoad + i;
                        break;
                    }
                }
                // The number of rows to load is `LOAD_LIMIT` or less if the beginning or end of the matrix is reached.
                const limit = Math.abs(lastItemToLoad - firstItemToLoad) + 1;
                // Calls `updateNextRequestData` with the start index and the limit of rows to load.
                updateNextRequestData({startIndex: Math.min(firstItemToLoad, lastItemToLoad), limit});
            }
        }
    }, [virtualItems, matrix, loadingData, updateNextRequestData]);

    useEffect(() => {
        if (selectedPositionDate) {
            const rowIndex = dateToRowIndex(selectedPositionDate);
            // check if rowIndex is not NaN
            if (!isNaN(rowIndex)) {
                virtualizer.scrollToIndex(rowIndex, {align: "center"});
            }
        }
    }, [selectedPositionDate, dateToRowIndex, virtualizer]);

    const cursorOffset = useMemo(() => {
        if (cursorPosition.date) {
            const rowIndex = dateToRowIndex(cursorPosition.date);
            if (virtualItems.find((virtualRow) => virtualRow.index === rowIndex)) {
                return rowIndex * CELL_HEIGHT + headerHeight;
            }
        }
        return null;
    }, [cursorPosition, dateToRowIndex, virtualItems, headerHeight]);

    const onTimeItemMouseEnter = useCallback((index) => {
        if (index !== null) {
            setCursorPosition({date: rowIndexToDate(index, dataParams), location: VALIDATION_TABLE});
        } else {
            setCursorPosition({date: null, location: null});
        }
    }, [setCursorPosition, dataParams, rowIndexToDate]);

    // Prevent selection with shift key
    const handleMouseDown = (event) => {
        if (event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleCellClick = useCallback((event, rowIndex, colIndex) => {
        if (selectedTool === SELECTION_TOOL) {
            handleSelectionClick(event, rowIndex, colIndex);
        }
        if (selectedTool === MAGIC_TOOL) {
            handleMagicSelectionClick(event, rowIndex, colIndex);
        }
    }, [selectedTool, handleSelectionClick, handleMagicSelectionClick]);

    const getCellSelection = useCallback((rowIndex, colIndex) => {
        return selectionMatrix[rowIndex]?.[colIndex];
    }, [selectionMatrix]);

    const renderedHeader = useMemo(() => {
        const startTime = rowIndexToDate(0, dataParams);
        const timeZoneString =
            (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
            || timeZoneOptions.UTC;
        const utcTimeZoneOffset = - moment.tz.zone(timeZoneString).utcOffset(startTime) / 60;
        return (
            <div className={clsx(classes.tableRow, classes.tableHeader)} ref={headerRef}>
                <div className={clsx(classes.tableCell, classes.tableHeaderCell, classes.firstRowCell,
                    selectedTool === SELECTION_TOOL && classes.firstHeaderCellSelectionActive)}
                     onClick={e => handleCellClick(e, -1, -1)}>
                    <b>{t("common.time")}</b><br/>
                    {`UTC ${utcTimeZoneOffset > 0 ? `+${utcTimeZoneOffset}` : utcTimeZoneOffset || ""}`}
                </div>
                {stationSensors.map((id, index) => (
                    <div key={id} className={clsx(classes.tableCell, classes.tableHeaderCell,
                        selectedTool === SELECTION_TOOL && classes.tableHeaderCellSelectionActive)}
                         onClick={e => handleCellClick(e, -1, index)}>
                        <b>{pollutantNames.get(id)}</b><br/>
                        {getVariableUnitName(id, units)}
                    </div>
                ))}
            </div>
        );
    }, [classes, t, rowIndexToDate, dataParams, selectedStationData, stationSensors, dataTimeZone, units, selectedTool,
        handleCellClick]);

    const getRenderedRow = useCallback((virtualRow) => {
        const row = matrix[virtualRow.index];
        const editorRow = editorMatrix[virtualRow.index];
        return (
            <div className={classes.tableRow} key={virtualRow.index} style={{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
            }}>
                <div className={clsx(classes.tableCell, classes.firstRowCell,
                    selectedTool === SELECTION_TOOL && classes.firstRowCellSelectionActive)}
                     onMouseEnter={() => onTimeItemMouseEnter(virtualRow.index)}
                     onMouseLeave={() => onTimeItemMouseEnter(null)}
                     onClick={e => handleCellClick(e, virtualRow.index, -1)}>
                    {rowIndexToTimeRange(virtualRow.index, dataParams)}
                </div>
                {stationSensors.map((sensorId, columnIndex) => {
                    const cell = row[columnIndex];
                    const editorCell = editorRow?.[columnIndex];
                    return (
                        <DataTableCell key={sensorId} className={classes.tableCell} style={{cursor: "default"}}
                                       sensorId={sensorId} value={cell?.[VALUE_TAG]}
                                       flag={editorCell || cell?.[FLAG_TAG]}
                                       selected={getCellSelection(virtualRow.index, columnIndex)}
                                       pivot={lastSelectedCell?.rowIndex === virtualRow.index &&
                                             lastSelectedCell?.colIndex === columnIndex}
                                       onClick={e => handleCellClick(e, virtualRow.index, columnIndex)}/>
                    );
                })}
            </div>
        );
    }, [classes, matrix, editorMatrix, dataParams, stationSensors, rowIndexToTimeRange, getCellSelection, selectedTool,
        handleCellClick, onTimeItemMouseEnter, lastSelectedCell]);

    const renderedRows = useMemo(() => (
        virtualItems.map((virtualRow) => getRenderedRow(virtualRow))
    ), [virtualItems, getRenderedRow]);

    return (
        <div className={classes.tableContainer}>
            {(loading || (loadingData && !matrix.length)) && (
                <div className={classes.loadingOverlay}>
                    <CardViewLoading/>
                </div>
            )}
            {(!loadingData && !matrix.length) ? <DataNotFound/> : (
                <div className={classes.tableScroll} ref={containerRef}>
                    <div className={classes.table} style={{height: `${virtualizer.getTotalSize()}px`}}
                         onMouseDown={handleMouseDown}>
                        {cursorOffset !== null && <div  className={classes.tableRowCursor} style={{top: cursorOffset}}/>}
                        {renderedHeader}
                        {renderedRows}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataTable;
