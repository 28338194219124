import React, {useCallback, useEffect, useState} from 'react';
import CardViewLoading from "../../../../common/chart/ChartLoading";
import {Button, Card, Grid, IconButton, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import DataNotFound from "../../../../common/DataNotFound";
import {makeStyles} from "@mui/styles";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
    getFormattedUtcDate,
} from "../../../../../utils/getFormattedDateTime";
import AppPasswordConfigPopUp from "./AppPasswordConfigPopUp";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {getPasswordListRequest} from "../../../../../requests/password/getPasswordListRequest";
import {createPasswordRequest} from "../../../../../requests/password/createPasswordRequest";
import CreatedPasswordPopUp from "./CreatedPasswordPopUp";
import {deletePasswordRequest} from "../../../../../requests/password/deletePasswordRequest";
import {useTranslation} from "react-i18next";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import useLoading from "../../../../../hooks/useLoading";
import useMessage from "../../../../../hooks/useMessage";
import {WRITE_ORGANIZATION_CONFIG} from "../../organization/members/UserPermissions";
import useAuth from "../../../../../hooks/useAuth";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useRequest from "../../../../../hooks/useRequest";
import {openLinkInNewTab} from "../../../../../utils/linkUtil";

const useStyles = makeStyles({
    deleteButton: {
        color: "#ffffff",
        background: "#f6343d",
        '&:hover': {
            background: "#81191c"
        },
    },
    subtitle: {
        color: "#8a8a8a"
    },
    organizationName: {
        fontWeight: "bold"
    },
    rowTable: {
        display: "flex",
        width: "100%vw",
        justifyContent: "center",
        margin: 0,
    },
    table: {
        maxWidth: 1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    }
});

const AppPasswordCardView = ({className, isOrganizationPass = false}) => {
    const {t} = useTranslation();
    const {hasPermission} = useAuth();
    const [{
        loading, deleting, enableDelete, error, selectionModel, passwords, openConfigPopUp,
        openConfirmDialog, createdPassword
    }, updateState] = useState(
        {
            loading: true, openConfigPopUp: false,
            deleting: false, enableDelete: false, error: "", selectionModel: [], createdPassword: "",
            openConfirmDialog: false, passwords: []
        });
    const classes = useStyles();
    const isMounted = useMountComponent();
    const {handleErrorResponse} = useRequest();
    const {setLoading} = useLoading();
    const {setError, setInfo} = useMessage();
    const [showCopyButtonKey, setShowCopyButtonKey] = useState(null);


    const updateTable = useCallback(() => {
        updateState(state => ({
            ...state, loading: true, error: "", passwords: [], createdPassword: "",
            deleting: false, selectionModel: [], enableDelete: false
        }));
        getPasswordListRequest((error, data) => {
            if (isMounted.current) {
                if (!error) {
                    updateState(state => ({
                        ...state, loading: false, passwords: data.map(item => {
                            return {
                                ...item,
                                created: new Date(item.created)
                            };
                        }).sort((a, b) => b.created - a.created)
                    }));
                } else {
                    handleErrorResponse(data, response => {
                        updateState(state => ({...state, loading: false, error: t("appPassword.error_fetching")}));
                        setError(response,
                            false,
                            `${t("error")} ${response?.status ?? ''},
                            ${t("appPassword.fetching_password")}`);
                    });
                }
            }
        }, isOrganizationPass);
    }, [isMounted, isOrganizationPass, handleErrorResponse, setError, t]);


    useEffect(() => {
        updateTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewAppPassword = () => {
        updateState(state => ({...state, openConfigPopUp: true}));
    };

    const handleDelete = () => {
        updateState(state => ({...state, openConfirmDialog: true}));
    };

    const onSelectionModelChange = (newSelectionModel) => {
        updateState(state => {
            return {
                ...state, enableDelete: (newSelectionModel.length !== 0),
                selectionModel: newSelectionModel
            };
        });
    };

    const handlePasswordDisplayClosed = useCallback(() => {
        updateTable();
    }, [updateTable]);

    const finishAppPasswordSetup = useCallback((json) => {
        setLoading(true);
        createPasswordRequest(json, (error, data) => {
            setLoading(false);
            if (isMounted.current) {
                if (!error) {
                    updateState(state => ({...state, createdPassword: data.password, openConfigPopUp: false}));
                } else {
                    handleErrorResponse(data, response => {
                        switch (response?.status) {
                            case 403:
                                setError(t("validation.403"), false, "");
                                break;
                            default:
                                setError(response,
                                    false,
                                    `${t("error")} ${response?.status ?? ''},
                                    ${t("appPassword.creating_password")}`);
                        }
                    });
                }
            }
        }, isOrganizationPass);
    }, [setLoading, isOrganizationPass, isMounted, handleErrorResponse, setError, t]);

    const cancelAppPasswordSetup = useCallback(() => {
        updateState(state => ({...state, openConfigPopUp: false}));
    }, []);

    const hideDialog = useCallback(() => {
        updateState(state => ({...state, openConfirmDialog: false}));
    }, []);

    const handleApiDocumentation = () => {
        openLinkInNewTab("https://docs.cloud.bettair.city/api");
    };

    const confirmDelete = useCallback(() => {
        updateState(state => ({...state, openConfirmDialog: false, deleting: true}));
        Promise.all(deletePasswordRequest(selectionModel, isOrganizationPass)).then(() => {
            if (!isMounted.current) {
                return;
            }
            updateTable();
        }).catch(err => {
            if (!isMounted.current) {
                return;
            }
            if (err.response) {
                handleErrorResponse(err.response, response => {
                    switch (response?.status) {
                        case 403:
                            setError(t("validation.403"), false, "");
                            break;
                        default:
                            setError(response,
                                false,
                                `${t("error")} ${response?.status ?? ''},
                                ${t("appPassword.deleting_password")}`);
                    }
                });
            } else {
                setError("appPassword.error_deleting_password");
            }
        });
    }, [selectionModel, isOrganizationPass, isMounted, updateTable, handleErrorResponse, setError, t]);

    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'name', headerName: t("appPassword.name"), width: 340},
        {
            field: 'date', headerName: t("appPassword.created"), width: 240,
            renderCell: (params) => (
                <Typography>{getFormattedUtcDate(params.row.created)}</Typography>
            )
        }
    ];

    const organizationColumns = [...columns,
        {
            field: 'key', headerName: t("appPassword.clientId"), width: 440,
            renderCell: (params) => (
                <div
                    onClick={ (event) =>{
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onMouseEnter={
                        () => setShowCopyButtonKey(params.row.id)
                    }
                    onMouseLeave={
                        () => setShowCopyButtonKey(null)
                    }
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography>{params.row.key}</Typography>

                    {showCopyButtonKey === params.row.id &&
                        <CopyToClipboard text={params.row.key} onCopy={() =>setInfo("appPassword.clipboardClientId")}>
                            <IconButton aria-label="copy" style={{color: "#1A23D0"}}>
                                <ContentCopyIcon/>
                            </IconButton>
                        </CopyToClipboard>
                    }

                </div>
            )
        }];

    return (
        <>
            <Card className={className}>
                {loading && <CardViewLoading/>}
                {!loading && error === "" &&
                    <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}
                          alignItems={"center"}>
                        <Grid item xs={12} sm={12} md={4} lg={6}>
                            <Typography className={classes.organizationName}
                                        variant={"h4"}>{t("appPassword.app_password")}</Typography>
                        </Grid>

                        {(!isOrganizationPass || (isOrganizationPass && hasPermission(WRITE_ORGANIZATION_CONFIG))) && <>

                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Button
                                    fullWidth
                                    data-testid={"create-password-button"}
                                    disabled={deleting}
                                    onClick={handleNewAppPassword}
                                    color={"primary"}
                                    variant="contained"
                                    startIcon={<VpnKeyIcon/>}
                                >
                                    {t("appPassword.create_app_password")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Button
                                    fullWidth
                                    data-testid={"delete-password-button"}
                                    disabled={!enableDelete || deleting}
                                    onClick={handleDelete}
                                    variant="contained"
                                    className={classes.deleteButton}
                                    startIcon={<DeleteIcon/>}
                                >
                                    {t("delete")}
                                </Button>
                            </Grid>
                        </>}
                        {(isOrganizationPass && !hasPermission(WRITE_ORGANIZATION_CONFIG)) &&
                            <Grid item xs={12} sm={12} md={8} lg={6}/>}

                        <Grid item container xs={12} style={{paddingTop: 4}}>
                            <Button
                                startIcon={<DescriptionOutlinedIcon/>}
                                onClick={handleApiDocumentation}
                            >
                                {t("dashboardMenu.api_manual")}
                            </Button>
                        </Grid>

                        <Grid item xs={12} className={classes.rowTable}>
                            <DataGrid
                                loading={deleting}
                                className={classes.table}
                                rows={passwords}
                                columns={(isOrganizationPass ? organizationColumns : columns).map((column) => {
                                        return {
                                            ...column,
                                            disableClickEventBubbling: column.field === "registered" || column.field === "key"
                                        };
                                    }
                                )}
                                pageSize={7}
                                rowsPerPageOptions={[7]}
                                Name="dataGrid1"
                                selectionModel={selectionModel}
                                onSelectionModelChange={onSelectionModelChange}
                                checkboxSelection density={"standard"}
                            />
                        </Grid>
                    </Grid>}
                {!loading && error !== "" && <DataNotFound message={error}/>}
            </Card>
            {createdPassword !== "" &&
                <CreatedPasswordPopUp password={createdPassword} handleClose={handlePasswordDisplayClosed}/>}

            {openConfigPopUp && <AppPasswordConfigPopUp open={openConfigPopUp}
                                                        finishAppPasswordSetup={finishAppPasswordSetup}
                                                        cancelAppPasswordSetup={cancelAppPasswordSetup}/>}
            {openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} title={t("appPassword.delete_app_password")}
                                                 question={t("appPassword.are_you_sure_to_delete")}
                                                 confirm={confirmDelete}/>
            }
        </>
    );
};

export default AppPasswordCardView;
