import {Checkbox, FormControlLabel, Grid, ToggleButton, ToggleButtonGroup, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import PeriodList from "./list/PeriodList";
import React, {useCallback, useState} from "react";
import Calendar from "./calendar/Calendar";
import {useTheme} from "@mui/styles";
import {usePeriodContext} from "./common/PeriodContext";

const PeriodTabs = () => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const isExtraWideScreen = useMediaQuery(theme.breakpoints.up("xxl"));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const {filterByCalendar, setFilterByCalendar} = usePeriodContext();

    const handleFilterByCalendarChanged = useCallback(()=>{
        setFilterByCalendar(!filterByCalendar);
    },[filterByCalendar, setFilterByCalendar]);

    return (
        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}>
            {!isWideScreen && <Grid item xs={12} display="flex" justifyContent="center">
                <ToggleButtonGroup
                    style={{height: 40, marginBottom: 16}}
                    color="primary"
                    value={activeTab}
                    onChange={(e, value) => setActiveTab(value)}
                    exclusive
                >
                    <ToggleButton size={"small"} style={{flex: 1, maxWidth: 150}} value={0}>
                        {t("validation.calendar")}
                    </ToggleButton>
                    <ToggleButton size={"small"} style={{flex: 1, maxWidth: 150}} value={1}>
                        {t("validation.history")}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>}
            {isWideScreen && <Grid item xs={12} container justifyContent="flex-end" style={{padding: 0}}>
                <FormControlLabel
                                  control={<Checkbox checked={filterByCalendar} onClick={handleFilterByCalendarChanged}/>}
                                  label={t("validation.filterWithCalendar")}
                                  labelPlacement="start"
                />
            </Grid>}


            {(activeTab === 0 || isWideScreen) && (
                <Grid item xs={12} lg={5} xl={4} xxl={6} display="flex" justifyContent="center">
                    <Calendar months={isExtraWideScreen || (!isWideScreen && !isSmallScreen) ? 2 : 1}/>
                </Grid>
            )}
            {(activeTab === 1 || isWideScreen) && (
                <Grid item xs={12} lg={7} xl={8} xxl={6}>
                    {!isWideScreen && <FormControlLabel style={{marginBottom:16}}
                        control={<Checkbox checked={filterByCalendar} onClick={handleFilterByCalendarChanged}/>}
                        label={t("validation.filterWithCalendar")}
                    />}
                    <PeriodList/>
                </Grid>
            )}
        </Grid>
    );
};

export default PeriodTabs;
