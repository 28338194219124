import {useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TextField} from "@mui/material";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

const TimePickerField = ({size, variant, value, onChange, ...props}) => {
    const [inputValue, setInputValue] = useState(value);

    const handleChange = (newValue) => {
        setInputValue(newValue);
        // Check if the value is a valid date
        if (newValue instanceof Date && !isNaN(newValue)) {
            onChange(newValue);
        } else {
            onChange(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                value={inputValue}
                onChange={handleChange}
                ampm={false}
                renderInput={(params) => (
                    <TextField size={size} variant={variant} {...params}/>
                )}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default TimePickerField;