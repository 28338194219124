import {webClient} from "../../config/axios";

const deleteLogRequest = (list) => {
    let deletePromises = [];
    list.forEach((logId)=>{
        deletePromises.push(webClient.delete(`stations/log/${logId}`));
    });
    return Promise.all(deletePromises);
};

export default deleteLogRequest;