import React, {useCallback} from 'react';
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setDownloadProgressMinimizedStateAction} from "../../../../../reducers/downloadReducer";

const useStyles = makeStyles((theme) => ({
    progressBase: {
        color:"#bebebe",
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:"100%",
        width:"100%"
    },
    progressTop:{
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:"100%",
        width:"100%"
    },
    buttom:{
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:20,
        width:10,
    }
    ,
    root:{
        zIndex:5000,
        position:"absolute",
        bottom:32,
        justifyContent:"center",
        display:"flex",
        alignItems:"center",
        background:"white",
        borderRadius:"50%",
        width:18,
        height:18,
        padding:16,
        right:8,
        boxShadow: "0 3px 3px rgba(0,0,0,0.4)"

    }
}));

const MinimizedProgress = () => {

    const dispatch = useDispatch();
    const {downloadProcessList } = useSelector( state => state.download );

    const classes = useStyles();

    const getMinProgress = useCallback(()=>{
        let max = 200;
        downloadProcessList.forEach(item => {
                if (item.progress < max) {
                    max = item.progress;
                }
            }
        );
        return max;
    },[downloadProcessList]);

    const handleMaximize = ()=>{
        dispatch(setDownloadProgressMinimizedStateAction(false));
    };

    return (
        <div className={classes.root}>
            <CircularProgress variant="determinate"
                              value={100}
                              thickness={4}
                              className={classes.progressBase}
            />
            <CircularProgress variant="determinate" value={getMinProgress()}
                              thickness={4}
                              className={classes.progressTop}
            />
            <Button style={{maxWidth: '10px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                    disableRipple ={true}
                    fullWidth className={classes.buttom}
                    onClick={handleMaximize}>
                {`${Math.round(getMinProgress())}%`}
            </Button>
        </div>
    );
};

export default MinimizedProgress;
