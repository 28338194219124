import {sensor} from "../../../constants";

export const pollutantNames = new Map([
    [sensor.no2, "NO₂"],
    [sensor.co2, "CO₂"],
    [sensor.co, "CO"],
    [sensor.o3, "O₃"],
    [sensor.no, "NO"],
    [sensor.so2, "SO₂"],
    [sensor.pm1, "PM₁"],
    [sensor.pm2p5, "PM₂․₅"],
    [sensor.pm4, "PM₄"],
    [sensor.pm10, "PM₁₀"],
    [sensor.h2s, "H₂S"],
    [sensor.nh3, "NH₃"],
    [sensor.temperature, "Temp"],
    [sensor.temperature_meteo, "Temp Meteo"],
    [sensor.rh, "RH"],
    [sensor.rh_meteo, "RH Meteo"],
    [sensor.ah, "AH"],
    [sensor.noise, "Ambient Noise"],
    [sensor.hcl, "HCl"],
    [sensor.voc_iaq, "VOC IAQ"],
    [sensor.voc, "VOC"],
    [sensor.pressure, "Pressure"],
    [sensor.equivalent_pressure, "Eq. Pressure"],
    [sensor.wind, "Wind"],
    [sensor.wind_speed, "Wind Speed"],
    [sensor.wind_gust, "Wind Gust"],
    [sensor.wind_direction, "Wind Direction"],
    [sensor.rain_rate, "Rain Rate"],
    [sensor.uv_index, "UV Index"],
    [sensor.solar_radiation, "Solar Radiation"],
    [sensor.aqi, "Air Quality Index"],
]);

export const getVariableName = (variable, index = null)=> {
    if (variable === sensor.aqi && index !== null) {
        return `${index === "AQHI-HK" ? "AQHI" : index === "AQI-QB" ? "AQI": index}`;
    }
    let output = pollutantNames.get(variable);
    return output !== undefined ? output : `Unhandled type ${variable}`;
};
