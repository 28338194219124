import {
    MODEL_EXTERNAL_PREFIX,
    stationState,
    airQualityIndexColors,
    STATION_DEFAULT_COLOR,
    STATION_SELECTED_COLOR, NO_INDEX_TYPE
} from "../constants";
import {DARK_GRAY, GRAY, TEXT_COLOR_CONTRAST} from "../components/routes/private/home/legend/colors";
import getIconStyle from "./getIconStyle";
import {getIndexRange, getValueColorPosition} from "./airQualityIndexUtil";

const MAX_HOUR_DIFFERENCE = 4;
const MAX_EXT_HOUR_DIFFERENCE = 24;

const applyMarkerStyle = (el, { model, index, state, lastData }, variable, selected) => {
    const value = index;
    const isExternal = model?.startsWith(MODEL_EXTERNAL_PREFIX);
    const markerSize = 20;
    const iconPadding = isExternal ? 8 : 4;
    const borderRadius = isExternal ? 0 : '100%';

    const subEl = document.createElement('div');
    el.appendChild(subEl);

    el.style.width = `${markerSize}px`;
    el.style.height = `${markerSize}px`;
    el.style.display = 'flex';
    el.style.alignItems = 'center';
    el.style.justifyContent = 'center';

    let backgroundColor = '';
    let backgroundUrl = '';
    let borderColor = '';
    let borderSize = null;
    let textColor = '';
    let opacity = '1';

    const now = new Date();

    const isModelExternal = model.includes("EXT");
    const maxHourDifference = isModelExternal ? MAX_EXT_HOUR_DIFFERENCE : MAX_HOUR_DIFFERENCE;

    if (state === stationState.offline) {
        subEl.innerText = "!";
        textColor = TEXT_COLOR_CONTRAST;
        backgroundColor = DARK_GRAY;
        opacity = 0.8;
    } else if (variable === NO_INDEX_TYPE) {
        backgroundColor = STATION_DEFAULT_COLOR;
    } else if (value === undefined ||
        new Date(lastData).getTime() < now - maxHourDifference * 60 * 60 * 1000) {
        subEl.innerText = "?";
        textColor = TEXT_COLOR_CONTRAST;
        backgroundColor = GRAY;
    } else {
        const rangeValues = getIndexRange(variable);
        const colorPosition = getValueColorPosition(value, rangeValues);
        backgroundColor = airQualityIndexColors[variable][colorPosition];
    }
    if (selected) {
        backgroundColor = STATION_SELECTED_COLOR;
        opacity = 1;
    }

    const iconSize = backgroundColor ? markerSize - iconPadding : markerSize;
    const style = getIconStyle({
        iconSize, backgroundUrl, backgroundColor, borderRadius, borderColor, borderSize, textColor, opacity
    });
    Object.assign(subEl.style, style);

    return el;
};

export default applyMarkerStyle;
