import {Fade, Paper, Popper} from "@mui/material";
import {useRef, useState} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    dottedText: {
        textDecorationStyle: "dotted",
        textDecorationLine: "underline",
        textUnderlineOffset: "4px",
        cursor: "pointer"
    },
    paper: {
        maxWidth: 300,
        padding: 8
    }
});

const TableHeaderTitle = ({children, helperText}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const hasHelperText = helperText?.length > 0;

    const handleMouseEnter = () => {
        if (hasHelperText) {
            setOpen(true);
        }
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    return (
        <>
            <span ref={anchorRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span className={hasHelperText ? classes.dottedText : ""}>
                    {children}
                </span>
            </span>
            <Popper id={helperText} open={open} anchorEl={anchorRef?.current} transition sx={{zIndex: 10}}
                    popperOptions={{
                        modifiers: [{
                            name: 'offset',
                            options: {
                                offset: [0, 8]
                            }
                        }]
                    }}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.paper} elevation={2}>
                            {helperText}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default TableHeaderTitle;