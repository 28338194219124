import {useCallback, useMemo, useState} from "react";
import {usePeriodContext} from "../common/PeriodContext";
import moment from "moment-timezone";
import {timeZoneOptions, VALIDATION_CHART, VALIDATION_TABLE} from "../../../../../constants";
import {useSelector} from "react-redux";
import SensorDataChart from "../../analytics/sensor_data_card/SensorDataChart";
import useTableData from "./dataTable/useTableData";
import useTableDataSelection from "./dataTable/useTableDataSelection";

const plotConfig = {
    displaylogo: false,
    displayModeBar: false
};

const PeriodDetailChart = () => {
    const [event, setEvent] = useState(null);
    const {selectedStation, stationSensors, selectedSensors, setSelectedSensors, rawData, loading,
        setSelectedPositionDate, dataParams, cursorPosition, setCursorPosition} = usePeriodContext();
    const {dateToRowIndex} = useTableData();
    const {addMatrixToSelection} = useTableDataSelection();
    const {timeZone: dataTimeZone} = useSelector(state => state.auth);
    const selectedStationData = useSelector(state => (
        state.dashboardUI.stations.find(({id}) => selectedStation === id)
    ));
    const timeZone =
        (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData?.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;

    const setCursorPositionByXString = useCallback((xString) => {
        const selectedDate = moment.tz(xString, timeZone).toDate();
        setCursorPosition({date: selectedDate, location: VALIDATION_CHART});
    }, [timeZone, setCursorPosition]);

    const setSelectedPositionDateByXString = useCallback((xString) => {
        const selectedDate = moment.tz(xString, timeZone).toDate();
        setSelectedPositionDate(selectedDate);
    }, [timeZone, setSelectedPositionDate]);

    const onChartHover = useCallback((event) => {
        if (event.points.length > 0) {
            setCursorPositionByXString(event.points[0].x);
        }
    }, [setCursorPositionByXString]);

    const onChartUnhover = useCallback((event) => {
        setCursorPosition({date: null, location: null});
        setEvent(event?.event);
    }, [setCursorPosition]);

    const onChartClick = useCallback((event) => {
        if (event.points.length > 0) {
            setSelectedPositionDateByXString(event.points[0].x);
        }
    }, [setSelectedPositionDateByXString]);

    const onSelect = useCallback((selection, lastXPosition) => {
        const startRowIndex = dateToRowIndex(selection.start);
        const endRowIndex = dateToRowIndex(selection.end);
        const selectionColumns = selectedSensors.map(sensor => stationSensors.findIndex(id => id === sensor));
        const selectionRow = Array.from({length: stationSensors.length});
        selectionColumns.forEach(column => selectionRow[column] = true);
        const selectionMatrix = [];
        for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
            selectionMatrix[rowIndex] = selectionRow;
        }
        addMatrixToSelection(selectionMatrix, event);
        setCursorPositionByXString(lastXPosition);
        setSelectedPositionDateByXString(lastXPosition);
    }, [dateToRowIndex, stationSensors, selectedSensors, addMatrixToSelection, event, setCursorPositionByXString,
        setSelectedPositionDateByXString]);

    const cursorInterval = useMemo(() => {
        if (cursorPosition.date && dataParams.resolution && cursorPosition.location === VALIDATION_TABLE) {
            const nextDate = new Date(cursorPosition.date.getTime() + dataParams.resolution);
            return {start: cursorPosition.date, end: nextDate};
        }
    }, [cursorPosition, dataParams.resolution]);

    return (
        <SensorDataChart height={400} loading={loading} rawData={rawData} dataTimeZone={dataTimeZone}
                         stationSensors={stationSensors} selectedSensors={selectedSensors}
                         setSelectedSensors={setSelectedSensors} onClick={onChartClick} onHover={onChartHover}
                         onUnHover={onChartUnhover} onSelect={onSelect} cursorInterval={cursorInterval}
                         config={plotConfig} />
    );
};

export default PeriodDetailChart;
