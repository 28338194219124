export const alarmOwner = {
    organization: "Organization",
    userAlarm: "UserAlarm"
};

export const alarmType = {
    threshold: "threshold",
    state: "state"
};

export const triggerTypes = {
    lt: "lt",
    lte:"lte",
    gt:"gt",
    gte:"gte",
};

