import {useRef, useEffect, useState} from 'react';

const usePrevious = (callback, deps) => {
    const callbackRef = useRef(null);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const depsRef = useRef(null);

    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (!initial && depsRef.current !== null && callbackRef.current !== null) {
            callbackRef.current(...depsRef.current);
        }

        depsRef.current = deps;
        setInitial(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default usePrevious;