import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {useMap} from "react-map-gl";
import {lineString, bbox} from "@turf/turf";
import {ButtonGroup, Button, Tooltip} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";


const ANIMATION_DURATION = 1500;
const STATION_LEVEL_ZOOM = 15;

const useStyles = makeStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: 10,
        left: 10
    },
    buttonGroup: {
        zIndex: 1,

        "& .MuiButtonGroup-grouped": {
            minWidth: "auto",
            fontSize: "1.0rem",
            padding: "0.2rem 0.4rem"
        }
    }
});


const MapControls = ({disabled, canSelectAll, stations, selectedStations, handleSelectAll, handleUnselectAll}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {selectorMap: map} = useMap();
    const defaultMapPosition = useSelector(state => state.auth.organization.map);

    const handleCenterSelection = useCallback(() => {
        if (selectedStations.length === 1) {
            const {position: {lat, long}} = stations.find(station => station.id === selectedStations[0]);
            map.flyTo({center: [long, lat], zoom: STATION_LEVEL_ZOOM, duration: ANIMATION_DURATION});
        } else {
            const stationsToCenter = selectedStations.length >= 2 ? selectedStations : stations.map(station => station.id);
            const coordinates = stationsToCenter.map(stationId => {
                const station = stations.find(station => station.id === stationId);
                return station && [station.position.long, station.position.lat];
            }).filter(coordinate => coordinate);
            const boundingBox = bbox(lineString(coordinates));
            map.fitBounds(boundingBox, {padding: 50, duration: ANIMATION_DURATION});
        }
    }, [selectedStations, stations, map]);

    const buttonArray = useMemo(() => [
        {
            label: t("selector.default_position"),
            Icon: HomeIcon,
            buttonDisabled: disabled,
            testId: "default-position-button",
            onClick: () => {
                map.flyTo({
                    center: [defaultMapPosition?.center.lon, defaultMapPosition?.center.lat],
                    zoom: defaultMapPosition?.zoom,
                    duration: ANIMATION_DURATION
                });
            }
        },
        {
            label: t("selector.center_stations"),
            Icon: GpsFixedIcon,
            buttonDisabled: disabled || stations.length < 2,
            testId: "center-stations-button",
            onClick: handleCenterSelection
        },
        {
            label: t("action.clear_selection"),
            Icon: DeselectIcon,
            buttonDisabled: disabled,
            testId: "clear-selection-button",
            onClick: handleUnselectAll
        },
        ...(canSelectAll ? [{
            label: t("action.select_all"),
            Icon: SelectAllIcon,
            buttonDisabled: disabled,
            testId: "select-all-button",
            onClick: handleSelectAll
        }] : [])
    ], [t, map, disabled, defaultMapPosition, stations, canSelectAll, handleCenterSelection, handleUnselectAll,
        handleSelectAll]);

    return (
        <div className={classes.container}>
            <ButtonGroup variant="contained" size="small" className={classes.buttonGroup}>
                {buttonArray.map(({Icon, label, onClick, testId, buttonDisabled}) => (
                    <Tooltip title={label} key={label} arrow disableInteractive>
                        <Button onClick={onClick} disabled={buttonDisabled} data-testid={testId}>
                            <Icon fontSize="inherit" />
                        </Button>
                    </Tooltip>
                ))}
            </ButtonGroup>
        </div>
    );
};

export default MapControls;
