import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const batteryRequest = (stationId, hours) => (
    new Promise((resolve, reject) => {
        const params = `?hours=${hours}`;
        const url = `/station-data/${stationId}/battery/${params}`;

        webClient.get(url)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                handleError(err, (_, error) => reject(error));
            });
    })
);
