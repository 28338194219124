import React, {useCallback, useEffect} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import validator from "validator";
import ReportParamsConfig from "./ReportParamsConfig";
import {localToUTCDate} from "../../../../../utils/dateUtil";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReportSteps from "./ReportSteps";
import Step2 from "../../download/steps/Step2";
import VariableStep from "./VariableStep";
import {DEFAULT_POPUP_WIDTH} from "../../../../../constants";
import SectionsStep from "./SectionsStep";
import {frequencyOptions} from "../autoReportControls/frequency/AutoReportsFrequencySelector";
import {periodTypeOptions} from "../autoReportControls/duration/AutoReportsDurationSelector";


const getCronExpression = (frequency, weekDay, monthFrequency) => {

    if (frequency === frequencyOptions.twiceAMonth) return "1,15 * *";

    if (frequency === frequencyOptions.monthlyFrequency) return `1 */${monthFrequency} *`;

    return `* * ${weekDay}`;
};

const getAutoReportPeriodInDays = (periodType, numberOfDays, numberOfMonths) => {
    const value = periodType === periodTypeOptions.dayPeriod ? numberOfDays : numberOfMonths;
    return {periodType, value};
};


export const YESTERDAY = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);

export const isCurrentPeriodValid = (date, endDate) => {
    return date?.getTime() < endDate?.getTime();
};

const useStyles = makeStyles({
    steps: {
        marginRight: 20,
        marginLeft: 20
    },
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    title: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    popUpContainer: {
        maxWidth: DEFAULT_POPUP_WIDTH
    },
    step1Container: {
        padding: 30,
        maxWidth: 1000,
        justifyContent: "center"
    },
    step2Container: {
        padding: 30,
        maxWidth: 700,
        justifyContent: "center"
    }
});

const ReportConfigPopUp = ({finishReportSetup, cancelReportSetup, isAutoReport}) => {

    const {t} = useTranslation();
    const currentDate = new Date();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {units} = useSelector(state => state.auth);
    const [{
        name,
        title,
        titleEnabled,
        initDate,
        finalDate,
        pollutants,
        temperature,
        index,
        windSpeed,
        excludeInvalidData,
        activeStep,
        selectedStations,
        selectedVariables,
        selectedSections,
        step1Valid,
        step2Valid,
        step3Valid,
        step4Valid,
        frequency,
        duration,
        weekDay,
        monthFrequency,
        numberOfDays,
        numberOfMonths,
        cronExpression,
        autoReportPeriod,
        timeZoneError
    }, updateState] = React.useState({
        name: "",
        title: "",
        titleEnabled: false,
        initDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() - 1),
        finalDate: new Date(YESTERDAY),
        pollutants: units.pollutants,
        temperature: units.temperature,
        index: units.index,
        windSpeed: units.windSpeed,
        excludeInvalidData: false,
        activeStep: 0,
        selectedStations: [],
        selectedVariables: [],
        selectedSections: null,
        step1Valid: false,
        step2Valid: false,
        step3Valid: false,
        step4Valid: false,
        frequency: frequencyOptions.weeklyFrequency,
        duration: periodTypeOptions.dayPeriod,
        weekDay: 1,
        monthFrequency: 1,
        numberOfDays: 7,
        numberOfMonths: 1,
        cronExpression: getCronExpression(frequencyOptions.weeklyFrequency, 1, 1),
        autoReportPeriod: getAutoReportPeriodInDays(periodTypeOptions.dayPeriod, 7, 1),
        timeZoneError: false
    });

    useEffect(() => {
        updateState(state => ({
            ...state,
            autoReportPeriod: getAutoReportPeriodInDays(duration, numberOfDays, numberOfMonths)
        }));
    }, [duration, numberOfDays, numberOfMonths]);

    useEffect(() => {
        updateState(state => ({...state, cronExpression: getCronExpression(frequency, weekDay, monthFrequency)}));
    }, [frequency, weekDay, monthFrequency]);

    const weekDayCallback = useCallback((event) => {
        updateState(state => ({...state, weekDay: event.target.value}));
    }, []);

    const monthFrequencyCallback = useCallback((event) => {
        updateState(state => ({...state, monthFrequency: event.target.value}));
    }, []);

    const numberOfDaysCallback = useCallback((event) => {
        updateState(state => ({...state, numberOfDays: event.target.value}));
    }, []);

    const numberOfMonthsCallback = useCallback((event) => {
        updateState(state => ({...state, numberOfMonths: event.target.value}));
    }, []);

    const frequencyCallback = useCallback((event) => {
        updateState(state => ({...state, frequency: event.target.value}));
    }, []);

    const durationCallback = useCallback((event) => {
        updateState(state => ({...state, duration: event.target.value}));
    }, []);

    const indexCallback = useCallback((event) => {
        updateState(state => ({...state, index: event.target.value}));
    }, []);

    const windSpeedCallback = useCallback((event) => {
        updateState(state => ({...state, windSpeed: event.target.value}));
    }, []);

    const nameCallback = useCallback((event) => {
        // Remove line breaks
        const name = event.target.value.replace(/(\r\n|\n|\r)/gm, "");
        updateState(state => ({...state, name}));
    }, []);

    const titleCallback = useCallback((event) => {
        // Remove line breaks
        const title = event.target.value.replace(/(\r\n|\n|\r)/gm, "");
        updateState(state => ({...state, title}));
    }, []);

    const titleEnabledCallback = useCallback((event) => {
        updateState(state => ({...state, titleEnabled: event.target.checked}));
    }, []);

    const tempUnitCallback = useCallback((event) => {
        updateState(state => ({...state, temperature: event.target.value}));
    }, []);

    const unitsCallback = useCallback((event) => {
        updateState(state => ({...state, pollutants: event.target.value}));
    }, []);

    const initDateCallback = useCallback((value) => {
        updateState(state => ({...state, initDate: localToUTCDate(value, timeZone)}));
    }, [timeZone]);

    const finalDateCallback = useCallback((value) => {
        updateState(state => ({...state, finalDate: localToUTCDate(value, timeZone)}));
    }, [timeZone]);

    const excludeInvalidDataCallback = useCallback((event) => {
        updateState(state => ({...state, excludeInvalidData: event.target.checked}));
    }, []);

    //Validate step 1
    useEffect(() => {
        const valid = !validator.isEmpty(name) && (!titleEnabled || !validator.isEmpty(title)) &&
            isCurrentPeriodValid(initDate, finalDate);
        updateState(state => ({...state, step1Valid: valid}));
    }, [name, titleEnabled, title, initDate, finalDate]);

    //Step 2 callbacks
    const selectedStationsCallback = useCallback((stations) => {
        const timeZoneError = stations.filter(item => !item.timeZone).length > 0;
        updateState(state => ({
            ...state,
            timeZoneError,
            selectedStations: stations,
            step2Valid: stations.length > 0 && !timeZoneError
        }));
    }, []);

    //Step 3 callbacks
    const selectedVariablesCallback = useCallback(variables => {
        if (!shallowEqual(variables.map(v => v.id), selectedVariables.map(v => v.id))) {
            updateState(state => ({...state, selectedVariables: variables, step3Valid: variables.length > 0}));
        }
    }, [selectedVariables]);

    //Step 4 callbacks
    const selectedSectionsCallback = useCallback((sections) => {
        if (!shallowEqual(sections.map(s => s.id), selectedSections?.map(s => s.id))) {
            updateState(state => ({...state, selectedSections: sections, step4Valid: sections.length > 0}));
        }
    }, [selectedSections]);

    const classes = useStyles();

    const handleFirstButton = () => {
        if (activeStep === 0) {
            cancelReportSetup();
        } else {
            updateState(state => ({...state, activeStep: activeStep - 1}));
        }
    };

    const handleSecondButton = useCallback(() => {
        if (activeStep < 3) {
            updateState(state => ({...state, activeStep: activeStep + 1}));
        } else {

            const body = {
                name,
                title: title.length > 0 ? title : undefined,
                time: initDate,
                endTime: finalDate,
                pollutants,
                temperature,
                windSpeed,
                index,
                stations: selectedStations.map(item => item.id),
                sensors: selectedVariables.map(item => item.id),
                sections: selectedSections.map(item => item.id),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                excludeInvalidData
            };

            if (isAutoReport) {
                body.autoReport = true;
                body.enabled = true;
                body.period = autoReportPeriod;
                body.cron = cronExpression;
            }
            finishReportSetup(body);
        }
    },[activeStep, autoReportPeriod, cronExpression, finalDate, finishReportSetup, index, initDate, isAutoReport, name,
        pollutants, selectedSections, selectedStations, selectedVariables, temperature, title, windSpeed,
        excludeInvalidData]);

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card} data-testid={"create-report-popup"}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.title}>{t("reportScreen.reportConfig.title")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                        <ReportSteps activeStep={activeStep}/>
                    </Grid>
                    {activeStep === 0 && <Grid container item xs={12} className={classes.step1Container}>
                        <ReportParamsConfig
                            isAutoReport={isAutoReport}
                            name={name} nameCallback={nameCallback}
                            title={title} titleCallback={titleCallback}
                            titleEnabled={titleEnabled} titleEnabledCallback={titleEnabledCallback}
                            pollutantUnit={pollutants}
                            tempUnits={temperature}
                            tempoCallback={tempUnitCallback}
                            pollutantUnitsCallback={unitsCallback}
                            initialDate={initDate}
                            updateInitialDate={initDateCallback}
                            finalDate={finalDate}
                            updateFinalDate={finalDateCallback}
                            index={index}
                            indexCallback={indexCallback}
                            windSpeedCallback={windSpeedCallback}
                            windSpeedUnit={windSpeed}
                            frequency={frequency}
                            frequencyCallback={frequencyCallback}
                            duration={duration}
                            durationCallback={durationCallback}
                            weekDay={weekDay}
                            monthFrequency={monthFrequency}
                            numberOfDays={numberOfDays}
                            numberOfMonths={numberOfMonths}
                            weekDayCallback={weekDayCallback}
                            monthFrequencyCallback={monthFrequencyCallback}
                            numberOfDaysCallback={numberOfDaysCallback}
                            numberOfMonthsCallback={numberOfMonthsCallback}
                            excludeInvalidData={excludeInvalidData}
                            excludeInvalidDataCallback={excludeInvalidDataCallback}
                        />
                    </Grid>}
                    {activeStep === 1 &&
                        <Grid container item xs={12} className={classes.step2Container}>
                            <Step2 initialSelection={selectedStations} callback={selectedStationsCallback}/>
                            {timeZoneError && <Grid item xs={12}>
                                <Typography variant={"body2"}
                                            color={"error"}>{t("reportScreen.reportConfig.timeZoneError")}</Typography>
                            </Grid>}
                        </Grid>
                    }
                    {activeStep === 2 && <Grid container item xs={12} className={classes.step2Container}>
                        <VariableStep selectedStations={selectedStations}
                                      initialSelection={selectedVariables} callback={selectedVariablesCallback}/>
                    </Grid>}
                    {activeStep === 3 && <Grid container item xs={12} className={classes.step2Container}>
                        <SectionsStep selectedStations={selectedStations}
                                      initialSelection={selectedSections} callback={selectedSectionsCallback}/>
                    </Grid>}
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelReportSetup}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"next-finish-report-config"}
                                disabled={activeStep === 0 ? !step1Valid : activeStep === 1 ? !step2Valid :
                                    activeStep === 2 ? !step3Valid : !step4Valid}
                                onClick={handleSecondButton}>{activeStep < 3 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default ReportConfigPopUp;
