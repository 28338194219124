import React from "react";
import {useTranslation} from "react-i18next";
import LoadingButton from "./LoadingButton";

const SaveButton = ({ loading, saving, onSave, ...props }) => {
    const {t} = useTranslation();
    return (
        <LoadingButton {...props} loading={loading || saving} customLoadingText={`${t("action.saving")}...`}
                       onClick={onSave}>
            {t("action.save")}
        </LoadingButton>
    );
};

export default SaveButton;