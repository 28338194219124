import React, {useMemo} from 'react';
import clsx from "clsx";
import {Divider, Grid, IconButton, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from './MapLegend.module.scss';
import getIconStyle from "../../../../../utils/getIconStyle";
import {DARK_GRAY, GRAY, LIGHT_GRAY, TEXT_COLOR_CONTRAST, WHITE} from "./colors";
import {useMapContext} from "../MapContext";
import PollutantRoseLegend from "./PollutantRoseLegend";
import {POLLUTANT_ROSE_MAP} from "../panel/StationDataPanel";
import {getVariableName} from "../../pollutantNames";
import AirQualityIndexLegend from "./AirQualityIndexLegend";
import {indexTypes} from "../../../../../constants";

const MapLegend = ({ indexType }) => {

    const { t } = useTranslation();
    const { downloadProcessList, minimized } = useSelector(state => state.download);
    const { selectedPollutant, stationLayerActive, pollutantRosesLayerActive, roseLegendData, showOfflineStationsOn,
        legendMinimized, setLegendMinimized } = useMapContext();

    const legendTitle = useMemo(() => {
        const allIndicesButDIBA = Object.keys(indexTypes).filter(item => item !== indexTypes.DIBA);
        let acronym = allIndicesButDIBA.includes(indexType) ? ` (${indexType})`: "";
        if(acronym.includes("AQHI-HK"))
            acronym = ' (AQHI)';
        if(acronym.includes("AQI-QB"))
            acronym = ' (AQI)';
        return `${t(`legend.${indexType}`)}${acronym}`;
    }, [indexType, t]);

    const handleMinimize = () => {
        setLegendMinimized(true);
    };

    const handleMaximize = () => {
        setLegendMinimized(false);
    };

    const statusClass = downloadProcessList.length && (
            minimized ? styles.minimized :
                downloadProcessList.length === 1 ? styles.length1 :
                downloadProcessList.length === 2 ? styles.length2 :
                styles.length3orMore
        );

    const legendItems = [
        {
            icon: {
                iconSize: 16,
                backgroundColor: WHITE,
                borderRadius: '100%',
                borderColor: LIGHT_GRAY
            },
            text: t("legend.bettair_station")
        },
        {
            icon: {
                iconSize: 12,
                backgroundColor: WHITE,
                borderRadius: 0,
                borderColor: LIGHT_GRAY
            },
            text: t("legend.external_source")
        },
        {
            divider: true
        },
        {
            icon: {
                iconSize: 16,
                backgroundColor: GRAY,
                borderRadius: '100%',
                textColor: TEXT_COLOR_CONTRAST
            },
            iconText: '?',
            text: t("legend.no_data")
        },
        ...(showOfflineStationsOn ? [{
            icon: {
                iconSize: 16,
                backgroundColor: DARK_GRAY,
                borderRadius: '100%',
                textColor: TEXT_COLOR_CONTRAST,
                opacity: 0.8
            },
            iconText: '!',
            text: t("legend.no_connection")
        }] : [])
    ];

    const canShowLegend = stationLayerActive || (pollutantRosesLayerActive && roseLegendData);

    return canShowLegend ? (
        <div className={styles.boxContainer}>
            {!legendMinimized &&
                <div className={clsx(styles.box, statusClass)}>
                    {stationLayerActive && <>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}
                            style={{padding: 0, minWidth: 365}}>
                            <Grid item>
                                <Typography variant={"h6"}
                                            className={styles.mainTitle}>{legendTitle}</Typography>
                            </Grid>
                            <AirQualityIndexLegend indexType={indexType}/>
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{margin: "0 10px"}} />
                    </>}
                    {pollutantRosesLayerActive && (
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"
                                style={{minWidth: 150}}>
                            <Grid item>
                                <Typography variant={"h6"} className={styles.mainTitle}>
                                    {t(`panel.station_data_layer.${POLLUTANT_ROSE_MAP}`)}{" "}
                                    {getVariableName(selectedPollutant)}
                                </Typography>
                            </Grid>
                            <PollutantRoseLegend data={roseLegendData}/>
                        </Grid>
                    )}
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-end">
                        <Grid item>
                            <IconButton aria-label="minimize" onClick={handleMinimize}>
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                        {stationLayerActive && (
                            <Grid item container alignItems={"flex-start"} rowSpacing="6px" style={{minWidth: 150}}>
                                {legendItems.map((item, index) => item.divider ? (
                                    <Divider style={{width:'100%', margin:'7px 0 0'}} key={index} />
                                ) : (
                                    <Grid item container direction="row" columnSpacing="5px" key={index}>
                                        <Grid item container xs={2}
                                              justifyContent={"center"} alignItems={"center"}>
                                            <div style={getIconStyle(item.icon)}>{item.iconText}</div>
                                        </Grid>
                                        <Grid item container xs={9} alignItems={"center"}>
                                            <Typography variant="body2">
                                                {item.text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </div>
            }
            {legendMinimized &&
                <div className={clsx(styles.infoButton, statusClass)}>
                    <IconButton onClick={handleMaximize}>
                        <InfoOutlinedIcon  />
                    </IconButton>
                </div>
            }
        </div>
    ) : null;
};

export default MapLegend;
