import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = (privateRouteProps) => {
    const {
        isAuthenticated,
        component: Component,
        children,
        ...rest
    } = privateRouteProps;

    return (
        <Route {...rest} render={(props) => (
            isAuthenticated ? (
                Component ? <Component { ...props } /> : children
            ) : <Redirect to="/login" />
        )} />
    );
};

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
};


