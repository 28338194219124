import {MINIMUN_PASSWORD_LENGHT} from "../constants";

export const isPasswordValid = (password) => {

    // check password length
    if (password.length < MINIMUN_PASSWORD_LENGHT) return false;

    // check contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) return false;

    // check contains at least one lowercase letter
    if (!/[a-z]/.test(password)) return false;

    // check contains at least one number
    if (!/\d/.test(password))return false;

    // check contains at least one special character
    return /[~!@#$%^&*\-+='|\\(){}\[\]:;"<>,.?/]/.test(password);
};
