import {useCallback, useEffect} from "react";
import useMatrix from "../../../../../../hooks/useMatrix";
import {usePeriodContext} from "../../common/PeriodContext";
import useTableData from "./useTableData";
import {postStationValidationData} from "../../../../../../requests/validation/validationPeriodsRequests";
import {dateToUTCServerFormatString} from "../../../../../../utils/dateToUtcStringConverter";
import useMessage from "../../../../../../hooks/useMessage";
import useRequest from "../../../../../../hooks/useRequest";

const useTableDataEditor = () => {
    const {setError, setSuccess} = useMessage();
    const {handleErrorResponse} = useRequest();
    const {matrix: editorMatrix, setMatrix, updateRows} = useMatrix([]);
    const {selectedStation, selectedPeriod, setPeriods, stationSensors, setLoading, dataParams} = usePeriodContext();
    const {rowIndexToDate} = useTableData();

    const clearEditor = useCallback(() => {
        setMatrix([]);
    }, [setMatrix]);

    useEffect(() => {
        // Clear editor when the period changes
        clearEditor();
    }, [clearEditor, selectedPeriod]);

    const addSelectionMatrix = useCallback((selectionMatrix, typeFlag) => {
        const newMatrix = [];
        selectionMatrix.forEach((row, rowIndex) => {
            row?.forEach((cell, colIndex) => {
                if (cell) {
                    if (!newMatrix[rowIndex]) {
                        newMatrix[rowIndex] = [];
                    }
                    newMatrix[rowIndex][colIndex] = typeFlag;
                }
            });
        });
        updateRows(newMatrix);
    }, [updateRows]);

    const matrixDataToPeriods = useCallback((matrixData) => {
        const periodData = [];
        for (let rowIndex = 0; rowIndex <= matrixData.length; rowIndex++) {
            const rowDate = rowIndexToDate(rowIndex, dataParams);
            const row = matrixData[rowIndex];
            for (let colIndex = 0; colIndex < stationSensors.length; colIndex++) {
                const cellValue = row?.[colIndex];
                if (cellValue && periodData[colIndex] === undefined) periodData[colIndex] = [];
                const lastPeriod = periodData[colIndex]?.[periodData[colIndex].length - 1];
                if (lastPeriod && !lastPeriod.endtime && lastPeriod.type !== cellValue) {
                    lastPeriod.endtime = rowDate.toISOString();
                }
                if (cellValue && (!lastPeriod || lastPeriod.endtime || lastPeriod.type !== cellValue)) {
                    periodData[colIndex].push({time: rowDate.toISOString(), type: cellValue});
                }
            }
        }
        const periodObject = {};
        stationSensors.forEach((sensor, index) => {
            if (periodData[index]) {
                periodObject[sensor] = periodData[index];
            }
        });
        return periodObject;
    }, [dataParams, rowIndexToDate, stationSensors]);

    const saveEditorMatrix = useCallback(async () => {
        const periodsToSave = matrixDataToPeriods(editorMatrix);
        setLoading(true);
        try {
            const response = await postStationValidationData({
                station: selectedStation,
                time: dateToUTCServerFormatString(selectedPeriod.startDate),
                endtime: dateToUTCServerFormatString(selectedPeriod.endDate),
                periods: periodsToSave,
                periodsInUTC: true
            });
            setPeriods(response.data);
            setSuccess("common.data_successfully_saved");
            clearEditor();
        } catch (err) {
            handleErrorResponse(err, () => {
                setError("common.could_not_update_data", false);
            });
        }
        setLoading(false);
    }, [editorMatrix, matrixDataToPeriods, selectedStation, selectedPeriod, setPeriods, clearEditor,
        handleErrorResponse, setError, setSuccess, setLoading]);

    return {editorMatrix, clearEditor, addSelectionMatrix, saveEditorMatrix};
};

export default useTableDataEditor;