import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import caLocale from 'date-fns/locale/ca';
import moment from "moment-timezone";
import {getI18n} from "react-i18next";

export const getLocale = () => {
    const i18n = getI18n();
    return i18n.language;
};

export const getDateFnsLocale = () => {
    const locale = getLocale();
    if (locale === "es") {
        return esLocale;
    } else if (locale === "ca") {
        return caLocale;
    }
    return enLocale;
};

export const getInitOfDayInUTC = (date)=>{
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()));
};

export const getFinishOfDayInUTC = (date)=>{
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()+1)-1);
};

export const getInitOfDayMonthInUTC = (date)=>{
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return new Date(Date.UTC(startOfMonth.getFullYear(),startOfMonth.getMonth(),startOfMonth.getDate()));
};

export const getFinalDayOfMonthInUTC = (date, monthOffset)=>{
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1+monthOffset, 0);
    return new Date(Date.UTC(lastDayOfMonth.getFullYear(),lastDayOfMonth.getMonth(),lastDayOfMonth.getDate()));
};

export const getTzUtcOffset = (timeZone, date) => {
    return moment.tz(date, timeZone).utcOffset();
};

export const fakePlotlyDate = (date, timeZone)=> {
    date = new Date(date);
    const momentDate = moment.utc(date);
    const timezoneOffset = moment.tz.zone(timeZone).utcOffset(momentDate);
    const output = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    return output.toISOString();
};

export const localToUTCDate = (date, timezone) => {
    const timezoneOffset = timezone ? getTzUtcOffset(timezone, date) : - date.getTimezoneOffset();
    return new Date(date.getTime() - timezoneOffset * 60000);
};

export const localToUTCDateString = (dateString) => {
    return localToUTCDate(new Date(dateString)).toISOString();
};
