export const getYRange = (data) => {

    let min = Number.MAX_VALUE;
    let max = -Number.MAX_VALUE;

    data.forEach(item => {
        const minA = Math.min(...item.map(item => item.min));
        const maxA = Math.max(...item.map(item => item.max));
        if(minA < min) min = minA;
        if(maxA > max) max = maxA;
    });
    const range = max - min;
    return [min - range * 0.1, max + range * 0.1];
};
