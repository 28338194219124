import React, {useCallback, useEffect, useState} from 'react';
import { Grid } from "@mui/material";
import {makeStyles} from "@mui/styles";
import OrganizationUnits from "./units/OrganizationUnits";
import OrganizationMembers from "./members/OrganizationMembers";
import Preferences from "./preference/Preferences";
import OrganizationSentilo from "./sentilo/OrganizationSentilo";
import PublicConfig from "./publicConfig/PublicConfig";
import OrganizationNodes from "./nodes/OrganizationNodes";
import useAuth from "../../../../hooks/useAuth";
import useMessage from "../../../../hooks/useMessage";
import {READ_ORGANIZATION_USERS} from "./members/UserPermissions";
import {organizationRequest} from "../../../../requests/organization/organizationRequests";
import AppPasswordCardView from "../profile/app_password/AppPasswordCardView";
import useRequest from "../../../../hooks/useRequest";
import ThresholdsConfig from "./legalThresholds/ThresholdsConfig";


const useStyles = makeStyles({
    root: {
        maxWidth:1800,
        paddingBottom:20,
        paddingTop:20,
        paddingLeft:40,
        paddingRight:40,
    },
    prefCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight: 200
    },
    unitsCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight: 200
    },
    thresholdsCard: {
        display: "flex",
        padding: 20,
        borderRadius: 5,
        minHeight: 200
    },
    sentilo: {
        display: "flex",
        padding: 20,
        borderRadius: 5,
        minHeight: 200
    },
    publicCard: {
        display: "flex",
        padding: 20,
        borderRadius: 5,
        minHeight: 200
    },
    membersCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight: 500
    },
    nodeCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight: 500
    },
    appPasswordCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight: 500
    }
});

const OrganizationScreen = () => {
    const { hasPermission } = useAuth();

    const classes = useStyles();
    const {handleErrorResponse} = useRequest();
    const {setError} = useMessage();
    const [{
        locale: localeConfig,
        units: unitsConfig,
        public: publicConfig,
        sentilo: sentiloConfig,
        legalThreshold: legalThresholdsConfig
    }, setConfig] = useState({});

    const updateData = useCallback(() => {
        organizationRequest((error, data) => {
            if (!error) {
                setConfig({
                    locale: data?.locale,
                    units: data?.units,
                    public: data?.public || {stations: []},
                    sentilo: data?.sentilo || {},
                    legalThreshold: data?.legalThreshold
                });
            } else {
                setConfig({
                    locale: null,
                    units: null,
                    public: null,
                    sentilo: null,
                    legalThreshold: null
                });
                handleErrorResponse(data, response => {
                    setError(response,
                        false,
                        "organizationPreferences.could_not_fetch_organization_preference");
                });
            }
        });
    }, [setError, handleErrorResponse]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return (
        <>
            <Grid container direction={"row"} className={classes.root}
                  alignItems={"center"} justifyContent="space-around" spacing={2}>
                <Grid item xs={12}>
                    <Preferences className={classes.prefCard} localeConfig={localeConfig}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationUnits className={classes.unitsCard} unitsConfig={unitsConfig}/>
                </Grid>
                <Grid item xs={12}>
                    <ThresholdsConfig className={classes.thresholdsCard} legalThresholdsConfig={legalThresholdsConfig}/>
                </Grid>
                {hasPermission(READ_ORGANIZATION_USERS) && (
                    <Grid item xs={12}>
                        <OrganizationMembers className={classes.membersCard}/>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <OrganizationNodes className={classes.nodeCard}/>
                </Grid>
                <Grid item xs={12}>
                    <AppPasswordCardView className={classes.appPasswordCard} isOrganizationPass={true}/>
                </Grid>
                <Grid item xs={12}>
                    <PublicConfig className={classes.publicCard} publicConfig={publicConfig}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationSentilo className={classes.sentilo} sentiloConfig={sentiloConfig}/>
                </Grid>
            </Grid>
        </>
    );
};

export default OrganizationScreen;

