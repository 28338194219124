import React, {useRef, useState, useEffect} from 'react';
import {Typography, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    container: {
        marginTop: 5,
        marginBottom: 5,
        display: "flex",
        alignItems: "center",
        cursor: props => (props.onItemSelected ? "pointer" : "default")
    },
    text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: 10,
        color: props => (props.isHidden ? "#7b7b7b" : "#1f1f1f")
    },
    dot: {
        borderRadius: "50%",
        flexShrink: 0,
        backgroundColor: props => (props.isHidden ? "transparent" : props.color),
        border: props => (props.isHidden ? `2px solid ${props.color}` : props.borderColor ? `2px solid ${props.borderColor}` : "none"),
        width: props => (props.isHidden || props.borderColor ? 10 : 14),
        height: props => (props.isHidden || props.borderColor ? 10 : 14)
    }
});

const LegendItem = ({key, id, label, color, borderColor, isHidden, onItemSelected}) => {
    const classes = useStyles({isHidden, onItemSelected});
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [label]);

    const handleMouseDown = (event) => {
        if (event.detail > 1) {
            event.preventDefault();
        }
    };

    const handleClick = () => {
        if (onItemSelected) {
            onItemSelected(id);
        }
    };

    return (
        <div key={key} onClick={handleClick} onMouseDown={handleMouseDown} className={classes.container}>
            <Dot color={color} borderColor={borderColor} isHidden={isHidden}/>
            <Tooltip title={isTruncated ? label : ""} disableInteractive
                     PopperProps={{modifiers: [{name: 'offset', options: {offset: [0, -12]}}]}}>
                <Typography ref={textRef} className={classes.text}>{label}</Typography>
            </Tooltip>
        </div>
    );
};

const Dot = ({color, borderColor, isHidden}) => {
    const classes = useStyles({color, borderColor, isHidden});
    return <div className={classes.dot} />;
};

export default LegendItem;