import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";


export const profileRequest = (callback) => {
    webClient.get('/user/profile')
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateProfileRequest = (body,callback) => {
    webClient.put('/user/profile', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updatePasswordRequest = (body,callback) => {
    webClient.post('/user/pass', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const getLastLoggedIn = () => {
    return webClient.get('/user/last-logged').then(response => response.data);
};