import {chartDomains} from "./initialConfig";

export const getVisibleVariables = (data, ids) => {
    const variables = [];
    data.forEach((item, index) => {
        if (ids.includes(item.id)) {
            variables.push(index);
        }
    });
    return variables;
};

export const getSelectedPollutants = (visibleVariables, data) => {
    let output = [];
    visibleVariables.forEach(position => {
        const variable = data[position];
        if (variable) output.push(variable);
    });
    return output;
};

export const getAxeVisibilityConfig = (visiblePollutants) => {
    const units = [...new Set(visiblePollutants.map(item => item.units))];
    const y1Visible = units.includes("hPa");
    const y2Visible = units.includes("celsius") || units.includes("fahrenheit");
    const y3Visible = units.includes("percentage");
    const y4Visible = units.includes("ug-m3") || units.includes("ppb");
    const y5Visible = units.includes("mg-m3") || units.includes("ppm");
    const y6Visible = units.includes("W/m²");
    const y7Visible = units.includes("UV");
    const y8Visible = units.includes("mm/h");
    const y3Side = y2Visible ? "right" : "left";
    const y4Side = "left";
    const y5Side = y4Visible ? "right" : "left";
    const y7Side = y6Visible ? "right" : "left";
    const y8Side = y1Visible ? "right" : "left";
    const y1Domain = (!y1Visible && !y8Visible) ? [0, 0.001] : (
        (y6Visible || y7Visible) ? (
            (y4Visible || y5Visible) ? (
                (y2Visible || y3Visible) ? chartDomains.third : chartDomains.secondExpanded
            ) : (
                (y2Visible || y3Visible) ? chartDomains.secondExpanded : chartDomains.secondFifty
            )
        ) : (
            (y4Visible || y5Visible) ? (
                (y2Visible || y3Visible) ? chartDomains.thirdExpanded : chartDomains.secondMoreExpanded
            ) : (
                (y2Visible || y3Visible) ? chartDomains.thirdFifty : chartDomains.full
            )
        )
    );
    const y2Domain = (!y2Visible && !y3Visible) ? [0, 0.001] : (
        (y6Visible || y7Visible) ? (
            (y4Visible || y5Visible) ? (
                (y1Visible || y8Visible) ? chartDomains.second : chartDomains.secondExpanded
            ) : (
                (y1Visible || y8Visible) ? chartDomains.firstExpanded : chartDomains.secondFifty
            )
        ) : (
            (y4Visible || y5Visible) ? (
                (y1Visible || y8Visible) ? chartDomains.secondExpanded : chartDomains.secondMoreExpanded
            ) : (
                (y1Visible || y8Visible) ? chartDomains.secondFifty : chartDomains.full
            )
        )
    );
    const y3Domain = y2Domain;
    const y4Domain = (!y4Visible && !y5Visible) ? [0.999, 1] : (
        (y6Visible || y7Visible) ? (
            ((y1Visible || y8Visible) && (y2Visible || y3Visible)) ? chartDomains.first : (
                (y1Visible || y8Visible || y2Visible || y3Visible) ? chartDomains.firstExpanded : chartDomains.firstMoreExpanded
            )
        ) : (
            ((y1Visible || y8Visible) && (y2Visible || y3Visible)) ? chartDomains.firstExpanded : (
                (y1Visible || y8Visible || y2Visible || y3Visible) ? chartDomains.firstMoreExpanded : chartDomains.full
            )
        )
    );
    const y5Domain = y4Domain;
    const y6Domain = (!y6Visible && !y7Visible) ? [0, 0.001] : (
        (y4Visible || y5Visible) ? (
            ((y1Visible || y8Visible) && (y2Visible || y3Visible)) ? chartDomains.fourth : (
                (y1Visible || y8Visible || y2Visible || y3Visible) ? chartDomains.thirdExpanded : chartDomains.secondMoreExpanded
            )
        ) : (
            ((y1Visible || y8Visible) && (y2Visible || y3Visible)) ? chartDomains.thirdExpanded : (
                (y1Visible || y8Visible || y2Visible || y3Visible) ? chartDomains.thirdFifty : chartDomains.full
            )
        )
    );
    const y7Domain = y6Domain;
    const y8Domain = y1Domain;

    return { y1Visible, y2Visible, y3Visible, y4Visible, y5Visible, y6Visible, y7Visible, y8Visible, y3Side, y4Side,
        y5Side, y7Side, y8Side, y1Domain, y2Domain, y3Domain, y4Domain, y5Domain, y6Domain, y7Domain, y8Domain };
};