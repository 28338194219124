import React from 'react';
import {Button, Grid, Link, TextField, Typography} from "@mui/material";
import logo from "../../../../assets/bettair_logo.png";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    button: {
        color: '#ffffff',
        marginBottom: 12,
        marginTop: 12
    },
    img: {
        width: 220,
        paddingBottom: 30
    },
    resend: {
        display: 'flex',
        justifyContent: "center",
        cursor: "pointer"
    },
    loginContainer: {
        minWidth: 400,
        maxWidth: 600,
        background: 'white',
        padding: 40,
        borderRadius: 6
    },
    form: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    otp: {
        fontSize: 32,
        fontWeight: 600,
        width: 150,
        textAlign: 'center'
    },
    text: {
        paddingLeft: 50,
        paddingRight: 50,
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 400,
        color: '#4f4f4f'
    }
});

const OTP = ({
                 loading,
                 handleValidateOtp,
                 handleResendOtp,
                 otp,
                 handleOtp,
                 validForm,
                 handleEnter
             }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Grid container item xs={6} md={4} justifyContent={"center"} alignItems={"center"} direction={"column"}
              className={classes.loginContainer} alignContent={"center"}
        >
            <Grid item>
                <img className={classes.img} src={logo} alt="logo"/>
            </Grid>
            <Grid container item>
                <Typography className={classes.text}>{t("loginScreen.otp.text")}</Typography>
            </Grid>
            <Grid container item>
                <form className={classes.form} noValidate>
                    <TextField
                        InputProps={{
                            classes: {
                                input: classes.otp,
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label={t('loginScreen.otp.value')}
                        variant="outlined"
                        required
                        autoFocus margin="normal"
                        onChange={handleOtp}
                        value={otp}
                        onKeyPress={handleEnter}
                    />
                    <Button
                        data-testid="login-validate-otp"
                        disabled={!validForm || loading} className={classes.button} color={"primary"} fullWidth
                        onClick={handleValidateOtp} size={"large"}
                        variant="contained">{t('loginScreen.otp.validate')}</Button>
                    <Link className={classes.resend} onClick={handleResendOtp} variant="body2">
                        {t('loginScreen.otp.resend')}
                    </Link>
                </form>
            </Grid>
        </Grid>
    );
};

export default OTP;
