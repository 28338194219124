import {useCallback} from "react";
import {usePeriodContext} from "./common/PeriodContext";
import {dateToUTCServerFormatString} from "../../../../utils/dateToUtcStringConverter";
import useMessage from "../../../../hooks/useMessage";
import {getValidatedPeriodsRequest} from "../../../../requests/validation/getValidatedPeriodsRequest";
import {getValidationHistory} from "../../../../requests/validation/getValidationHistory";
import useRequest from "../../../../hooks/useRequest";

const usePeriodData = () => {
    const {setError} = useMessage();
    const {handleErrorResponse} = useRequest();
    const {setValidatedPeriods, setValidationHistory, selectedStation, selectedPeriod, filterByCalendar, setHistoryLoading} = usePeriodContext();

    const updateValidationCalendar = useCallback(() => {
        if (selectedStation) {
            setValidatedPeriods([]);
            getValidatedPeriodsRequest({station: selectedStation}, (err, data) => {
                if (!err && data?.validatedPeriods) {
                    const newValidatedPeriods = data.validatedPeriods.map(({time, endtime}) => ({
                        time,
                        endTime: endtime
                    }));
                    setValidatedPeriods(newValidatedPeriods);
                } else {
                    handleErrorResponse(data, response => {
                        setError(response, false);
                    });
                }
            });
        }
    }, [setValidatedPeriods, selectedStation, setError, handleErrorResponse]);

    const updateValidationHistory = useCallback(() => {
        if (selectedStation && selectedPeriod) {
            const body = {station: selectedStation};
            if(filterByCalendar) {
                body.time = dateToUTCServerFormatString(selectedPeriod.startDate);
                body.endtime = dateToUTCServerFormatString(selectedPeriod.endDate);
            }
            setValidationHistory([]);
            setHistoryLoading(true);
            getValidationHistory(body).then(data => {
                const newValidationHistory = data.map(({endtime, ...item}) => ({...item, endTime: endtime}));
                setValidationHistory(newValidationHistory);
            }).catch(err => {
                handleErrorResponse(err?.response, response => {
                    setError(response, false);
                });
            }).finally(() => {
                setHistoryLoading(false);
            });
        }
    }, [selectedStation, selectedPeriod, filterByCalendar, setValidationHistory, setHistoryLoading, handleErrorResponse, setError]);

    return {updateValidationCalendar, updateValidationHistory};
};

export default usePeriodData;
