
const weekDayTranslation =
    ["analyticScreen.calendar.sunday",
        "analyticScreen.calendar.monday",
        "analyticScreen.calendar.tuesday",
        "analyticScreen.calendar.wednesday",
        "analyticScreen.calendar.thursday",
        "analyticScreen.calendar.friday",
        "analyticScreen.calendar.saturday"];

const abbreviatedWeekDaysTranslation =
    ["analyticScreen.temporal_variation.sunday",
        "analyticScreen.temporal_variation.monday",
        "analyticScreen.temporal_variation.tuesday",
        "analyticScreen.temporal_variation.wednesday",
        "analyticScreen.temporal_variation.thursday",
        "analyticScreen.temporal_variation.friday",
        "analyticScreen.temporal_variation.saturday"];

const monthOfYeasTranslation =
    ["analyticScreen.temporal_variation.january",
        "analyticScreen.temporal_variation.february",
        "analyticScreen.temporal_variation.march",
        "analyticScreen.temporal_variation.april",
        "analyticScreen.temporal_variation.may",
        "analyticScreen.temporal_variation.june",
        "analyticScreen.temporal_variation.july",
        "analyticScreen.temporal_variation.august",
        "analyticScreen.temporal_variation.september",
        "analyticScreen.temporal_variation.october",
        "analyticScreen.temporal_variation.november",
        "analyticScreen.temporal_variation.december"];

module.exports = {
    weekDayTranslation,
    abbreviatedWeekDaysTranslation,
    monthOfYeasTranslation
};
