import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import HomeScreen from "./home/HomeScreen";
import NotFoundScreen from "../public/not_found/NotFoundScreen";
import CompareScreen from "./compare/CompareScreen";
import DownloadScreen from "./download/DownloadScreen";
import AlarmsScreen from "./alarms/AlarmsScreen";
import AnalyticScreen from "./analytics/AnalyticScreen";
import {PrivateRoute} from "./PrivateRoute";
import OrganizationScreen from "./organization/OrganizationScreen";
import {
    ALARM_ROUTE,
    ANALYTICS_ROUTE, appModules,
    COMPARE_ROUTE,
    DOWNLOAD_ROUTE, HOME_ROUTE, LOGS_ROUTE,
    ORGANIZATION_ROUTE,
    PROFILE_ROUTE, REPORT_ROUTE, VALIDATION_ROUTE
} from "../../../constants";
import ReportScreen from "./report/ReportScreen";
import ProfileScreen from "./profile/ProfileScreen";
import LogsScreen from "./logs/LogsScreen";
import ValidationScreen from "./validation/ValidationScreen";
import {PeriodContextProvider} from "./validation/common/PeriodContext";
import {MapContextProvider} from "./home/MapContext";
import useModules from "../../../hooks/useModules";


export const PrivateRoutesTree = ({isAuthenticated, updateNotFound}) => {

    const {hasModule} = useModules();


    return (
        <Switch>
            <PrivateRoute isAuthenticated={isAuthenticated}
                          exact path={HOME_ROUTE}>
                <MapContextProvider>
                    <HomeScreen/>
                </MapContextProvider>
            </PrivateRoute>
            <PrivateRoute isAuthenticated={isAuthenticated} exact path={ANALYTICS_ROUTE} component={AnalyticScreen}/>
            {hasModule(appModules.compare) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={COMPARE_ROUTE} component={CompareScreen}/>
            }
            {hasModule(appModules.downloads) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={DOWNLOAD_ROUTE} component={DownloadScreen}/>
            }
            {hasModule(appModules.reports) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={REPORT_ROUTE} component={ReportScreen}/>
            }
            {hasModule(appModules.alarms) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={ALARM_ROUTE} component={AlarmsScreen}/>
            }
            {hasModule(appModules.cmms) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={`${LOGS_ROUTE}/:stationId?`}
                              component={LogsScreen}/>
            }
            {hasModule(appModules.dataValidation) &&
                <PrivateRoute isAuthenticated={isAuthenticated} exact path={VALIDATION_ROUTE}>
                    <PeriodContextProvider>
                        <ValidationScreen/>
                    </PeriodContextProvider>
                </PrivateRoute>
            }
            <PrivateRoute isAuthenticated={isAuthenticated} exact path={PROFILE_ROUTE} component={ProfileScreen}/>
            <PrivateRoute isAuthenticated={isAuthenticated} exact path={ORGANIZATION_ROUTE}
                          component={OrganizationScreen}/>
            <Route exact path="/">
                <Redirect to={HOME_ROUTE}/>
            </Route>
            <Route path={"*"}
                   render={() => (
                       <NotFoundScreen updateNotFound={updateNotFound}/>
                   )}
            />
        </Switch>
    );
};


