import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import {MapProvider, Map} from 'react-map-gl';
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import StationMarker from "./StationMarker";
import MapControls from "./MapControls";
import {MAPBOX_ACCESS_TOKEN} from "../../../constants";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flex: "1",
        position: "relative"
    },
    hidden: {
        display: "none"
    }
});

const filterStationsWithLocation = (stations) => {
    return stations.filter(station => {
        return station.position !== undefined && station.position.lat <= 90 && station.position.lat >= -90 &&
            station.position.long <= 180 && station.position.long >= -180;
    });
};

const MapSelector = ({disabled, items, selectedItems, maxItems, hidden, handleToggle, handleSelectAll,
                         handleUnselectAll}) => {
    const classes = useStyles();
    const [opened, setOpened] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const defaultMapPosition = useSelector( state => state.auth.organization.map );
    const stations = useSelector(state => {
        const stationsData = state.dashboardUI.stations;
        if (!stationsData) return [];
        const stationsWithLocation = filterStationsWithLocation(stationsData);
        return items.filter(item => stationsWithLocation.find(station => station.id === item.id))
            .map(({id: stationId}) => {
                const {id, alias, position, model, index, state} = stationsData.find(station => station.id === stationId);
                return ({id, alias, position, model, index, state});
            });
    }, isEqual);

    const getStationMarker = useCallback((station) => {
        const selected = selectedItems.find(itemId => itemId === station.id);
        const handleMarkerClick = () => {
            handleToggle(station);
        };
        return <StationMarker station={station} disabled={(!selected && selectedItems?.length >= maxItems) || disabled}
                              selected={selected} key={station.id} handleClick={handleMarkerClick} />;
    }, [selectedItems, handleToggle, maxItems, disabled]);

    const stationMarkers = useMemo(() => {
        const selectedStations = selectedItems.map(itemId => stations.find(station => itemId === station.id))
            .filter(station => station);
        const unselectedStations = stations.filter(station => (
            selectedItems.indexOf(station.id) === -1
        ));
        return (
            <>
                {unselectedStations.map(station => getStationMarker(station))}
                {selectedStations.map(station => getStationMarker(station))}
            </>
        );
    }, [stations, selectedItems, getStationMarker]);

    useEffect(() => {
        if (!hidden) {
            setOpened(true);
        }
    }, [hidden]);

    return opened && (
        <div className={clsx(classes.container, hidden && classes.hidden)}>
            <MapProvider>
                <Map id="selectorMap"
                     reuseMaps
                     initialViewState={{
                         longitude: defaultMapPosition?.center.lon,
                         latitude: defaultMapPosition?.center.lat,
                         zoom: defaultMapPosition?.zoom,
                     }}
                     style={{width: "100%", height: "100%"}}
                     mapStyle="mapbox://styles/mapbox/light-v10"
                     mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                     attributionControl={false}
                     logoPosition={"bottom-right"}
                     cursor={'default'}
                     onLoad={() => setLoaded(true)}
                >
                    {stationMarkers}
                </Map>
                {loaded && (
                    <MapControls disabled={disabled} canSelectAll={!maxItems} stations={stations} selectedStations={selectedItems}
                                 handleSelectAll={handleSelectAll} handleUnselectAll={handleUnselectAll} />
                )}
            </MapProvider>
        </div>
    );
};

export default MapSelector;