import {useSelector} from "react-redux";
import {useCallback} from "react";
import moment from "moment-timezone";
import {getFormattedDateTime} from "../utils/getFormattedDateTime";
import {timeZoneOptions} from "../constants";

const useUserPrefTimeZone = () => {

    const userTimeZone = useSelector(state => state.auth.timeZone);

    const stationsLocalTimeZone = useSelector(state => {
        return state.dashboardUI.stations.map(item => {return {id: item.id, timeZone: item?.position?.locationInfo?.timeZone ?? "UTC"};});
    });

    const convertStationDateToUserPrefTz = useCallback((station, date, includeTimeZone = false, includeSeconds = true)=>{

        if (!(date instanceof Date)) {
            return date;
        }

        const timeZone = (userTimeZone === timeZoneOptions.StationLocalTime &&
            stationsLocalTimeZone.find(item => item.id === station)?.timeZone) || "UTC";

        return getFormattedDateTime(date, {timeZone, includeTimeZone, timeZoneConfig: userTimeZone, includeSeconds});
    },[userTimeZone, stationsLocalTimeZone]);

    const convertDateToUserPrefTz = useCallback((date,  includeTimeZone = false, includeSeconds = true)=>{

        if (!(date instanceof Date)) {
            return date;
        }

        const timeZone = (userTimeZone === timeZoneOptions.StationLocalTime && moment.tz.guess()) || "UTC";

        return getFormattedDateTime(date, {timeZone, includeTimeZone, timeZoneConfig: userTimeZone, includeSeconds});
    },[userTimeZone]);

    return { userTimeZone, convertStationDateToUserPrefTz, convertDateToUserPrefTz };
};

export default useUserPrefTimeZone;
