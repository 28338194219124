import type from "../../../../reducers/action_types/type";

export const notificationInitialState = {
    notifications: [],
    notificationCount: 0
};

export const notificationReducer = (state = notificationInitialState, action) => {
    switch (action.type) {
        case type.addNotificationAction:
            return !state.notifications.find(notification => notification.eventId === action.notification.eventId) ? {
                notifications: [{...action.notification}, ...state.notifications],
                notificationCount: state.notificationCount + 1
            } : {...state};
        case type.removeNotificationByIdsAction:
            return {
                notifications: state.notifications.filter(notification => !action.ids.includes(notification.eventId)),
                notificationCount: state.notificationCount - action.ids.length
            };
        case type.setNotificationDataAction:
            return action.payload.count > 0 && action.payload.data.length > 0 ?{
                notifications: [...state.notifications, ...action.payload.data],
                notificationCount:
                    state.notifications.length + action.payload.count * 1 - action.payload.offset * 1
            } : {...state};
        case type.resetNotificationDataAction:
            return {...notificationInitialState};
        default:
            return state;
    }
};

export const addNotificationAction = (notification) => ({
    type: type.addNotificationAction,
    notification
});

export const removeNotificationByIdsAction = (ids) => ({
    type: type.removeNotificationByIdsAction,
    ids
});

export const setNotificationDataAction = (payload) => ({
    type: type.setNotificationDataAction,
    payload
});

export const resetNotificationDataAction = () => ({
    type: type.resetNotificationDataAction
});
