import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {expiredSession} from "../reducers/authReducer";
import useMessage from "./useMessage";

const useRequest = () => {
    const dispatch = useDispatch();
    const {setError} = useMessage();

    const handleErrorResponse = useCallback((response, unhandledErrorCallback) => {
        switch (response?.status) {
            case 401:
                const path = window.location.pathname;
                expiredSession(path)(dispatch);
                setError("loginScreen.expired_session_message");
                break;
            default:
                unhandledErrorCallback && unhandledErrorCallback(response);
        }
    }, [dispatch, setError]);

    return {handleErrorResponse};
};

export default useRequest;
