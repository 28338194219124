import React, {useCallback, useEffect, useMemo, useState} from "react";
import {predominantPollutantRequest} from "../../../../../requests/analytics/predominantPollutantRequest";
import {useSelector} from "react-redux";
import useRequest from "../../../../../hooks/useRequest";
import {Grid} from "@mui/material";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {getIndexRange} from "../../../../../utils/airQualityIndexUtil";
import {indexColors, getAQILegend} from "bettairplaformutil/src/indexUtil";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d", "autoScale2d", "zoomIn2d", "zoomOut2d", "zoom2d",  "toggleHover",
        "resetViews", "sendDataToCloud", "toggleSpikelines", "resetViewMapbox", "pan2d", "hoverClosestCartesian",
        "hoverCompareCartesian"],
    displaylogo : false
};

const initialPlotLayout = {
    autosize: true,
    showlegend: false,
    yaxis: {
        linecolor: "black",
        showgrid: false
    },
    xaxis: {
        showgrid: false,
        domain: [0, 1],
        rangemode: "normal",
        tickformat: "%-Hh"
    },
    hovermode: "closest",
    margin: {t: 25, b: 20, l: 30, r: 40}
};

const initialPlotData = {
    hoverlabel: {namelength: 0},
    hovertemplate: '%{text}',
    mode: "lines+markers",
    marker: {
        size: 8,
        line: {
            color: "white",
            width: 1.5
        }
    },
    line: {
        color: "white",
        width: 2.5
    }
};

const CalendarDayDetail = ({selectedDay, loadingDayData, setLoadingDayData}) => {
    const {t, i18n} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {units} = useSelector(state => state.auth);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const [dayData, setDayData] = useState(null);
    const [error, setError] = useState(null);

    const fetchData = useCallback(() => {
        if (!selectedDay) return;
        setLoadingDayData(true);
        setError(null);
        predominantPollutantRequest(selectedStation, selectedDay, units.index, true)
            .then((data) => {
                setDayData(data);
            })
            .catch((error) => {
                handleErrorResponse(error?.response, response => {
                    switch (response?.status) {
                        case 404:
                            setError(t("analyticScreen.scatterPlot.sensor_data_not_found"));
                            setDayData(null);
                            break;
                        case 400:
                            setError(t("analyticScreen.calendar.could_not_update_calendar_invalid_date"));
                            break;
                        default:
                            setError(t("analyticScreen.calendar.could_not_update"));
                    }
                });
            })
            .finally(() => {
                setLoadingDayData(false);
            });
    }, [units.index, selectedStation, selectedDay, handleErrorResponse, t, setLoadingDayData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const aqiLegend = useMemo(() => {
        return getAQILegend(units.index, i18n.language);
    }, [units.index, i18n.language]);

    const activeIndexColors = useMemo(() => {
        return indexColors[units.index];
    }, [units.index]);

    const indexRange = useMemo(() => getIndexRange(units.index), [units.index]);

    const isCategoricalIndex = !indexRange;

    const calculatedRange = useMemo(() => {
        if (indexRange) return indexRange;
        return activeIndexColors?.map((color, index) => index + 1);
    }, [indexRange, activeIndexColors]);

    const maxRangeValue = useMemo(() => {
        let maxValue = calculatedRange?.[calculatedRange.length - 1] ?? 0;

        if (maxValue === Infinity) {
            maxValue = dayData?.y.reduce((max, value) => {
                return Math.max(max, value?.value ?? 0);
            }, 0);

            const secondLastValue = calculatedRange[calculatedRange.length - 2];
            maxValue = Math.max(maxValue, secondLastValue);
        }

        return maxValue;
    }, [calculatedRange, dayData?.y]);

    const colorRangeShapes = useMemo(() => {
        return calculatedRange.map((value, index) => {
            return {
                type: "rect",
                xref: "paper",
                yref: "y",
                x0: 0,
                x1: 1,
                y0: calculatedRange[index - 1] ?? 0,
                y1: Math.min(calculatedRange[index], maxRangeValue),
                fillcolor: activeIndexColors[index],
                opacity: 1,
                line: {
                    width: 0
                },
                layer: "below"
            };
        });
    }, [calculatedRange, activeIndexColors, maxRangeValue]);

    const plotLayout = useMemo(() => {
        const xRange = dayData ? [dayData.x[0], dayData.x[dayData.x.length - 1]] : [0, 1];
        return {
            ...initialPlotLayout,
            yaxis: {
                ...initialPlotLayout.yaxis,
                range: [0, maxRangeValue],
                showticklabels: !isCategoricalIndex
            },
            xaxis: {
                ...initialPlotLayout.xaxis,
                range: xRange
            },
            shapes: colorRangeShapes
        };
    }, [dayData, maxRangeValue, isCategoricalIndex, colorRangeShapes]);

    const plotData = useMemo(() => {
        if (!dayData) return [];
        return [{
            ...initialPlotData,
            x: dayData.x,
            y: dayData.y.map(value => value && (value.value + (isCategoricalIndex ? 0.5 : 0))),
            text: dayData.y.map((value, index) => {
                if (value) {
                    const indexValue = aqiLegend[value.index];
                    const hourText = moment(dayData.x[index]).tz("UTC").format("H");
                    const hourLine = `${hourText}h<br>`;
                    return `${hourLine}<b>${isCategoricalIndex ? indexValue : `${value.value} (${indexValue})`}</b>`;
                }
            }),
            marker: {
                ...initialPlotData.marker,
                color: dayData.y.map(value => value && activeIndexColors[value.index])
            }
        }];
    }, [dayData, aqiLegend, isCategoricalIndex, activeIndexColors]);

    return (
        <Grid container item xs={12}>
            <ChartLayout loading={loadingDayData} error={error} emptyData={plotData.length === 0} height={350}
                         position={"top"} chartStyles={{ "& .modebar": { left: "45%" }}}>
                <ChartView
                    layout={plotLayout}
                    data={plotData}
                    config={plotConfig}
                />
            </ChartLayout>
        </Grid>
    );
};

export default CalendarDayDetail;