import React from "react";
import {Button, CircularProgress} from "@mui/material";

const BettairLoadingButton = ({color = "primary", size = "large", variant = "contained", loading, disabled, customLoadingText, ...props}) => {
    return (
        <Button {...props} disabled={disabled || loading} color={color} size={size} variant={variant}>
            {loading && (
                <CircularProgress size={20} color="inherit" sx={{mr: 1}}/>
            )}
            {loading && customLoadingText ? customLoadingText : props.children}
        </Button>
    );
};

export default BettairLoadingButton;
