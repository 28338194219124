import React from 'react';
import {getFormattedUtcDate} from "../../../../../utils/getFormattedDateTime";
import {useTranslation} from "react-i18next";
import SelectControl from "../../../../common/SelectControl";

export const CUSTOM_RANGE = -1;

const PeriodSelector = ({formControlClass, loading, period, handleSelectorChange, onCustomPressedCallback, dateRange,
                            variant = "outlined", margin = "normal", fullWidth}) => {

    const {t} = useTranslation();

    const options = [
        {value: 1, label: t("periodSelector.last_24h")},
        {value: 7, label: t("periodSelector.last_7_day")},
        {value: 30, label: t("periodSelector.last_30_day")},
        {value: 60, label: t("periodSelector.last_60_day")},
        {value: CUSTOM_RANGE, label: t("periodSelector.custom_period"), onClick: onCustomPressedCallback}
    ];

    const helperText = !(dateRange === null || dateRange === undefined) &&
        `${getFormattedUtcDate(dateRange.startDate)} - ${getFormattedUtcDate(dateRange.endDate)}`;

    return (
        <SelectControl
            className={formControlClass}
            label={t("periodSelector.period")}
            disabled={loading}
            options={options}
            value={period}
            onChange={handleSelectorChange}
            variant={variant}
            helperText={helperText}
            margin={margin}
            fullWidth={fullWidth}
        />
    );
};

export default PeriodSelector;
