import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HelpPopup from "../../../../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    deleteLogoRequest,
    downloadOrganizationLogoRequest,
    updateLocale,
    uploadLogoRequest
} from "../../../../../requests/organization/organizationRequests";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import CommonDialog from "../../../../CommonDialog";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import useMessage from "../../../../../hooks/useMessage";
import {WRITE_ORGANIZATION_CONFIG} from "../members/UserPermissions";
import SaveButton from "../../../../common/buttons/SaveButton";
import useRequest from "../../../../../hooks/useRequest";
import {appLanguages, imageErrorTypes} from "../../../../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import ConflictLogoDialog from "./ConflictLogoDialog";

const useStyles = makeStyles({
    saveButton: {
        color: '#ffffff',
    },
    title: {
        fontWeight: "bold"
    },
    helpButton: {
        color: "black"
    }
});

const Preferences = ({className, localeConfig}) => {

        const {t} = useTranslation();
        const {hasPermission} = useAuth();
        const [{organizationLocale, saving, openWarningDialog}, updateState] = useState({
            organizationLocale: null, saving: false, openWarningDialog: false
        });
        const [loading, setLoading] = useState(false);
        const [loadingError, setLoadingError] = useState(false);
        const [loadedConfig, setLoadedConfig] = useState(null);
        const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
        const {handleErrorResponse} = useRequest();
        const {setError, setSuccess} = useMessage();

        const [selectedFile, setSelectedFile] = useState(null);
        const [downloadedImage, setDownloadedImage] = useState(null);
        const [isDownloadingImage, updateIsDownloadingImage] = useState(true);
        const [serverImageDeleted, updateServerImageDeleted] = useState(false);
        const [hasChanges, updateHasChanges] = useState(false);

        const [openConflictLogoDialog, setOpenConflictLogoDialog] = useState(false);
        const [openFileError, setOpenFileError] = useState("");

        const classes = useStyles();

        const resetData = useCallback(() => {
            updateState(state => ({...state, organizationLocale: localeConfig}));
        }, [localeConfig]);

        useEffect(() => {
            if (localeConfig) {
                setLoading(false);
                setLoadingError(false);
                resetData();
                setLoadedConfig(localeConfig);
            } else {
                setLoading(localeConfig !== null);
                setLoadingError(localeConfig === null);
            }
        }, [localeConfig, resetData]);

        const handleWarningDialogClose = useCallback(() => {
            updateState(state => ({...state, openWarningDialog: false}));
        }, []);

        const handleLanguageChange = (event) => {
            updateState(state => ({...state, organizationLocale: event.target.value}));
        };

        const fetchLogoFunction = useCallback(() => {
            return async () => {
                updateIsDownloadingImage(true);
                try {
                    const url = await downloadOrganizationLogoRequest();
                    if (url)
                        setDownloadedImage(url);
                } catch (e) {
                    handleErrorResponse(e, response => {
                        setError(response,
                            false,
                            `${t("error")} ${response?.status ?? ''},
                         ${t("organizationPreferences.downloading_organization_logo")}`);
                    });
                } finally {
                    updateIsDownloadingImage(false);
                }
            };
        }, [handleErrorResponse, setError, t]);


        const handleSave = useCallback(() => {

            const saveChange = async () => {
                updateState(state => ({...state, saving: true}));

                if (serverImageDeleted || selectedFile) {

                    if (serverImageDeleted) {
                        try {
                            await deleteLogoRequest();
                            updateServerImageDeleted(false);
                            if (!selectedFile) setSuccess("organizationPreferences.logo_update");
                            else {
                                try {
                                    await uploadLogoRequest(selectedFile);
                                    setSuccess("organizationPreferences.logo_update");
                                    setSelectedFile(null);
                                    await fetchLogoFunction()();
                                }catch (e) {
                                    handleErrorResponse(e, response => {
                                        setError(response, false, `${t("error")} ${response?.status ?? ''}, ${t("organizationPreferences.updating_organization_logo")}`);
                                    });
                                }
                            }
                        } catch (e) {
                            handleErrorResponse(e, response => {
                                setError(response, false, `${t("error")} ${response?.status ?? ''}, ${t("organizationPreferences.deleting_logo")}`);
                            });
                        }
                    } else {
                        try {
                            await uploadLogoRequest(selectedFile);
                            setSuccess("organizationPreferences.logo_update");
                            setSelectedFile(null);
                            await fetchLogoFunction()();
                        } catch (e) {
                            handleErrorResponse(e, response => {
                                if (e.response?.status === 400) {
                                    if (imageErrorTypes.includes(e.response.data.error)) {
                                        setOpenFileError(t("organizationPreferences.fileTypeErrorDescription"));
                                    } else
                                        setOpenConflictLogoDialog(true);
                                } else if (e.response?.status === 413) {
                                    setOpenFileError(t("organizationPreferences.fileSizeErrorDescription"));
                                }
                                else
                                    setError(response, false, `${t("error")} ${response?.status ?? ''}, ${t("organizationPreferences.updating_organization_logo")}`);
                            });
                        }
                    }
                }

                if (loadedConfig !== organizationLocale) {
                    try {
                        await updateLocale({locale: organizationLocale});
                        updateState(state => ({...state, saving: false, openWarningDialog: true}));
                        setSuccess("organizationPreferences.locale_update");
                        setLoadedConfig(organizationLocale);
                    } catch (e) {
                        resetData();
                        handleErrorResponse(e, response => {
                            setError(response,
                                false,
                                `${t("error")} ${response?.status ?? ''},
                        ${t("organizationPreferences.updating_organization_locale")}`);
                        });
                    }
                }
                setLoading(false);
                updateState(state => ({...state, saving: false}));
            };

            saveChange();

        }, [serverImageDeleted, selectedFile, loadedConfig, organizationLocale, fetchLogoFunction, handleErrorResponse, setError, t, setSuccess, resetData]);

        const handleDelete = useCallback(() => {
            setSelectedFile(null);
        }, []);

        const handleDeleteDownloadedImage = useCallback(() => {
            updateServerImageDeleted(true);
            setDownloadedImage(null);
        }, []);


        useEffect(() => {
            updateHasChanges(loadedConfig !== organizationLocale || selectedFile !== null || serverImageDeleted);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [organizationLocale, serverImageDeleted, selectedFile]);


        useEffect(() => {
            fetchLogoFunction()();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleFileChange = (event) => {
            setSelectedFile(event.target.files[0]);
        };

        useEffect(() => {
            const id = requestAnimationFrame(() => {
                document.getElementById('preference-selector-logo')?.blur();
            });

            // Cleanup function to cancel the animation frame if the component unmounts
            return () => cancelAnimationFrame(id);
        }, [selectedFile]);

        const onCancel = useCallback(() => {
            setSelectedFile(null);
            setOpenConflictLogoDialog(false);
            fetchLogoFunction()();
        }, [fetchLogoFunction]);

        const onReplace = useCallback(() => {
                const replaceLogo = async () => {
                    try {
                        setOpenConflictLogoDialog(false);
                        await deleteLogoRequest();
                        await uploadLogoRequest(selectedFile);
                        setSuccess("organizationPreferences.replace_logo_success");
                        setSelectedFile(null);
                        await fetchLogoFunction()();
                    } catch (e) {
                        handleErrorResponse(e, response => {
                            setError(response,
                                false,
                                `${t("error")} ${response?.status ?? ''},
                        ${t("organizationPreferences.error_replacing_logo")}`);
                        });
                    }
                };
                replaceLogo();
            }, [fetchLogoFunction, handleErrorResponse, selectedFile, setError, setSuccess, t]
        );

        return (
            <>
                <Card className={className}>
                    {loading && <CardViewLoading/>}
                    {loadingError && <DataNotFound/>}
                    {!loading && !loadingError && (
                        <div style={{position: "relative", width: "100%"}}>
                            <form noValidate className={classes.form}>
                                <Grid className={classes.grip} container direction={"row"} spacing={2}>
                                    <Grid item container xs={12}>
                                        <Typography className={classes.tittle}
                                                    variant={"h4"}>{t("organizationPreferences.title")}</Typography>
                                        <IconButton className={classes.helpButton} aria-label="help"
                                                    onClick={e => setAnchorEl(e.currentTarget)}>
                                            <HelpOutlineIcon/>
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12} md={6}>

                                        {isDownloadingImage && <div style={{display: "flex", width: "100%"}}>
                                            <Skeleton width="400px" height={200} animation="wave"
                                                      sx={{transform: 'scale(1)'}}/>
                                        </div>}
                                        {!isDownloadingImage && downloadedImage &&
                                            <div style={{position: "relative", width: 400, height: 200}}>
                                                <img src={downloadedImage} alt="Organization logo"
                                                     style={{width: 400, height: 200, objectFit: 'contain'}}/>
                                                <IconButton
                                                    style={{position: "absolute", bottom: 0, right: 0}}
                                                    onClick={handleDeleteDownloadedImage}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </div>}

                                        {!isDownloadingImage && !downloadedImage &&
                                            <FormControl variant="outlined" fullWidth className={classes.formControl}
                                                         margin={"normal"}>
                                                <InputLabel>{t("organizationPreferences.image_file")}</InputLabel>
                                                <Select
                                                    open={false}
                                                    sx={{
                                                        '& .MuiSelect-icon': {display: 'none'},

                                                    }}
                                                    id={"preference-selector-logo"}
                                                    data-testid="preference-logo"
                                                    disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                                    label={t("organizationPreferences.image_file")}
                                                    value={"default"}

                                                    renderValue={_ => (
                                                        selectedFile ? <div>
                                                            <Chip
                                                                label={selectedFile.name}
                                                                onDelete={() => handleDelete()}
                                                                style={{height: '24px'}}
                                                                deleteIcon={<DeleteIcon/>}
                                                            />
                                                        </div> : <div>
                                                            <input
                                                                type="file"
                                                                id="fileInput"
                                                                onChange={handleFileChange}
                                                                style={{display: 'none'}}
                                                                accept="image/jpeg,image/png"
                                                            />
                                                            <Typography
                                                                onClick={() => document.getElementById('fileInput').click()}>Seleccionar
                                                                archivo</Typography>
                                                        </div>
                                                    )}
                                                    MenuProps={{
                                                        open: false,
                                                        onEntering: (event) => {
                                                            event.preventDefault();
                                                        },
                                                    }}
                                                />
                                            </FormControl>}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl}
                                                     margin={"normal"}>
                                            <InputLabel>{t("organizationPreferences.language")}</InputLabel>
                                            <Select
                                                id={"preference-selector-language"}
                                                data-testid="preference-language"
                                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                                onChange={handleLanguageChange}
                                                label={t("organizationPreferences.language")}
                                                value={organizationLocale || ""}
                                            >
                                                {appLanguages.map(language => ({
                                                    key: language,
                                                    text: t(`organizationPreferences.languages.${language}`),
                                                })).sort((a, b) => a.text.localeCompare(b.text)).map(({key, text}) => (
                                                    <MenuItem key={key} value={key}>{text}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}/>
                                    <Grid item md={8} lg={9}/>
                                    {hasPermission(WRITE_ORGANIZATION_CONFIG) && <Grid item xs={12} md={4} lg={3}>
                                        <SaveButton className={classes.saveButton} data-testid={"preference-save"}
                                                    disabled={saving || isDownloadingImage || !hasChanges} saving={saving}
                                                    fullWidth
                                                    onSave={handleSave}/>
                                    </Grid>}
                                </Grid>
                            </form>
                        </div>
                    )}
                </Card>
                <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                           message={t("organizationPreferences.help")}/>
                {openWarningDialog && <CommonDialog
                    title={t("warning")}
                    handleClose={handleWarningDialogClose}
                    description={t("organizationPreferences.previous_report_keep_language")}/>}
                {openConflictLogoDialog && <ConflictLogoDialog onCancel={onCancel} onReplace={onReplace}/>}
                {openFileError !== "" && <CommonDialog title={t("organizationPreferences.fileTypeErrorTitle")} handleClose={_ => {
                    setOpenFileError("");
                }} description={openFileError}/>}
            </>
        );
    }
;

export default Preferences;
