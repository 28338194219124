import {precisions} from "bettairplaformutil/src/dataUtil";
import {periodTypeColor} from "../../../../../../constants";
import {contrastColor} from "contrast-color";
import addAlphaColor from "../../../../../../utils/addAlphaColor";

const DataTableCell = ({className, style, sensorId, value, flag, selected, pivot, onClick}) => {
    const baseColor = periodTypeColor[flag] || "#FFFFFF";
    const color = selected ? addAlphaColor(baseColor, "#8A8AFF70") : baseColor;
    const textColor = contrastColor({
        bgColor: color,
        fgDarkColor: "#000000",
        fgLightColor: "#FFFFFF"
    });
    return (
        <div key={sensorId} className={className} style={{
            color: textColor,
            background: color,
            ...style,
            ...(pivot ? {boxShadow: "inset 0 0 0 3px #8A8AFF"} : {})
        }}
             onClick={onClick}>
            {value === undefined ? <div style={{
                    marginTop: 8,
                    height: 12,
                    borderRadius: 10,
                    background: "#e0e0e0"
                }} /> :
                value === null ? "N/A" :
                    value?.toFixed(precisions[sensorId])}
        </div>
    );
};

export default DataTableCell;