import React, {useEffect, useState} from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import CompareIcon from '@mui/icons-material/Compare';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useSelector} from "react-redux";
import {Badge, ListItemButton, Tooltip} from "@mui/material";
import {useLocation} from "react-router-dom";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {
    ALARM_ROUTE,
    DOWNLOAD_ROUTE,
    HOME_ROUTE,
    LOGS_ROUTE,
    VALIDATION_ROUTE,
    REPORT_ROUTE,
    appModules
} from "../../../../constants";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import {
    READ_LOG,
    READ_ORGANIZATION_DOWNLOADS,
    READ_ORGANIZATION_REPORTS, READ_ORGANIZATION_VALID_PERIODS
} from "../organization/members/UserPermissions";
import useModules from "../../../../hooks/useModules";

export const DrawerListItems = ({selectedPageCallback, drawerOpen})=>{

    const { t } = useTranslation();
    const {hasModule} = useModules();
    const location = useLocation();
    const { hasPermission } = useAuth();
    const [{selectedTab}, updateState] = useState({selectedTab:-1});

    const handleOnClick = (position)=>{
        selectedPageCallback(position);
    };
    const notificationCount = useSelector(state => state.notification.notificationCount);
    const updateTabPosition = (position)=>{
        updateState({selectedTab:position});
    };

    useEffect(()=>{
        if (location.pathname.includes("/analytics")){
            updateTabPosition(1);
            return;
        }
        if (location.pathname.includes("/compare")){
            if(hasModule(appModules.compare))
                updateTabPosition(2);
            return;
        }
        if (location.pathname.includes("/validation")) {
            if(hasModule(appModules.dataValidation))
                updateTabPosition(7);
            return;
        }
        switch (`/${location.pathname.split("/")[1]}`) {
            case HOME_ROUTE:
                updateTabPosition(0);
                break;
            case ALARM_ROUTE:
                if(hasModule(appModules.alarms))
                    updateTabPosition(3);
                break;
            case DOWNLOAD_ROUTE:
                if(hasModule(appModules.downloads))
                    updateTabPosition(4);
                break;
            case REPORT_ROUTE:
                if(hasModule(appModules.reports))
                    updateTabPosition(5);
                break;
            case LOGS_ROUTE:
                if(hasModule(appModules.cmms))
                    updateTabPosition(6);
                break;
            case VALIDATION_ROUTE:
                if(hasModule(appModules.dataValidation))
                    updateTabPosition(7);
                break;
            default:
                updateTabPosition(-1);
                break;
        }
    },[hasModule, location]);


   return  <>
       <Tooltip title={t("drawer.home")} key={"home"} disableInteractive placement="right" arrow
                disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
        <ListItemButton onClick={ (_)=>{ handleOnClick(0);}} selected={selectedTab===0}>
            <ListItemIcon>
                <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.home")} />
        </ListItemButton>
       </Tooltip>
       <Tooltip title={t("drawer.analytics")} key={"analytics"} disableInteractive placement="right" arrow
                disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
        <ListItemButton
            data-testid={"dashboard-analytic"}
            onClick={ (_)=>{ handleOnClick(1);}} selected={selectedTab===1} >
            <ListItemIcon>
                <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.analytics")} />
        </ListItemButton>
       </Tooltip>

       { hasModule(appModules.compare) &&
       <Tooltip title={t("drawer.compare")} key={"compare"} disableInteractive placement="right" arrow
                disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
        <ListItemButton
            data-testid={"dashboard-compare"}
            onClick={ (_)=>{ handleOnClick(2);}} selected={selectedTab===2}>
            <ListItemIcon>
                <CompareIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.compare")} />
        </ListItemButton>
       </Tooltip>}

       { hasModule(appModules.alarms) &&
       <Tooltip title={t("drawer.alarms")} key={"alarms"} disableInteractive placement="right" arrow
                disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
       <ListItemButton
           data-testid={"dashboard-alarm"}
           onClick={ (_)=>{ handleOnClick(3);}} selected={selectedTab===3}>
           <ListItemIcon>
               <Badge badgeContent={notificationCount} color="secondary">
                   <NotificationsIcon />
               </Badge>
           </ListItemIcon>
           <ListItemText primary={t("drawer.alarms")} />
       </ListItemButton>
       </Tooltip>}

       {hasPermission(READ_ORGANIZATION_DOWNLOADS) && hasModule(appModules.downloads) && (
           <Tooltip title={t("drawer.download")} key={"download"} disableInteractive placement="right" arrow
                    disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
           <ListItemButton
               data-testid={"dashboard-download"}
               onClick={ (_)=>{ handleOnClick(4);}} selected={selectedTab===4}>
               <ListItemIcon>
                   <CloudDownloadIcon />
               </ListItemIcon>
               <ListItemText primary={t("drawer.download")} />
           </ListItemButton>
           </Tooltip>
       )}

       {hasPermission(READ_ORGANIZATION_REPORTS) && hasModule(appModules.reports) && (
           <Tooltip title={t("drawer.reports")} key={"reports"} disableInteractive placement="right" arrow
                    disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
           <ListItemButton
               data-testid={"dashboard-report"}
               onClick={(_) => handleOnClick(5)} selected={selectedTab===5}>
               <ListItemIcon>
                   <DescriptionIcon />
               </ListItemIcon>
               <ListItemText primary={t("drawer.reports")} />
           </ListItemButton>
           </Tooltip>
       )}

       {hasPermission(READ_LOG) && hasModule(appModules.cmms) && (
           <Tooltip title={t("logs.title")} key={"logs"} disableInteractive placement="right" arrow
                    disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
           <ListItemButton
               data-testid={"dashboard-log"}
               onClick={ (_)=>{ handleOnClick(6);}} selected={selectedTab===6}>
               <ListItemIcon>
                   <EventNoteIcon />
               </ListItemIcon>
               <ListItemText primary={t("logs.title")} />
           </ListItemButton>
           </Tooltip>
       )}

       {
           hasPermission(READ_ORGANIZATION_VALID_PERIODS) && hasModule(appModules.dataValidation) && (
               <Tooltip title={t("validation.validation")} key={"validation"} disableInteractive placement="right" arrow
                        disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
                   <ListItemButton
                       data-testid={"dashboard-validation"}
                       onClick={(_) => handleOnClick(7)} selected={selectedTab===7}>
                       <ListItemIcon>
                           <AssignmentTurnedInIcon />
                       </ListItemIcon>
                       <ListItemText primary={t("validation.validation")} />
                   </ListItemButton>
               </Tooltip>
           )
       }

       <Tooltip title={t("drawer.send_suggestions")} key={"send_suggestions"} disableInteractive placement="right" arrow
                disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} disableTouchListener={drawerOpen}>
       <ListItemButton onClick={ (_)=>{ handleOnClick(8);}} selected={selectedTab===8}>
           <ListItemIcon>
               <AnnouncementIcon />
           </ListItemIcon>
           <ListItemText primary={t("drawer.send_suggestions")} />
       </ListItemButton>
       </Tooltip>
    </>;
};


