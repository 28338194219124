import {
    createDownloadAction,
    deleteDownloadAction, removeProcessingProgressListAction,
    setProcessingProgressListAction,
    updateDownloadStateAction,
    updateProcessingProgressListAction
} from "../reducers/downloadReducer";
import {
    createReportAction,
    deleteReportAction,
    removeReportProcessingProgressListAction,
    setReportProcessingProgressListAction,
    updateReportProcessingProgressListAction, updateReportStateAction
} from "../reducers/reportReducer";
import {putNewNotificationAction} from "../reducers/notificationReducer";
import {
    updateBattery, updateFW,
    updateLastConnection,
    updateLastData, updateModel,
    updatePosition, updateSerial, updateState
} from "../reducers/dashboardUIReducer";
import {handleSentryError} from "../handleSentryError";


export const eventMessage = (socket,dispatch,messageEvent,updateDataCallbackRef, alarmModuleEnabled) =>{

    try{
        const data = JSON.parse(messageEvent.data);
        switch (data.path){
            case "download":
                processDownloadPackage(data,dispatch,updateDataCallbackRef);
                break;
            case "report":
                processReportPackage(data,dispatch,updateDataCallbackRef);
                break;
            case "alarmNotification":
                if(alarmModuleEnabled)
                    processNotificationPackage(data,dispatch);
                break;
            case "station":
                processStationPackage(data,dispatch);
                break;
            default:
                handleSentryError(`WebSocket path ${data.path} not supported`);
                break;
        }
    }
    catch (e) {
        handleSentryError(`Error parsing websocket message:`, messageEvent.data);
    }
};

    const processStationPackage = (data,dispatch)=>{
        switch (data.type) {
            case "lastConnection":
                dispatch(updateLastConnection(data.payload));
                break;
            case "lastData":
                dispatch(updateLastData(data.payload));
                break;
            case "position":
                dispatch(updatePosition(data.payload));
                break;
            case "battery":
                dispatch(updateBattery(data.payload));
                break;
            case "state":
                dispatch(updateState(data.payload));
                break;
            case "fw":
                dispatch(updateFW(data.payload));
                break;
            case "serial":
                dispatch(updateSerial(data.payload));
                break;
            case "model":
                dispatch(updateModel(data.payload));
                break;
            default:
                handleSentryError(`WebSocket station path data type ${data.type} not supported`);
                break;
        }
    };

    const processDownloadPackage = (data,dispatch,updateDataCallbackRef)=> {
        switch (data.type) {
            case "init":
                updateDataCallbackRef.current("download",null);
                dispatch(setProcessingProgressListAction(data.payload));
                break;
            case "progress":
                dispatch(updateProcessingProgressListAction(data.payload));
                break;
            case "state":
                if(data.payload.state === "completed" || data.payload.state === "error"){
                    dispatch(removeProcessingProgressListAction([data.payload.id]));
                }
                dispatch(updateDownloadStateAction(data.payload));
                break;
            case "delete":
                dispatch(removeProcessingProgressListAction(data.payload));
                dispatch(deleteDownloadAction(data.payload));
                break;
            case "create":
                dispatch(createDownloadAction(data.payload));
                break;
            default:
                handleSentryError(`WebSocket download path data type ${data.type} not supported`);
                break;
        }
    };

    const processReportPackage = (data,dispatch,updateDataCallbackRef) =>{
        switch (data.type) {
            case "init":
                updateDataCallbackRef.current("report",null);
                dispatch(setReportProcessingProgressListAction(data.payload));
                break;
            case "progress":
                dispatch(updateReportProcessingProgressListAction(data.payload));
                break;
            case "state":
                if(data.payload.state === "completed" || data.payload.state === "error"){
                    dispatch(removeReportProcessingProgressListAction([data.payload.id]));
                }
                dispatch(updateReportStateAction(data.payload));
                break;
            case "delete":
                dispatch(removeReportProcessingProgressListAction(data.payload));
                dispatch(deleteReportAction(data.payload));
                break;
            case "create":
                dispatch(createReportAction(data.payload));
                break;
            default:
                handleSentryError(`WebSocket report path data type ${data.type} not supported`);
                break;
        }
    };


    const processNotificationPackage =(data,dispatch)=>{
        switch (data.type) {
            case "create":
                dispatch(putNewNotificationAction(data.payload));
                break;
            default:
                handleSentryError(`WebSocket report path data type ${data.type} not supported`);
                break;
        }
    };
