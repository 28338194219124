import {Marker, Popup} from "react-map-gl";
import React, {useEffect, useRef, useState} from "react";
import applyMarkerStyle from "../../../utils/applyMarkerStyle";
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {NO_INDEX_TYPE, stationState} from "../../../constants";
import {Typography} from "@mui/material";


const OFFSET_SIZE = 15;
const popupOffsets = {
    "top": [0, OFFSET_SIZE],
    "top-left": [0, OFFSET_SIZE],
    "top-right": [0, OFFSET_SIZE],
    "bottom": [0, -OFFSET_SIZE],
    "bottom-left": [0, -OFFSET_SIZE],
    "bottom-right": [0, -OFFSET_SIZE],
    "left": [OFFSET_SIZE, 0],
    "right": [-OFFSET_SIZE, 0]
};

const useStyles = makeStyles({
    popup: {
        zIndex: 2,

        "& .mapboxgl-popup-content": {
            padding: "2px 6px",
            maxWidth: 120,
            wordBreak: "break-word",
        }
    }
});


const StationMarker = ({station, disabled, selected, handleClick}) => {
    const classes = useStyles();

    const index = useSelector(state => state.auth.units.index);
    const ref = useRef(null);

    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        while (ref.current.firstChild) {
            ref.current.removeChild(ref.current.firstChild);
        }
        applyMarkerStyle(ref.current, station, NO_INDEX_TYPE, selected);
    }, [station, index, selected]);

    return (
        <Marker key={station.id}
                longitude={station.position.long}
                latitude={station.position.lat}
                style={{zIndex: selected ? 1 : 0}}
        >
            <div ref={ref}
                 style={{cursor: disabled ? "not-allowed" : "pointer"}}
                 onMouseEnter={() => setShowTooltip(true)}
                 onMouseLeave={() => setShowTooltip(false)}
                 onClick={disabled ? null : handleClick} data-testid="station-marker" />
            {showTooltip && (
                <Popup offset={popupOffsets}
                       className={classes.popup}
                       longitude={station.position.long}
                       latitude={station.position.lat}
                       closeOnClick={false}
                       closeButton={false}>
                    {station.alias}
                    {station.state === stationState.offline && (
                        <Typography variant="subtitle2" color="error" component="span">
                            {" "}(offline)
                        </Typography>
                    )}
                </Popup>)}
        </Marker>
    );
};

export default StationMarker;