import React, {useCallback, useEffect, useMemo, useState} from "react";
import {batteryRequest} from "../../../../../requests/analytics/batteryRequest";
import ChartView from "../../../../common/chart/ChartView";
import {Grid} from "@mui/material";
import ChartLayout from "../../../../common/chart/ChartLayout";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";

const HOURS_VISIBLE = 24 * 7;

const plotLayout = {
    autosize: true,
    showlegend: false,
    yaxis: {
        title: '%',
        range: [0, 101]
    },
    xaxis: {showgrid: false, domain: [0.01, 1]},
    hovermode: "x unified",
    margin: {t: 30, b: 30, l: 35, r: 50}
};

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d", "autoScale2d", "zoomIn2d", "zoomOut2d", "zoom2d",  "toggleHover",
        "resetViews", "sendDataToCloud", "toggleSpikelines", "resetViewMapbox", "pan2d", "hoverClosestCartesian",
        "hoverCompareCartesian"],
    displaylogo : false
};

const BatteryTabView = ({ station }) => {
    const { setError } = useMessage();
    const { t } = useTranslation();
    const { handleErrorResponse } = useRequest();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            const requestData = await batteryRequest(station, HOURS_VISIBLE);
            setData(requestData);
        } catch (err) {
            handleErrorResponse(err, response => {
                if (response?.status !== 404) {
                    setError(response,
                        false,
                        "analyticScreen.deviceDescription.error_fetching_battery_level");
                }
            });
        }
        setLoading(false);
    }, [station, setError, handleErrorResponse]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const plotData = useMemo(() => {
        if (!data?.battery) return [];
        return [{
            ...data.battery,
            name: t("analyticScreen.deviceDescription.battery_level"),
            hovertemplate: `%{y}%`,
            marker: {
                color: "#1A23D0"
            }
        }];
    }, [data, t]);

    return (
        <Grid container item xs={12}>
            <ChartLayout loading={loading} emptyData={plotData.length === 0} height={175} position={"top"}
                         chartStyles={{ "& .modebar": { left: "45%" } }}>
                <ChartView
                    layout={plotLayout}
                    data={plotData}
                    config={plotConfig}
                />
            </ChartLayout>
        </Grid>
    );
};

export default BatteryTabView;
