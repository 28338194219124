

const type ={
    login:"[auth] login",
    logout:"[auth] logout",
    updateProfile:"[auth] updateProfile",
    updateOrganization: "[auth] updateOrganization",

    setRequestError:"[request] error",
    removeRequestError:"[request] remove error",
    startLoading:"[request] start loading",
    stopLoading:"[request] stop loading",
    setInfoMessage:"[request] info message",
    removeInfoMessage:"[request] remove info message",

    updateWebGlFail:"[dashboard] update webgl fail",
    updateDashboardDrawerVisibility:"[dashboard] update drawer visibility",
    updateStation:"[dashboard] update sensors",
    updateSelectedStation:"[dashboard] update selectedStation",
    updateMapSelectedStation:"[dashboard] update map selectedStation",
    updateAlias:"[dashboard] update alias",
    updateCompareStation:"[dashboard] update compareStations",
    updateLastConnection:"[dashboard] update last connection",
    updateLastData:"[dashboard] update last data",
    updatePosition:"[dashboard] update position",
    updateBattery:"[dashboard] update battery",
    updateState:"[dashboard] update state",
    updateFW:"[dashboard] update fw",
    updateSerial:"[dashboard] update serial",
    updateModel:"[dashboard] update model",

    //download list actions
    setDownloadListAction:"[download list] set downloadList",
    updateDownloadStateAction:"[download list] update downloadList state",
    deleteDownloadAction:"[download list] delete download ",
    createDownloadAction:"[download list] create download ",

    //download progress actions
    addDownloadProcessAction:"[download process] add download process",
    removeDownloadProcessAction:"[download process] remove download process",
    removeAllDownloadProcessAction:"[download process] remove all download process",
    updateDownloadProgressAction:"[download process] update download process progress",
    setDownloadProcessErrorAction:"[download process] set download process error",

    //visibility action
    setDownloadMinimizedAction:"[download visibility] set download minimized state",

    //download progress actions
    setProcessingProgressListAction:"[download progress] set processing progress list",
    removeProcessingProgressListAction:"[download progress] remove processing progress downloads",
    updateProcessingProgressListAction:"[download progress] update processing progress downloads",

    //report
    setReportListAction:"[report] set report list",
    updateReportStateAction:"[report] update report list",
    deleteReportAction:"[report] delete report from list",
    createReportAction:"[report] add report to list",

    //report progress actions
    setReportProcessingProgressListAction:"[report progress] set processing progress list",
    removeReportProcessingProgressListAction:"[report progress] remove processing progress report",
    updateReportProcessingProgressListAction:"[report progress] update processing progress report",


    //notifications
    putNewNotificationAction : "[notification] put notification into lastNotification",
    addNotificationAction : "[notification] add notification",
    removeNotificationByIdsAction : "[notification] remove notification by ids",
    setNotificationCountAction : "[notification] set notification count",
    setNotificationDataAction : "[notification] set notification data",
    resetNotificationDataAction : "[notification] reset notification data",

};

export default type;
