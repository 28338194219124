import React from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";

const AlarmConfigSteps = ({activeStep, hasStep2}) => {

    const {t} = useTranslation();

    const steps = [t("alarmScreen.alarm")];
    if (hasStep2) {
        steps.push(t("alarmScreen.trigger"));
    }
    steps.push(t("alarmScreen.stations"));

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default AlarmConfigSteps;
