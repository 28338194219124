import {useCallback, useEffect, useState} from 'react';
import {getIncidents} from "../requests/incidents/incidentsRequest";
import * as Sentry from "@sentry/react";

const usePlatformStatus = () => {

    const [isPlatformOk, setIsPlatformOk] = useState(true);
    const [lastNotifiedIncidentEpoch, setLasNotifiedEpoch] = useState(0);


    const fetchIncidentsStatus = useCallback(async ()=>{
        try {
            const openIncidents = await getIncidents();

            if(openIncidents.length === 0) return setIsPlatformOk(true);

            const epochList = openIncidents.map(item => (new Date(item.creationDate)).getTime());
            const lastEpoch = Math.max(...epochList);

            if (lastEpoch > lastNotifiedIncidentEpoch) {
                setLasNotifiedEpoch(lastEpoch);
                setIsPlatformOk(false);
            }
        }catch (e) {
            Sentry.captureException(e);
        }
    },[lastNotifiedIncidentEpoch]);

    const acknowledgePlatformError = useCallback(()=>{
        setIsPlatformOk(true);
    },[]);

    useEffect(() => {
        fetchIncidentsStatus();

        const intervalId = setInterval(fetchIncidentsStatus, 10000);

        return () => clearInterval(intervalId);
    }, [fetchIncidentsStatus]);

    return { isPlatformOk, acknowledgePlatformError };
};

export default usePlatformStatus;
