export const dayOfWeekAdapter = (data, dayKeyName, startOnSunday) => {

    if (startOnSunday)
        return data;

    const output = [];

    [0, 1, 2, 3, 4, 5, 6].forEach((dayIndex) => {
        const dayData = data.find(item => item[dayKeyName] === dayIndex);
        if (dayData) {
            const adjustedDayIndex = dayIndex === 0 ? 6 : dayIndex - 1;
            output.push({...dayData, [dayKeyName]: adjustedDayIndex});
        }
    });

    return output.sort((a, b) => a[dayKeyName] - b[dayKeyName]);

};
