import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import validator from "validator";
import {useHistory, useLocation} from "react-router-dom";
import {joinOrganizationRequest, validateInvitationRequest} from "../../../../requests/invitationRequests";
import {useDispatch} from "react-redux";
import InvalidInvitationScreen from "./InvalidInvitationScreen";
import {ERROR_500, LOGIN_ROUTE, MINIMUN_PASSWORD_LENGHT} from "../../../../constants";
import {hashGenerator} from "../../../../utils/hashGenerator";
import {forgotChangePasswordRequest, logoutRequest} from "../../../../requests/auth/authRequests";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../hooks/useLoading";
import useMessage from "../../../../hooks/useMessage";
import {isPasswordValid} from "../../../../utils/passwordValidator";
import {profileRequest} from "../../../../requests/profile/profileRequest";
import CommonDialog from "../../../CommonDialog";
import {logoutAction} from "../../../../reducers/authReducer";

const queryString = require('query-string');


const useStyles = makeStyles({
    saveButton: {
        color: '#ffffff',
    },
    card: {
        maxWidth: 1600,
        width: "100%",
        minHeight: 350,
        background: "white",
        padding: 80,
        marginTop: 90,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 6
    },
    email: {
        color: "#8a8a8a"
    },
    emailTittle: {
        fontWeight: "bold"
    },
    grip: {
        marginTop: 22
    },
    form: {
        width: "100%"
    },
    background: {
        display: "flex",
        background: "#eaeaea",
        height: "100%",
        width: "100vw",
        justifyContent: "center"
    }
});

const InvitationAndResetScreen = () => {

    const {t, i18n} = useTranslation();
    const {language} = i18n;
    const classes = useStyles();
    let location = useLocation();
    let history = useHistory();
    let dispatch = useDispatch();
    const {loading, setLoading} = useLoading();
    const {setError, setSuccess} = useMessage();
    const [consentChecked, setConsentChecked] = useState(false);
    const [showLogoutRequired, setShowLogoutRequired] = useState(false);
    const[showSuccessRegistered, setShowSuccessRegistered] = useState(false);

    useEffect(
        () => {
            const parsedValues = queryString.parse(location.search);
            let token = parsedValues.token || null;

            if (token === null) {
                updateErrorMessage(t("invitationAndResetScreen.invalid_link"));
            } else {
                setLoading(true);
                validateInvitationRequest(token, (error, data) => {
                    if (!error) {
                        profileRequest((error, _) => {
                            if (!error) setShowLogoutRequired(true);
                            updateRegisterScreen(data.create, data.organization);
                        });
                    } else {
                        switch (data.status) {
                            case 404:
                                updateErrorMessage(t("invitationAndResetScreen.invalid_link"));
                                break;
                            case 401:
                                updateErrorMessage(t("invitationAndResetScreen.expired_link"));
                                break;
                            case 400:
                                updateErrorMessage(t("invitationAndResetScreen.no_token_provided"));
                                break;
                            case 500:
                                setError(ERROR_500, true);
                                break;
                            default:
                                //setError({message: t("invitationAndResetScreen.unknown_error")});
                                break;
                        }
                    }
                    setLoading(false);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [dispatch, location.search]);


    const initialFormValues = {
        name: '', lastName: '', password: '', confirmPassword: '',
        organization: '', emptyFields: true, showPasswordError: false, showConfirmPasswordError: false,
        validInvitation: false, errorMessage: '', isJoinOrganizationTokenType: false
    };

    const [{
        name, lastName, password, confirmPassword, emptyFields,
        showPasswordError, showConfirmPasswordError, organization,
        validInvitation, errorMessage, isJoinOrganizationTokenType
    }, updateFormState] = useState(initialFormValues);


    const updateRegisterScreen = (isJoinOrganizationType, organizationName) => {
        updateFormState(state => {
            return {
                ...state, organization: organizationName, validInvitation: true,
                isJoinOrganizationTokenType: isJoinOrganizationType
            };
        });
    };

    const updateErrorMessage = (message) => {
        updateFormState(state => {
            return {...state, errorMessage: message, validInvitation: false};
        });
    };

    const handleName = (event) => {
        const value = event.target.value;
        const emptyField = validateEmptyFields(value, undefined,
            undefined, undefined);
        updateFormState(state => {
            return {...state, name: value, emptyFields: emptyField};
        });
    };

    const handleLastName = (event) => {
        const value = event.target.value;
        const emptyField = validateEmptyFields(undefined, value,
            undefined, undefined);
        updateFormState(state => {
            return {...state, lastName: value, emptyFields: emptyField};
        });
    };

    const handlePassword = (event) => {
        updatePasswordError(false);
        updateConfirmPasswordError(false);
        const value = event.target.value;
        const emptyField = validateEmptyFields(undefined,
            undefined, value, undefined);
        updateFormState(state => {
            return {...state, password: value, emptyFields: emptyField};
        });
    };

    const handleConfirmPassword = (event) => {
        updateConfirmPasswordError(false);
        const value = event.target.value;
        const emptyField = validateEmptyFields(undefined,
            undefined, undefined, value);
        updateFormState(state => {
            return {...state, confirmPassword: value, emptyFields: emptyField};
        });
    };

    const handleSave = async () => {
        if (validatePassword()) {
            const parsedValues = queryString.parse(location.search);
            let token = parsedValues.token || null;
            let digestHex = await hashGenerator(password);
            if (isJoinOrganizationTokenType) {
                joinOrganization(token, digestHex);
            } else {
                updatePassword(token, digestHex);
            }
        }
    };

    const joinOrganization = (token, digestHex) => {
        setLoading(true);
        joinOrganizationRequest({name, lastName, token, pass: digestHex}, (err, data) => {
            setLoading(false);
            if (!err) {
                setShowSuccessRegistered(true);
            } else {
                switch (data.status) {
                    case 400:
                        updateErrorMessage(t("invitationAndResetScreen.an_error_occurred_check_info"));
                        break;
                    case 401:
                        updateErrorMessage(t("invitationAndResetScreen.invitation_link_expired"));
                        break;
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        setError({message: t("invitationAndResetScreen.unknown_error")});
                        break;
                }
            }
        });
    };

    const updatePassword = (token, digestHex) => {
        setLoading(true);
        forgotChangePasswordRequest({token, pass: digestHex}, (err, data) => {
            setLoading(false);
            if (!err) {
                localStorage.removeItem('user_email');
                setSuccess("invitationAndResetScreen.password_sucessfully_updated");
                history.replace(LOGIN_ROUTE);
            } else {
                switch (data.status) {
                    case 400:
                        updateErrorMessage(t("invitationAndResetScreen.an_error_occurred_check_info"));
                        break;
                    case 401:
                        updateErrorMessage(t("invitationAndResetScreen.recover_link_expired"));
                        break;
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        setError({message: t("invitationAndResetScreen.unknown_error")});
                        break;
                }
            }
        });
    };

    const validatePassword = () => {
        if (!isPasswordValid(password)) {
            updatePasswordError(true);
            return false;
        }
        if (password !== confirmPassword) {
            updateConfirmPasswordError(true);
            return false;
        }
        return true;
    };

    const updatePasswordError = (showError) => {
        updateFormState(state => {
            return {...state, showPasswordError: showError};
        });
    };
    const updateConfirmPasswordError = (showError) => {
        updateFormState(state => {
            return {...state, showConfirmPasswordError: showError};
        });
    };


    const handleCloseSession = useCallback(()=>{
        logoutRequest((error, _) => {
            if(!error) {
                setShowLogoutRequired(false);
                dispatch(logoutAction());
            }
            else setError({message: t("invitationAndResetScreen.error_closing_session")});
        });
    },[dispatch, setError, t]);

    const handleSuccessRegistered = useCallback(()=>{
        localStorage.removeItem('user_email');
        history.replace(LOGIN_ROUTE);
    },[history]);

    const validateEmptyFields = (nameForm = name, lastNameForm = lastName,
                                 passwordForm = password, confirmPasswordForm = confirmPassword) => {
        if (isJoinOrganizationTokenType) {
            return (validator.isEmpty(nameForm) ||
                validator.isEmpty(lastNameForm) ||
                validator.isEmpty(passwordForm) ||
                validator.isEmpty(confirmPasswordForm));
        } else {
            return (
                validator.isEmpty(passwordForm) ||
                validator.isEmpty(confirmPasswordForm));
        }
    };

    const handleConsentChange = (event) => {
        setConsentChecked(event.target.checked);
    };

    const termsUrl = ["es", "ca"].includes(language)
        ? "https://bettaircities.com/es/aviso-legal/"
        : "https://bettaircities.com/legal-advice/";

    return (
        <div className={classes.background}>
            {validInvitation && <Card className={classes.card}>
                <form noValidate className={classes.form}>
                    <Typography className={classes.emailTittle} variant={"h3"}>{isJoinOrganizationTokenType
                        ? t("invitationAndResetScreen.invitation_to_join") : t("invitationAndResetScreen.reset_password")}</Typography>
                    <Typography className={classes.email} variant={"h4"}>{organization}</Typography>
                    <Grid className={classes.grip} container direction={"row"} spacing={1}>
                        {isJoinOrganizationTokenType && <Grid item xs={12} md={6}>
                            <TextField autoFocus={isJoinOrganizationTokenType}
                                       label={t("invitationAndResetScreen.name")} variant="outlined"
                                       required fullWidth margin="normal"
                                       inputProps={{"data-testid": "invitation-name"}}
                                       onChange={handleName} value={name}
                            />
                        </Grid>}
                        {isJoinOrganizationTokenType && <Grid item xs={12} md={6}>
                            <TextField label={t("invitationAndResetScreen.last_name")} variant="outlined"
                                       required
                                       fullWidth margin="normal"
                                       onChange={handleLastName}
                                       value={lastName}/>
                        </Grid>}

                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus={!isJoinOrganizationTokenType}
                                error={showPasswordError}
                                label={showPasswordError ? t("error") : t("invitationAndResetScreen.password")}
                                helperText={showPasswordError ? `${t("invitationAndResetScreen.invalidPassword")}` :
                                    ` ${t("invitationAndResetScreen.password_must_contain")} ${MINIMUN_PASSWORD_LENGHT} ${t("invitationAndResetScreen.characters")} ${t("invitationAndResetScreen.rest_password_requirements")}`}
                                variant="outlined"
                                required
                                type={"password"}
                                fullWidth margin="normal"
                                onChange={handlePassword}
                                value={password}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                error={showConfirmPasswordError}
                                label={showConfirmPasswordError ? t("error") : t("invitationAndResetScreen.confirm_password")}
                                helperText={showConfirmPasswordError ? t("invitationAndResetScreen.confirm_must_match")
                                    : t("invitationAndResetScreen.confirm_your_password")}
                                variant="outlined"
                                required
                                type={"password"}
                                inputProps={{"data-testid": "invitation-password"}}
                                fullWidth margin="normal"
                                onChange={handleConfirmPassword}
                                value={confirmPassword}/>
                        </Grid>
                        <Grid item md={8} lg={9}>
                            {isJoinOrganizationTokenType && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={consentChecked}
                                            onChange={handleConsentChange}
                                        />
                                    }
                                    label={(
                                        <Typography variant={"body2"}>
                                            {t("invitationAndResetScreen.accept_terms_and_conditions")}{" "}
                                            <a href={termsUrl} target={"_blank"} rel="noreferrer">
                                                {t("invitationAndResetScreen.terms_and_conditions")}
                                            </a>
                                        </Typography>
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                disabled={emptyFields || loading || (isJoinOrganizationTokenType && !consentChecked)}
                                className={classes.saveButton}
                                color={"primary"} fullWidth
                                onClick={handleSave} size={"large"}
                                variant="contained">{isJoinOrganizationTokenType ? t("invitationAndResetScreen.join") : t("invitationAndResetScreen.reset")}</Button>

                        </Grid>
                    </Grid>
                </form>
            </Card>}
            {!validInvitation && <InvalidInvitationScreen errorMessage={errorMessage}/>}
            {showLogoutRequired && <CommonDialog title={t("invitationAndResetScreen.error_open_session")} description={t("invitationAndResetScreen.error_open_session_message")} handleClose={handleCloseSession}/>}
            {showSuccessRegistered && <CommonDialog title={t("notification")} description={t("invitationAndResetScreen.successfully_joined")} handleClose={handleSuccessRegistered}/>}
        </div>

    );
};

export default InvitationAndResetScreen;
