import React from 'react';
import {
    FormControl,
    FormControlLabel,
    FormLabel, Radio,
    RadioGroup,
} from "@mui/material";
import WeekOption from "./WeekOption";
import MonthOption from "./MonthOption";
import {useTranslation} from "react-i18next";

export const frequencyOptions = {
    weeklyFrequency: "weeklyFrequency",
    twiceAMonth: "twiceAMonth",
    monthlyFrequency: "monthlyFrequency"
};

const AutoReportsFrequencySelector = ({
                         selectedFrequency,
                         handleFrequencyChange,
                         weekDay,
                         weekDayCallback,
                         monthFrequency,
                         monthFrequencyCallback
                     }) => {

    const { t } = useTranslation();

    return (
        <FormControl>
            <FormLabel >{t("reportScreen.reportConfig.frequency")}</FormLabel>
            <RadioGroup
                value={selectedFrequency}
                onChange={handleFrequencyChange}
            >
                <FormControlLabel value={frequencyOptions.weeklyFrequency} control={<Radio/>}
                                  label={<WeekOption weekDay={weekDay} weekDayCallback={weekDayCallback}
                                                     enable={selectedFrequency === frequencyOptions.weeklyFrequency}/>}/>
                <FormControlLabel value={frequencyOptions.twiceAMonth} control={<Radio/>}
                                  label={t("reportScreen.reportConfig.twiceAMonth")}/>
                <FormControlLabel value={frequencyOptions.monthlyFrequency} control={<Radio/>}
                                  label={<MonthOption monthFrequency={monthFrequency}
                                                      monthFrequencyCallback={monthFrequencyCallback}
                                                      enable={selectedFrequency === frequencyOptions.monthlyFrequency}/>}/>
            </RadioGroup>
        </FormControl>
    );
};

export default AutoReportsFrequencySelector;
