import { useState, createContext, useContext } from "react";
import useMatrix from "../../../../../hooks/useMatrix";

const PeriodContext = createContext({});

export const PeriodContextProvider = ({ children }) => {
    const [validatedPeriods, setValidatedPeriods] = useState([]);
    const [validationHistory, setValidationHistory] = useState([]);
    const [selectedStation, setSelectedStation] = useState("");
    const [stationSensors, setStationSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [periods, setPeriods] = useState({});
    const [newSelectedPeriod, setNewSelectedPeriod] = useState(null);
    const [rawData, setRawData] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedPositionDate, setSelectedPositionDate] = useState(null);
    const {matrix: dataMatrix, setMatrix: setDataMatrix, updateRows: updateDataMatrixRows} = useMatrix([]);
    const [dataParams, setDataParams] = useState({});
    const {matrix: selectionMatrix, setMatrix: setSelectionMatrix, updateCell: updateSelectionMatrixCell} =
        useMatrix([]);
    const [lastSelectedCell, setLastSelectedCell] = useState(null);
    const [cursorPosition, setCursorPosition] = useState({date: null, location: null});
    const [filterByCalendar, setFilterByCalendar] = useState(false);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [scrollToTableTriggered, setScrollToTableTriggered] = useState(false);


    return (
        <PeriodContext.Provider value={{validatedPeriods, setValidatedPeriods, validationHistory, setValidationHistory,
            selectedStation, setSelectedStation, stationSensors, setStationSensors, selectedSensors, setSelectedSensors,
            selectedPeriod, setSelectedPeriod, periods, setPeriods, newSelectedPeriod, setNewSelectedPeriod, rawData,
            setRawData, loading, setLoading, selectedPositionDate, setSelectedPositionDate, dataMatrix, setDataMatrix,
            updateDataMatrixRows, dataParams, setDataParams, selectionMatrix, setSelectionMatrix,
            updateSelectionMatrixCell, lastSelectedCell, setLastSelectedCell, cursorPosition, setCursorPosition,
            filterByCalendar, setFilterByCalendar, setHistoryLoading, historyLoading, scrollToTableTriggered, setScrollToTableTriggered
        }}>
            {children}
        </PeriodContext.Provider>
    );
};

export const usePeriodContext = () => useContext(PeriodContext);
