import React from 'react';
import {FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

export const periodTypeOptions = {
    dayPeriod: "dayPeriod",
    monthsPeriod: "monthsPeriod"
};

const useStyles = makeStyles({
    formControl: {
        width: 50,
        marginLeft: 10,
        marginRight: 10
    }
});

const DurationRow = ({isDaySelector, enable, value, callback}) => {

    const { t } = useTranslation();

    const options =  Array.from({length: isDaySelector ? 30 : 12}, (_, index) => index + 1);

    const classes = useStyles();

    return (<div>
        <div style={{display: "flex", alignItems: "center"}}>
            <Typography>{t("reportScreen.reportConfig.last")}</Typography>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
                <Select
                    value={value}
                    onChange={callback}
                    disabled={!enable}
                >
                    {
                        options.map((item, index) => {
                            return <MenuItem key={index} value={item}>{item}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
            <Typography>{isDaySelector ? t("reportScreen.reportConfig.days") : t("reportScreen.reportConfig.months")}</Typography>
        </div>
    </div>);
};


const AutoReportsDurationSelector = ({
                                         selectedDuration,
                                         handleDurationChange,
                                         numberOfDays,
                                         numberOfMonths,
                                         numberOfDaysCallback,
                                         numberOfMonthsCallback
                                     }) => {


    const { t } = useTranslation();

    return (
        <FormControl>
            <FormLabel>{t("reportScreen.reportConfig.period")}</FormLabel>
            <RadioGroup
                value={selectedDuration}
                onChange={handleDurationChange}
            >
                <FormControlLabel value={periodTypeOptions.dayPeriod} control={<Radio/>}
                                  label={<DurationRow value={numberOfDays} callback={numberOfDaysCallback}
                                                      isDaySelector={true}
                                                      enable={selectedDuration === periodTypeOptions.dayPeriod}/>}/>
                <FormControlLabel value={periodTypeOptions.monthsPeriod} control={<Radio/>}
                                  label={<DurationRow value={numberOfMonths} callback={numberOfMonthsCallback}
                                                      isDaySelector={false}
                                                      enable={selectedDuration === periodTypeOptions.monthsPeriod}/>}/>
            </RadioGroup>
        </FormControl>
    );
};

export default AutoReportsDurationSelector;
