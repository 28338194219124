import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMapContext} from "../MapContext";

export const STREET_MAP = "street";
export const SATELLITE_MAP = "satellite";

export const defaultMapType = SATELLITE_MAP;

const BaseMapPanel = () => {
    const {t} = useTranslation();
    const {mapType, setMapType} = useMapContext();

    const handleMapTypeChange = (event, newType) => {
        setMapType(newType);
    };

    return (
        <RadioGroup value={mapType} onChange={handleMapTypeChange}>
            <FormControlLabel value={STREET_MAP} control={<Radio/>} label={t(`panel.mapType.${STREET_MAP}`)}/>
            <FormControlLabel value={SATELLITE_MAP} control={<Radio/>} label={t(`panel.mapType.${SATELLITE_MAP}`)}/>
        </RadioGroup>
    );
};

export default BaseMapPanel;
