import {sensor} from "../../../../constants";

const variableRangeValueMap = new Map([
    [sensor.pm1, [0, 500]],
    [sensor.pm2p5, [0, 500]],
    [sensor.pm4, [0, 500]],
    [sensor.pm10, [0, 500]],
    [sensor.o3, [0, 500]],
    [sensor.no, [0, 1000]],
    [sensor.no2, [0, 1000]],
    [sensor.so2, [0, 1000]],
    [sensor.h2s, [0, 500]],
    [sensor.nh3, [0, 500]],
    [sensor.hcl, [0, 500]],
    [sensor.co, [0, 50]],
    [sensor.co2, [0, 2000]],
    [sensor.rh, [0, 100]],
    [sensor.temperature, [-100, 140]],
    [sensor.noise, [0, 140]],
    [sensor.pressure, [0, 1500]],
    [sensor.wind, [0, 1000]],
    [sensor.battery, [0, 100]],
    [sensor.temperature_meteo, [-100, 140]],
    [sensor.rh_meteo, [0, 100]],
    [sensor.solar_radiation, [0, 2000]],
    [sensor.uv_index, [0, 10]],
    [sensor.rain_rate, [0, 100]],
    [sensor.voc_iaq, [0, 1000]],
    [sensor.voc, [0, 500]]
]);

export const getVariableMaxValue = (variable) => {
    return variableRangeValueMap.get(variable)[1];
};

export const getVariableMinValue = (variable) => {
    return variableRangeValueMap.get(variable)[0];
};
