import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Chip,
    Grid,
    List,
    ListItem,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import ListSelector from "./ListSelector";
import MapSelector from "./MapSelector";


const useStyles = makeStyles({
    container: {
        alignItems: "flex-start",
        justifyContent: "center",
        flexWrap: "nowrap",
    },
    selectorList: {
        flex: "1 1 auto",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    selectedList: {
        flex: "0 0 auto",
        width: 265,
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    selectedListTitle: {
        marginLeft: 16,
        height: 32,
    },
    selectedListContent: {
        overflowY: 'auto'
    },
    validationMessage: {
        width: 565,
        height: 0,
        marginTop: 0,
        opacity: 0,
        transition: "height 0.3s, margin 0.3s, opacity 0.3s"
    },
    validationMessageVisible: {
        height: 16,
        marginTop: 8,
        opacity: 1
    },
    validationMessageText: {
        fontSize: 14,
        color: "#434343",
    },
    validationMessageTextError: {
        color: "#ce2323"
    }
});

const LIST_MODE = "listMode";
const MAP_MODE = "mapMode";

const MultipleSelector = ({initialSelected, items, selectedCallback, isSmallSize = false, isStationList, height,
                              disable = false, min, max, searchableFields, onValidateSelection = () => null,
                              customSort, isStation = undefined}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const initialSelectedStations = items.filter(
        station => initialSelected.find(selected => selected.id === station.id)
    ).map(station => station.id);

    const [checked, setChecked] = useState(initialSelectedStations);
    const [underMin, setUnderMin] = useState(false);
    const [selectionMode, setSelectionMode] = useState(LIST_MODE);

    const containerHeight = height ?? (isSmallSize ? 320 : 454);
    const checkedCount = checked?.length ?? 0;

    useEffect(() => {
        let valid = true;

        if (!disable) {
            if (min && checkedCount < min) {
                valid = false;
                setUnderMin(true);
            } else {
                setUnderMin(false);
            }
            if (max && checkedCount > max) {
                valid = false;
            }
        }
        onValidateSelection(valid);
    }, [checkedCount, disable, min, max, onValidateSelection]);

    useEffect(() => {
        const selectedItems = checked.map(id => items.find(station => station.id === id));
        return selectedCallback(selectedItems);
    }, [checked, items, selectedCallback]);

    const handleToggleMode = useCallback((event, newMode) => {
        if (newMode) {
            setSelectionMode(newMode);
        }
    }, []);

    const handleToggle = useCallback((value) => {
        const currentIndex = checked.indexOf(value.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        if (customSort) {
            newChecked.sort(customSort);
        }

        setChecked(newChecked);
    }, [checked, customSort]);

    const handleDelete = useCallback((id) => {
        const newChecked = checked.filter(stationId => stationId !== id);
        setChecked(newChecked);
    }, [checked]);

    const selectAllItems = useCallback(() => {
        setChecked(items.map(station => station.id));
    }, [items]);

    const unselectAllItems = useCallback(() => {
        setChecked([]);
    }, []);

    const handleToggleAll = useCallback(() => {
        if (checkedCount >= items.length) {
            unselectAllItems();
        } else {
            selectAllItems();
        }
    }, [checkedCount, items, selectAllItems, unselectAllItems]);

    const selectedList = useMemo(() => (
        <List className={classes.selectedListContent} data-testid="selected-list">
            {checked.map(itemId => {
                const item = items.find(item => item.id === itemId);
                return (
                    <ListItem key={item.id}>
                        <Chip
                            label={item.label}
                            onDelete={() => handleDelete(item.id)}
                        />
                    </ListItem>
                );
            })}
        </List>
    ), [classes, items, checked, handleDelete]);

    return (
        <Grid container>
            <Grid item container spacing={1} className={classes.container} sx={{height: containerHeight}}>
                <Grid item className={classes.selectorList}>
                    {isStationList && (
                        <ToggleButtonGroup
                            style={{width: "100%", height: 40, marginBottom: 16}}
                            color="primary"
                            value={selectionMode}
                            onChange={handleToggleMode}
                            exclusive
                        >
                            <ToggleButton size={"small"} style={{flex: 1, maxWidth: 150}} value={LIST_MODE}>{t("selector.selector_list")}</ToggleButton>
                            <ToggleButton size={"small"} style={{flex: 1, maxWidth: 150}} value={MAP_MODE}>{t("selector.selector_map")}</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    <ListSelector disabled={disable} items={items} selectedItems={checked} maxItems={max}
                                  searchableFields={searchableFields} hidden={selectionMode !== LIST_MODE}
                                  handleToggle={handleToggle} handleToggleAll={handleToggleAll} isStation={isStation}/>
                    {isStationList && (
                        <MapSelector disabled={disable} items={items} selectedItems={checked} maxItems={max}
                                     hidden={selectionMode !== MAP_MODE} handleToggle={handleToggle}
                                     handleSelectAll={selectAllItems} handleUnselectAll={unselectAllItems} />
                    )}
                </Grid>
                <Grid item className={classes.selectedList}>
                    {!disable && (
                        <Typography className={classes.selectedListTitle} variant={"subtitle1"}
                                    data-testid="selected-list-title">{
                            checked.length ? `${t("selector.current_selection")}:` : t("selector.no_selection")
                        }</Typography>
                    )}
                    {!disable && selectedList}
                </Grid>
            </Grid>
            <Grid item className={clsx(classes.validationMessage, !disable && (underMin || max) && classes.validationMessageVisible)}>
                <Typography className={
                    clsx(classes.validationMessageText, underMin && classes.validationMessageTextError)
                } data-testid="validation-text">
                    {underMin ? t("selector.select_at_least", {count: min}) : (
                        max ? t("selector.select_up_to", {count: max}) : ""
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MultipleSelector;
