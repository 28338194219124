import React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SATELLITE_MAP, STREET_MAP} from "../../../home/panel/BaseMapPanel";
import {useMapContext} from "./MapContext";

const MapLayer = () => {

    const {t} = useTranslation();
    const {mapType, setMapType} = useMapContext();

    const handleMapTypeChange = (event, newType) => {
        setMapType(newType);
    };

    return (
        <div style={{
                     marginTop: 24,
                     width: 200,
                 }}>
            <FormControl>
                <FormLabel id="pollutant-map-group-label">{t("panel.base_map")}</FormLabel>
                <RadioGroup value={mapType} onChange={handleMapTypeChange}>
                    <FormControlLabel value={STREET_MAP} control={<Radio/>} label={t(`panel.mapType.${STREET_MAP}`)}/>
                    <FormControlLabel value={SATELLITE_MAP} control={<Radio/>} label={t(`panel.mapType.${SATELLITE_MAP}`)}/>
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default MapLayer;
