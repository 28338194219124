import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const downloadReportListRequest = (callback) => {
    webClient.get('/report')
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};