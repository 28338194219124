import {useCallback, useMemo, useState} from "react";
import useMessage from "../../../../../../hooks/useMessage";
import {usePeriodContext} from "../../common/PeriodContext";
import {useSelector} from "react-redux";
import useRequest from "../../../../../../hooks/useRequest";
import {getStationTableData} from "../../../../../../requests/validation/tableDataRequests";
import {dateToUTCServerFormatString} from "../../../../../../utils/dateToUtcStringConverter";
import {timeZoneOptions} from "../../../../../../constants";
import {getFormattedTime, getDataTableFormattedTime} from "../../../../../../utils/getFormattedDateTime";
import {useTranslation} from "react-i18next";

export const LOAD_LIMIT = 50;
export const VALUE_TAG = "value";

const useTableData = () => {
    const {t} = useTranslation();
    const {setError} = useMessage();
    const [loadingData, setLoadingData] = useState(false);
    const [firstLoadingCompleted, setFirstLoadingCompleted] = useState(false);
    const {selectedStation, stationSensors, selectedPeriod, setDataMatrix: setMatrix, updateDataMatrixRows: updateRows,
        dataParams, setDataParams} = usePeriodContext();
    const selectedStationData = useSelector(state => (
        state.dashboardUI.stations.find(({id}) => selectedStation === id)
    ));
    const {timeZone: dataTimeZone, units} = useSelector(state => state.auth);
    const {handleErrorResponse} = useRequest();

    const getData = useCallback(async (offset = undefined, limit = LOAD_LIMIT) => {
        const {temperature, pollutants} = units;
        try {
            return await getStationTableData({
                station: selectedStation,
                time: dateToUTCServerFormatString(selectedPeriod.startDate),
                endtime: dateToUTCServerFormatString(selectedPeriod.endDate),
                offset,
                limit,
                temperature,
                pollutants
            });
        } catch (err) {
            handleErrorResponse(err, () => {
                setError(err, false);
            });
        }
    }, [units, selectedStation, selectedPeriod, handleErrorResponse, setError]);

    const addDataToMatrix = useCallback((response) => {
        // Calculate resolution
        const firstDate = new Date(response.data.x[0]);
        const secondDate = new Date(response.data.x[1]);
        const dataResolution = secondDate - firstDate;
        // Set data params
        const startDate = new Date(response.time);
        const newDataParams = {resolution: dataResolution, startDate};
        setDataParams(dataParams => {
            if (dataParams.resolution !== newDataParams.resolution ||
                startDate.getTime() !== dataParams.startDate?.getTime()) {
                return newDataParams;
            }
            return dataParams;
        });

        const newRows = [];
        response.data.x.forEach((date, index) => {
            const indexDate = new Date(date);
            const rowIndex = Math.floor((indexDate - startDate) / dataResolution);
            newRows[rowIndex] = stationSensors.map((sensor) => response.data[sensor].data[index]);
        });
        updateRows(newRows, VALUE_TAG);
    }, [stationSensors, updateRows, setDataParams]);

    const getTableData = useCallback(() => {
        if (!firstLoadingCompleted && selectedStation && selectedPeriod && !loadingData) {
            setLoadingData(true);

            getData().then((res) => {
                const pollutantData = res?.data;
                if (pollutantData) {
                    // Create new matrix
                    setMatrix(new Array(res.count).fill([]));
                    addDataToMatrix(res);
                }
            }).finally(() => {
                setFirstLoadingCompleted(true);
                setLoadingData(false);
            });
        }
    }, [firstLoadingCompleted, selectedStation, selectedPeriod, loadingData, setLoadingData, setMatrix, getData,
        addDataToMatrix]);

    const monthNames = useMemo(() => [
        t("validation.monthNames.january"),
        t("validation.monthNames.february"),
        t("validation.monthNames.march"),
        t("validation.monthNames.april"),
        t("validation.monthNames.may"),
        t("validation.monthNames.june"),
        t("validation.monthNames.july"),
        t("validation.monthNames.august"),
        t("validation.monthNames.september"),
        t("validation.monthNames.october"),
        t("validation.monthNames.november"),
        t("validation.monthNames.december")
    ], [t]);

    const rowIndexToDate = useCallback((rowIndex, dataParams) => (
        new Date(dataParams.startDate?.getTime() + rowIndex * dataParams.resolution)
    ), []);

    const rowIndexToTimeRange = useCallback((rowIndex, dataParams) => {
        const startTime = rowIndexToDate(rowIndex, dataParams);
        const endTime = new Date(startTime.getTime() + dataParams.resolution);
        const timeZone =
            (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
            || timeZoneOptions.UTC;
        return `${getDataTableFormattedTime(monthNames, startTime, timeZone)} - ${getFormattedTime(endTime, timeZone, false)}`;
    }, [rowIndexToDate, monthNames, dataTimeZone, selectedStationData]);

    const rowIndexToTimeString = useCallback((rowIndex, dataParams) => (
        rowIndexToDate(rowIndex, dataParams).toISOString()
    ), [rowIndexToDate]);

    const dateToRowIndex = useCallback((date) => {
        return Math.floor((date - dataParams.startDate) / dataParams.resolution);
    }, [dataParams]);

    const timeStringToRowIndex = useCallback((timeString) => {
        const time = new Date(timeString);
        return dateToRowIndex(time);
    }, [dateToRowIndex]);

    const loadMoreElements = useCallback(async (startIndex, limit) => {
        setLoadingData(true);
        const offset = rowIndexToTimeString(startIndex, dataParams);
        try {
            const res = await getData(offset, limit);
            const pollutantData = res?.data;
            if (pollutantData) {
                addDataToMatrix(res);
            }
        } catch (err) {
            setError("chart.errorFetchingData");
        } finally {
            setLoadingData(false);
        }
    }, [getData, rowIndexToTimeString, dataParams, addDataToMatrix, setError, setLoadingData]);

    return {loadMoreElements, rowIndexToTimeRange, rowIndexToDate, dateToRowIndex, timeStringToRowIndex, getTableData,
        loadingData};
};

export default useTableData;