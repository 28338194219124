import React, {useState} from 'react';
import {Backdrop} from "@mui/material";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRange from "react-date-range/dist/components/DateRange";
import {getFinishOfDayInUTC, getInitOfDayInUTC, getDateFnsLocale} from "../../../utils/dateUtil";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    buttonContainer: {
        display:"flex",
        justifyContent:"flex-end",
        background:"white"
    },
    pickerContainer:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        height:445,
        borderRadius:6,
        padding:20,
        background:"white",
    }
});

const DateRangeComponent = ({open, changeState,onDateRangePicked}) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const handleCancel = ()=>{
        changeState(false);
    };

    const initialState = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    };
    const [state, setState] = useState([initialState]);

    const handleOk = ()=>{
        onDateRangePicked({startDate:getInitOfDayInUTC(state[0].startDate),
            endDate:getFinishOfDayInUTC(state[0].endDate)});
        setState([initialState]);
    };

    const updateState =(item)=>{
        setState([item.selection]);
    };

    return (
        <Backdrop style={{zIndex: 1999, color: '#fff'}} open={open}>
            <div className={classes.pickerContainer}>
                <DateRange
                    locale={getDateFnsLocale()}
                    editableDateInputs={true}
                    onChange={updateState}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    fixedHeight={true}
                    rangeColors = {["#3333FF"]}
                />;
                <div className={classes.buttonContainer}>
                    <Button onClick={handleCancel} color={"primary"}>{t("cancel")}</Button>
                    <Button onClick={handleOk} color={"primary"} >{t("ok")}</Button>
                </div>
            </div>
        </Backdrop>
    );
};

export default DateRangeComponent;
