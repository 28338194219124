import React, {useCallback, useState} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import {getStationOptions, STATION_SEARCHABLE_FIELDS} from "../../../../../utils/stationUtil";

const useStyles = makeStyles({
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
    popUpContainer: {
        maxWidth: 750
    },
    selectorContainer: {
        padding: 30
    }
});

const CompareStationSelectorPopUp = ({open,handleClose}) => {

    const {t} = useTranslation();
    const stations = useSelector(state => getStationOptions(state.dashboardUI.stations), isEqual);
    const selectCompareStations = useSelector(state =>
        getStationOptions(state.dashboardUI.selectCompareStations), isEqual);
    const [{transferCurrentSelection}, updateSelectedStations] = useState({selectedStations:selectCompareStations});
    const [isValidSelection, setValidSelection] = useState(false);


    const updateSelectedStationsCallback = useCallback((selectedStations)=>{
        updateSelectedStations({transferCurrentSelection:selectedStations});
    },[updateSelectedStations]);

    const classes = useStyles();

    const history = useHistory();

    const handleSelected = () => {
        let query = "";
        transferCurrentSelection.forEach((value,index) =>{

            if(index === 0){
                query = query +`?id=${value.id}`;
            }else{
                query = query+`&id=${value.id}`;
            }
        });
        history.push(`/compare/${query}`);
        handleClose();
    };

    const handleSelectionValidation = (isValid) => {
        setValidSelection(isValid);
    };

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card}>
                <Grid container className={classes.popUpContainer} justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("dashboard.select_stations")}</Typography>
                        <Divider/>
                    </Grid>
                    <Grid container className={classes.selectorContainer} item xs={12}>
                        {(stations?.length>0) && <MultipleSelector items={stations}
                                                                   isStationList
                                                                   initialSelected={selectCompareStations}
                                                                   selectedCallback={updateSelectedStationsCallback}
                                                                   min={2} max={8}
                                                                   searchableFields={STATION_SEARCHABLE_FIELDS}
                                                                   onValidateSelection={handleSelectionValidation}
                                                                   isStation={true}
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleClose}>{t("cancel")}</Button>
                            <Button onClick={handleSelected} disabled={!isValidSelection}>{t("action.save")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default CompareStationSelectorPopUp;
