import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {getVariableName} from "../../routes/private/pollutantNames";

const PollutantToggleButtons = ({className,pollutantList,pollutantCallback,selectedPollutant,disable = false}) => {

        const handleAlignment = (event, newAlignment) => {
            if (newAlignment !== null) {
                pollutantCallback(newAlignment);
            }
        };

    return (
        <ToggleButtonGroup
            className={className}
            value={selectedPollutant}
            exclusive
            onChange={handleAlignment}
        >
            {pollutantList.map(pollutant => {
            return <ToggleButton
                disabled={disable}
                        data-testid={"toggle-"+pollutant}
                        key={pollutant}
                        value={pollutant} >
                        {
                            getVariableName(pollutant)
                        }
                </ToggleButton>;
            })}
        </ToggleButtonGroup>
    );
};

export default PollutantToggleButtons;
