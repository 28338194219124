import React from "react";
import Plot from "react-plotly.js";

const ChartView = ({config, layout, data, onLegendClick, onLegendDoubleClick, onClick, onSelected, onHover,
                       onUnHover}) => {
    return <Plot
        useResizeHandler={true}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        layout={layout}
        data={data}
        onLegendDoubleClick={onLegendDoubleClick}
        onLegendClick={onLegendClick}
        onClick={onClick}
        onSelected={onSelected}
        onHover={onHover}
        onUnhover={onUnHover}
        config={config}>
    </Plot>;
};

export default ChartView;
