const hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const rgbToHex = (rgb) => {
    return "#" + ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1);
};

const addAlphaColor = (baseColor, alphaColor) => {
    // Convert base and alpha colors to RGB
    let baseRGB = hexToRgb(baseColor);
    let alphaRGB = hexToRgb(alphaColor.slice(0, 7));

    // Convert alpha value to decimal
    let alpha = parseInt(alphaColor.slice(7), 16) / 255;

    // Calculate new color
    let newRGB = {
        r: Math.round((1 - alpha) * baseRGB.r + alpha * alphaRGB.r),
        g: Math.round((1 - alpha) * baseRGB.g + alpha * alphaRGB.g),
        b: Math.round((1 - alpha) * baseRGB.b + alpha * alphaRGB.b)
    };

    // Convert new color to hexadecimal
    return rgbToHex(newRGB);
};

export default addAlphaColor;