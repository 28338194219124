import {unit} from "../../../constants";
import {getVariableUnit} from "../../../utils/getVariableUnit";

export const unitsMap = new Map([
    [unit.ppb, "ppb"],
    [unit.ppm, "ppm"],
    [unit.ug_m3, "µg/m³"],
    [unit.mg_m3, "mg/m³"],
    [unit.celsius, "ºC"],
    [unit.percentage, "%"],
    [unit.fahrenheit, "ºF"],
    [unit.mps, "m/s"],
    [unit.mph, "mph"],
    [unit.knots, "kn"],
    [unit.degrees, "º"],
    [unit.hpa, "hPa"],
    [unit.dba, "dB(A)"],
    [unit.iaq, "VOC IAQ"],
    [unit.mm_h, "mm/h"],
    [unit.w_m2, "W/m²"],
    [unit.uv, "UV"]
]);

export const getVariableUnitName = (variable, units) => {
    const variableUnit = getVariableUnit(variable, units);
    return unitsMap.get(variableUnit);
};
