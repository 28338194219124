import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {updateAliasRequest} from "../../../../../requests/analytics/stationInfoRequest";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../../hooks/useLoading";
import useMessage from "../../../../../hooks/useMessage";

const UpdateAliasPopUp = ({open, handleClose, currentAlias, stationId}) => {

    const {t} = useTranslation();
    const isMounted = useMountComponent();
    const { loading, setLoading } = useLoading();
    const { setError, setSuccess } = useMessage();

    useEffect(
        () => {
            updateState(state => ({...state, alias: currentAlias}));
        }, [currentAlias]);

    let [{alias}, updateState] = useState({alias: ""});

    const handleTextFieldChange = (event) => {
        updateState(state => ({...state, alias: event.target.value}));
    };

    const handleUpdate = () => {
        setLoading(true);
        updateAliasRequest({stationId, alias}, (err, data) => {
            setLoading(false);
            if (isMounted.current) {
                if (!err) {
                    setSuccess("analyticScreen.deviceDescription.alias_success_updated");
                    handleClose(true, [stationId, alias]);
                } else {
                    setError(data,
                        false,
                        `${t("error")} ${data.status},
                        ${t("analyticScreen.deviceDescription.updating_alias")}`);
                    handleClose(false, null);
                }
            }
        });
    };

    return (
        <Dialog fullWidth open={open} onClose={_ => handleClose(false, null)}
                aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title">{t("analyticScreen.deviceDescription.change_station_alias")}</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={loading}
                    inputProps={{"data-testid": "update-alias-text-field"}}
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("analyticScreen.deviceDescription.device_alias")}
                    type="text"
                    value={alias}
                    onChange={handleTextFieldChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={_ => handleClose(false, null)} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    data-testid={"update-alias-update-button"}
                    disabled={alias === "" || loading} onClick={_ => handleUpdate()} color="primary">
                    {t("update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateAliasPopUp;
