import {
    convertToCelsius,
    getDecimalPrecision,
    molecularWeight,
    conversionFahrenheit,
    getVelocityMultiplier,
    getGasMultiplier
} from "bettairplaformutil/src/dataUtil";

const convertUnit = (multiplier, value, convertToServer) => {
    if (convertToServer) {
        return value / multiplier;
    }
    return value * multiplier;
};

/**
 *
 * @param variable {string} - variable type
 * @param value {number} - value to convert
 * @param units {{
 *         "pollutants": "usa|eu",
 *         "temperature": "celsius|fahrenheit",
 *         "index": "AQI|EAQI|ICQA|DIBA|ISPU|AQHI|APIMS|AQHI-HK|AQI-QB|ICA",
 *         "windSpeed": "mps"
 *     }} - user units
 * @param convertToServer {boolean} - If true, convert to server units. If false, convert to user units
 * @return {number} return converted value
 */
export const convertValueToUnit = (variable, value, units, convertToServer) => {
    let newValue = value;
    if (Object.keys(molecularWeight).includes(variable)) {
        newValue = units.pollutants === "eu" ? value : convertUnit(getGasMultiplier(variable), value, convertToServer);
    } else if (variable === "temperature") {
        newValue = units.temperature === "celsius" ? value : (
            convertToServer ? convertToCelsius(value) : conversionFahrenheit(value)
        );
    } else if (variable === "windSpeed" || "windGust") {
        newValue = convertUnit(getVelocityMultiplier(units.windSpeed), value, convertToServer);
    }
    return getDecimalPrecision(newValue, variable);
};



