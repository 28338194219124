import {useEffect, useState} from "react";

const now = new Date();

const useDateTime = (dateTime = now) => {
    const [selectedDate, setSelectedDate] = useState(new Date(dateTime));
    const [selectedTime, setSelectedTime] = useState(new Date(dateTime));
    const [selectedDateTime, setSelectedDateTime] = useState(new Date(dateTime));

    useEffect(() => {
        const date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        setSelectedDate(date);

        const time = new Date(dateTime);
        time.setSeconds(0, 0);
        setSelectedTime(time);
    }, [dateTime]);

    useEffect(() => {
        if (selectedDate) {
            const newDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                selectedDateTime.getHours(),
                selectedDateTime.getMinutes()
            );
            if (newDate.getTime() !== selectedDateTime.getTime()) {
                setSelectedDateTime(newDate);
            }
        }
    }, [selectedDate, selectedDateTime]);

    useEffect(() => {
        if (selectedTime) {
            const newDate = new Date(
                selectedDateTime.getFullYear(),
                selectedDateTime.getMonth(),
                selectedDateTime.getDate(),
                selectedTime.getHours(),
                selectedTime.getMinutes()
            );
            if (newDate.getTime() !== selectedDateTime.getTime()) {
                setSelectedDateTime(newDate);
            }
        }
    }, [selectedTime, selectedDateTime]);

    return {
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        selectedDateTime,
        setSelectedDateTime
    };
};

export default useDateTime;