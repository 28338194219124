import {useCallback, useReducer} from "react";

const SET_MATRIX = "SET_MATRIX";
const UPDATE_CELL = "UPDATE_CELL";
const UPDATE_ROW = "UPDATE_ROW";
const UPDATE_ROWS = "UPDATE_ROWS";

const matrixReducer = (state, action) => {
    let newMatrix;
    switch (action.type) {
        case SET_MATRIX:
            return action.payload;
        case UPDATE_CELL:
            newMatrix = [...state];
            newMatrix[action.payload.row] = [...(newMatrix[action.payload.row] ?? [])];
            newMatrix[action.payload.row][action.payload.column] = action.payload.value;
            return newMatrix;
        case UPDATE_ROW:
            newMatrix = [...state];
            newMatrix[action.payload.row] = action.payload.newRow;
            return newMatrix;
        case UPDATE_ROWS:
            newMatrix = [...state];
            Object.entries(action.payload.rows).forEach(([row, newRow]) => {
                newMatrix[row] = [...(newMatrix[row] ?? [])];
                newRow.forEach((value, index) => {
                    if (action.payload.tag === null) {
                        newMatrix[row][index] = value;
                    } else {
                        newMatrix[row][index] = {...newMatrix[row][index], [action.payload.tag]: value};
                    }
                });
            });
            return newMatrix;
        default:
            return state;
    }
};

const useMatrix = (initialRows) => {
    const [matrix, dispatch] = useReducer(matrixReducer, initialRows);

    const setMatrix = useCallback((newMatrix) => {
        dispatch({type: SET_MATRIX, payload: newMatrix});
    }, [dispatch]);

    const updateCell = useCallback((row, column, value) => {
        dispatch({type: UPDATE_CELL, payload: {row, column, value}});
    }, [dispatch]);

    const updateRow = useCallback((row, newRow) => {
        dispatch({type: UPDATE_ROW, payload: {row, newRow}});
    }, [dispatch]);

    const updateRows = useCallback((rows, tag = null) => {
        dispatch({type: UPDATE_ROWS, payload: {rows, tag}});
    }, [dispatch]);

    return {matrix, setMatrix, updateCell, updateRow, updateRows};
};

export default useMatrix;