export const
    WRITE_ALIAS = 'writeAlias',
    READ_ORGANIZATION_USERS = 'readOrganizationUsers',
    WRITE_ORGANIZATION_USERS = 'writeOrganizationUsers',
    WRITE_ORGANIZATION_CONFIG = 'writeOrganizationConfig',
    READ_ORGANIZATION_ROLES = 'readOrganizationRoles',
    WRITE_ORGANIZATION_ROLES = 'writeOrganizationRoles',
    READ_ORGANIZATION_REPORTS = 'readOrganizationReports',
    READ_ORGANIZATION_DOWNLOADS = 'readOrganizationDownloads',
    WRITE_REPORTS = 'writeReports',
    WRITE_DOWNLOADS = 'writeDownloads',
    READ_ALARMS = 'readAlarms',
    WRITE_ALARMS = 'writeAlarms',
    WRITE_LOG = 'writeLog',
    READ_LOG = 'readLog',
    READ_ORGANIZATION_VALID_PERIODS = 'readOrganizationValidPeriods',
    WRITE_ORGANIZATION_VALID_PERIODS = "writeOrganizationValidPeriods";
