import {useMemo} from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";

export const sections = [
    {id: "cover", name: "cover"},
    {id: "pageIndex", name: "page_index"},
    {id: "context", name: "context"},
    {id: "summary", name: "summary"},
    {id: "rd"},
    {id: "stationsData", name: "stations_detail"},
    {id: "annex", name: "hourly_data_table"},
    {id: "cmms", name: "cmms_table"}
];

const initialSelected = [
    "cover",
    "pageIndex",
    "context",
    "summary",
    "rd",
    "stationsData"
];

const customSort = (a, b) => {
    const aIndex = sections.findIndex(({id}) => id === a);
    const bIndex = sections.findIndex(({id}) => id === b);
    return aIndex - bIndex;
};

const SectionsStep = ({initialSelection, callback}) => {
    const {t} = useTranslation();

    const { organization } = useSelector( state => state.auth );

    const sectionItems = useMemo(() => {
        return sections.map(({id, name}) => ({
            id,
            label: id !== "rd" ? t(`reportScreen.reportConfig.${name}`) : `${t(`reportScreen.reportConfig.${organization.legalThreshold}`)}*`,
        }));
    }, [t, organization.legalThreshold]);

    return (
        <div>
        <MultipleSelector initialSelected={initialSelection || initialSelected.map(item => sections.find(({id}) => id === item))}
                          items={sectionItems}
                          selectedCallback={callback}
                          customSort={customSort}
                          min={1}
            height={420}
        />
            <Typography style={{marginTop:10}}>{t("reportScreen.reportConfig.change_legal_limits")}</Typography>
        </div>
    );
};

export default SectionsStep;
