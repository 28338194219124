import {
    FormControl,
    FormControlLabel,
    FormGroup, IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import VisibilityCheckbox from "./VisibilityCheckbox";
import {useTranslation} from "react-i18next";
import {useMapContext} from "../MapContext";
import {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import {getAvailableSensors, getStationSensors} from "../../../../../utils/stationUtil";
import {pollutants, sensor} from "../../../../../constants";
import LocationOffIcon from '@mui/icons-material/LocationOff';
import AnalyticsStationSelector from "../../dashboard/AnalyticsStationSelector";

export const AQI_MAP = "aqi";
export const POLLUTANT_ROSE_MAP = "pollutant_roses";

const StationDataPanel = () => {
    const {t} = useTranslation();
    const index = useSelector(state => state.auth.units.index);
    const stations = useSelector(state => state.dashboardUI.stations, isEqual);
    const {
        selectedPollutant, setSelectedPollutant, showOfflineStationsOn, setShowOfflineStationsOn,
        setStationLayerActive, pollutantRosesLayerActive, setPollutantRosesLayerActive
    } = useMapContext();

    const noPositionCount = useMemo(() => {
        return stations.filter(station => !station.position).length;
    }, [stations]);

    const sensors = useSelector(state => {
        return getAvailableSensors(state.dashboardUI.stations).filter(({id}) => (
            pollutants.includes(id)
        )).sort((a, b) => pollutants.indexOf(a.id) - pollutants.indexOf(b.id));
    }, isEqual);

    const hidePollutantRoseOptions = useMemo(() => {
        return stations.map(item => getStationSensors(item)).every(array => !array.includes(sensor.wind));
    }, [stations]);

    useEffect(() => {
        if (stations.length > 0 && hidePollutantRoseOptions) {
            setStationLayerActive(true);
        }
    }, [stations, hidePollutantRoseOptions, setStationLayerActive]);

    useEffect(() => {
        if (sensors.length > 0 && !selectedPollutant) {
            setSelectedPollutant(sensors[0].id);
        }
    }, [sensors, selectedPollutant, setSelectedPollutant]);

    const stationLayer = pollutantRosesLayerActive ? POLLUTANT_ROSE_MAP : AQI_MAP;

    const handleStationLayerChange = useCallback((event, newValue) => {
        setStationLayerActive(newValue === AQI_MAP);
        setPollutantRosesLayerActive(newValue === POLLUTANT_ROSE_MAP);
    }, [setStationLayerActive, setPollutantRosesLayerActive]);

    const handleMapPollutantChange = useCallback((event) => {
        setSelectedPollutant(event.target.value);
    }, [setSelectedPollutant]);

    const handleShowOfflineStationsChange = () => {
        setShowOfflineStationsOn(!showOfflineStationsOn);
    };

    return (
        <div style={{overflow: "visible"}}>
            <FormGroup>
                {!hidePollutantRoseOptions &&
                    <RadioGroup value={stationLayer} onChange={handleStationLayerChange}>
                        <FormControlLabel value={AQI_MAP} control={<Radio/>}
                                          label={t(`panel.station_data_layer.index_data`, {index})}/>
                        <FormControlLabel value={POLLUTANT_ROSE_MAP} control={<Radio/>}
                                          label={t(`panel.station_data_layer.${POLLUTANT_ROSE_MAP}`)}/>
                        {pollutantRosesLayerActive && (
                            <FormControl
                                variant="outlined"
                                fullWidth
                                margin={"normal"}
                                size="small">
                                <InputLabel>{t("panel.pollutant")}</InputLabel>
                                <Select
                                    label={t("panel.pollutant")}
                                    onChange={handleMapPollutantChange}
                                    value={selectedPollutant || ""}
                                    data-testid="select-map-pollutant"
                                >
                                    {sensors.map(({id, alias}) => (
                                        <MenuItem key={id} value={id}>{alias}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </RadioGroup>
                }
                <FormControlLabel
                    control={
                        <VisibilityCheckbox checked={showOfflineStationsOn} onChange={handleShowOfflineStationsChange}/>
                    }
                    label={t("legend.no_connection")}
                />
                {noPositionCount > 0 && <FormControlLabel style={{cursor: 'default'}}
                                                          control={
                                                              <IconButton style={{cursor: 'default', backgroundColor: "transparent"}}>
                                                                  <LocationOffIcon color={"primary"}/>
                                                              </IconButton>
                                                          }
                                                          label={t("home.noLocationStations", {count: noPositionCount})}
                />}
            </FormGroup>
            {noPositionCount > 0 &&
                <div style={{paddingLeft: 28, overflow: "visible"}}>
                    <AnalyticsStationSelector
                        hideStationsWithLocation={true} dark={true}/>
                </div>}
        </div>
    );
};

export default StationDataPanel;
