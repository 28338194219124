import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from "@mui/material";
import {MINIMUN_PASSWORD_LENGHT} from "../../../../constants";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {isPasswordValid} from "../../../../utils/passwordValidator";

const useStyles = makeStyles({
    button: {
        color: '#ffffff',
        marginBottom: 12,
        marginTop: 12
    },
    loginContainer: {
        minWidth: 400,
        maxWidth: 600,
        background: 'white',
        padding: 40,
        borderRadius: 6
    },
    form: {
        width: '100%'
    },
    box: {
        background: "#f18484",
        color: "#fff",
        borderRadius: 6,
        width: "100%",
        padding: 16,
        marginBottom: 24,
        boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
        cursor: "default",
        transition: "opacity 0.5s ease-in-out, left 0.5s ease-in-out, right 0.5s ease-in-out",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    greenBox: {
        background: "#77a04b",
        color: "#ffffff",
        borderRadius: 6,
        width: "100%",
        padding: 16,
        marginBottom: 8,
        boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
        cursor: "default",
        transition: "opacity 0.5s ease-in-out, left 0.5s ease-in-out, right 0.5s ease-in-out",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
});

const ExpiredPassword = ({loading, handleUpdatePassword}) => {
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [showNotEqualPasswordsMessage, setShowNotEqualPasswordsMessage] = useState(false);

    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        setValidForm(isPasswordValid(newPassword) && isPasswordValid(repeatPassword));
        setShowNotEqualPasswordsMessage(false);
    }, [newPassword, repeatPassword]);

    const validateEqualPasswords = () => {
        const equalPasswords = newPassword === repeatPassword;
        setValidForm(equalPasswords);
        setShowNotEqualPasswordsMessage(!equalPasswords);
        return equalPasswords;
    };

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const handleRepeatPassword = (event) => {
        setRepeatPassword(event.target.value);
    };

    const handleSetNewPassword = () => {
        if (validateEqualPasswords()) {
            handleUpdatePassword(newPassword);
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleSetNewPassword();
        }
    };

    return (
        <Grid container item xs={6} md={4} justifyContent={"center"} alignItems={"center"} direction={"column"}
              className={classes.loginContainer} alignContent={"center"}
        >

            <Grid container item>
                <div className={clsx(classes.greenBox)}>
                    <CheckCircleIcon/>
                    <Typography variant={"subtitle1"}
                                style={{
                                    marginLeft: 8,
                                    fontWeight: "bold"
                                }}>
                        {t("loginScreen.successful_otp_message")}
                    </Typography>
                </div>
            </Grid>
            <Grid container item>
                <div className={clsx(classes.box)}>
                    <WarningIcon/>
                    <Typography variant={"subtitle1"}
                                style={{
                                    marginLeft: 8,
                                    fontWeight: "bold"
                                }}>
                        {t("loginScreen.expired_password_message")}
                    </Typography>
                </div>
            </Grid>
            <Grid container item>
                <form className={classes.form} noValidate>
                    <TextField label={t('changePassword.new_password')} variant="outlined"
                               required autoComplete="current-password"
                               type="password" fullWidth margin="none"
                               onChange={handleNewPassword}
                               value={newPassword}
                               onKeyPress={handleEnter}
                               inputProps={{"data-testid": "login-new-password"}}
                               helperText={`${t('loginScreen.minimun_password')} ${MINIMUN_PASSWORD_LENGHT} ${t('loginScreen.characters')} ${t('loginScreen.rest_password_requirements')}`}
                    />
                    <TextField label={t("invitationAndResetScreen.confirm_password") }
                               helperText={showNotEqualPasswordsMessage
                                   ? t("invitationAndResetScreen.confirm_must_match")
                                   : `${t('loginScreen.minimun_password')} ${MINIMUN_PASSWORD_LENGHT} ${t('loginScreen.characters')} ${t('loginScreen.rest_password_requirements')}`}
                               error={showNotEqualPasswordsMessage}
                               variant="outlined"
                               required autoComplete="current-password"
                               type="password" fullWidth margin="none"
                               onChange={handleRepeatPassword}
                               value={repeatPassword}
                               onKeyPress={handleEnter}
                               inputProps={{"data-testid": "login-repeat-password"}}
                    />
                    <Button
                        data-testid="login-sign-in"
                        disabled={!validForm || loading} className={classes.button} color={"primary"} fullWidth
                        onClick={handleSetNewPassword} size={"large"} variant="contained">{t('action.save')}</Button>
                </form>
            </Grid>
        </Grid>
    );
};
export default ExpiredPassword;
