import React from 'react';
import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    formControl: {
        width: 50,
        marginLeft: 10,
        marginRight:10
    }
});
const MonthOption = ({enable, monthFrequency, monthFrequencyCallback}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
            <div style={{display:"flex", alignItems:"center"}}>
                <Typography>{t("reportScreen.reportConfig.every")}</Typography>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                    <Select
                        value={monthFrequency}
                        onChange={monthFrequencyCallback}
                        disabled={!enable}
                    >
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
                                return <MenuItem key={index} value={item}>{item}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
                <Typography>{t("reportScreen.reportConfig.months")}</Typography>
            </div>
    );
};

export default MonthOption;
