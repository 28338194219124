import React from 'react';
import {Provider} from 'react-redux';
import {store} from "./reducers/store/store";
import {TopLevelRoutesTree} from "./components/routes/TopLevelRoutesTree";
import {SnackbarProvider} from "notistack";
import * as Sentry from "@sentry/react";

import './translate/i18n';

const BettairApp = () => {

    return (

        <Provider store={store}>
            <SnackbarProvider maxSnack={8}>
                <Sentry.ErrorBoundary showDialog>
                    <TopLevelRoutesTree/>
                </Sentry.ErrorBoundary>
            </SnackbarProvider>
        </Provider>

    );
};

export default BettairApp;
