import {useTranslation} from "react-i18next";
import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import {notValidPeriod, periodTypes} from "bettairplaformutil/src/validationUtil";
import {makeStyles} from "@mui/styles";
import {periodTypeColor} from "../../../../../constants";

const BUTTON_TYPE = {
    TOOL: "tool",
    ACTION: "action"
};
export const SELECTION_TOOL = "selection";
export const MAGIC_TOOL = "magic";
export const CLEAR_ACTION = "clear";

const useStyles = makeStyles({
    selectionControlIconContainer: {
        display: "flex",
        fontSize: 25
    },
    toggleButton: {
        padding: "0px 10px",
        minHeight: 40,
        lineHeight: "20px",
        position: "relative",
        overflow: "hidden"
    },
    typeColorCircle: {
        borderRadius: "50%",
        border: "1px solid #808080",
        width: 15,
        minWidth: 15,
        height: 15,
        marginRight: 8
    },
    validPeriodLine: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: 3,
        backgroundColor: periodTypeColor[periodTypes.valid],
        opacity: 0.5
    },
    invalidPeriodLine: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: 3,
        backgroundColor: periodTypeColor[periodTypes.notValid],
        opacity: 0.5
    }
});

const EditorControls = ({className, formControlClass, selectedTool, onToolSelectorChange, onActionDispatch,
    onPeriodTypeClick, disable}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const selectionControls = [
        {
            value: SELECTION_TOOL,
            type: BUTTON_TYPE.TOOL,
            icon: <HighlightAltIcon fontSize="inherit" />,
            label: t("validation.selectionControls.selection_tool")
        },
        {
            value: MAGIC_TOOL,
            type: BUTTON_TYPE.TOOL,
            icon: <AutoFixHighIcon fontSize="inherit" />,
            label: t("validation.selectionControls.magic_tool")
        },
        {
            value: CLEAR_ACTION,
            type: BUTTON_TYPE.ACTION,
            icon: <ClearAllIcon fontSize="inherit" />,
            label: t("validation.selectionControls.clear_selection")
        }
    ];
    const handleSelectorChange = (event, newValue) => {
        if (newValue === null) return;
        const selectedControl = selectionControls.find(control => control.value === newValue);
        if (selectedControl.type === BUTTON_TYPE.TOOL) {
            onToolSelectorChange(newValue);
        }
        if (selectedControl.type === BUTTON_TYPE.ACTION) {
            onActionDispatch(newValue);
        }
    };

    const handleTypeChange = (event, newValue) => {
        onPeriodTypeClick(newValue);
    };

    return (
        <div className={className} style={{display: "flex", justifyContent: "space-between"}}>
            <ToggleButtonGroup

                className={formControlClass}
                value={selectedTool}
                exclusive
                onChange={handleSelectorChange}
                size="small"
                style={{marginRight: 10}}
            >
                {selectionControls.map(control => (
                    <ToggleButton className={classes.toggleButton} key={control.value} value={control.value}>
                        <Tooltip title={control.label}>
                            <div className={classes.selectionControlIconContainer}>
                                {control.icon}
                            </div>
                        </Tooltip>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <ToggleButtonGroup
                className={formControlClass}
                value={selectedTool}
                exclusive
                onChange={handleTypeChange}
            >
                {Object.keys(periodTypes).map((key) => {
                    const value = periodTypes[key];
                    const invalidPeriod = notValidPeriod.includes(value);
                    return {key, value, invalidPeriod};
                }).sort((a, b) => a.invalidPeriod - b.invalidPeriod).map(({key, value, invalidPeriod}) => (
                    <ToggleButton disabled={disable} className={classes.toggleButton} key={key} value={value}>
                        <span className={classes.typeColorCircle} style={{backgroundColor: periodTypeColor[value]}}/>
                        {t(`validation.periodType.${key}`)}
                        <span className={invalidPeriod ? classes.invalidPeriodLine : classes.validPeriodLine}/>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

export default EditorControls;
