import React from 'react';
import PropTypes from 'prop-types';

import {Route, useHistory} from 'react-router-dom';
import {HOME_ROUTE} from "../../../constants";


export const PublicRoute = ({
    isAuthenticated,
    component: Component, redirectCallback,
    ...rest
}) => {
    const history = useHistory();

    if (isAuthenticated) {
        const lastPath = localStorage.getItem('lastPath');
        if (lastPath?.startsWith('https://')) {
            const domainWhitelist = ["bettair.city"];
            const url = new URL(lastPath);
            const hostSplit = url.hostname.split('.');
            const mainDomain = hostSplit.slice(-2).join('.');
            if (domainWhitelist.includes(mainDomain)) {
                redirectCallback(true);
                window.location.href = lastPath;
            } else {
                redirectCallback(false);
                history.push(HOME_ROUTE);
            }
        } else {
            redirectCallback(false);
            history.push(lastPath || HOME_ROUTE);
        }
    }

    return (
        <Route { ...rest }
            component={ (props) => (
                !isAuthenticated && <Component { ...props } />
            )}
        />
    );
};

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
};
