import React, {useState} from 'react';
import {Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';
import ToastContainer from "./ToastContainer";
import clsx from "clsx";
import {getDocumentationUrl} from "../../utils/documentationUtil";
import {openLinkInNewTab} from "../../utils/linkUtil";

const useStyles = makeStyles({
    box: {
        background: "#f18484",
        width: 260,
        left: 100,
        opacity: 1,
    },
    boxHidden: {
        opacity: 0,
        left: -300
    }
});

const WebSockedError = () => {

    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const [hidden, setHidden] = useState(false);

    const handleOnClick = () => {
        const documentationUrl = getDocumentationUrl(i18n.language);
        openLinkInNewTab(`${documentationUrl}error_messages/`);
    };

    const handleOnClose = () => {
        setHidden(true);
    };

    return (<ToastContainer className={clsx(classes.box, hidden && classes.boxHidden)} onClick={handleOnClick}
                            onCloseButton={handleOnClose}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }}>
            <ErrorIcon style={{color: "#fff"}}/>
            <Typography variant={"subtitle1"}
                        style={{
                            color: "#fff",
                            marginLeft: 8,
                            fontWeight: "bold"
                        }}>{t("requestErrors.websocket.disconnected")}</Typography>
        </div>
        <Link
            onClick={handleOnClick}
            style={{color: "#fff", marginLeft: 28, marginTop: 14, fontSize: 14, cursor: "pointer"}}>
            {t("more_info")}
        </Link>
    </ToastContainer>);
};

export default WebSockedError;
