import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";


export let organizationRequest = (callback) => {
    return webClient.get('/organization')
        .then(response => {
            if (callback) {
                callback(false, response.data);
            } else {
                return response.data;
            }
        })
        .catch(err => {
            callback && handleError(err, callback);
        });
};

export const deleteMemberRequest = (list) => {
    let deletePromises = [];
    list.forEach((userId) => {
        deletePromises.push(webClient.delete(`/organization/user/${userId}`));
    });
    return deletePromises;
};

export const inviteMembersRequest = (body, callback) => {
    webClient.post('/organization/user', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const editMembersRequest = (body, callback) => {
    webClient.put('/organization/user', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const resendInvitationRequest = (body, callback) => {
    webClient.put('organization/invitation', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const unblockUserRequest = (userId) => {
    return webClient.put(`/organization/unblock/${userId}`);
};

export let updateUnitsRequest = (body, callback) => {
    webClient.put('/organization/units', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export let updateLocale = (body) => {
    return webClient.put('/organization/locale', body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export const updatePublicMapRequest = (body, callback) => {
    webClient.patch('/organization/public-map', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateSentiloRequest = (body, callback) => {
    webClient.patch('/organization/sentilo', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateLegalThresholdsRequest = (body) => {
    return webClient.put('/organization/legalThreshold', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    });
};

export const downloadOrganizationLogoRequest = () => {
    return webClient.get(`/organization/logo`, {
        responseType: 'blob'
    }).then(response => {
        return URL.createObjectURL(response.data);
    }).catch(err => {
            if (err.response.status === 404)
                return null;
            throw err;
        }
    );
};

export const deleteLogoRequest = () => {
    return webClient.delete('/organization/logo').catch(err => {
        if (err?.response?.status === 400)
            return true;
        throw err;
    });
};

export const uploadLogoRequest = (file) => {
    let formData = new FormData();
    formData.append('file', file);
    return webClient.post('/organization/logo', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
