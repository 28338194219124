import {useCallback, useState} from "react";
import {Button, Card, CardContent, Grid, IconButton, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {makeStyles} from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import clsx from "clsx";
import useRequest from "../../../../hooks/useRequest";
import {ERROR_500} from "../../../../constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {useAnchorEl} from "../../../../hooks/useAnchorEl";
import HelpPopup from "../../../common/HelpPopup";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../hooks/useMessage";
import useAuth from "../../../../hooks/useAuth";
import {WRITE_LOG} from "../organization/members/UserPermissions";
import useTableQuery from "../../../../hooks/useTableQuery";
import getLogRequest from "../../../../requests/logs/getLogRequest";
import AddLogPopup from "./popups/AddLogPopup";
import postLogRequest from "../../../../requests/logs/postLogRequest";
import ConfirmDialog from "../../../common/ConfirmDialog";
import deleteLogRequest from "../../../../requests/logs/deleteLogRequest";
import LogsTable, {LIST_PAGE_SIZE} from "./LogsTable";
import {styles} from "./styles";

const useStyles = makeStyles(styles);

const LogsScreen = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {setError} = useMessage();
    const {hasPermission} = useAuth();
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [{enableDelete, selectionModel, openConfirmDialog,  open}, updateState] =
        useState({
            selectionModel: [],
            openConfirmDialog: false,
            enableDelete: false,
            open: false
        });
    const {reloadData, ...tableQueryData} =
        useTableQuery(getLogRequest, {pageSize: LIST_PAGE_SIZE, translationBaseRoute: "logs"});

    const hideDialog = useCallback(() => {
        updateState(state => ({...state,openConfirmDialog: false}));
    },[]);

    const onSelectionModelChange = (newSelectionModel) => {
        updateState((state) => {
            const filteredEditableItems = !hasPermission(WRITE_LOG) ? [] : newSelectionModel;
            return {...state, enableDelete: filteredEditableItems.length !== 0, selectionModel: filteredEditableItems};
        });
    };

    const handleDeleteButtonPressed = () => {
        updateState(state =>({...state,openConfirmDialog: true}));
    };

    const cancelNewLog = useCallback(() => {
       updateState(state =>({...state, open: false}));
    }, [updateState]);

    const addLog = useCallback(async (log) => {
        updateState(state => ({...state, open: false}));
        try {
            await postLogRequest(log);
        } catch (err) {
            handleErrorResponse(err?.response, response => {
                switch (response?.status) {
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        setError(response,
                            false,
                            `${t("error")} ${response?.status ?? ''},
                            ${t("logs.could_not_update_data")}`);
                }
            });
        }
        reloadData();
    }, [reloadData, setError, t, handleErrorResponse]);

    const handleConfigureNewLog = () => {
        updateState((state =>({...state, open: true})));
    };

    const confirmDelete = useCallback(async () => {
        updateState(state => ({ ...state, openConfirmDialog: false }));
        setLoadingMetadata(true);
        try {
            await deleteLogRequest(selectionModel);
            reloadData();
        } catch (err) {
            if (err.response) {
                handleErrorResponse(err.response, response => {
                    switch (response?.status) {
                        case 500:
                            setError(ERROR_500, true);
                            break;
                        default:
                            reloadData();
                            setError(response,
                                false,
                                `${t("error")} ${response?.status ?? ''},
                            ${t("logs.error_deleting")}`);
                    }
                });
            } else {
                setError("logs.error_deleting");
            }
        }
        setLoadingMetadata(false);
    },[t, reloadData, selectionModel, setLoadingMetadata, setError, handleErrorResponse]);

    return <>
        <Grid className={classes.root} container direction={"row"} alignItems={"center"} justifyContent="space-around"
              spacing={2}>
            <Grid item xs={12}>
                <Card className={clsx(classes.card, classes.firstRowCard)}>
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"}
                              alignItems={"center"}>
                            <Grid container item xs={12} sm={12} md={4} lg={6} alignItems={"center"}
                                  alignContent={"flex-start"}>
                                <Typography className={classes.cardTitle} variant={"h5"}>
                                    {t("logs.title")}
                                </Typography>
                                <IconButton className={classes.helpButton} aria-label="help"
                                            onClick={e => setAnchorEl(e.currentTarget)}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Grid>
                            {hasPermission(WRITE_LOG) && <>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <Button
                                        data-testid="log-add-button"
                                        fullWidth
                                        color={"primary"}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={handleConfigureNewLog}
                                    >
                                        {t("logs.add")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <Button
                                        fullWidth
                                        data-testid="log-delete-button"
                                        disabled={!enableDelete}
                                        variant="contained"
                                        className={classes.deleteButton}
                                        startIcon={<DeleteIcon />}
                                        onClick={handleDeleteButtonPressed}
                                    >
                                        {t("downloadScreen.delete")}
                                    </Button>
                                </Grid>
                            </>}
                            <LogsTable loading={loadingMetadata} setLoading={setLoadingMetadata}
                                       tableQueryData={tableQueryData} reloadData={reloadData}
                                       selectionModel={selectionModel} onSelectionModelChange={onSelectionModelChange}/>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("logs.help_text")}/>
        <AddLogPopup confirm={addLog} cancel={cancelNewLog} open={open} />
        {openConfirmDialog && (
            <ConfirmDialog confirm={confirmDelete} hideDialog={hideDialog} question={t("logs.confirm_delete")} />
        )}
    </>;
};

export default LogsScreen;
