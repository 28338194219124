import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";

const SelectControl = ({className, label, disabled, options, value, onChange, helperText, variant, margin, testId,
                           fullWidth}) => {
    return (
        <FormControl className={className} disabled={disabled} margin={margin} fullWidth={fullWidth}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                label={label}
                value={value || ""}
                onChange={onChange}
                variant={variant}
                data-testid={testId}
            >
                {options.map(item => (
                    <MenuItem key={item.value} value={item.value} onClick={item.onClick}>{item.label}</MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default SelectControl;
