import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import {Card, CardContent, Divider, Grid, TextField, Typography} from "@mui/material";
import Detail from "./detail/Detail";
import PeriodTabs from "./PeriodTabs";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect} from "react";
import {updateSelectedStationAction} from "../../../../reducers/dashboardUIReducer";
import usePeriodData from "./usePeriodData";
import {usePeriodContext} from "./common/PeriodContext";
import DeviceNotFound from "../analytics/DeviceNotFound";
import CardLoading from "../../../common/card_views/CardLoading";
import {
    READ_ORGANIZATION_VALID_PERIODS,
    WRITE_ORGANIZATION_VALID_PERIODS
} from "../organization/members/UserPermissions";
import useAuth from "../../../../hooks/useAuth";
import DataNotFound from "../../../common/DataNotFound";
import HelpButton from "../../../common/buttons/HelpButton";
import {useTranslation} from "react-i18next";
import {getStationOptions, stationSelectorFilter} from "../../../../utils/stationUtil";
import {isEqual} from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import ListCustomItem from "../../../common/multipleSelector/ListCustomItem";
import QuickValidation from "./quickValidation/QuickValidation";
import useSensorData from "./useSensorData";
import ListCustomItemText from "../../../common/multipleSelector/ListCustomItemText";


const useStyles = makeStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40
    },
    card: {
        background: "white",
        padding: 20,
        borderRadius: 5,
        minHeight: 1000
    },
    quickValidationCard: {
        minHeight: 160
    },
    cardTitle: {
        marginLeft: 20,
        color: "#000000",
        fontWeight: "bold"
    },
    pollutantSelect: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
    },
    notFoundCard: {
        height: 600
    },
    loadingCard: {
        minHeight: 385,
        paddingTop: 40,
        flexDirection: "column"
    },
    divider: {
        marginTop: 50,
        marginBottom: 50
    }
});

export const ValidationScreen = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {selectedStation, setSelectedStation, loading} = usePeriodContext();
    const {hasPermission} = useAuth();

    const options = useSelector(state => getStationOptions(state.dashboardUI.stations), isEqual);

    const setStation = useCallback((stationId) => {
        if (stationId) localStorage.setItem('lastStation', stationId);
        dispatch(updateSelectedStationAction(stationId));
        setSelectedStation(stationId);
    }, [dispatch, setSelectedStation]);

    useEffect(() => {
        const lastStationId = localStorage.getItem('lastStation');
        const stationId = options.find(option => option.id === lastStationId) ? lastStationId : options[0]?.id;
        if (!stationId) return;
        if (!selectedStation) {
            setStation(stationId);
        }
    }, [options, selectedStation, setStation]);

    const onStationChange = (event, newValue) => {
        if (selectedStation && newValue?.id) {
            setStation(newValue.id);
        }
    };

    useSensorData(true);

    const {updateValidationCalendar, updateValidationHistory} = usePeriodData();

    useEffect(() => {
        updateValidationCalendar();
    }, [updateValidationCalendar]);

    useEffect(() => {
        updateValidationHistory();
    }, [updateValidationHistory]);

    return (
        <Grid className={classes.root} container direction={"row"} alignItems={"center"} justifyContent="space-around"
              spacing={2}>

            {hasPermission(WRITE_ORGANIZATION_VALID_PERIODS) && <Grid item xs={12}>
                <Card className={clsx(classes.card, classes.quickValidationCard)}>
                    <CardContent>
                        <QuickValidation/>
                    </CardContent>
                </Card>
            </Grid> }

            {hasPermission(READ_ORGANIZATION_VALID_PERIODS) ?
                <>
                    {selectedStation && (
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid container item xs={12} sm={5} md={6} lg={8} alignItems={"center"}
                                              alignContent={"flex-start"} mb={5}>
                                            <Grid item xs={12} display="flex" alignItems="center" alignContent="flex-start">
                                                <Typography className={classes.cardTitle}
                                                            variant={"h5"}>{t("validation.detailed_validation")}</Typography>
                                                <HelpButton helpText={t("validation.period_detail_help")}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={6} lg={4}>
                                            <Autocomplete
                                                onChange={onStationChange}
                                                value={options.find(option => option.id === selectedStation) ?? null}
                                                disabled={!!loading}
                                                disablePortal
                                                options={options}
                                                size="small"
                                                fullWidth
                                                data-testid="station-select"
                                                filterOptions={stationSelectorFilter}
                                                renderOption={(props, option) => (
                                                    <ListCustomItem {...props} key={option.id}>
                                                        <ListCustomItemText option={option} isStation={true} />
                                                    </ListCustomItem>
                                                )}
                                                renderInput={(params) =>
                                                    <TextField label={t("dashboard.select_station")} {...params}/>}
                                            />
                                        </Grid>
                                        <Grid container xs={12} className={clsx(loading && classes.loadingCard)}>
                                            {loading ? <CardLoading/> : <PeriodTabs/>}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider className={classes.divider}/>
                                <CardContent>
                                    <Detail/>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {!selectedStation &&
                        <Grid item xs={12}>
                            <DeviceNotFound className={clsx(classes.card, classes.notFoundCard)}/>
                        </Grid>
                    }
                </> : <Grid item xs={12}>
                    <Card className={clsx(classes.card, classes.notFoundCard)}>
                        <DataNotFound/>
                    </Card>
                </Grid>
            }

        </Grid>
    );
};

export default ValidationScreen;
