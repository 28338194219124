import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {pollutantChartCategoryColor} from "../chart/PollutantRoseChart";
import {cloneDeep} from "lodash";
import {unitsMap} from "../../../unitsNames";

const getLegendData = (data) => {
    const copy = cloneDeep(data.data);
    return copy.map((item) => {
        return {
            name: `${item.name} ${unitsMap.get(data.unit)}`,
            color: pollutantChartCategoryColor[item.category]
        };
    });
};

const RoseChartLegend = ({data}) => {

    const [legendData, updateLegendData] = useState(getLegendData(data));

    useEffect(() => {
        updateLegendData(getLegendData(data));
    }, [data]);

    return (
        <div style={
            {
                boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
                borderRadius: 6,
                marginTop: 12,
                width: 200,
                padding: 16,
            }}>
            {legendData.map(item =>
                <div key={item.name} style={{width: "100%", display: "flex", flexDirection: "row",alignItems:"center"}}>
                    <div style={
                        {
                            width: 16,
                            height: 12,
                            background: item.color,
                            marginRight: 10,
                            borderRadius:2
                        }}/>
                    <div>
                        <Typography>{item.name}</Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RoseChartLegend;
