import {pollutantNames} from "../../../pollutantNames";
import {getYRange} from "../chartRangeUtil";
import {getWeekDaySeries, wrapColors} from "../week/weekChart";
import {compareStationsColors} from "../../CompareScreen";

export const getDayOfWeekChartLayout = (dayOfWeek, startOnSunday, unit, title, data) => {

    const dayNames = startOnSunday === true ? dayOfWeek : [...dayOfWeek.slice(1, 7), dayOfWeek[0]];

    return {
        xaxis10: {
            anchor: `y10`,
            showgrid: true,
            domain: [0.7, 1],
            side: "bottom",
            mirror: true,
            ticklen: 8,
            title: {
                text: title
            },
            linecolor: '#000000',
            linewidth: 1,
            tickmode: "array",
            autotick: false,
            tickvals: Array.from({length: 7}, (_, i) => i ),
            ticktext: dayNames,
            range: [0,6],
            fixedrange: true
        },
        yaxis10: {
            anchor: `x10`,
            domain: [0, 0.5],
            mirror: true,
            showline: true,
            linecolor: '#000000',
            linewidth: 1,
            ticklen: 8,
            title: {text: unit, standoff: 10},
            zeroline:false,
            range: getYRange(data),
            fixedrange: true
        }
    };
};

export const getDayOfWeekChartData = (pollutant, unit, periodData, index) => {
    const mean = getWeekDaySeries(pollutant,unit,"mean", periodData, 10,index );
    const min = getWeekDaySeries(pollutant,unit,"min", periodData, 10, index );
    const max = getWeekDaySeries(pollutant,unit,"max", periodData, 10, index );
    const p25 = getWeekDaySeries(pollutant,unit,"p25", periodData, 10, index );
    const p75 = getWeekDaySeries(pollutant,unit,"p75", periodData, 10, index );

    return [mean, min, p25, p75, max];
};

export const getLineBarChartData = (pollutant, unit, periodData, isYearChart = false, index) => {

    const data = periodData;
    const xData = Array.from({length: isYearChart ? 12 : 7}, (_, i) => i + 1);

    const yaxis = isYearChart ? "y9" : "y10";
    const xaxis = isYearChart ? "x9" : "x10";

    const min = {
        yaxis,
        xaxis,
        x: xData,
        y: xData.map(item => data.find(item2 => item2.x === item -1)?.min ?? null),
        marker: {
            color: 'rgba(1,1,1,0.0)'
        },
        type: 'bar',
        hoverlabel: {namelength: 0},
        hovertemplate: `<b>${pollutantNames.get(pollutant)} min</b>: %{y} ${unit}`
    };

    const pMaxChart = {
        yaxis,
        xaxis,
        x: xData,
        y: xData.map(item => {
            const result = data.find(item2 => item2.x === item -1);
            if(!result) return null;
            let {max, min} = result;
            return max - min;
        }),
        y2: xData.map(item => data.find(item2 => item2.x === item -1)?.max ?? null),
        marker: {
            color: wrapColors[index]
        },
        type: 'bar',
        hoverlabel: {namelength: 0},
        hovertemplate: ``,
        hoverinfo: 'none'
    };

    const mean = {
        yaxis,
        xaxis,
        x: xData,
        y: xData.map(item => data.find(item2 => item2.x === item -1)?.mean ?? null),
        marker: {
            color: compareStationsColors[index]
        },
        type: 'line',
        hoverlabel: {namelength: 0},
        hovertemplate: `<b>${pollutantNames.get(pollutant)} mean</b>: %{y} ${unit}`
    };

    const pMaxHover = {
        yaxis,
        xaxis,
        x: xData,
        y: xData.map(item => data.find(item2 => item2.x === item -1)?.max ?? null),
        marker: {
            color: 'rgba(26,35,208,0)'
        },
        type: 'line',

        hoverlabel: {namelength: 0},
        hovertemplate: `<b>${pollutantNames.get(pollutant)} max</b>: %{y} ${unit}`
    };

    return [min, pMaxChart, mean, pMaxHover];

};
