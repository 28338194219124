import {useCallback, useState} from "react";



const useDateRange = () => {

    const[state,updateDateRangeState] =
        useState({openDateRangePicker:false,dateRange: null});

    const updateOpenDatePickerCallback = useCallback((value)=>{
        updateDateRangeState(state =>({...state,openDateRangePicker: value}));
    },[]);

    const updateDatePickedCallback = useCallback((dateRange)=>{
        updateDateRangeState(state =>({dateRange,openDateRangePicker: false}));
    },[]);

    const clearDataRange = useCallback(()=>{
        updateDateRangeState(state =>({...state,dateRange: null}));
    },[]);

    return [state,updateOpenDatePickerCallback,updateDatePickedCallback,clearDataRange];
};

export default useDateRange;


//{openDateRangePicker,dateRange}