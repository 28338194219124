import {webClient} from "../../config/axios";
import {handleSentryError} from "../../handleSentryError";

export const getFileExport = (id, progress, cancelToken, callback) => {
    let value = 0;
    progress(id, value);
    webClient.get(`/download/file/${id}`, {
        responseType: 'blob',
        timeout: 0,
        cancelToken,
        onDownloadProgress: progressEvent => {
            const total = progressEvent.event.target.getResponseHeader('x-actual-content-length');
            if (total >= 0) {
                const loaded = progressEvent.loaded ?? progressEvent.position;
                if (loaded * 100 / total > value + 5 || value === 0) {
                    value = loaded * 100 / total;
                    if (value < 10) value = 5;
                    progress(id, value);
                }
            } else
               handleSentryError(new Error("x-actual-content-length header not found"));

        }
    })
        .then((response) => {
            callback(false, id, response);
        })
        .catch(error => {
            if (error.response) {
                callback(true, id, {status: error.response.status, error: error.response.data});
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an mapGenerator of XMLHttpRequest in the browser and an mapGenerator of
                // http.ClientRequest in node.js
                callback(true, id, {status: 500, error: 'Service could not be reached'});
            } else {
                // Something happened in setting up the request that triggered an Error
                callback(true, id, {status: 500, error: 'Service could not be reached'});
            }
        });
};

