import React, {useCallback, useEffect, useRef, useState} from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import {Checkbox, Chip, TextField, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import ListCustomItem from "./multipleSelector/ListCustomItem";
import ListCustomItemText from "./multipleSelector/ListCustomItemText";
import {stationSelectorFilter} from "../../utils/stationUtil";
import getItemSelectorFilter from "../../utils/getItemSelectorFilter";

const useStyles = makeStyles(_ => ({
    removeWhiteSpace: {
        '& .MuiFilledInput-root': {
            paddingRight: '39px !important',
        },
    }
}));

const AutoCompleteWithCheckBox = ({items, shipSize = 220, value, setValue, placeholder, label, isStation = false,
                                      ...props}) => {

    const classes = useStyles();

    const {t} = useTranslation();
    const [selectAll, setSelectAll] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [limitTags, setLimitTags] = useState(2);

    const componentRef = useRef(null);


    useEffect(() => {
        const updateTagLimit = () => {
            if (componentRef.current) {
                setLimitTags(Math.floor(componentRef.current.offsetWidth / shipSize));
            }
        };

        window.addEventListener('resize', updateTagLimit);
        updateTagLimit();

        return () => window.removeEventListener('resize', updateTagLimit);
    }, [shipSize]);


    useEffect(() => {
        if(value.length === 0)
            setSelectAll(false);
    }, [value]);

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        setValue(event.target.checked ? items : []);
    };

    const onSelectionChange = useCallback((newValue) => {
        setSelectAll(newValue.length === items.length);
        setValue(newValue);
    }, [items, setValue]);


    return (
        <div ref={componentRef}>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                {...props}
                multiple
                id="custom-auto-complete"
                clearIcon={null}
                limitTags={limitTags}
                options={items.sort((a, b) => {
                    const aSelected = value.find(item => item.value === a.value);
                    const bSelected = value.find(item => item.value === b.value);
                    if (aSelected && !bSelected) return -1;
                    if (bSelected && !aSelected) return 1;
                    return 0;
                })}
                value={value}
                onChange={(event, newValue) => {
                    onSelectionChange(newValue);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                sx={{width: '100%'}}
                className={classes.removeWhiteSpace}
                renderOption={(props, option, {selected}) => (
                    <ListCustomItem key={option.id} withCheckbox={true} checked={selected} {...props}>
                        <ListCustomItemText option={option} isStation={isStation} />
                    </ListCustomItem>
                )}
                getOptionLabel={(option) => option.label}
                filterOptions={isStation ? stationSelectorFilter : getItemSelectorFilter(["label"])}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            variant="filled"
                            label={label}
                            placeholder={placeholder}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Tooltip title={t(selectAll ? "deselectAll" : "selectAll")}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                                indeterminate={value.length > 0 && value.length < items.length}
                                            />
                                        </Tooltip>
                                        {params.InputProps.startAdornment}
                                    </div>
                                )
                            }}
                        />
                    );
                }}
                renderTags={isFocused ? (value, getTagProps) =>
                    <div style={{maxHeight: '190px', overflowY: 'auto'}}>
                        {value.map((item, index) => {
                            return (
                                <Chip key={index} variant="outlined" label={item.label} {...getTagProps({index})} />
                            );
                        })}
                    </div> : null
                }
                openOnFocus={true}
            />
        </div>);
};

export default AutoCompleteWithCheckBox;
