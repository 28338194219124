import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {useSelector} from "react-redux";
import {DEFAULT_PERIOD, sensor} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {windDataRequest} from "../../../../../requests/analytics/windDataRequest";
import {compareStationsColors} from "../CompareScreen";
import {sortBySelection} from "../../../../../utils/sortComparedPollutants";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {areAllSameTimezone, getCommonTimezone, getStationTimezones} from "../timezoneUtil";
import {getRange} from "../../../../../utils/chartAxeRangeUtil";
import {AUTO_RESOLUTION} from "../../analytics/common/ResolutionSelector";


const initialPlotLayout = {
    autosize: true,
    showlegend: false,
    yaxis: {
        title: "m/s",
    },
    xaxis:{showgrid:false,domain: [0.01, 1]},
    hovermode: "x unified",
    margin: {l: 60, r: 80, b: 80, t: 20}
};

const plotConfig = {
    modeBarButtonsToRemove:[ "select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines","hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo : false
};

const CompareWindSpeedCardView = ({className}) => {

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const initialState = {data:[], chartAxeRange: [], hiddenVariables:[], loading:true, error:""};
    const[{data, chartAxeRange, hiddenVariables, loading, error}, updateState] = useState(initialState);
    const [plotLayout, setPlotLayout] = useState(initialPlotLayout);
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const {units} = useSelector(state => state.auth);
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const dataTimeZone = useSelector(state => state.auth.timeZone);

    const commonTimezone = useMemo(() => (
        getCommonTimezone(selectCompareStations, selectedRange[0], dataTimeZone)
    ), [selectedRange, dataTimeZone, selectCompareStations]);

    useEffect(()=>{
            updateData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[selectCompareStations, selectedRange, selectedResolution]);

    const handleRefresh = ()=>{
        updateData(null);
    };

    const getChartData = useCallback( (serverData, hiddenStations)=>{

            let currentHiddenStations = hiddenStations !== null ? hiddenStations : hiddenVariables !== null ? hiddenVariables :[];
            let dataArray = Object.entries(serverData);
            let output = [];
            let sortedDataArray = sortBySelection(dataArray, selectCompareStations);
            const timezones = getStationTimezones(selectCompareStations, selectedRange[0], dataTimeZone);
            const sameTimezone = areAllSameTimezone(timezones);

            sortedDataArray.forEach((station,stationIndex) => {
                if (station === undefined) return;
                const stationName = selectCompareStations.find(item => item.id === station[0])?.alias;
                const stationData = station[1].timeSeries[sensor.wind_speed];
                const timezone = timezones[stationIndex];
                const timezoneString = sameTimezone ? "" : ` (UTC${timezone > 0 ? `+${timezone}` : timezone || ""})`;
                output.push({
                    visible: currentHiddenStations.includes(stationIndex) ? "legendonly" : true,
                    marker: {color:compareStationsColors[stationIndex]},
                    name: stationName,
                    type: 'scatter',
                    y: stationData.y !== undefined ? stationData.y : [],
                    x: stationData.x,
                    hovertemplate: `<b>${stationName}${timezoneString}</b> - Wind speed %{y} m/s`,
                    hoverlabel: {namelength: 0}
                });
            });

        return output;
    },[selectCompareStations, hiddenVariables, dataTimeZone, selectedRange]);

    const updateData = useCallback(async (hiddenStations)=>{

        updateState(state =>{
            return {...state,
                data:[],
                rawData: {},
                loading: true,
                error: "",
                hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables
                };
        });

        if (selectCompareStations?.length) {
            const newData = {};
            try {
                const windData = await Promise.all(selectCompareStations.map(station => (
                    windDataRequest(units.windSpeed, station.id, selectedRange[0], selectedRange[1], selectedResolution)
                )));
                windData.forEach((stationData, index) => {
                    if (stationData !== null) {
                        newData[selectCompareStations[index].id] = stationData;
                    }
                });
            } catch (err) {
                const {request} = err;
                updateState(state => {
                    return {...state, loading: false, error: t("analyticScreen.windSpeed.could_not_update_station_data")};
                });
                handleErrorResponse(request, response => {
                    setError(response, false,
                        "analyticScreen.windSpeed.could_not_update_station_data");
                });
            }

            if (!isMounted.current) return;

            if (Object.keys(newData).length) {
                const chartData = getChartData(newData, hiddenStations);
                updateState(state => {
                    return {
                        ...state,
                        data: chartData,
                        rawData: newData,
                        chartAxeRange: getRange(chartData, units),
                        loading: false,
                        hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables
                    };
                });
            } else {
                updateState(state => {
                    return {...state, loading: false, error: t("analyticScreen.windSpeed.sensor_data_not_found")};
                });
            }
        }
    },[selectCompareStations, isMounted, units, selectedRange, selectedResolution, handleErrorResponse, setError, getChartData, t]);

    useEffect(() => {
        setPlotLayout({
            ...initialPlotLayout,
            xaxis: {
                ...initialPlotLayout.xaxis,
                hoverformat: `%-d %b, %Y %H:%M:%S${commonTimezone}`
            },
            yaxis: {
                ...initialPlotLayout.yaxis,
                range: chartAxeRange
            }
        });
    }, [commonTimezone, chartAxeRange]);

    const legendItems = data.map((item, index) => ({
        id: index,
        label: item.name,
        color: item.marker.color,
        isHidden: item.visible === "legendonly"
    }));

    const onLegendItemSelect = useCallback((index) => {
        const newHiddenVariables = [...hiddenVariables];
        const position = newHiddenVariables.indexOf(index);
        if (position === -1) {
            newHiddenVariables.push(index);
        } else {
            newHiddenVariables.splice(position, 1);
        }
        const newData = data.map((item, itemIndex) => ({
            ...item,
            visible: newHiddenVariables.includes(itemIndex) ? "legendonly" : true
        }));
        const error = newData.length === newHiddenVariables.length
            ? t("compareScreen.noStationSelected")
            : "";
        updateState(state => ({...state, data: newData, hiddenVariables: newHiddenVariables, error}));
    }, [data, hiddenVariables, t]);

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("analyticScreen.windSpeed.title")}
                    helpText={t("compareScreen.windSpeed.compare_wind_speed_help")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout loading={loading} error={error} emptyData={data.length === hiddenVariables.length} height={510}
                         chartStyles={{ "& .modebar": { left: "47%" } }} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} legendItems={legendItems}
                         onResolutionChange={setSelectedResolution} resolution={selectedResolution}
                         onLegendItemSelect={onLegendItemSelect} position="end">
                <ChartView
                    layout={plotLayout}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default CompareWindSpeedCardView;
