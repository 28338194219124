import React from 'react';
import {Divider, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import {openDocumentationInNewTab} from "../../../../utils/documentationUtil";
import {openLinkInNewTab} from "../../../../utils/linkUtil";

const SettingMenu = ({anchorEl, closeMenu,logoutSession,openProfile,openOrganization}) => {

    const { t, i18n } = useTranslation();

    const handleCloseSession = ()=>{
        closeMenu();
        logoutSession();
    };

    const handleProfile=()=>{
        closeMenu();
        openProfile();
    };

    const handleOrganization = ()=>{
        closeMenu();
        openOrganization();
    };

    const handleClose = ()=>{
        closeMenu();
    };

    const handlePlatformManual = () => {
        closeMenu();
        openDocumentationInNewTab(i18n.language);
    };

    const handleApiManual = () => {
        closeMenu();
        openLinkInNewTab("https://docs.cloud.bettair.city/api");
    };

    return (

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handlePlatformManual}>{t("dashboardMenu.platform_manual")}</MenuItem>
            <MenuItem onClick={handleApiManual}>{t("dashboardMenu.api_manual")}</MenuItem>
            <Divider />
            <MenuItem
                data-testid={"dashboard-profile"}
                onClick={handleProfile}>{t("dashboardMenu.profile")}</MenuItem>
            <MenuItem
                data-testid={"dashboard-organization"}
                onClick={handleOrganization}>{t("dashboardMenu.organization")}</MenuItem>
            <Divider />
            <MenuItem
                data-testid={"dashboard-logout"}
                onClick={handleCloseSession}>{t("dashboardMenu.logout")}</MenuItem>
        </Menu>
    );
};

export default SettingMenu;
