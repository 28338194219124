import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {DEFAULT_PERIOD} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useTranslation} from "react-i18next";
import {scatterPlotRequest} from "../../../../../requests/analytics/scatterPlotRequest";
import {pollutantNames} from "../../pollutantNames";
import {unitsMap} from "../../unitsNames";
import {isEqual} from "lodash";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import useMessage from "../../../../../hooks/useMessage";
import ChartView from "../../../../common/chart/ChartView";
import {generateIncrementalArray} from "../../../../../utils/regresionLineUtil";
import useRequest from "../../../../../hooks/useRequest";

const plotLayoutInitialState = {
    autosize: true,
    legend: {x: 0.23, y: -0.25, valign: 'top', orientation: 'h'},
    hovermode: 'closest',
    xaxis: {showline: true, zeroline: false},
    yaxis: {showline: true, zeroline: false},
    trendline: "ols",
    margin: {t: 10, b: 40, l: 60, r: 40},
};

const plotConfig = {
    modeBarButtonsToRemove: [
        "select2d", "lasso2d", "toggleHover", "sendDataToCloud", "toggleSpikelines", "hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo: false
};

const ScatterPlotCardView = ({className}) => {

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const [{data, loading, error}, updateState] = useState({data: [], loading: true, error: notFoundMessage});
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const [plotLayout, setPlotLayout] = useState(plotLayoutInitialState);

    const {units} = useSelector(state => state.auth);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);

    const availableSensors = useSelector(state => {
        const station = state.dashboardUI.stations.find(item => item.id === state.dashboardUI.selectedStation);
        const sensors = station?.sensors ? getAvailableSensors([station]).filter(sensor => sensor.id !== "noise") : [];
        return sensors.map(sensor => sensor.id);
    }, isEqual);

    const [sensorX, setSensorX] = useState(availableSensors[0]);
    const [sensorY, setSensorY] = useState(availableSensors[1]);

    const updateSensorIfNotAvailable = useCallback((setSensor, selectedSensors = []) => {
        return new Promise((resolve) => {
            setSensor(oldValue => {
                if (availableSensors.includes(oldValue)) {
                    resolve(oldValue);
                    return oldValue;
                }
                const newSensor = availableSensors.find(sensor => !selectedSensors.includes(sensor));
                resolve(newSensor);
                return newSensor;
            });
        });
    }, [availableSensors]);

    useEffect(() => {
        // If the selected sensorX is not available, we will set the first available sensor as the selected sensor
        updateSensorIfNotAvailable(setSensorX).then((sensorX) => {
            // If the selected sensorY is not available, we will set the first available sensor as the selected sensor,
            // or the second available sensor if the first one is the same as the selected sensorX
            updateSensorIfNotAvailable(setSensorY, [sensorX]);
        });
    }, [updateSensorIfNotAvailable]);

    const handleRefresh = () => {
        updateData();
    };

    const updateData = useCallback(() => {
        if (!availableSensors.includes(sensorX) || !availableSensors.includes(sensorY)) return;

        updateState(state => {
            return {...state, loading: true, data: [], error: ""};
        });

        scatterPlotRequest(units.temperature, units.pollutants, selectedStation, sensorX, sensorY,
            selectedRange[0],
            selectedRange[1],
            (err, data) => {

                if (!isMounted.current) {
                    return;
                }
                if (!err) {
                    if (Array.isArray(data) && data.length === 0) {
                        updateState(state => {
                            return {...state, data: [], error: notFoundMessage, loading: false};
                        });
                    } else {
                        const xUnit = unitsMap.get(data.x.units);
                        const yUnit = unitsMap.get(data.y.units);
                        const xName = pollutantNames.get(sensorX);
                        const yName = pollutantNames.get(sensorY);
                        const fetchData = [{
                            name: t("analyticScreen.scatterPlot.title"),
                            x: data.x[sensorX],
                            y: data.y[sensorY],
                            hovertemplate: `<b>${xName}</b>: %{x} ${xUnit}<br><b>${yName}</b>: %{y} ${yUnit}`,
                            hoverlabel: {namelength: 0},
                            type: 'scatter',
                            mode: 'markers',
                            marker: {color: '#1A23D0', size: 4}
                        }];

                        if (data.x[sensorX].length > 0) {
                            const {m, y} = data.linearRegression.equation;
                            const {r2} = data.linearRegression;

                            let minX;
                            let maxX;

                            for (let i = 0; i < data.x[sensorX].length; i++) {
                                const x = data.x[sensorX][i];
                                if (!minX || x < minX) {
                                    minX = x;
                                }
                                if (!maxX || x > maxX) {
                                    maxX = x;
                                }
                            }

                            const xFit = generateIncrementalArray(minX, maxX, 200);
                            const yFit = xFit.map(x => m * x + y);

                            const formula = `y = ${m}x + ${y} <br> R² = ${r2}`;
                            fetchData.push({
                                name: "Línea de tendencia",
                                x: xFit,
                                y: yFit,
                                mode: "lines",
                                line: {color: "#FF0000", width: 2, dash: "dot"},
                                text: formula,
                                hoverinfo: "text"
                            });
                        }

                        updateState(state => {
                            return {...state, data: fetchData, loading: false};
                        });

                        setPlotLayout({
                            ...plotLayoutInitialState,
                            xaxis: {...plotLayoutInitialState.xaxis, title: `${xName} (${xUnit})`},
                            yaxis: {...plotLayoutInitialState.yaxis, title: `${yName} (${yUnit})`}
                        });
                    }


                } else {
                    updateState(state => {
                        return {...state, loading: false, error: data.status};
                    });
                    handleErrorResponse(data, response => {
                            setError(response,
                                false,
                                "analyticScreen.scatterPlot.could_not_update_station_data");
                    });
                }
            });
    }, [units.temperature, units.pollutants, selectedStation, availableSensors, sensorX, sensorY, selectedRange,
        isMounted, t, notFoundMessage, handleErrorResponse, setError]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const handleSensorXChange = useCallback((value) => {
        if (availableSensors.includes(value)) setSensorX(value);
    }, [availableSensors]);

    const handleSensorYChange = useCallback((value) => {
        if (availableSensors.includes(value)) setSensorY(value);
    }, [availableSensors]);

    return (
        <CardLayout className={className} title={t("analyticScreen.scatterPlot.title")}
                    helpText={t("analyticScreen.scatterPlot.en_analytics_scatter_plot")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout error={error} loading={loading} emptyData={data.length === 0} height={420}
                         chartStyles={{"& .modebar": {left: "42%"}}} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} pollutantList={availableSensors}
                         variableNames={["chart.pollutantX", "chart.pollutantY"]}
                         onPollutantSelect={[handleSensorXChange, handleSensorYChange]}
                         selectedPollutant={[sensorX, sensorY]}>
                <ChartView
                    layout={plotLayout}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default ScatterPlotCardView;
