import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useMessage from "../../../../../hooks/useMessage";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useSelector} from "react-redux";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {DEFAULT_PERIOD, meteoDataSource, sensor, meteoDataSourceMap} from "../../../../../constants";
import {unitsMap} from "../../unitsNames";
import {compareStationsColors} from "../CompareScreen";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {compareStationsDataRequest} from "../../../../../requests/compare/compareStationsDataRequest";
import {sortBySelection} from "../../../../../utils/sortComparedPollutants";
import {getSensorDataRanges} from "../../../../../utils/chartAxeRangeUtil";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {areAllSameTimezone, getCommonTimezone, getStationTimezones} from "../timezoneUtil";
import useRequest from "../../../../../hooks/useRequest";
import {AUTO_RESOLUTION} from "../../analytics/common/ResolutionSelector";
import {getVariableUnit} from "../../../../../utils/getVariableUnit";

const chartDomains = {
    first: [0.48, 1],
    second: [0.32, 0.44],
    third: [0.16, 0.28],
    fourth: [0, 0.12],
    firstExpanded: [0.4, 1],
    secondExpanded: [0.19, 0.33],
    thirdExpanded: [0, 0.12]
};

const getAxeVisibilityConfig = (visiblePollutants) => {
    const units = [...new Set(visiblePollutants.map(item => item.units))];
    const pressureData = visiblePollutants.filter((item) => item.units === "hPa" && item.visible === true);

    const y1Visible = pressureData.some((item) => item.y.find((value) => value !== null && !Number.isNaN(value)));
    const y2Visible = units.includes("percentage");
    const y3Visible = ["ug-m3", "ppb", "mg-m3", "ppm", "IAQ", "mm/h", "W/m²", "UV"].some((unit) => units.includes(unit));
    const y5Visible = units.includes("celsius") || units.includes("fahrenheit");
    const y1Domain = !y1Visible ? [0, 0.001] : chartDomains.fourth;
    const y2Domain = !y2Visible ? [0, 0.001] : y1Visible ? chartDomains.third : chartDomains.thirdExpanded;
    const y3Domain = !y3Visible ? [0, 0.001] : y1Visible ? chartDomains.first : chartDomains.firstExpanded;
    const y5Domain = !y5Visible ? [0, 0.001] : y1Visible ? chartDomains.second : chartDomains.secondExpanded;

    return { y2Visible, y5Visible, y3Visible, y1Visible, y2Domain, y5Domain, y3Domain, y1Domain };
};

const initialPlotLayout = {
    showlegend: false,
    yaxis: {
        title: "hPa"
    },
    yaxis2: {
        title: "RH %",
        range: [0, 100]
    },
    yaxis5: {
        title: "ºC",
    },
    xaxis: {showgrid: false},
    hovermode: "x unified",
    margin: {l: 60, r: 50, b: 20, t: 20}
};

const CompareSensorDataCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);

    const initialState = {
        rawData: {},
        pollutantRange: [],
        temperatureRange: [],
        pressureRange: [],
        data: [],
        hiddenVariables: [],
        loading: true,
        plotLayout: initialPlotLayout,
        selectedPollutant: null,
        pollutantList: [],
        y1Visible: true,
        y2Visible: true,
        y3Visible: true,
        y5Visible: true,
        y1Domain: chartDomains.fourth,
        y2Domain: chartDomains.third,
        y3Domain: chartDomains.first,
        y5Domain: chartDomains.second
    };

    const[{
        data, loading, pollutantRange, temperatureRange, pressureRange, plotLayout, rawData, hiddenVariables,
        error, selectedPollutant, pollutantList, y1Visible, y2Visible, y3Visible, y5Visible,
        y1Domain, y2Domain, y3Domain, y5Domain}, updateState] = useState({...initialState, error: notFoundMessage});

    const {handleErrorResponse} = useRequest();
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const {units} = useSelector(state => state.auth);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const [timeZone, setTimeZone] = useState(dataTimeZone);
    const [meteoDataOrigin, setMeteoDataOrigin] = useState(meteoDataSource.internal);

    const commonTimezone = useMemo(() => (
        getCommonTimezone(selectCompareStations, selectedRange[0], timeZone)
    ), [selectedRange, timeZone, selectCompareStations]);

    useEffect(() => {

        const availableSensors = getAvailableSensors(selectCompareStations).map(it => (it.id));

        if(!availableSensors.includes(selectedPollutant)) {
            let newPollutant = getAvailableSensors(selectCompareStations).map(it => (it.id))[0];
            updateData(newPollutant, true, []);
        }else
            updateData(selectedPollutant, true, []);

     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCompareStations, selectedRange, timeZone, selectedResolution]);

    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...initialPlotLayout,
                yaxis5: units.temperature === "celsius" ? {...initialPlotLayout.yaxis5} : {
                    title: "ºF",
                },
                xaxis: {
                    ...initialPlotLayout.xaxis,
                    hoverformat: `%-d %b, %Y %H:%M:%S${commonTimezone}`
                }
            }
        }));
    }, [units.pollutants, units.temperature, selectedPollutant, commonTimezone]);

    const selectedVariableUnit = useMemo(() => (
        unitsMap.get(getVariableUnit(selectedPollutant, units))
    ), [selectedPollutant, units]);

    const legendItems = useMemo(() => (
        data.filter(item => item.showlegend).map(item => ({
            id: item.stationId,
            label: item.name,
            color: item.marker.color,
            isHidden: item.visible === "legendonly"
        }))
    ), [data]);

    const availableStations = useMemo(() => legendItems.map(item => (
        selectCompareStations.find(station => station.id === item.id)
    )).filter(item => item), [legendItems, selectCompareStations]);

    const hasMultipleMeteoData = useMemo(() => {
        const availableSensors = getAvailableSensors(availableStations).map(it => (it.id));
        return meteoDataSourceMap[meteoDataSource.external].some(((item, index) => {
            const internalSensor = meteoDataSourceMap[meteoDataSource.internal][index];
            return availableSensors.includes(item) && availableSensors.includes(internalSensor);
        }));
    }, [availableStations]);

    const getChartData = useCallback((serverData, pollutant, hiddenStations) => {

        let currentHiddenStations = hiddenStations ? hiddenStations : [];
        const stationsHiddenList = currentHiddenStations.map(item => {
            return [...new Set(data.map(item => item.stationId))][item];});

        let currentData = serverData || rawData;
        let currentPollutant = pollutant || selectedPollutant;
        let dataArray = Object.entries(currentData);
        let sortedDataArray = sortBySelection(dataArray, selectCompareStations);
        let output = [];
        const timezones = getStationTimezones(selectCompareStations, selectedRange[0], timeZone);
        const sameTimezone = areAllSameTimezone(timezones);
        const isOriginMeteo = hasMultipleMeteoData && meteoDataOrigin === meteoDataSource.external;
        const temperatureSensor = isOriginMeteo ? sensor.temperature_meteo : sensor.temperature;
        const humiditySensor = isOriginMeteo ? sensor.rh_meteo : sensor.rh;

        sortedDataArray.forEach((station, stationIndex) => {
            let aux = Object.entries(station[1]);
            let variablesListToShow = aux.filter(pollutantItem => (
                [currentPollutant, temperatureSensor, humiditySensor, sensor.pressure].includes(pollutantItem[0])
            ));
            let currentStationChartInfo = [];
            const timezone = timezones[stationIndex];
            const timezoneString = sameTimezone ? "" : ` (UTC${timezone > 0 ? `+${timezone}` : timezone || ""})`;
            variablesListToShow.forEach((aux3, variableIndex) => {
                let units = aux3[1].units;
                let stationName = selectCompareStations.find(item => item.id === station[0])?.alias;
                currentStationChartInfo.push({
                    visible: stationsHiddenList.includes(station[0]) ? "legendonly" : true,
                    stationId: station[0],
                    marker: {color: compareStationsColors[stationIndex]},
                    legendgroup: `group${station[0]}`,
                    name: stationName,
                    ...aux3[1],
                    type: 'scatter',
                    showlegend: variableIndex === 0,
                    y: aux3[1].y.map(yValue => {
                        return yValue;
                    }),
                    yaxis: ["ug-m3", "ppb", "mg-m3", "ppm", "IAQ", "mm/h", "W/m²", "UV"].includes(units) ? 'y3' :
                                units === "celsius" || units === "fahrenheit" ? 'y5' :
                                    units === "percentage" ? 'y2' :
                                        units === "hPa" ? 'y1' : "error",

                    hovertemplate: `<b>${stationName}${timezoneString}</b> - %{y} ${unitsMap.get(aux3[1].units)}`,
                    hoverlabel: {namelength: 0},
                });
            });
            output.push(...currentStationChartInfo);
        });
        return output;
    }, [data, selectCompareStations, rawData, selectedPollutant, selectedRange, hasMultipleMeteoData,
        meteoDataOrigin, timeZone]);

    const isVariableDownloaded = useCallback((variable) => {
        if (Object.keys(rawData).length === 0) {
            return false;
        }
        let isContained = false;
        Object.entries(rawData).forEach(station => {
                Object.keys(station[1]).forEach(pollutant => {
                    if (pollutant === variable) {
                        isContained = true;
                    }
                });
            }
        );
        return isContained;
    }, [rawData]);

    const getMergedRawAndNewData = useCallback((data, rawData) => {
        let output = rawData;
        if (Object.keys(rawData).length === 0) {
            return data;
        }
        selectCompareStations.forEach(station => {
            output[station.id] = {...output[station.id], ...data[station.id]};
        });
        return output;
    }, [selectCompareStations]);


    const updateData = useCallback((variable, updateAll, hiddenStations) => {
        let currentPollutant = variable || selectedPollutant;
        let variableAlreadyDownloaded = updateAll ? false : isVariableDownloaded(currentPollutant);

        let pollutantList = getAvailableSensors(selectCompareStations).map(it => (it.id)).filter(item => (
            ![
                sensor.rh,
                sensor.temperature,
                sensor.temperature_meteo,
                sensor.rh_meteo,
                sensor.noise,
                sensor.pressure,
                sensor.equivalent_pressure,
                sensor.wind
            ].includes(item)
        ));

        updateState(state => {
            return {
                ...state,
                data: [],
                rawData: updateAll ? {} : state.rawData,
                loading: true,
                pollutantList,
                error: "",
                hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables,
                pollutantRange: [],
                temperatureRange: [],
                pressureRange: [],
                selectedPollutant: currentPollutant
            };
        });

        if (!variableAlreadyDownloaded) {
            let variablesToDownload = updateAll ? [
                sensor.temperature,
                sensor.rh,
                sensor.temperature_meteo,
                sensor.rh_meteo,
                sensor.pressure,
                currentPollutant
            ] : [currentPollutant];
            compareStationsDataRequest({
                    stations: selectCompareStations.map(item => item.id),
                    pollutants: units.pollutants,
                    variables: variablesToDownload,
                    temperature: units.temperature,
                    time: selectedRange[0],
                    endtime: selectedRange[1],
                    dataTimeZone: timeZone,
                    resolution: selectedResolution
                }, (err, data) => {
                    if (!isMounted.current) {
                        return;
                    }
                    if (!err) {

                        if(Object.values(data).every(item => item.length === 0)){
                            updateState(state => {
                                return {...state, loading: false, error: notFoundMessage};
                            });
                            return;
                        }

                        let mergedData = getMergedRawAndNewData(data, updateAll ? {} : rawData);
                        let chartData = getChartData(mergedData, currentPollutant, hiddenStations != null ? hiddenStations : hiddenVariables);

                        if (chartData.length > 0) {
                            updateState(state => {
                                return {
                                    ...state, data: chartData, rawData: mergedData, ...getSensorDataRanges(chartData, units),
                                    loading: false,
                                    hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables,
                                    ...getAxeVisibilityConfig(chartData)
                                };
                            });
                        } else {
                            updateState(state => {
                                return {
                                    ...state, data: chartData, rawData: mergedData,
                                    loading: false,
                                    hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables
                                };
                            });
                        }
                    } else {
                        if (data.status === 404) {
                            updateState(state => {
                                return {...state, loading: false, error: notFoundMessage};
                            });
                        } else {
                            updateState(state => {
                                return {...state, loading: false, error: t("compareScreen.stationData.could_not_compare")};
                            });
                            handleErrorResponse(data, response => {
                                setError(response,
                                    false,
                                    "compareScreen.stationData.could_not_compare");
                            });
                        }
                    }
                }
            );
        } else {

            const chartData = getChartData(null, currentPollutant, null);
            if (chartData.length > 0) {
                updateState(state => {
                    return {...state, data: chartData, loading: false, ...getSensorDataRanges(chartData, units),
                        ...getAxeVisibilityConfig(chartData)};
                });
            } else {
                updateState(state => {
                    return {...state, data: chartData, loading: false};
                });
            }

        }
    }, [selectedPollutant, isVariableDownloaded, selectCompareStations, units, selectedRange, timeZone,
        selectedResolution, isMounted, getMergedRawAndNewData, rawData, getChartData, hiddenVariables, notFoundMessage,
        handleErrorResponse, t, setError]);

    useEffect(() => {
        if (selectedPollutant)
            updateData(selectedPollutant, false, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meteoDataOrigin]);

    const pollutantCallback = useCallback((selectedPollutant) => {
        updateData(selectedPollutant, false, []);
    }, [updateData]);

    const plotConfig = {
        modeBarButtonsToRemove: ["select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines",
        ],
        displaylogo: false
    };

    const onLegendItemSelect = useCallback((stationId) => {
        const newHiddenVariables = [...hiddenVariables];
        const position = newHiddenVariables.indexOf(stationId);
        if (position === -1) {
            newHiddenVariables.push(stationId);
        } else {
            newHiddenVariables.splice(position, 1);
        }
        const newData = data.map(item => ({
            ...item,
            visible: newHiddenVariables.includes(item.stationId) ? "legendonly" : true
        }));
        const chartData = getChartData(null, null, newHiddenVariables);
        const error = legendItems.length === newHiddenVariables.length
            ? t("compareScreen.noStationSelected")
            : "";
        updateState(state => ({
            ...state,
            data: newData,
            hiddenVariables: newHiddenVariables,
            ...getAxeVisibilityConfig(chartData),
            error
        }));
    }, [data, getChartData, legendItems, hiddenVariables, t]);

    const handleRefresh = () => {
        updateData(null, true, null);
    };

    const handleTimeZoneChange = (event, newValue) => {
        setTimeZone(newValue);
    };

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    const onMeteoDataOriginChange = useMemo(() => {
        if (hasMultipleMeteoData) {
            return event => setMeteoDataOrigin(event.target.value);
        }
        return null;
    }, [hasMultipleMeteoData]);

    return (
        <CardLayout className={className} title={t("compareScreen.stationData.title")}
                    helpText={t("compareScreen.stationData.en_compare_stationData")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout loading={loading} error={error} emptyData={legendItems.length === hiddenVariables.length}
                         height={660} chartStyles={{ "& .modebar": { left: "47%" } }} timeZone={timeZone}
                         onTimeZoneChange={handleTimeZoneChange} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} onPollutantSelect={pollutantCallback}
                         selectedPollutant={selectedPollutant} pollutantList={pollutantList} legendItems={legendItems}
                         onResolutionChange={setSelectedResolution} resolution={selectedResolution}
                         onLegendItemSelect={onLegendItemSelect} meteoDataOrigin={meteoDataOrigin}
                         onMeteoDataOriginChange={onMeteoDataOriginChange} position="end">
                <ChartView
                    layout={{
                        ...plotLayout,
                        yaxis: {
                            ...plotLayout.yaxis,
                            visible: y1Visible,
                            domain: y1Domain,
                            range: pressureRange.length > 0 ? pressureRange : undefined
                        },
                        yaxis2: {...plotLayout.yaxis2, visible: y2Visible, domain: y2Domain},
                        yaxis3: {
                            title: selectedVariableUnit,
                            visible: y3Visible,
                            domain: y3Domain,
                            range: pollutantRange.length > 0 ? pollutantRange : undefined
                        },
                        yaxis5: {
                            ...plotLayout.yaxis5,
                            visible: y5Visible,
                            domain: y5Domain,
                            range: temperatureRange.length > 0 ? temperatureRange : undefined
                        }
                    }}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default CompareSensorDataCardView;
