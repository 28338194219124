import {openLinkInNewTab} from "./linkUtil";

export const getDocumentationLanguage = (language) => {
    // Show spanish documentation if the user's language is one of the following
    const languagesWithSpanishDocumentation = ["es", "ca"];
    return languagesWithSpanishDocumentation.includes(language) ? "es" : "en";
};

const getDocumentationLanguagePrefix = (language) => {
    const documentationLanguage = getDocumentationLanguage(language);
    return documentationLanguage === "en" ? "" : `${documentationLanguage}/`;
};

export const getDocumentationUrl = (language) => {
    const languagePrefix = getDocumentationLanguagePrefix(language);
    return `https://docs.cloud.bettair.city/${languagePrefix}`;
};

export const openDocumentationInNewTab = (language) => {
    openLinkInNewTab(getDocumentationUrl(language));
};
