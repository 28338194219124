import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const aqiRequest = () => (
    new Promise((resolve, reject) => {

        const url = `/map/aqi`;

        webClient.get(url)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                handleError(err, (_, error) => reject(error));
            });
    })
);
