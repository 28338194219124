import React from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {alarmOwner, alarmType} from "../../alarmConfigs";
import {DEFAULT_POPUP_WIDTH} from "../../../../../../constants";

const useStyles = makeStyles({
    container: {
        maxWidth: DEFAULT_POPUP_WIDTH
    },
    formControl: {
        marginBottom: 50
    }
});

const AlarmConfigStep1 = ({name,nameCallback,type,typeCallback,isOrganizationAlarm,organizationAlarmCallback}) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const organizationLevel = isOrganizationAlarm ? alarmOwner.organization : alarmOwner.userAlarm;

    const handleOrganizationLevelChange = (event) => {
        organizationAlarmCallback(event.target.value === alarmOwner.organization);
    };

    return (<>
        <Grid container className={classes.container} spacing={4}>
            <Grid item xs={12}>
                <TextField
                    inputProps={{ "data-testid": "add-alarm-name" }}
                    autoFocus={true} label={t("alarmScreen.popUp.name")} variant="outlined"
                    required fullWidth margin="normal"
                    onChange={nameCallback} value={name}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel>{t("alarmScreen.popUp.type")}</InputLabel>
                    <Select
                        value={type}
                        onChange={typeCallback}
                        label={t("alarmScreen.popUp.type")}
                        inputProps={{ "data-testid": "add-alarm-type" }}
                    >
                        <MenuItem value={alarmType.threshold}>{t("alarmScreen.type_threshold")}</MenuItem>
                        <MenuItem value={alarmType.state}>{t("alarmScreen.type_state")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel >{t("alarmScreen.popUp.alarm_level")}</InputLabel>
                    <Select
                        value={organizationLevel}
                        onChange={handleOrganizationLevelChange}
                        label={t("alarmScreen.popUp.alarm_level")}
                    >
                        <MenuItem value={alarmOwner.organization}>{t("alarmScreen.level_organization")}</MenuItem>
                        <MenuItem value={alarmOwner.userAlarm}>{t("alarmScreen.level_user")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </>);
};

export default AlarmConfigStep1;
