import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {STREET_MAP} from "../../../home/panel/BaseMapPanel";
import {getSavedLayerState} from "../../../../../../utils/localStorageUtil";

const STORAGE_NAME = "roseMapLayerState";

const MapContext = createContext({});
export const MapContextProvider = ({ children }) => {
    const userId = useSelector(state => state.auth.id);
    const [layerState, setLayerState] = useState({
        mapType: STREET_MAP,
        ...getSavedLayerState(STORAGE_NAME,userId)
    });
    const {mapType } = layerState;

    const setMapType = useCallback(value => (
        setLayerState(prevState => ({ ...prevState, mapType: value }))
    ), []);

    useEffect(() => {
        localStorage.setItem(`${STORAGE_NAME}-${userId}`, btoa(JSON.stringify(layerState)));
    }, [layerState, userId]);

    return (
        <MapContext.Provider value={{mapType, setMapType }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMapContext = () => useContext(MapContext);
