import React, {useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import validator from 'validator';
import {inviteMembersRequest} from "../../../../../requests/organization/organizationRequests";
import {DEFAULT_POPUP_WIDTH} from "../../../../../constants";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../../hooks/useLoading";
import {Backdrop, Grid, Typography} from "@mui/material";
import UserRole from "./UserRole";
import {ADMIN_ROLE} from "./UserRoles";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";

export default function InviteDialog({setInviteDialogState,sucessfullySent}) {

    const initialState = {
        email:'',
        validEmail:false,
        userType:ADMIN_ROLE,
        stations:[],
    };

    const { t } = useTranslation();
    const { setLoading } = useLoading();
    const { setError, setSuccess } = useMessage();
    const { handleErrorResponse } = useRequest();
    const [state, setState] = useState(initialState);
    const {email,validEmail,userType,stations} = state;

    const handleUserChange =
        useCallback((event, newUserRole)=>{
            if(newUserRole !== null){
                setState(state => ({...state,userType: newUserRole}));
            }
        },[]);

    const handleSelectedStationChange = useCallback((newStations)=>{
        setState(state=>({...state,stations: newStations}));
    },[]);

    const handleSendInvitation = () => {
        const body = {
            mail: email.toLowerCase(),
            role: [userType],
            stations: userType === ADMIN_ROLE ? [] : stations
        };
        setLoading(true);
        inviteMembersRequest(body,(error,data)=>{
            setLoading(false);
            if (!error) {
                setSuccess("organizationMembersDialog.sucessfully_sent");
                sucessfullySent();
            } else {
                handleErrorResponse(data, response => {
                    setInviteDialogState(false);
                    if(response?.status === 409)
                        setError(response, false, t("organizationMembersDialog.already_exist"));
                    else
                         setError(response, false, t("organizationMembersDialog.unknown_error"));
                });
            }
        });
    };

    const handleEmail =( event)=>{
        const value = event.target.value;
        setState(state => ({...state,email: value,validEmail:validator.isEmail(value)}));
    };

    const handleClose = ()=>{
        setState(initialState);
        setInviteDialogState( false);
    };

    return (
        <Backdrop open={true} style={{zIndex:1999}} >
            <Grid container style={{backgroundColor: "#fff", maxWidth: DEFAULT_POPUP_WIDTH, padding: 30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{t("organizationMembersDialog.invite_member")}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:20}}>
                    <Typography> {t("organizationMembersDialog.please_enter_email")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        style={{minWidth:400}}
                        inputProps={{ "data-testid": "invite-dialog-textfield" }}
                        label={t("organizationMembersDialog.email")} variant="outlined"
                                required autoComplete="email" autoFocus fullWidth  margin="normal"
                                onChange={handleEmail} value={email}/>
                </Grid>
                <UserRole stations={stations} userType={userType}
                handleSelectedStationChange={handleSelectedStationChange}
                          handleUserChange={handleUserChange}/>
                <Grid item container xs={12} style={{justifyContent:"flex-end"}}>
                    <Button
                        onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button
                        data-testid={"invite-dialog-send"}
                        disabled={!validEmail || (userType === ADMIN_ROLE ? false : stations.length === 0)}
                        onClick={handleSendInvitation} color="primary">
                        {t("organizationMembersDialog.send_invitation")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
}
