import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/CheckCircle';
import {useHistory} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import clsx from "clsx";
import {getStationAliasesMap} from "../../utils/stationUtil";
import ToastContainer from "./ToastContainer";

const useStyles = makeStyles({
    box: {
        background: "#26D13D",
        width: 400,
        right: -450,
        opacity: 0,
        pointerEvents: "none"
    },
    boxVisible: {
        right: 100,
        opacity: 1,
        pointerEvents: "all",
    },
    boxTriggered: {
        background: "#f6343d",
    }
});

const AlarmNotification = () => {

    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();

    const [notificationVisible, setNotificationVisible] = useState(false);
    const {lastNotification} = useSelector(state => state.notification);
    const stationAliases = useSelector(state => getStationAliasesMap(state.dashboardUI.stations), shallowEqual);

    const triggeredText = (lastNotification?.triggered
        ? t("alarmScreen.triggered")
        : t("alarmScreen.not_triggered"))
        .toLowerCase();

    useEffect(() => {
        if (lastNotification) {
            setNotificationVisible(true);
            const timeout = setTimeout(() => {
                setNotificationVisible(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [lastNotification]);

    const handleOnClick = () => {
        history.push("/alarm");
        setNotificationVisible(false);
    };

    const handleOnClose = () => {
        setNotificationVisible(false);
    };

    return <ToastContainer className={
        clsx(classes.box, notificationVisible && classes.boxVisible, lastNotification?.triggered && classes.boxTriggered)
    } onClick={handleOnClick} onCloseButton={handleOnClose}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }}>
            {lastNotification?.triggered
                ? <WarningIcon/>
                : <CheckIcon/>}
            <Typography variant={"subtitle1"}
                        style={{
                            marginLeft: 8,
                            fontWeight: "bold"
                        }}>
                {`${t("alarmScreen.alarm")} ${triggeredText}: ${lastNotification?.alarmName}`}
            </Typography>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 8,
        }}>
            <Typography variant={"subtitle2"}
                        style={{
                            marginLeft: 32,
                            fontWeight: "bold"
                        }}>{stationAliases[lastNotification?.station]}
            </Typography>
            <Typography
                style={{marginRight: 28, fontSize: 14, textDecoration: "underline", cursor: "pointer"}}>
                {t("home.more_detail")}
            </Typography>
        </div>
    </ToastContainer>;
};

export default AlarmNotification;
