import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

const CookiesDialog = () => {

    const { t } = useTranslation();
    const persistedState = localStorage.getItem('cookie_agreement');
    const initialCookieDialogState = persistedState ? JSON.parse(persistedState): {showDialog:true};

    const [state, setState] = useState(initialCookieDialogState);
    const {showDialog} = state || {};

    const handleClose = () =>{
        const cookieState = {showDialog:false};
        localStorage.setItem('cookie_agreement',JSON.stringify(cookieState));
        setState({showDialog:false});
    };

    return (
        <div>
            <Dialog
                open={showDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('cookies.enable')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('cookies.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {t('cookies.accept')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CookiesDialog;
