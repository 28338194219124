import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {updatePasswordRequest} from "../../../../../requests/profile/profileRequest";
import {ERROR_500, MINIMUN_PASSWORD_LENGHT} from "../../../../../constants";
import {hashGenerator} from "../../../../../utils/hashGenerator";
import {useTranslation} from "react-i18next";
import useLoading from "../../../../../hooks/useLoading";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {isPasswordValid} from "../../../../../utils/passwordValidator";

const ChangePasswords = ({open,closeDialog}) => {

    const { t } = useTranslation();
    const { loading, setLoading } = useLoading();
    const { setError, setSuccess } = useMessage();
    const { handleErrorResponse } = useRequest();

    const initialState = {
        newPassword:'',
        confirmPassword:'',
        showNewPasswordFieldError:false,
        showConfirmPasswordFieldError:false};


    const [{newPassword,confirmPassword,
    showNewPasswordFieldError,
        showConfirmPasswordFieldError},updateFormState] = useState(initialState);

    const handleClose =()=>{
        clearAndCloseDialog();
    };

    function clearAndCloseDialog(){
        updateFormState(initialState);
        closeDialog();
    }

    const handleNewPassword = (event)=>{
        updateNewPasswordErrorState(false);
        const value = event.target.value;
        updateFormState(state =>{
            return {...state,newPassword:value};
        });
    };

    const handleConfirmPassword = (event)=>{
        updateConfirmPasswordErrorState(false);
        const value = event.target.value;
        updateFormState(state =>{
            return {...state,confirmPassword:value};
        });
    };

    const handleUpdatePassword = async ()=>{
        const isFormValid = validateForm();
        if(isFormValid){
            setLoading(true);
            let digestHex = await hashGenerator(newPassword);
            updatePasswordRequest({pass:digestHex},(error,data)=>{
                setLoading(false);
                if (!error) {
                    setSuccess("changePassword.success_changed");
                    clearAndCloseDialog();
                } else {
                    handleErrorResponse(data, response => {
                        switch (response?.status) {
                            case 400:
                                setError({message: t("changePassword.invalid_new_password")});
                                break;
                            case 500:
                                setError(ERROR_500, true);
                                break;
                            default:
                                setError({message: t("changePassword.error_unknown")});
                        }
                    });
                }
            });
        }
    };

    function updateNewPasswordErrorState(showError){
        updateFormState(state =>{
            return{...state,showNewPasswordFieldError:showError};
        });
    }

    function updateConfirmPasswordErrorState(showError){
        updateFormState(state =>{
            return{...state,showConfirmPasswordFieldError:showError};
        });
    }

    const validateForm = ( )=>{
        if(!isPasswordValid(newPassword)){
            updateNewPasswordErrorState(true);
            return false;
        }
         if(newPassword !== confirmPassword){
             updateConfirmPasswordErrorState(true);
             return false;
         }
         return true;
    };

    return (
        <Dialog onClose={handleClose}  open={open} fullWidth>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {t("changePassword.change_password")}
            </DialogTitle>
            <DialogContent >
                <TextField  error={showNewPasswordFieldError}
                            label={showNewPasswordFieldError ? t("error") : t("changePassword.new_password") }
                            variant="outlined"
                            required autoComplete="current-password"
                            type="password" fullWidth  margin={"normal"}
                            value={newPassword}
                            onChange={handleNewPassword}
                            helperText={showNewPasswordFieldError ? `${t("changePassword.new_password_must_contain")}
                             ${MINIMUN_PASSWORD_LENGHT} ${t("changePassword.character")} ${t("changePassword.rest_password_requirements")}`
                                : t("changePassword.enter_new_password")}

                />
                <TextField  error={showConfirmPasswordFieldError}
                            label={showConfirmPasswordFieldError ? t("error") :t("changePassword.confirm") }
                            variant="outlined"
                            required autoComplete="current-password"
                            type="password" fullWidth  margin={"normal"}
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                            helperText={showConfirmPasswordFieldError ? t("changePassword.confirm_must_match")
                                : t("changePassword.confirm_your_password")}

                />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose} color="primary">{
                    t("cancel")
                }
                </Button>

                <Button  disabled={loading} onClick={handleUpdatePassword} color="primary">
                    { t("update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswords;
