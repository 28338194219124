import { createStyles } from '@mui/styles';

export const styles = createStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    error: {
        color: "#d22929",
        fontWeight: "bold"
    },
    card: {
        background: "white",
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 5,
        minHeight: 245
    },
    firstRowCard: {
        minHeight: 600
    },
    cardTitle: {
        color: "#000000",
        fontWeight: "bold"
    },
    deleteButton:{
        color: "#ffffff",
        background: "#f6343d",
        '&:hover': {
            background: "#81191c"
        },
    },
    helpButton: {
        color: "black"
    },
    rowTable: {
        marginTop: 20
    },
    table: {
        height: 485,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    },
    not_deletable: {
        fontWeight: '500',
        color: '#bcbcbc',
        backgroundColor: 'rgba(105,105,105,0.05)',
    },
    deletable: {
    },
    itemLink: {
        fontSize: "1rem"
    }
});
