import {webClient} from "../../config/axios";

export const pollutantTrendRequest = (params) => {

    const {stationId, pollutants, temperature, time, sensor} = params;
    const url = `/station-data/${stationId}/pollutant-trend?pollutants=${pollutants}&time=${time}&sensor=${sensor}&temperature=${temperature}`;

    return webClient.get(url).then( result => result.data).catch(err => {
        if (err.response?.status === 404)
            return undefined;
        throw(err);
    });
};


