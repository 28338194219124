import React from 'react';
import {pollutantNames} from "../../routes/private/pollutantNames";
import {useTranslation} from "react-i18next";
import PollutantToggleButtons from "./PollutantToggleButtons";
import SelectControl from "../SelectControl";

const PollutantSelect = ({index, name, formControlClass, type, disabled, pollutantList, selectedPollutant,
                             onPollutantSelect, fullWidth}) => {

    const {t} = useTranslation();

    const options = pollutantList?.map(value => ({value, label: pollutantNames.get(value)}));

    const handleSelectorChange = (event) => {
        const pollutant = event.target.value;
        onPollutantSelect(pollutant);
    };

    return type === "tabs" ? (
        pollutantList ? (
            <PollutantToggleButtons className={formControlClass}
                                    pollutantList={pollutantList}
                                    pollutantCallback={onPollutantSelect}
                                    selectedPollutant={selectedPollutant}/>
        ) : null
    ) : (
        <SelectControl
            className={formControlClass}
            label={name ? t(name) : `${t("chart.pollutant")}${index ? ` ${index}` : ''}`}
            disabled={disabled}
            options={options}
            value={selectedPollutant}
            onChange={handleSelectorChange}
            variant="standard"
            margin="dense"
            testId={index ? `pollutant-select-${index}` : 'pollutant-select'}
            fullWidth={fullWidth}
        />
    );
};

export default PollutantSelect;
