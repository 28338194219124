import React, {useCallback, useMemo} from 'react';
import {Grid, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ADMIN_ROLE, ADMIN_ROLE_DISTRIBUTOR, VIEWER_ROLE} from "./UserRoles";
import {getStationOptions, STATION_SEARCHABLE_FIELDS} from "../../../../../utils/stationUtil";
import {isEqual} from "lodash";

const UserRole = ({stations, userType, handleUserChange, handleSelectedStationChange}) => {
    const {t} = useTranslation();
    const allStations = useSelector(state => getStationOptions(state.dashboardUI.stations), isEqual);
    const {role} = useSelector((state) => state.auth);

    const hasDistributionPermission = useMemo(() => {
        return role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined;
    }, [role]);


    const initialSelected = allStations.filter((station) => stations.includes(station.id));

    const selectedCallback = useCallback((selStations) => {
        const stationIds = selStations.map((station) => station.id);
        handleSelectedStationChange(stationIds);
    }, [handleSelectedStationChange]);

    return (
        <>
            <Grid item xs={12} style={{marginTop: 10}}>
                <Typography> {t("organizationMembersDialog.setUser")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <ToggleButtonGroup
                    style={{marginTop: 10}}
                    color="primary"
                    value={userType}
                    exclusive
                    onChange={handleUserChange}
                >
                    {hasDistributionPermission && <ToggleButton
                        value={ADMIN_ROLE_DISTRIBUTOR}>{t("organizationMembersDialog.adminDistributor")}</ToggleButton>}
                    <ToggleButton value={ADMIN_ROLE}>{t("organizationMembersDialog.admin")}</ToggleButton>
                    <ToggleButton value={VIEWER_ROLE}>{t("organizationMembersDialog.viewer")}</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <Typography> {t("organizationMembersDialog.visibleStations")}</Typography>
            </Grid>
            <Grid item xs={12} style={{
                marginBottom: 10, marginTop: 16, display: "flex", flexDirection: "column",
                alignItems: "center"
            }}>
                <MultipleSelector initialSelected={initialSelected}
                                  items={allStations}
                                  isSmallSize={true}
                                  disable={userType !== VIEWER_ROLE}
                                  selectedCallback={selectedCallback}
                                  min={1}
                                  searchableFields={STATION_SEARCHABLE_FIELDS}
                                  isStation={true}
                />
            </Grid>
        </>
    );
};

export default UserRole;
