import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import useRequest from "../../../../../hooks/useRequest";
import {DEFAULT_PERIOD} from "../../../../../constants";
import {useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import useMessage from "../../../../../hooks/useMessage";
import {unitsMap} from "../../unitsNames";
import {sensorStatisticRequest} from "../../../../../requests/analytics/sensorStatisticRequest";
import {pollutantNames} from "../../pollutantNames";
import {useTranslation} from "react-i18next";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";

const useStyles = makeStyles({
    table: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20
    }
});

const SensorStatisticCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {handleErrorResponse} = useRequest();
    const initialState = {rawData: [], data: [], error: notFoundMessage, loading: true};
    const [{rawData, loading, error}, updateState] = useState(initialState);
    const { setError } = useMessage();
    const isMounted = useMountComponent();
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const { units } = useSelector( state => state.auth );
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);

    const classes = useStyles();

    const updateData = useCallback(() => {

        updateState(state =>{
            return {...state,loading: true, data: [],error: "",rawData:[]};
        });

        sensorStatisticRequest(units.temperature,units.pollutants,selectedStation,
            selectedRange[0],
            selectedRange[1], (err,data)=>{
                if (!isMounted.current) {return;}
                if(!err){
                    updateState(state =>{
                        return {
                            ...state,
                            loading: false,
                            rawData: data
                        };
                    });
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false, error:notFoundMessage};
                        });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error: t("analyticScreen.statistic.could_not_update_data")};
                        });
                        handleErrorResponse(data, response => {
                            setError(response,
                                false,
                                "analyticScreen.statistic.could_not_update_data");
                        });
                    }
                }
            } );
    },[units.temperature, units.pollutants, selectedStation, selectedRange, isMounted, notFoundMessage,
        handleErrorResponse, t, setError]);

    useEffect(()=>{
        updateData();
    },[updateData]);

    const statsTableColumns = useMemo(() => [
        {
            id: "min",
            title: t("analyticScreen.statistic.min")
        },
        {
            id: "p5",
            title: `${t("analyticScreen.statistic.percentile_short")} 5`
        },
        {
            id: "p25",
            title: `${t("analyticScreen.statistic.percentile_short")} 25`
        },
        {
            id: "mean",
            title: t("analyticScreen.statistic.mean")
        },
        {
            id: "p75",
            title: `${t("analyticScreen.statistic.percentile_short")} 75`
        },
        {
            id: "p95",
            title: `${t("analyticScreen.statistic.percentile_short")} 95`
        },
        {
            id: "max",
            title: t("analyticScreen.statistic.max")
        }
    ], [t]);

    const statsTableData = useMemo(() => {
        const sensorData = {};
        statsTableColumns.forEach(({id}) => {
            const columnData = rawData[id];
            if (columnData) {
                Object.keys(columnData).forEach((sensorId) => {
                    if (!sensorData[sensorId]) {
                        sensorData[sensorId] = {units: columnData[sensorId].units};
                    }
                    sensorData[sensorId][id] = columnData[sensorId].value;
                });
            }
        });
        return sensorData;
    }, [statsTableColumns, rawData]);

    return (
        <CardLayout className={className} title={t("analyticScreen.statistic.statistic")}
                    helpText={t("analyticScreen.statistic.en_analytics_stationStatistic")}>
            <ChartLayout error = {error} loading={loading} emptyData={Object.keys(statsTableData).length === 0} height={680}
                         period={period} onPeriodChange={setPeriod} dateRange={dateRange}
                         onDateRangeChange={setDateRange}>
                <TableContainer>
                    <Table stickyHeader aria-label="event table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    {t("alarmScreen.sensor")}
                                </TableCell>
                                <TableCell align="left">
                                    {t("analyticScreen.statistic.unit")}
                                </TableCell>
                                {statsTableColumns.map(({id, title}) => (
                                    <TableCell key={id} align="right">
                                        {title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(statsTableData)?.map((sensorId) => (
                                <TableRow key={sensorId}>
                                    <TableCell align="left">
                                        {pollutantNames.get(sensorId)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {unitsMap.get(statsTableData[sensorId].units)}
                                    </TableCell>
                                    {statsTableColumns.map(({id}) => (
                                        <TableCell key={id} align="right">
                                            {statsTableData[sensorId][id] ?? "-"}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ChartLayout>
        </CardLayout>
    );
};

export default SensorStatisticCardView;
