export const
    GREEN_AQI           = "#52B946",
    YELLOW_AQI          = "#F3EC18",
    ORANGE_AQI          = "#F67D1E",
    RED_AQI             = "#ED1C25",
    PURPLE_AQI          = "#7D287C",
    DARK_PURPLE_AQI     = "#480D27",

    DARK_GRAY           = "#444444",
    GRAY                = "#AAAAAA",
    LIGHT_GRAY          = "#CCCCCC",
    WHITE               = "#EEEEEE",

    TEXT_COLOR          = "#484848",
    TEXT_COLOR_CONTRAST = "#FFFFFF",

    SELECTED_COLOR      = "#FFFFFF";
