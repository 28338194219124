import {webClient} from "../../config/axios";

export const postInvalidPeriodsRequest = (body) => {
    return webClient.post("/validate", body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 20000,
    });
};
