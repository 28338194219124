import {Icon} from "@mui/material";
import LogoBettairMini from "../../../../assets/logo_bettair_mini.svg";

const ToolbarBettairIcon = () => {
    return (
        <Icon>
            <img src={LogoBettairMini} alt="logo" style={{width: "1em", height: "1em"}}/>
        </Icon>
    );
};

export default ToolbarBettairIcon;