import React, {useCallback, useEffect, useState} from 'react';
import {
    FormControl,
    FormHelperText,
    Link,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import useDateRange from "../../../../../hooks/useDateRange";
import {makeStyles} from "@mui/styles";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {useTranslation} from "react-i18next";
import {DECIMAL_SEPARATOR} from "../DownloadConfigPopUp";
import {getDateRangeIncludingEndDate} from "../../../../../utils/requestPeriodGenerator";
import {getFormattedDateTimeWithNavigatorTimeZone} from "../../../../../utils/getFormattedDateTime";
import {getDocumentationLanguage, getDocumentationUrl} from "../../../../../utils/documentationUtil";
import {openLinkInNewTab} from "../../../../../utils/linkUtil";
import useModules from "../../../../../hooks/useModules";
import {appModules, indexTypes} from "../../../../../constants";
import moment from "moment-timezone";
import {getAqiIndexNameAndRegionText} from "../../../../../utils/aqiUtil";

const useStyles = makeStyles({
    saveButton: {
        color: '#ffffff',
    },
    changePasswordButton: {
        marginTop: 24
    },
    card: {
        maxWidth: 1600,
        width: "100%",
        display: "flex",
        background: "white",
        padding: 40,
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 6,
    },
    form: {
        width: "100%",
    },
    email: {
        color: "#8a8a8a"
    },
    emailTittle: {
        fontWeight: "bold"
    },
    unitsTittle: {
        marginTop: 32,
        color: "#c4c4c4",
        marginBottom: 0
    },
    grip: {
        marginTop: 12,

    }
});

const Step1 = ({
                   name, nameCallback, pollutantUnit, pollutantCallback,
                   tempUnit, tempCallback, period, initialDate, updateDate, windSpeedCallback, windSpeedUnit,
                   groupInterval, groupIntervalCallback, decimalSeparator, decimalSeparatorCallback, includeValidation,
                   includeValidationCallback, index, indexCallback
               }) => {

    const [{openDateRangePicker, dateRange}, updateOpenDatePickerCallback,
        updateDatePickedCallback, clearDataRange] = useDateRange();
    const {hasModule} = useModules();
    const [{nameError}, updateState] = useState({nameError: false});
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const handleNameUpdate = (event) => {
        let value = event.target.value;
        updateState({nameError: value.includes(`/`) || value.includes(`\\`) || value.startsWith(".")});
        nameCallback(event);
    };

    useEffect(
        () => {
            if (dateRange != null) {
                updateDate(CUSTOM_RANGE, dateRange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dateRange]);

    useEffect(() => {
        if (initialDate !== null) {
            updateDatePickedCallback(initialDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectorChange = useCallback((event) => {
        if (Number(event.target.value) !== CUSTOM_RANGE) {
            clearDataRange();
            updateDate(event.target.value, null);
        }
    }, [clearDataRange, updateDate]);

    const onCustomPressedCallback = useCallback(() => {
            updateOpenDatePickerCallback(true);
        }
        , [updateOpenDatePickerCallback]);

    const timeZone = moment.tz.guess();

    const selectedRange = getDateRangeIncludingEndDate(period, dateRange, timeZone);

    const handleOnClickHelpLink = () => {
        let path = `${getDocumentationUrl(i18n.language)}downloads/`;
        if (getDocumentationLanguage(i18n.language) === "es") {
            path += "#crear-descargas";
        } else {
            path += "#create-downloads";
        }
        openLinkInNewTab(path);
    };

    return (
        <><DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                              onDateRangePicked={updateDatePickedCallback}/>
            <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        error={nameError}
                        inputProps={{"data-testid": "download-step1-name"}}
                        onChange={handleNameUpdate}
                        value={name}
                        helperText={nameError ? t("downloadScreen.popUp.invalid_characters") : null}
                        label={!nameError ? t("downloadScreen.popUp.download_name") : t("error")} fullWidth/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                        <InputLabel>{t("units.concentration_units")}</InputLabel>
                        <Select
                            label={t("units.concentration_units")}
                            onChange={pollutantCallback}
                            value={pollutantUnit}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                        >
                            <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                            <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                        <InputLabel>{t("units.temperature_units")}</InputLabel>
                        <Select
                            label={t("units.temperature_units")}
                            onChange={tempCallback}
                            value={tempUnit}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                        >
                            <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                            <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                        <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                        <Select
                            label={t("units.wind_speed_unit")}
                            onChange={windSpeedCallback}
                            value={windSpeedUnit}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                        >
                            <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                            <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                            <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                        </Select>
                        <FormHelperText> </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                        <InputLabel>{t("units.pollutant_index")}</InputLabel>
                        <Select
                            label={t("units.pollutant_index")}
                            onChange={indexCallback}
                            value={index}
                            data-testid = "step1-pollutant-index"
                            id="step1-selector-pollutant"
                        >
                            {Object.values(indexTypes).map(item =>
                                <MenuItem key={item} value={item}>{getAqiIndexNameAndRegionText(item, t)}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText> </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                        <InputLabel>{t("downloadScreen.popUp.decimal_separator")}</InputLabel>
                        <Select
                            label={t("downloadScreen.popUp.decimal_separator")}
                            value={decimalSeparator}
                            onChange={decimalSeparatorCallback}
                        >
                            <MenuItem value={DECIMAL_SEPARATOR.dot}>{t("downloadScreen.popUp.dot_separator")}</MenuItem>
                            <MenuItem
                                value={DECIMAL_SEPARATOR.comma}>{t("downloadScreen.popUp.comma_separator")}</MenuItem>
                        </Select>
                        <FormHelperText>
                            <a href={`${t("docs.domain")}downloads/#import-download`} target="_blank"
                               rel="noopener noreferrer">
                                {t("docs.whatIsThis")}
                            </a>
                        </FormHelperText>
                    </FormControl>
                </Grid>

                {hasModule(appModules.dataValidation) &&
                    <Grid item container xs={12} sm={4}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("downloadScreen.popUp.include_validation")}</InputLabel>
                                <Select
                                    label={t("downloadScreen.popUp.include_validation")}
                                    value={includeValidation}
                                    onChange={includeValidationCallback}
                                >
                                    <MenuItem value={0}>{t("no")}</MenuItem>
                                    <MenuItem value={1}>{t("yes")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <Link
                                    onClick={handleOnClickHelpLink}
                                    style={{cursor: "pointer"}}>
                                    {t("what_is_this")}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <Grid item container xs={12} sm={8} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("downloadScreen.popUp.group_interval")}</InputLabel>
                            <Select
                                label={t("downloadScreen.popUp.group_interval")}
                                value={groupInterval}
                                onChange={groupIntervalCallback}
                            >
                                <MenuItem value={0}>{`${t("maxResolution")}`}</MenuItem>
                                <MenuItem value={5}>{`5 ${t("units.minutes")}`}</MenuItem>
                                <MenuItem value={10}>{`10 ${t("units.minutes")}`}</MenuItem>
                                <MenuItem value={15}>{`15 ${t("units.minutes")}`}</MenuItem>
                                <MenuItem value={30}>{`30 ${t("units.minutes")}`}</MenuItem>
                                <MenuItem value={60}>{`1 ${t("units.hour")}`}</MenuItem>
                                <MenuItem value={1440}>{`1 ${t("units.day")}`}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PeriodSelector loading={false}
                                        period={period}
                                        handleSelectorChange={handleSelectorChange}
                                        dateRange={dateRange}
                                        onCustomPressedCallback={onCustomPressedCallback}
                                        fullWidth={true}
                        />
                    </Grid>


                    <Grid item xs={12} style={{paddingTop: 0, textAlign: "right"}}>
                        <Typography variant="caption" style={{color: "#a1a1a1"}}>
                            {
                                getFormattedDateTimeWithNavigatorTimeZone(new Date(selectedRange[0]))
                            } - {
                            getFormattedDateTimeWithNavigatorTimeZone(new Date(selectedRange[1]))
                        }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Step1;
