import type from "./action_types/type";

const initialState = {
    notificationCount: 0,
    lastNotification: null,
};

export const notificationReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.putNewNotificationAction:
            return {
                ...state,
                notificationCount: state.notificationCount + 1,
                lastNotification: {...action.payload}
            };
        case type.setNotificationCountAction:
            return {
                ...state,
                notificationCount: action.payload * 1
            };
        default:
            return state;
    }
};

export const putNewNotificationAction = function (notificationObject){
    return {
        type:type.putNewNotificationAction,
        payload:notificationObject
    };
};

export const setNotificationCountAction = function (notificationCount) {
    return {
        type:type.setNotificationCountAction,
        payload:notificationCount
    };
};