import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Card, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {isURL} from "validator";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import {useTranslation} from "react-i18next";
import {updateSentiloRequest} from "../../../../../requests/organization/organizationRequests";
import useMessage from "../../../../../hooks/useMessage";
import DataNotFound from "../../../../common/DataNotFound";
import SaveButton from "../../../../common/buttons/SaveButton";


const useStyles = makeStyles({
    title: {
        fontWeight: "bold"
    },
    saveButton: {
        color: "#ffffff"
    },
    helpButton: {
        color: "black"
    }
});


const OrganizationSentilo = ({className, sentiloConfig}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [saving, setSaving] = useState(false);
    const {setError, setSuccess} = useMessage();
    const [sentiloEnabled, setSentiloEnabled] = useState(false);
    const [sentiloUrl, setSentiloUrl] = useState("");
    const [sentiloProvider, setSentiloProvider] = useState("");
    const [sentiloToken, setSentiloToken] = useState("");
    const [loadedConfig, setLoadedConfig] = useState(null);

    const [urlError, setUrlError] = useState("");
    const [providerError, setProviderError] = useState("");
    const [tokenError, setTokenError] = useState("");

    const clearErrors = useCallback(() => {
        setUrlError("");
        setProviderError("");
        setTokenError("");
    }, []);

    const resetData = useCallback(() => {
        setSentiloEnabled(!!sentiloConfig?.enabled);
        setSentiloUrl(sentiloConfig?.baseUrl || "");
        setSentiloProvider(sentiloConfig?.provider || "");
        setSentiloToken(sentiloConfig?.token || "");
    }, [sentiloConfig]);

    useEffect(() => {
        clearErrors();
    }, [clearErrors, sentiloEnabled]);

    useEffect(() => {
        if (sentiloConfig) {
            setLoading(false);
            setLoadingError(false);
            clearErrors();
            resetData();
            setLoadedConfig(sentiloConfig);
        } else {
            setLoading(sentiloConfig !== null);
            setLoadingError(sentiloConfig === null);
        }
    }, [sentiloConfig, clearErrors, resetData]);

    const validateForm = useCallback(() => {
        let valid = true;
        if (!sentiloEnabled) {
            return valid;
        }
        if (sentiloUrl === "") {
            setUrlError("validation.required");
            valid = false;
        } else if (!isURL(sentiloUrl)) {
            setUrlError("validation.invalid_url");
            valid = false;
        } else {
            setUrlError("");
        }
        if (sentiloProvider === "") {
            setProviderError("validation.required");
            valid = false;
        } else {
            setProviderError("");
        }
        if (sentiloToken === "") {
            setTokenError("validation.required");
            valid = false;
        } else {
            setTokenError("");
        }
        return valid;
    }, [sentiloEnabled, sentiloUrl, sentiloProvider, sentiloToken]);


    const onChangeUrl = useCallback((event) => {
        setSentiloUrl(event.target.value);
        setUrlError("");
    }, []);

    const onChangeProvider = useCallback((event) => {
        setSentiloProvider(event.target.value);
        setProviderError("");
    }, []);

    const onChangeToken = useCallback((event) => {
        setSentiloToken(event.target.value);
        setTokenError("");
    }, []);

    const onChangeEnabled = useCallback((event) => {
        setSentiloEnabled(event.target.checked);
    }, []);

    const onSaveButtonClick = useCallback(() => {
        const body = {
            enabled: sentiloEnabled,
            baseUrl: sentiloUrl,
            provider: sentiloProvider,
            token: sentiloToken
        };
        if (validateForm()) {
            setSaving(true);
            updateSentiloRequest(body, (error) => {
                setSaving(false);
                if (error) {
                    setError("organizationSentilo.error_updating_config");
                    resetData();
                } else {
                    setSuccess("organizationSentilo.config_updated");
                    setLoadedConfig(body);
                }
            });
        }
    }, [sentiloEnabled, sentiloUrl, sentiloProvider, sentiloToken, validateForm, resetData, setError, setSuccess]);

    const hasChanges = useMemo(() => {
        return sentiloEnabled !== !!loadedConfig?.enabled
            || sentiloUrl !== (loadedConfig?.baseUrl || "")
            || sentiloProvider !== (loadedConfig?.provider || "")
            || sentiloToken !== (loadedConfig?.token || "");
    }, [sentiloEnabled, sentiloUrl, sentiloProvider, sentiloToken, loadedConfig]);

    const hasErrors = !!(urlError || providerError || tokenError);

return (
        <Card className={className}>
            {loading && <CardViewLoading/>}
            {loadingError && <DataNotFound/>}
            {!loading && !loadingError && (
                <Grid container direction={"row"} spacing={2}>
                    <Grid item container xs={12}>
                        <Typography className={classes.title} variant={"h4"}>
                            {t("organizationSentilo.title")}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} spacing={3} justifyContent="flex-end">
                        <Grid item xs={12}>
                            <FormControlLabel label={t("organizationSentilo.enable_sentilo")}
                                              disabled={saving}
                                              control={<Checkbox checked={sentiloEnabled}
                                                                 onChange={onChangeEnabled}
                                                                 data-testid="sentilo-enabled-checkbox" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t("organizationSentilo.sentilo_url")}
                                name="sentiloUrl"
                                variant="outlined"
                                disabled={!sentiloEnabled || saving}
                                value={sentiloUrl}
                                onChange={onChangeUrl}
                                helperText={urlError && t(urlError)}
                                error={!!urlError}
                                InputProps={{
                                    "data-testid": "sentilo-url-input"
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label={t("organizationSentilo.sentilo_provider")}
                                name="sentiloUser"
                                variant="outlined"
                                disabled={!sentiloEnabled || saving}
                                value={sentiloProvider}
                                onChange={onChangeProvider}
                                helperText={providerError && t(providerError)}
                                error={!!providerError}
                                InputProps={{
                                    "data-testid": "sentilo-provider-input"
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label={t("organizationSentilo.sentilo_token")}
                                name="sentiloPassword"
                                variant="outlined"
                                disabled={!sentiloEnabled || saving}
                                value={sentiloToken}
                                onChange={onChangeToken}
                                helperText={tokenError && t(tokenError)}
                                error={!!tokenError}
                                InputProps={{
                                    "data-testid": "sentilo-token-input"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <SaveButton className={classes.saveButton} data-testid="save-button" fullWidth
                                        disabled={!hasChanges || hasErrors || saving} saving={saving}
                                        onSave={onSaveButtonClick} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
};

export default OrganizationSentilo;