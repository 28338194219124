import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const sensorStatisticRequest = (temp,pollutantUnits,stationId,init,end,callback) => {
    let params = end ? `?temperature=${temp}&pollutants=${pollutantUnits}&time=${init}&endtime=${end}` :
        `?temperature=${temp}&pollutants=${pollutantUnits}&time=${init}`;
    let url = `/station-data/${stationId}/sensor-statistics/${params}`;
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};