import React, {useCallback, useEffect, useState} from 'react';
import {Button, CardContent, Grid, IconButton, Typography, Link} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HelpPopup from "../../../../common/HelpPopup";
import CalendarComponent from "./CalendarComponent";
import CalendarMonthSelector from "./CalendarMonthSelector";
import CalendarDaysTittle from "./CalendarDaysTittle";
import {predominantPollutantRequest} from "../../../../../requests/analytics/predominantPollutantRequest";
import {useSelector} from "react-redux";
import CardTitle from "../../../../common/card_views/CardTitle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CalendarLegend from "./CalendarLegend";
import {useTranslation} from "react-i18next";
import CardLayout from "../../../../common/card_views/CardLayout";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {getDocumentationUrl} from "../../../../../utils/documentationUtil";
import moment from "moment-timezone";
import CalendarDayDetail from "./CalendarDayDetail";
import clsx from "clsx";
import {getFormattedUtcDate} from "../../../../../utils/getFormattedDateTime";
import {getInitOfDayMonthInUTC} from "../../../../../utils/dateUtil";
import {sensor, timeZoneOptions} from "../../../../../constants";
import {getVariableName} from "../../pollutantNames";

const useStyles = makeStyles({
    calendarTittle:{
        marginTop:8
    },
    title:{
        display:"flex",
        alignItems:"center"
    },
    backButton: {
        marginLeft: 15
    },
    dateSelector: {
        paddingRight: 20,
        justifyContent: "flex-end"
    },
    dateText: {
        marginLeft: 5,
        marginRight: 5
    },
    cardView: {
        position: "relative"
    },
    legendContainer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        marginBottom: 5,
        paddingLeft: 10,
        paddingRight: 10
    },
    hidden: {
        display: "none"
    }
});

const AQICalendarCardView = ({className}) => {

    const {t, i18n} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const { setError } = useMessage();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const isMounted = useMountComponent();
    const { units } = useSelector( state => state.auth );
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const timeZone =
        (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;
    const nowLocalDateString = moment().tz(timeZone).format("YYYY-MM-DD");
    const [{data,selectedDate,loading},updateState] =
        useState({data:[],loading:false,selectedDate: new Date(),error:false});
    const [selectedDay, setSelectedDay] = useState(null);
    const [loadingDayData, setLoadingDayData] = useState(false);


    const fetchAQIData = useCallback(() =>{
        updateState(state =>{
            return {...state,data:[],loading: true,error:false};
        });
        const selectedMonth = getInitOfDayMonthInUTC(selectedDate);
        predominantPollutantRequest(selectedStation, selectedMonth, units.index)
            .then((data) => {
                if (!isMounted.current) return;
                updateState(state => {
                    return {...state, data, loading: false, error: false};
                });
            })
            .catch((error) => {
                if (!isMounted.current) return;
                updateState(state => {
                    return {...state, data: [], loading: false, error: true};
                });
                handleErrorResponse(error?.response, response => {
                    switch (response?.status) {
                        case 404:
                            break;
                        case 400:
                            setError("analyticScreen.calendar.could_not_update_calendar_invalid_date");
                            break;
                        default:
                            setError(error, false, "analyticScreen.calendar.could_not_update");
                    }
                });
            });
    },[units.index, isMounted, setError, handleErrorResponse, selectedStation, selectedDate]);

    useEffect(()=>{
        fetchAQIData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDate,selectedStation]);

    const handleDateChange  = useCallback((value)=>{
        updateState( state =>{
            return {...state,selectedDate:value};
        });
    },[]);

    const onCalendarDayClick = useCallback((value) => {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();
        const utcDate = moment.utc([year, month, value]).toDate();
        setSelectedDay(utcDate);
    }, [selectedDate]);

    const setPreviousDay = useCallback(() => {
        setSelectedDay(moment(selectedDay).subtract(1, "days").toDate());
    } , [selectedDay]);

    const setNextDay = useCallback(() => {
        setSelectedDay(moment(selectedDay).add(1, "days").toDate());
    }, [selectedDay]);

    const nextDayDisabled = moment(selectedDay).isSameOrAfter(nowLocalDateString);

    const classes = useStyles();

    return (
        <CardLayout className={clsx(classes.cardView, className)} loading={loading}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={6} lg={7}>
                    <div className={classes.title}>
                        <CardTitle title={`${getVariableName(sensor.aqi, units.index)} - ${t("analyticScreen.calendar.predominant_pollutant")}`}
                                   setAnchorEl={setAnchorEl}/>
                    </div>
                </Grid>
                <Grid item xs={6} lg={5} container className={classes.dateSelector}>
                    {selectedDay === null ? (
                        <CalendarMonthSelector
                            date={selectedDate}
                            handleDateChange={handleDateChange}/>
                    ) : (
                        <div className={classes.title}>
                            <IconButton size="small" onClick={setPreviousDay} disabled={loadingDayData}>
                                <ArrowBackIosNewIcon fontSize="small"/>
                            </IconButton>
                            <Typography variant={"body1"} className={classes.dateText}>
                                {getFormattedUtcDate(selectedDay)}
                            </Typography>
                            <IconButton size="small" onClick={setNextDay} disabled={loadingDayData || nextDayDisabled}>
                                <ArrowForwardIosIcon fontSize="small"/>
                            </IconButton>
                        </div>
                    )}
                </Grid>
                {selectedDay !== null && (
                    <Grid item xs={12}>
                        <Button startIcon={<ArrowBackIcon/>} size="small" className={classes.backButton}
                                onClick={() => setSelectedDay(null)}>
                            {t("validation.calendar")}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <CardContent className={clsx({[classes.hidden]: selectedDay !== null})}>
                <Grid item xs={12} className={classes.calendarTittle}>
                    <CalendarDaysTittle/>
                </Grid>
                <Grid item xs={12}>
                    <CalendarComponent data={data} date={selectedDate} indexType={units.index}
                                       onCalendarDayClick={onCalendarDayClick}/>
                </Grid>
            </CardContent>
            <Grid container xs={12} className={clsx({[classes.hidden]: selectedDay === null})}>
                <CalendarDayDetail selectedDay={selectedDay} loadingDayData={loadingDayData}
                                   setLoadingDayData={setLoadingDayData} />
            </Grid>
            <div className={classes.legendContainer}>
                <CalendarLegend indexType={units.index}/>
            </div>
            <HelpPopup anchorEl={anchorEl}
                       handleHelpClose={handleHelpClose}
                       message={<>
                           {t("analyticScreen.calendar.en_analytics_calendar")}{`. `}
                           <Link href={`${getDocumentationUrl(i18n.language)}${units.index.toLowerCase()}/`}
                                 target="_blank" rel="noopener" style={{color:"#5da8ff"}}>
                               {t("home.more_detail")}
                           </Link>
                       </>}
                       halfCard={true}/>
        </CardLayout>
    );
};

export default AQICalendarCardView;
