import {meteoSensors, pollutants, sensor} from "../../../../../constants";

export const sensorOptions = [...pollutants, ...meteoSensors].filter(
    item => (
        ![
            sensor.wind,
            sensor.wind_direction,
            sensor.uv_index
        ].includes(item)
    )
);

export const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d", "autoScale2d",
        "zoomIn2d", "zoomOut2d", "resetScale2d", "zoom2d",
        "toggleHover", "resetViews", "sendDataToCloud",
        "toggleSpikelines", "resetViewMapbox", "pan2d", "hoverClosestCartesian",
        "hoverCompareCartesian"
    ],
    displaylogo: false
};
