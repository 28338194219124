import {useCallback, useEffect} from "react";
import {usePeriodContext} from "../../common/PeriodContext";
import checkCtrlKey from "../../../../../../utils/checkCtrlKey";

const useTableDataSelection = () => {
    const {selectedPeriod, stationSensors, dataMatrix, selectionMatrix, setSelectionMatrix, updateSelectionMatrixCell,
        lastSelectedCell, setLastSelectedCell} = usePeriodContext();

    const clearSelection = useCallback(() => {
        setSelectionMatrix([]);
        setLastSelectedCell(null);
    }, [setSelectionMatrix, setLastSelectedCell]);

    const handleSelectionClick = useCallback((event, rowIndex, colIndex) => {
        const commandKey = checkCtrlKey(event);

        let newMatrix = [];

        // If the user clicks in the top left corner, select all cells
        if (rowIndex < 0 && colIndex < 0) {
            // If the command key is pressed and all cells are selected, deselect all cells
            if (commandKey) {
                const rowsToCheck = Array.from({length: dataMatrix.length}, (_, i) => i);
                const allItemsSelected = !rowsToCheck.some(row => {
                    return !selectionMatrix[row] || selectionMatrix[row].some((cell) => !cell);
                });
                if (allItemsSelected) {
                    clearSelection();
                    return;
                }
            }
            // Create a new matrix filled with true values
            newMatrix = new Array(dataMatrix.length).fill(new Array(stationSensors.length).fill(true));
            setSelectionMatrix(newMatrix);
            setLastSelectedCell({rowIndex: 0, colIndex: 0, matrixState: newMatrix});
            return;
        }

        // Check if the user is holding the shift key
        if (event.shiftKey && lastSelectedCell) {
            // Determine the start and end rows and columns for the selection
            let startRow = Math.min(rowIndex, lastSelectedCell.rowIndex);
            let endRow = Math.max(rowIndex, lastSelectedCell.rowIndex);
            let startCol = Math.min(colIndex, lastSelectedCell.colIndex);
            let endCol = Math.max(colIndex, lastSelectedCell.colIndex);

            // Adjust the start and end rows and columns if they are negative
            if (startRow < 0) {
                startRow = 0;
                endRow = dataMatrix.length - 1;
            }
            if (startCol < 0) {
                startCol = 0;
                endCol = stationSensors.length - 1;
            }

            // If the command key is pressed, copy the state of the previous matrix
            if (commandKey) {
                newMatrix = [...lastSelectedCell.matrixState];
            } else {
                // If not, clear the state of the previous matrix
                setLastSelectedCell({...lastSelectedCell, matrixState: []});
            }

            // Fill the new matrix with true in the selected range
            for (let i = startRow; i <= endRow; i++) {
                for (let j = startCol; j <= endCol; j++) {
                    newMatrix[i] = [...(newMatrix[i] ?? [])];
                    newMatrix[i][j] = true;
                }
            }

            setSelectionMatrix(newMatrix);
        } else {
            // If the command key is pressed and a cell inside the matrix is clicked, update the cell
            if (commandKey && rowIndex >= 0 && colIndex >= 0) {
                updateSelectionMatrixCell(rowIndex, colIndex, !selectionMatrix[rowIndex]?.[colIndex]);
                setLastSelectedCell({rowIndex, colIndex, matrixState: selectionMatrix});
            } else {
                // If the command key is pressed, copy the selection matrix state
                if (commandKey) {
                    newMatrix = [...selectionMatrix];
                }
                // Determine the rows and columns to change and the value to set
                let rowsToChange = [rowIndex];
                let colsToChange = [colIndex];
                let valueToSet = true;
                if (rowIndex < 0) {
                    rowsToChange = Array.from({length: dataMatrix.length}, (_, i) => i);
                    // Invert the value to set if the command key is pressed and the column is fully selected
                    valueToSet = !commandKey || !!rowsToChange.some((rowIndex) => !newMatrix[rowIndex]?.[colIndex]);
                }
                if (colIndex < 0) {
                    colsToChange = Array.from({length: stationSensors.length}, (_, i) => i);
                    // Invert the value to set if the command key is pressed and the row is fully selected
                    valueToSet = !commandKey || !!colsToChange.some(colIndex => !newMatrix[rowIndex]?.[colIndex]);
                }
                // Change the selected cells to true
                rowsToChange.forEach((rowToChangeIndex) => {
                    newMatrix[rowToChangeIndex] = [...(newMatrix[rowToChangeIndex] ?? [])];
                    colsToChange.forEach((colToChangeIndex) => {
                        newMatrix[rowToChangeIndex][colToChangeIndex] = valueToSet;
                    });
                });
                // Set the new matrix and save the matrix state
                setSelectionMatrix(newMatrix);
                setLastSelectedCell({
                    rowIndex: Math.max(rowIndex, 0),
                    colIndex: Math.max(colIndex, 0),
                    matrixState: newMatrix
                });
            }
        }
    }, [dataMatrix.length, stationSensors.length, selectionMatrix, updateSelectionMatrixCell, setSelectionMatrix,
        lastSelectedCell, setLastSelectedCell, clearSelection]);

    const addMatrixToSelection = useCallback((matrixToAdd, event = null) => {
        const commandKey = checkCtrlKey(event);
        let newMatrix = [];
        if (commandKey) {
            newMatrix = [...selectionMatrix];
        }
        matrixToAdd.forEach((row, rowIndex) => {
            row?.forEach((cell, colIndex) => {
                if (cell !== undefined) {
                    newMatrix[rowIndex] = [...(newMatrix[rowIndex] ?? [])];
                    newMatrix[rowIndex][colIndex] = cell;
                }
            });
        });
        setSelectionMatrix(newMatrix);
    }, [selectionMatrix, setSelectionMatrix]);

    useEffect(() => {
        // Clear the selection when the period changes
        clearSelection();
    }, [clearSelection, selectedPeriod]);

    return {selectionMatrix, clearSelection, addMatrixToSelection, handleSelectionClick};
};

export default useTableDataSelection;