import {Box, Checkbox, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const ListCustomItem = ({disabled, withCheckbox = false, checked, labelId, children, ...props}) => {
    if (withCheckbox) {
        return (
            <ListItemButton role="listitem" disabled={disabled} {...props}>
                <ListItemIcon>
                    <Checkbox
                        disabled={disabled}
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={labelId && {
                            'aria-labelledby': labelId
                        }}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={children} />
            </ListItemButton>
        );
    }

    return (
        <Box component="li" {...props}>
            {children}
        </Box>
    );
};

export default ListCustomItem;