import React, {useCallback, useEffect, useState} from 'react';
import {
    Backdrop,
    Button,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Select,
    Skeleton,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import emptyBox from '../../../../../assets/no_image.jpg';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "../../../../../hooks/useAuth";
import {WRITE_ALIAS} from "../../organization/members/UserPermissions";

const StationImagePopUp = ({url, loading , onClose, onDelete, onUpload, selectedFile, handleDeleteFile, handleFileChange }) => {

    const {t} = useTranslation();
    const {hasPermission} = useAuth();

    const [imageUrl, setImageUrl] = useState(url ?? emptyBox);

    useEffect(() => {
        setImageUrl(url ?? emptyBox);
    },[url]);


    const handleUploadImage = useCallback(()=>{
        onUpload(selectedFile);
    },[onUpload, selectedFile]);

    return (
        <Backdrop data-testid={"conflict-logo-pop-up"} open={true} style={{zIndex: 1999}}>
            <Grid container style={{backgroundColor: "#fff", maxWidth: 680, padding: 30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{t("analyticScreen.deviceDescription.stationImagePreview")}</Typography>
                </Grid>
                <Grid item container xs={12} style={{marginTop: 40, marginBottom: 40}} justifyContent={"center"} alignContent={"center"}>
                    {loading && <div style={{display: "flex",}}>
                        <Skeleton width="600px" height={400} animation="wave"
                                  sx={{transform: 'scale(1)'}}/>
                    </div>}
                    { !loading && <div style={{position: 'relative', width: 600, height: 400}}>
                        <img src={imageUrl} alt={t("analyticScreen.deviceDescription.stationImage")} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
                        {url && hasPermission(WRITE_ALIAS) && <IconButton
                            style={{position: 'absolute', bottom: 0, right: 0}}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </IconButton>}
                    </div>}
                </Grid>
                {hasPermission(WRITE_ALIAS) && <>
                    <Grid item xs={10}>
                        <FormControl variant="outlined" fullWidth
                                     margin={"none"}>
                            <InputLabel>{t("analyticScreen.deviceDescription.image_file")}</InputLabel>
                            <Select
                                open={false}
                                sx={{
                                    '& .MuiSelect-icon': {display: 'none'},
                                }}
                                id={"preference-selector-logo"}
                                data-testid="preference-logo"
                                disabled={loading}
                                label={t("analyticScreen.deviceDescription.image_file")}
                                value={"default"}

                                renderValue={_ => (
                                    selectedFile ? <div>
                                        <Chip
                                            label={selectedFile.name}
                                            onDelete={() => handleDeleteFile()}
                                            style={{height: '24px'}}
                                            deleteIcon={<DeleteIcon/>}
                                        />
                                    </div> : <div>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            style={{display: 'none'}}
                                            accept="image/jpeg,image/png"
                                        />
                                        <Typography
                                            onClick={() => document.getElementById('fileInput').click()}>Seleccionar
                                            archivo</Typography>
                                    </div>
                                )}
                                MenuProps={{
                                    open: false,
                                    onEntering: (event) => {
                                        event.preventDefault();
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            disabled={loading || !selectedFile}
                            style={{marginLeft:8, height:52}}
                            fullWidth variant = {"contained"}
                            color="primary"
                            onClick={handleUploadImage}
                        >{t("action.save")}</Button>
                    </Grid>
                </>}

                <Grid item container xs={12} style={{justifyContent: "flex-end", marginTop:12}}>
                    <Button data-testid={"cancel-replace-logo-button"}
                            disabled={loading}
                            onClick={onClose}
                            color="primary">
                        {t("action.close")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default StationImagePopUp;
