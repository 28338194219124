import React from 'react';
import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    formControl: {
        width: 80,
        marginLeft: 10,
        marginRight:10
    }
});
const WeekOption = ({enable, weekDay, weekDayCallback}) => {

    const {t} = useTranslation();

    const classes = useStyles();

    return (
            <div style={{display:"flex", alignItems:"center"}}>
                <Typography>{t("reportScreen.reportConfig.every")}</Typography>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                    <Select
                        value={weekDay}
                        onChange={weekDayCallback}
                        disabled={!enable}
                    >
                        <MenuItem value={1}>{t("reportScreen.reportConfig.monday")}</MenuItem>
                        <MenuItem value={2}>{t("reportScreen.reportConfig.tuesday")}</MenuItem>
                        <MenuItem value={3}>{t("reportScreen.reportConfig.wednesday")}</MenuItem>
                        <MenuItem value={4}>{t("reportScreen.reportConfig.thursday")}</MenuItem>
                        <MenuItem value={5}>{t("reportScreen.reportConfig.friday")}</MenuItem>
                        <MenuItem value={6}>{t("reportScreen.reportConfig.saturday")}</MenuItem>
                        <MenuItem value={7}>{t("reportScreen.reportConfig.sunday")}</MenuItem>
                    </Select>
                </FormControl>
            </div>
    );
};

export default WeekOption;
