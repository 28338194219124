import React, {useEffect, useRef} from 'react';
import {removeRequestErrorAction} from "../../reducers/requestUIReducer";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import ServerErrorScreen from "./ServerErrorScreen";
import LoadingFullScreen from "./LoadingFullScreen";

const RequestInfoComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { loading, error } = useSelector( state => state.requestUI );
    const pendingErrorDispatch = useRef(false);

    //Remove fullScreen error if user use the next and back navigation buttons
    useEffect(() => {
        if (pendingErrorDispatch.current) {
            dispatch(removeRequestErrorAction());
            pendingErrorDispatch.current = false;
        }
    }, [location, dispatch, error]);

    useEffect(() => {
        pendingErrorDispatch.current = !!error;
    }, [error]);

    return (
        <div>
            <LoadingFullScreen loading={loading}/>
            {error !== null && <ServerErrorScreen error={error}/>}
        </div>
    );
};

export default RequestInfoComponent;
