const getItemSelectorFilter = searchableFields => (
    (items, state) => {
        const inputValue = state.inputValue.toLowerCase();
        return items.filter(item => (
            searchableFields.some(field => (
                item[field]?.toLowerCase().includes(inputValue)
            ))
        ));
    }
);

export default getItemSelectorFilter;