import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const pollutantRoseRequest = (temp, pollutantUnit, stationId, selectedPollutant, init, end) => (
    new Promise((resolve, reject) => {
        let params = `?temperature=${temp}&pollutants=${pollutantUnit}&time=${init}&sensor=${selectedPollutant}`;
        if (end) params += `&endtime=${end}`;
        let url = `/station-data/${stationId}/pollutant-rose/${params}`;

        webClient.get(url)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                handleError(err, (_, error) => reject(error));
            });
    }
));