import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import AlarmConfigSteps from "./AlarmConfigSteps";
import AlarmConfigStep1 from "./steps/AlarmConfigStep1";
import AlarmConfigStep2 from "./steps/AlarmConfigStep2";
import AlarmConfigStep3 from "./steps/AlarmConfigStep3";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import useUnits from "../../../../../hooks/useUnits";
import {alarmType, triggerTypes} from "../alarmConfigs";
import {DEFAULT_POPUP_WIDTH} from "../../../../../constants";

const useStyles = makeStyles({
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    popUpContainer: {
        maxWidth: DEFAULT_POPUP_WIDTH
    },
    tittle: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    step1Container: {
        paddingTop: 60,
        paddingLeft: 30,
        paddingRight: 30,
        maxWidth: 780,
        justifyContent: "center"
    },
    step2Container: {
        padding: 30,
        maxWidth: 720,
        justifyContent: "center"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    steps: {
        marginRight: 20,
        marginLeft: 20
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
});

const AlarmConfigPopUp = ({open, cancelAlarmSetup, finishAlarmSetup}) => {

    const {t} = useTranslation();
    const {units, convertToServerUnit} = useUnits();
    const classes = useStyles();

    const [{
        name,
        activeStep,
        pollutant, type, isOrganizationAlarm,
        trigger, value, step1Valid,
        selectedStations, step2Valid, step3Valid,
    }, updateState] = useState({
        name: "",
        selectedStations: [],
        type: alarmType.threshold,
        isOrganizationAlarm: true,
        trigger: triggerTypes.gt,
        value: 0,
        pollutant: "",
        activeStep: 0,
        step1Valid: false,
        step2Valid: true,
        step3Valid: false
    });
    const[validPeriod, updateValidPeriod] = useState([0, 0]);
    const[allDayChecked, updateAllDayChecked] = useState(true);

    const allStations = useSelector(state => state.dashboardUI.stations);

    const showTimeZoneError = useMemo(()=>{

        const partialDay = validPeriod[0] !== 0 || validPeriod[1] !== 0;

        const selectedStationsIds = selectedStations.map(item => item.id);

        let noTimeZoneFound = allStations.filter(item => selectedStationsIds.includes(item.id)).
        filter(item => item?.position?.locationInfo?.timeZone === undefined).length > 0;

        return partialDay && noTimeZoneFound;

    }, [allStations, selectedStations, validPeriod]);

    const stationSensors = useSelector(state => (
        getAvailableSensors(state.dashboardUI.stations).map(sensor => sensor.id)
    ), shallowEqual);

    //Step 1 callbacks
    const nameCallback = useCallback((event) => {
        updateState(state => ({...state, name: event.target.value, step1Valid: event.target.value !== ""}));
    }, []);

    const typeCallback = useCallback((event) => {
        updateState(state => ({...state, type: event.target.value}));
    }, []);

    const updateOrganizationAlarmCallback = useCallback((isOrganizationAlarm) => {
        updateState(state => ({...state, isOrganizationAlarm}));
    }, []);

    //Step 2 callbacks
    const updateSelectedPollutantCallback = useCallback((event) => {
        updateState(state => ({...state, pollutant: event.target.value}));
    }, []);

    const updateTriggerCallback = useCallback((event) => {
        updateState(state => ({...state, trigger: event.target.value}));
    }, []);

    const handleSliderChange = (event, newValue) => {
        updateState(state => ({...state, value: newValue}));
    };

    const handleInputChange = (event) => {
        updateState(state => ({...state, value: event.target.value === '' ? '' : Number(event.target.value)}));
    };

    const handleBlur = () => {
        if (value < 0) {
            updateState(state => ({...state, value: 0}));
        }
    };

    //Step 3 callback
    const updateSelectedStationsCallback = useCallback((selectedStations) => {
        updateState(state => ({...state, selectedStations, step3Valid: selectedStations.length > 0}));
    }, []);


    useEffect(() => {
        if (type === alarmType.threshold && stationSensors.length > 0) {
            updateSelectedPollutantCallback({target: {value: stationSensors[0]}});
        } else {
            updateSelectedPollutantCallback({target: {value: ""}});
        }
    }, [stationSensors, updateSelectedPollutantCallback, type]);


    const handleFirstButton = () => {
        if (activeStep === 0) {
            cancelAlarmSetup();
        } else {
            if (type === alarmType.threshold) {
                updateState(state => ({...state, activeStep: activeStep - 1}));
            } else {
                updateState(state => ({...state, activeStep: activeStep - 2}));
            }
        }
    };


    const handleSecondButton = () => {
        if (activeStep < 2) {
            if (type === alarmType.threshold) {
                updateState(state => ({...state, activeStep: activeStep + 1}));
            } else {
                updateState(state => ({...state, activeStep: activeStep + 2}));
            }
        } else {
            const body = {
                name,
                type,
                stations: selectedStations.map(station => {
                    return station.id;
                }),
                isOrganizationAlarm
            };
            if (type === alarmType.threshold) {
                body.config = {
                    variable: pollutant,
                    thresholdType: trigger,
                    thresholdValue: convertToServerUnit(pollutant, value),
                    validPeriod: validPeriod
                };
            }

            finishAlarmSetup(body);
        }
    };

    const validPeriodCallback = useCallback((period)=>{
        updateValidPeriod(period);
    },[]);

    const allDayCheckedCallback = useCallback((state)=>{
        updateAllDayChecked(state);
    },[]);

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.tittle}>{t("alarmScreen.popUp.configureAlarm")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                        <AlarmConfigSteps activeStep={activeStep} hasStep2={type === alarmType.threshold}/>
                    </Grid>
                    {activeStep === 0 && <Grid container item xs={12} className={classes.step1Container}>
                        <AlarmConfigStep1
                            name={name}
                            nameCallback={nameCallback}
                            cancelCallback={cancelAlarmSetup}
                            typeCallback={typeCallback}
                            type={type}
                            organizationAlarmCallback={updateOrganizationAlarmCallback}
                            isOrganizationAlarm={isOrganizationAlarm}
                        />
                    </Grid>}
                    {activeStep === 1 && <Grid container item xs={12} className={classes.step1Container}>
                        <AlarmConfigStep2
                            selectedPollutant={pollutant}
                            pollutantCallback={updateSelectedPollutantCallback}
                            trigger={trigger}
                            triggerCallback={updateTriggerCallback}
                            value={value}
                            handleBlur={handleBlur}
                            handleInputChange={handleInputChange}
                            handleSliderChange={handleSliderChange}
                            organizationUnits={units}
                            validPeriod={validPeriod}
                            validPeriodCallback={validPeriodCallback}
                            allDayChecked={allDayChecked}
                            allDayCheckedCallback={allDayCheckedCallback}
                        />
                    </Grid>}
                    {activeStep === 2 && <Grid container item xs={12} className={classes.step2Container}>
                        <AlarmConfigStep3 selectedVariable={pollutant} callback={updateSelectedStationsCallback} showTimeZoneError={showTimeZoneError}/>
                    </Grid>}

                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelAlarmSetup}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"create-alarm-button"}
                                disabled={activeStep === 0 ? !step1Valid : activeStep === 1 ? !step2Valid : !step3Valid}
                                onClick={handleSecondButton}>{activeStep < 2 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default AlarmConfigPopUp;
