import {Grid} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import CompareSensorDataCardView from "./compare_data_card/CompareSensorDataCardView";
import CompareStatisitcCardView from "./statistic_data_card/CompareStatisitcCardView";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {updateCompareStationsAction} from "../../../../reducers/dashboardUIReducer";
import NoStationSelected from "./NoStationSelected";
import CompareAmbientNoiseCardView from "./noise_data_card/CompareAmbientNoiseCardView";
import CompareWindSpeedCardView from "./wind_speed_card/CompareWindSpeedCardView";
import VerifyingDevice from "../analytics/VerifyingDevice";
import RdThresholdCardView from "./rd_threshold_card/RdThresholdCardView";
import TemporalVarationCardView from "./temporal_variation_card/TemporalVarationCardView";
import ScatterPlotCardView from "./scatter_plot_card/ScatterPlotCardView";
import {isEqual} from "lodash";
import {sensor} from "../../../../constants";
import {getStationSensors} from "../../../../utils/stationUtil";
const queryString = require('query-string');

export const compareStationsColors = [ '#1A23D0',
    '#D01A55',
    '#40D01A',
    '#efca11',
    '#1ad0a6',
    '#496186',
    '#e76735',
    '#991ad0',
    '#000',
    '#7B7B7B'
];

const useStyles = makeStyles({
    root: {
        maxWidth:1800,
        paddingBottom:20,
        paddingTop:20,
        paddingLeft:40,
        paddingRight:40,
    },
    card: {
        background:"white",
        padding:20,
        borderRadius: 5,
        minHeight:245
    },
    firstCard: {
        height:765
    },
    secondCard:{
        height:615
    },
    thirdCard: {
        minHeight: 300
    },
    fourthCard: {
        minHeight: 300
    },
    temporalVariationCard:{
      height:1000,
    },
    loading:{
        height:600
    },
    scatterPlotCard: {
        height: 540
    }
});

export const CompareScreen = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const selectedStations = useSelector(state => {
        const parsedValues = queryString.parse(location.search);
        if (parsedValues.id === undefined) {
            return [];
        }
        const stations = state.dashboardUI.stations;
        if (Array.isArray(parsedValues.id)){
            return stations.filter(value => {
                return parsedValues.id.includes(value.id);
            });
        }
        return stations.filter( value => {
            return parsedValues.id === value.id;
        });
    }, shallowEqual);

    const selectedStationsSensors = useMemo(() => {
        const sensors = [];
        selectCompareStations.forEach(station => {
            sensors.push(...getStationSensors(station));
        });
        return sensors;
    }, [selectCompareStations]);

    const [{validatedUrl},updateState] = useState({validatedUrl:false});

    useEffect(()=>{
        if (selectedStations) {
            dispatch(updateCompareStationsAction(selectedStations));
        }
        updateState({validatedUrl: true});
    },[selectedStations, dispatch]);

    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2} >

            {selectCompareStations.length > 0 && validatedUrl && <>
                <Grid item xs={12}>
                    <CompareSensorDataCardView className={clsx(classes.card,classes.firstCard)} boxShadow={2} />
                </Grid>
                <Grid item xs={12}>
                    <TemporalVarationCardView className={clsx(classes.card, classes.temporalVariationCard)} boxShadow={2} />
                </Grid>
                {selectedStationsSensors.includes(sensor.noise) && (
                    <Grid item xs={12}>
                        <CompareAmbientNoiseCardView className={clsx(classes.card,classes.secondCard)} boxShadow={2} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CompareStatisitcCardView className={clsx(classes.card,classes.thirdCard)} boxShadow={2} />
                </Grid>
                <Grid item xs={12}>
                    <ScatterPlotCardView className={clsx(classes.card,classes.scatterPlotCard)}/>
                </Grid>
                {selectedStationsSensors.includes(sensor.wind) && (
                    <Grid item xs={12}>
                        <CompareWindSpeedCardView className={clsx(classes.card,classes.thirdCard)} boxShadow={2} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <RdThresholdCardView className={clsx(classes.card, classes.fourthCard)} boxShadow={2} />
                </Grid>
            </>}
            {selectCompareStations.length === 0 && validatedUrl && <Grid item xs={12}>
                <NoStationSelected/>
            </Grid>}
            {!validatedUrl && <VerifyingDevice className={classes.loading}/>}
        </Grid>
    );
};

export default CompareScreen;
