import React, {useMemo} from 'react';
import './chart_style.css';
import {roundAccurately} from "../../../../../../utils/roundNumbers";
import {useTranslation} from "react-i18next";
import ChartView from "../../../../../common/chart/ChartView";

const colors = ["#f8cc3d", "#f89b3d", "#f87e3d", "#f8593d", "#f83d3d"];

const dTickScales = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100];
const getDTickScale = (radius, minVisibleTicks) => {
    const dTick = dTickScales.sort((a, b) => b - a)
        .find(dTick => Math.ceil(radius / dTick) > minVisibleTicks);
    return dTick ? dTick : Math.min(...dTickScales);
};

const getLabel = (value) => {
    let output = "";
    // eslint-disable-next-line default-case
    switch (value) {
        case "0":
            output = "0 - 0.278 m/s";
            break;
        case "0.277778":
            output = "0.278 - 1.5 m/s";
            break;
        case "1.5":
            output = "1.5 - 6 m/s";
            break;
        case "6":
            output = "> 6 m/s";
            break;
    }
    return output;
};

const WindRoseChart = ({data, calmWind}) => {

    const {t} = useTranslation();

    const plotConfig = {
        modeBarButtonsToRemove: [
            "select2d", "lasso2d", "toggleHover", "sendDataToCloud", "toggleSpikelines","zoom2d"
        ],
        displaylogo: false
    };

    const theta = useMemo(() => [
        t("analyticScreen.windRose.north"),
        t("analyticScreen.windRose.north_north_east"),
        t("analyticScreen.windRose.north_east"),
        t("analyticScreen.windRose.east_north_east"),
        t("analyticScreen.windRose.east"),
        t("analyticScreen.windRose.east_south_east"),
        t("analyticScreen.windRose.south_east"),
        t("analyticScreen.windRose.south_south_east"),
        t("analyticScreen.windRose.south"),
        t("analyticScreen.windRose.south_south_west"),
        t("analyticScreen.windRose.south_west"),
        t("analyticScreen.windRose.west_south_west"),
        t("analyticScreen.windRose.west"),
        t("analyticScreen.windRose.west_north_west"),
        t("analyticScreen.windRose.north_west"),
        t("analyticScreen.windRose.north_north_west")
    ], [t]);

    const formattedData = useMemo(() => {
        const totalSamples = data.total;
        const filteredData = {...data};
        delete filteredData.total;
        return Object.entries(filteredData).
        sort((itemA, itemB) => Number(itemA[0]) - Number(itemB[0])).
        filter(item => item[0] > 0).map((item,index) => {
            return {
                r: Object.values(item[1]).map(item => roundAccurately((item / totalSamples) * 100, 2)),
                theta,
                name: getLabel(item[0]),
                marker: {color: colors[index]},
                type: "barpolar"
            };
        });
    }, [data, theta]);

    const maxR = useMemo(() => {
        const rTotalValues = formattedData.reduce((acc, item) => {
            return item.r.map((r, index) => r + acc[index]);
        }, Array.from({length: theta.length}, () => 0));
        return Math.max(...rTotalValues);
    }, [formattedData, theta.length]);

    const centerMargin = maxR / 2;
    const externalRadius = maxR + maxR / 8;
    const radius = centerMargin + externalRadius;

    const layout = useMemo(() => {
        const dTick = getDTickScale(externalRadius, 3);
        const tickVals = Array.from({length: Math.ceil(externalRadius / dTick) - 1}, (_, i) => (i + 1) * dTick);
        return {
            margin: {t: 30, b: 30, l: 50, r: 0},
            polar: {
                domain: {
                    x: [0,1],
                    y: [0,1]
                },
                barmode: "stack",
                bargap: 0.2,
                radialaxis: {
                    ticksuffix: "%",
                    angle: 45,
                    tickvals: tickVals.map(value => value + centerMargin),
                    ticktext: tickVals.map(value => `${value}%`),
                    range: [0, radius],
                    showline: false,
                    ticklen: 0,
                    tickfont: {size: 9}
                },
                angularaxis: {
                    direction: "clockwise",
                    tickvals: Array.from({length: 8}, (_, i) => i * 2),
                    ticktext: Array.from({length: 8}, (_, i) => theta[i * 2])
                }
            },
            annotations: [
                {
                    x: 0.5,
                    y: 0.5,
                    showarrow: false,
                    text: `${t("analyticScreen.windSpeed.calm")}<br>${calmWind}%`,
                    font: {
                        size: 10
                    },
                    hoverinfo: "text"
                }
            ]
        };
    }, [radius, externalRadius, centerMargin, calmWind, theta, t]);

    const circleBackground = useMemo(() => {
        const rValues = Array.from({length: theta.length}, () => centerMargin);
        return {
            r: rValues,
            theta,
            type: 'barpolar',
            marker: {
                color: '#FFFFFF',
                line: {
                    color: '#FFFFFF',
                    width: 0.5
                }
            },
            name: 'Background',
            showlegend: false,
            hoverinfo: 'none'
        };
    }, [theta, centerMargin]);

    const formattedDataWithCircle = useMemo(() => {
        return [circleBackground, ...formattedData];
    }, [formattedData, circleBackground]);

    return (
        <div className={"windRoseChart"}>
            <ChartView
                config={plotConfig}
                layout={layout}
                data={formattedDataWithCircle}
            />
        </div>
    );
};

export default WindRoseChart;