import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {format} from "date-fns";
import DateRange from "react-date-range/dist/components/DateRange";
import {getDateFnsLocale, getTzUtcOffset} from "../../../../../utils/dateUtil";
import {makeStyles} from "@mui/styles";
import {usePeriodContext} from "../common/PeriodContext";
import {getTodayUTCDate, localToUTCDate, UTCToLocalDate} from "../common/functions";
import {timeZoneOptions} from "../../../../../constants";
import moment from "moment-timezone";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    validatedBackground: {
        background: "#00FF4040",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
});

const today = getTodayUTCDate();
const todayLocal = UTCToLocalDate(today);
const oneMonth = 31 * 24 * 60 * 60 * 1000;

const Calendar = ({months}) => {
    const classes = useStyles();
    const {validatedPeriods, selectedStation, selectedPeriod, setNewSelectedPeriod} = usePeriodContext();
    const [localSelectedPeriod, setLocalSelectedPeriod] = useState(null);
    const [maxDate, setMaxDate] = useState(todayLocal);
    const selectedPeriodRef = useRef();
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const timeZone =
        (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;

    const updateRange = (item) => {
        const {startDate, endDate, ...otherProps} = item.selection;
        const newEndDate = localToUTCDate(endDate);
        newEndDate.setDate(newEndDate.getDate() + 1);
        const newSelection = {
            ...otherProps,
            startDate: localToUTCDate(startDate),
            endDate: newEndDate
        };
        setLocalSelectedPeriod(newSelection);
        selectedPeriodRef.current = newSelection;
    };

    const onRangeFocusChange = (event) => {
        if (event[0] === 0) {
            if (event[1] === 1 && selectedPeriodRef.current?.startDate) {
                const endDate = new Date(selectedPeriodRef.current.startDate.getTime() + oneMonth);
                setMaxDate(todayLocal < endDate ? todayLocal : endDate);
            }
            if (event[1] === 0) {
                setNewSelectedPeriod(selectedPeriodRef.current);
                setLocalSelectedPeriod(selectedPeriod);
                setMaxDate(todayLocal);
            }
        }
    };

    const ranges = useMemo(() => {
        const newRange = {startDate: today, endDate: today, key: "selection"};
        if (localSelectedPeriod) {
            const endDate = UTCToLocalDate(localSelectedPeriod.endDate);
            endDate.setDate(endDate.getDate() - 1);
            return [{...newRange,
                startDate: UTCToLocalDate(localSelectedPeriod.startDate),
                endDate
            }];
        }
        return [newRange];
    }, [localSelectedPeriod]);

    useEffect(() => {
        if (selectedPeriod) {
            setLocalSelectedPeriod(selectedPeriod);
        }
    }, [selectedPeriod]);

    const renderDayContent = useCallback((day) => {
        const localDateUtcOffset = moment(day).utcOffset();
        const stationUtcOffset = getTzUtcOffset(timeZone, day);
        const totalOffset = localDateUtcOffset - stationUtcOffset;

        const validated = validatedPeriods.some((period) => {
            const dayDate = moment(day).add(totalOffset, "minutes").toDate();
            const endDayDate = moment(dayDate).add(1, "day").toDate();
            const periodStartDate = moment(period.time).toDate();
            const periodEndDate = moment(period.endTime).toDate();
            return periodStartDate < endDayDate && periodEndDate > dayDate;
        });
        return (
            <div>
                {validated && <div className={classes.validatedBackground} />}
                <span>{format(day, "d")}</span>
            </div>
        );
    }, [validatedPeriods, classes, timeZone]);

    return (
        <>
            <DateRange
                locale={getDateFnsLocale()}
                editableDateInputs={true}
                onChange={updateRange}
                onRangeFocusChange={onRangeFocusChange}
                moveRangeOnFirstSelection={false}
                ranges={ranges}
                months={months}
                maxDate={maxDate}
                direction="horizontal"
                rangeColors={["#3333FF"]}
                dayContentRenderer={renderDayContent}
            />
        </>
    );
};

export default Calendar;
