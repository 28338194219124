import {useCallback, useEffect} from "react";
import {usePeriodContext} from "../../common/PeriodContext";
import {dateToUTCServerFormatString} from "../../../../../../utils/dateToUtcStringConverter";
import {getStationValidationData} from "../../../../../../requests/validation/validationPeriodsRequests";
import useMessage from "../../../../../../hooks/useMessage";
import useRequest from "../../../../../../hooks/useRequest";
import useTableData from "./useTableData";

export const FLAG_TAG = "flag";

const useTableDataFlags = () => {
    const {setError} = useMessage();
    const {handleErrorResponse} = useRequest();
    const {timeStringToRowIndex} = useTableData();
    const {selectedStation, selectedPeriod, periods, setPeriods, stationSensors, dataMatrix: matrix,
        updateDataMatrixRows: updateRows} = usePeriodContext();

    const addFlagsToMatrix = useCallback((flags) => {
        const newRows = [];
        Object.entries(flags).forEach(([sensor, periods]) => {
            periods.forEach(({time, endtime, type}) => {
                const startIndex = timeStringToRowIndex(time);
                const endIndex = timeStringToRowIndex(endtime);
                if (startIndex === null || endIndex === null) return;
                for (let i = startIndex; i < endIndex; i++) {
                    if (!newRows[i]) {
                        newRows[i] = [];
                    }
                    const columnIndex = stationSensors.indexOf(sensor);
                    if (columnIndex === -1) continue;
                    newRows[i][columnIndex] = type;
                }
            });
        });
        updateRows(newRows, FLAG_TAG);
    }, [updateRows, stationSensors, timeStringToRowIndex]);

    useEffect(() => {
        if (matrix.length) {
            addFlagsToMatrix(periods);
        }
    }, [matrix.length, addFlagsToMatrix, periods]);

    const getValidationData = useCallback(() => {
        if (selectedStation && selectedPeriod) {
            getStationValidationData({
                station: selectedStation,
                time: dateToUTCServerFormatString(selectedPeriod.startDate),
                endtime: dateToUTCServerFormatString(selectedPeriod.endDate)
            }).then(data => {
                setPeriods(data);
            }).catch(err => {
                handleErrorResponse(err, () => {
                    setError(err, false);
                });
            });
        }
    }, [selectedStation, selectedPeriod, setPeriods, setError, handleErrorResponse]);

    return {getValidationData};
};

export default useTableDataFlags;