import {getFormattedDate} from "../../../../../utils/getFormattedDateTime";

const getTimezoneOffsetMilliseconds = (date) => {
    return new Date(date).getTimezoneOffset() * 60 * 1000;
};

export const localToUTCDate = (date) => {
    return new Date(new Date(date).getTime() - getTimezoneOffsetMilliseconds(date));
};

export const UTCToLocalDate = (date) => {
    return new Date(new Date(date).getTime() + getTimezoneOffsetMilliseconds(date));
};

export const formatDateRange = (startDate, endDate, timeZone) => {
    const dateString = getFormattedDate(startDate, timeZone);
    const endDateString = getFormattedDate(endDate, timeZone);
    return dateString === endDateString ? dateString : `${dateString} - ${endDateString}`;
};

export const getTodayUTCDate = () => {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    return now;
};

export const getLastDaySelection = () => {
    const endDate = getTodayUTCDate();
    const startDate = new Date(endDate);
    startDate.setUTCDate(startDate.getUTCDate() - 1);
    return {startDate, endDate};
};
