import {useCallback, useEffect, useState} from "react";
import {Card, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Switch from "@mui/material/Switch";
import {useTranslation} from "react-i18next";
import {getNotificationsRequest, putNotificationsRequest} from "../../../../../requests/profile/notificationsRequest";
import useMessage from "../../../../../hooks/useMessage";

const useStyles = makeStyles({
    form: {
        width: "100%",
    },
    title: {
        fontWeight: "bold"
    },
    grip: {
        marginTop: 12,
    }
});

const NotificationsCardView = ({className}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {setError} = useMessage();
    const [alarmEmailsActive, setAlarmEmailsActive] = useState(false);

    const loadNotifications = () => {
        getNotificationsRequest().then(({mailNotifications}) => {
            setAlarmEmailsActive(mailNotifications);
        });
    };

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleAlarmEmailsActiveChange = useCallback((_, active) => {
        setAlarmEmailsActive(active);
        putNotificationsRequest({mailNotifications: active}).catch(() => {
            setError("notifications.error_updating_config");
            loadNotifications();
        });
    }, [setError]);

    return (<>
        <Card className={className}>
            <form noValidate className={classes.form}>
                <Typography className={classes.title} variant={"h4"}>{t("alarmScreen.notifications.notifications")}</Typography>
                <Grid className={classes.grip} container direction={"row"} spacing={2}>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                label={t("notifications.alarm_notifications_by_email")}
                                control={<Switch checked={alarmEmailsActive}
                                                 onChange={handleAlarmEmailsActiveChange}
                                                 data-testid={"alarm-email-notifications"} />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </form>
        </Card>
    </>);
};

export default NotificationsCardView;
