import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import "moment/locale/es";
import {useTranslation} from "react-i18next";

const esFormat = ["ca", "es"].some((lang) => window.navigator.language.startsWith(lang));

export const getLocale = ()=> {
    if (esFormat) {
        return "es";
    } else {
        return "en";
    }
};

const DateTimePickerField = ({size, variant, value, onChange, className, error, ...props}) => {
    const [inputValue, setInputValue] = useState(moment.utc(value));
    const {t} = useTranslation();

    useEffect(() => {
        setInputValue(moment.utc(value));
    }, [value]);

    const handleChange = (newValue) => {
        setInputValue(newValue);
        // Check if the value is a valid object
        if (newValue && newValue.isValid()) {
            onChange(newValue.toDate());
        } else {
            onChange(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} adapterLocale={getLocale()}>
            <DateTimePicker
                className={className}
                value={inputValue}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        error={error}
                        helperText={error ? t("common.invalidPeriod") : null}
                        size={size} variant={variant} {...params}
                        FormHelperTextProps={{ style: { color: 'red' } }}
                    />
                )}
                minutesStep={5}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default DateTimePickerField;
