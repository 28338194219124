import React from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";

const DownloadSteps = ({activeStep}) => {

    const {t} = useTranslation();

    const steps = [
        t("reportScreen.reportConfig.step1"),
        t("reportScreen.reportConfig.step2"),
        t("reportScreen.reportConfig.step3"),
        t("reportScreen.reportConfig.sections")
    ];

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={index} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default DownloadSteps;
