import {webClient} from "../../../config/axios";
import {handleError} from "../../handleRequestError";


export const downloadAlarmsRequest = (temp,pollutantUnit,callback) => {
    webClient.get(`/alarm?temperature=${temp}&pollutants=${pollutantUnit}`)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};
