import * as Sentry from "@sentry/react";

export const handleSentryError = (error, captureContext = undefined) => {

    const isStringMessage = typeof error === 'string';

    if(!['production','preproduction'].includes(process.env.REACT_APP_ENVIRONMENT)) return console.error(error);

    if(isStringMessage) return captureContext ? Sentry.captureMessage(error, captureContext) : Sentry.captureMessage(error);
    else return captureContext ? Sentry.captureException(error, captureContext) : Sentry.captureException(error);
};
