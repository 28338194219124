import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PeriodDetailChart from "./PeriodDetailChart";
import {Button, Grid, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {usePeriodContext} from "../common/PeriodContext";
import {isEqual} from "lodash";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {getLastDaySelection} from "../common/functions";
import {WRITE_ORGANIZATION_VALID_PERIODS} from "../../organization/members/UserPermissions";
import useAuth from "../../../../../hooks/useAuth";
import DataTable from "./dataTable/DataTable";
import EditorControls, {CLEAR_ACTION, SELECTION_TOOL} from "./EditorControls";
import useTableDataSelection from "./dataTable/useTableDataSelection";
import useTableDataFlags from "./dataTable/useTableDataFlags";
import useTableDataEditor from "./dataTable/useTableDataEditor";
import {periodTypes} from "bettairplaformutil/src/validationUtil";
import usePeriodData from "../usePeriodData";

const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
    },
    editorControls: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
});

const Detail = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [openConfirmDialog, setOpenSaveDialog] = useState(false);
    const [selectedTool, setSelectedTool] = useState(SELECTION_TOOL);
    const {
        selectedStation, stationSensors, selectedPeriod, setSelectedPeriod, newSelectedPeriod, setNewSelectedPeriod,
        rawData, loading, dataMatrix, setDataMatrix, setDataParams, scrollToTableTriggered, setScrollToTableTriggered
    } = usePeriodContext();
    const {updateValidationCalendar, updateValidationHistory} = usePeriodData();
    const {getValidationData} = useTableDataFlags();
    const {selectionMatrix, clearSelection, addMatrixToSelection, handleSelectionClick} = useTableDataSelection();
    const {editorMatrix, clearEditor, addSelectionMatrix, saveEditorMatrix} = useTableDataEditor();
    const {hasPermission} = useAuth();
    const table = useRef(null);
    const [isWaitingLoading, setWaitingLoadingToFinish] = useState(false);
    const timeout = useRef(null);

    useEffect(() => {
        if (selectedStation) {
            // Reset matrix data when station changes
            setDataMatrix([]);
            setDataParams({});
            clearSelection();
        }
    }, [selectedStation, setDataMatrix, setDataParams, clearSelection]);

    useEffect(() => {
        if (!selectedPeriod) {
            const lastDaySelection = getLastDaySelection();
            setSelectedPeriod(lastDaySelection);
        } else {
            // Reset matrix data when period changes
            setDataMatrix([]);
            setDataParams({});
        }
    }, [selectedPeriod, setSelectedPeriod, setDataMatrix, setDataParams]);

    useEffect(() => {
        getValidationData();
    }, [getValidationData]);

    const modified = useMemo(() => {
        if (editorMatrix?.length > 0) {
            return editorMatrix.some((row, rowIndex) => row?.some((flag, colIndex) => {
                const loadedCellFlag = dataMatrix?.[rowIndex]?.[colIndex]?.flag ?? periodTypes.generated;
                const editorCellFlag = flag ?? loadedCellFlag;
                return editorCellFlag !== loadedCellFlag;
            }));
        }
        return false;
    }, [editorMatrix, dataMatrix]);

    useEffect(() => {
        if (newSelectedPeriod && !isEqual(newSelectedPeriod, selectedPeriod)) {
            if (modified) {
                setOpenSaveDialog(true);
            } else {
                setSelectedPeriod(newSelectedPeriod);
            }
        }
    }, [selectedPeriod, newSelectedPeriod, modified, setSelectedPeriod]);

    const handleCloseConfirmeDialog = useCallback(() => {
        setOpenSaveDialog(false);
        setNewSelectedPeriod(selectedPeriod);
    }, [setNewSelectedPeriod, selectedPeriod]);

    const handleConfirmExit = useCallback(() => {
        setOpenSaveDialog(false);
        setSelectedPeriod(newSelectedPeriod);
    }, [setSelectedPeriod, newSelectedPeriod]);

    const emptyData = rawData?.[stationSensors[0]] === undefined || rawData[stationSensors[0]].length === 0;

    const handleEditorAction = useCallback((action) => {
        if (action === CLEAR_ACTION) {
            clearSelection();
        }
    }, [clearSelection]);

    const handleTypeClick = useCallback((type) => {
        addSelectionMatrix(selectionMatrix, type);
    }, [selectionMatrix, addSelectionMatrix]);

    const getCellFlag = useCallback((rowIndex, colIndex) => {
        return editorMatrix?.[rowIndex]?.[colIndex] ?? dataMatrix?.[rowIndex]?.[colIndex]?.flag ?? periodTypes.generated;
    }, [editorMatrix, dataMatrix]);

    const handleMagicSelectionClick = useCallback((event, rowIndex, colIndex) => {
        if (colIndex < 0 || rowIndex < 0) return;
        const newSelectionMatrix = [];
        const selectedCellValue = getCellFlag(rowIndex, colIndex);
        for (let i = rowIndex - 1; i >= 0; i--) {
            if (getCellFlag(i, colIndex) !== selectedCellValue) break;
            newSelectionMatrix[i] = [];
            newSelectionMatrix[i][colIndex] = true;
        }
        for (let i = rowIndex; i < dataMatrix.length; i++) {
            if (getCellFlag(i, colIndex) !== selectedCellValue) break;
            newSelectionMatrix[i] = [];
            newSelectionMatrix[i][colIndex] = true;
        }
        addMatrixToSelection(newSelectionMatrix, event);
    }, [getCellFlag, dataMatrix.length, addMatrixToSelection]);

    const handleSave = useCallback(async () => {
        await saveEditorMatrix();
        updateValidationCalendar();
        updateValidationHistory();
    }, [saveEditorMatrix, updateValidationCalendar, updateValidationHistory]);

    useEffect(() => {
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        if(loading && scrollToTableTriggered){
            // if update is required wait until it finishes and reset trigger event
            setScrollToTableTriggered(false);
            setWaitingLoadingToFinish(true);
        }else if (scrollToTableTriggered){
            // if not update is required scroll and reset trigger event
            timeout.current = setTimeout(() => {
                setScrollToTableTriggered(false);
                table.current?.scrollIntoView({behavior: "smooth"});
            }, 350);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, scrollToTableTriggered]);


    useEffect(() => {
        if(isWaitingLoading && !loading){
            setTimeout(() => {
                setWaitingLoadingToFinish(false);
                table.current?.scrollIntoView({behavior: "smooth"});
            }, 500);
        }
    }, [isWaitingLoading, loading, setScrollToTableTriggered]);


    return (
        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
            <Grid item xs={12}>
                <PeriodDetailChart/>
            </Grid>
            {!emptyData && <>
                <Grid item xs={2}/>
                <Grid item xs={8} className={classes.editorControls} autoFocus={scrollToTableTriggered}>
                    <EditorControls selectedTool={selectedTool} onToolSelectorChange={setSelectedTool}
                                    onActionDispatch={handleEditorAction} onPeriodTypeClick={handleTypeClick} disable={selectionMatrix.length === 0} />
                </Grid>
                <Grid ref={table} item xs={2} style={{display: "flex", justifyContent: "flex-end"}}>
                    {hasPermission(WRITE_ORGANIZATION_VALID_PERIODS) && <>
                        <Tooltip title={t("action.discard")}>
                            <Button
                                data-testid={"discard-button"}
                                onClick={clearEditor}
                                disabled={loading || !modified}
                                color="error"
                                variant="contained"
                            >
                                <CloseIcon/>
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("action.save")}>
                            <Button
                                data-testid="validate-button"
                                onClick={handleSave}
                                disabled={loading || !modified}
                                color="success"
                                variant="contained"
                                sx={{marginLeft: 1}}
                            >
                                <CheckIcon/>
                            </Button>
                        </Tooltip>
                    </>}
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        selectedTool={selectedTool} selectionMatrix={selectionMatrix}
                               handleSelectionClick={handleSelectionClick} editorMatrix={editorMatrix}
                               handleMagicSelectionClick={handleMagicSelectionClick} />
                </Grid>
            </>}
            {openConfirmDialog &&
                <ConfirmDialog hideDialog={handleCloseConfirmeDialog} title={t("messages.exit_without_saving")}
                               question={t("messages.are_you_sure_to_exit_without_saving")}
                               confirmText={t("action.yes")}
                               confirm={handleConfirmExit}/>}
        </Grid>
    );
};

export default Detail;
