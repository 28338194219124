import {triggerTypes} from "./alarmConfigs";

const thresholdType = new Map([
    [triggerTypes.lt, "alarmScreen.popUp.value_smaller_threshold"],
    [triggerTypes.lte, "alarmScreen.popUp.value_smaller_equal_threshold"],
    [triggerTypes.gt, "alarmScreen.popUp.value_greater_threshold"],
    [triggerTypes.gte, "alarmScreen.popUp.value_greater_equal_threshold"]
]);

export const getTriggerName = (value, t) => {
    const path = thresholdType.get(value);
    return path === undefined ? "ND" : t(path);
};
