import React from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import {mapStationToIdAlias, STATION_SEARCHABLE_FIELDS} from "../../../../../utils/stationUtil";

const Step2 = ({initialSelection,callback}) => {

    const stations = useSelector(state => state.dashboardUI.stations.map((item) => ({
        ...mapStationToIdAlias(item),
        label: item.alias,
        serial: item.serial,
        sensors: item.sensors,
        state: item.state,
        isStation: true,
        timeZone: item?.position?.locationInfo?.timeZone,
        position: item.position
    })), isEqual);

    return (
            <MultipleSelector initialSelected={initialSelection}
                              items={stations}
                              isStationList
                              selectedCallback={callback}
                              min={1}
                              searchableFields={STATION_SEARCHABLE_FIELDS}
                              isStation={true}
            />
    );
};

export default Step2;
