import React, {useCallback, useEffect, useRef, useState} from "react";
import theme from "../../theme/bettairTheme";
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {profileRequest} from "../../requests/profile/profileRequest";
import {useDispatch, useSelector} from "react-redux";
import {loginAction} from "../../reducers/authReducer";
import {appModules, ERROR_500, ERROR_WEBSOCKET_FAIL_TO_CONNECT, LOGIN_ROUTE} from "../../constants";
import {stationRequest} from "../../requests/stationsRequests";
import {
    updateCompareStationsAction,
    updateSelectedStationAction,
    updateStationsAction
} from "../../reducers/dashboardUIReducer";
import {downloadConfigRequest} from "../../requests/downloads/downloadConfigRequest";
import {setDownloadListAction} from "../../reducers/downloadReducer";
import {webSocket} from "../../websocket/webSocket";
import {downloadReportListRequest} from "../../requests/report/downloadReportListRequest";
import {useMountComponent} from "../../hooks/useMountComponent";
import {setReportListAction} from "../../reducers/reportReducer";
import TopLevelSwitch from "./TopLevelSwitch";
import WebSockedError from "./WebSockedError";
import useLoading from "../../hooks/useLoading";
import RequestInfoComponent from "../common/RequestInfoComponent";
import useMessage from "../../hooks/useMessage";
import {setNotificationCountAction} from "../../reducers/notificationReducer";
import {notificationRequest} from "../../requests/alarms/notifications/notificationRequest";
import AlarmNotification from "./AlarmNotification";
import {DialogMessageProvider} from "../../hooks/useMessageDialog";
import {handleSentryError} from "../../handleSentryError";
import useRequest from "../../hooks/useRequest";
import useModules from "../../hooks/useModules";
import usePlatformStatus from "../../hooks/usePlatformStatus";
import {useTranslation} from "react-i18next";
import {statusURL} from "../../config/axios";


export const TopLevelRoutesTree = () => {

    const isMounted = useMountComponent();
    const {isPlatformOk, acknowledgePlatformError} = usePlatformStatus();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {handleErrorResponse} = useRequest();
    const [downloadedStationList, setDownloadedStationList] = useState(false);
    const [{checkedProfileState, isSocketConnected}, updateState] = useState({
        checkedProfileState: false,
        isSocketConnected: null,
    });
    const {setLoading} = useLoading();
    const {setError, setLargeInfo} = useMessage();
    const {signedIn, units} = useSelector(state => state.auth);
    let webSockedRef = useRef(null);
    let updateDataCallbackRef = useRef(null);
    const {hasModule} = useModules();

    const [redirectExternalSite, setRedirectExternalSite] = useState(true);

    const redirectCallback = useCallback((redirectExternalSite) => {
        setRedirectExternalSite(redirectExternalSite);
    }, []);


    const sockedFailToConnectCallback = useCallback(() => {
        setError(ERROR_WEBSOCKET_FAIL_TO_CONNECT);
    }, [setError]);

    const webSocketStatusCallback = useCallback((isConnected) => {
        updateState(state => ({...state, isSocketConnected: isConnected}));
    }, []);

    const downloadReportList = useCallback(() => {
        downloadReportListRequest((err, data) => {
            if (!isMounted.current) {
                return;
            }
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(setReportListAction(data));
                }
            }
            if (err) {
                handleErrorResponse(data, response => {
                    setError(response, false, `Error ${response?.status ?? ''} fetching report list`);
                });
            }
        });
    }, [setError, dispatch, isMounted, handleErrorResponse]);

    const downloadDownloadsConfig = useCallback(() => {
        downloadConfigRequest((err, data) => {
            if (!isMounted.current) {
                return;
            }
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(setDownloadListAction(data));
                }
            }
            if (err) {
                handleErrorResponse(data, response => {
                    setError(response, false, `Error ${response?.status ?? ''} fetching download list`);
                });
            }
        });
    }, [isMounted, dispatch, setError, handleErrorResponse]);


    const updateDataCallback = useCallback((type) => {
        switch (type) {
            case "download":
                downloadDownloadsConfig();
                break;
            case "report":
                downloadReportList();
                break;
            case "alarms":
                break;
            default:
                handleSentryError(`Error, updateDataCallback called with invalid type ${type}`);
        }
    }, [downloadReportList, downloadDownloadsConfig]);

    useEffect(() => {
        updateDataCallbackRef.current = updateDataCallback;
    }, [updateDataCallback]);

    useEffect(() => {
        if (signedIn) {
            updateDataCallbackRef.current = updateDataCallback;
            webSocket(dispatch, webSockedRef, sockedFailToConnectCallback, updateDataCallbackRef, webSocketStatusCallback, hasModule(appModules.alarms));
        } else {
            if (webSockedRef.current !== null) {
                webSockedRef.current.close(1000);
            } else {
                handleSentryError("Error, web socked close function ref is null");
            }

            // Reset data
            dispatch(updateStationsAction([]));
            dispatch(updateSelectedStationAction(''));
            dispatch(updateCompareStationsAction([]));
            dispatch(setDownloadListAction([]));
            dispatch(setReportListAction([]));
            if (signedIn === false) localStorage.setItem('lastStation', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedIn]);


    useEffect(() => {
        setLoading(true);
        document.getElementById('splash-screen').style.display = 'none';
        downloadCurrentUseProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
            if (signedIn && !redirectExternalSite) {
                downloadStations(units.index);
                downloadDownloadsConfig();
                downloadReportList();
                downloadNotifications();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [units.index, redirectExternalSite]);

    const downloadStations = useCallback((index) => {
        setDownloadedStationList(false);
        stationRequest(index, (err, data) => {
            if (!err && data) {
                dispatch(updateStationsAction(data));
            }
            if (err) {
                handleErrorResponse(data, response => {
                    setError(response, false, `Error ${response?.status ?? ''} fetching sensors`);
                });
            }
            setDownloadedStationList(true);
        });
    }, [dispatch, setError, handleErrorResponse]);

    const downloadCurrentUseProfile = useCallback(() => {
        profileRequest((err, data) => {
            if (err && data.status === 500) {
                return setError(ERROR_500, true);
            }
            if (!err) {
                localStorage.removeItem('lastPath');
                setRedirectExternalSite(false);
                dispatch(loginAction(data));
            } else {

                if(data.status === 401)
                    setDownloadedStationList(true);

                let arr = window.location.href.split("/");
                let path = `/${arr.slice(3, arr.length).join("/")}`;
                if (path !== LOGIN_ROUTE && !path.includes("register") && !path.includes("reset")) {
                    localStorage.setItem('lastPath', path);
                } else {
                    localStorage.removeItem('lastPath');
                }
            }
            updateState(state => ({...state, checkedProfileState: true}));
        });
    }, [dispatch, setError]);

    useEffect(() => {
        if (checkedProfileState && downloadedStationList) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedProfileState, downloadedStationList]);

    const downloadNotifications = useCallback(() => {
        notificationRequest(1, null, (err, data) => {
            if (!err && data) {
                if (data.count > 0) {
                    dispatch(setNotificationCountAction(data.count));
                }
            }
            if (err) {
                handleErrorResponse(data, response => {
                    setError(response, false, `Error ${response?.status ?? ''} downloading notifications`);
                });
            }
        });
    }, [dispatch, setError, handleErrorResponse]);

    useEffect(() => {
        if (!isPlatformOk)
            setLargeInfo(<div>
                {`${t("platformError")} - `}
                <a href={`https://${statusURL}`}
                   style={{textDecoration: "underline", color: "black", fontWeight: "bold"}} target="_blank"
                   rel="noopener noreferrer">
                    {t("common.seeMore")}
                </a>
            </div>, "warning", false, true, () => {
                acknowledgePlatformError();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlatformOk]);


    return (<ThemeProvider theme={theme}>
        <DialogMessageProvider>
            <Router>
                {checkedProfileState && downloadedStationList &&
                    <TopLevelSwitch signedIn={signedIn} redirectCallback={redirectCallback}/>
                }
                <RequestInfoComponent/>
                <AlarmNotification/>
            </Router>
            {isSocketConnected === false && signedIn && !redirectExternalSite && <WebSockedError/>}
        </DialogMessageProvider>
    </ThemeProvider>);
};

