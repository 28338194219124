import React, {Fragment, useMemo} from 'react';
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {indexColors, getAQILegend} from "bettairplaformutil/src/indexUtil";
import {contrastColor} from "contrast-color";
import {TEXT_COLOR, TEXT_COLOR_CONTRAST} from "../../home/legend/colors";

const useStyles = makeStyles({
    legendContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    base:{
        width:15,
        height:10,
        background:"#000",
        borderRadius:5
    },
    textLabel:{
        fontSize:"x-small",
        marginLeft:2,
        marginRight:10,
        color:"#545454"
    },
    lastTextLabel:{
        fontSize:"x-small",
        marginLeft:2,
        color:"#545454"
    }
});

const CalendarLegend = ({indexType}) => {
    const classes = useStyles();
    const {i18n} = useTranslation();

    const {colors, indexInfo} = useMemo(() => {
        if (indexType && i18n.language !== null) {
            return {
                colors: indexColors[indexType],
                indexInfo: getAQILegend(indexType, i18n.language)
            };
        }
        return {colors: [], rangeInfo: [], indexInfo: []};
    }, [indexType, i18n.language]);

    return indexInfo.length > 0 && (
        <div className={classes.legendContainer}>
            {indexInfo.map((item, index) => {
                const contrast = contrastColor({
                    bgColor: colors[index],
                    fgDarkColor: TEXT_COLOR,
                    fgLightColor: TEXT_COLOR_CONTRAST
                });
                return <Fragment key={index}>
                    <div className={classes.base} style={{background: colors[index]}}/>
                    <Typography data-testid={`${indexType}-${index}`} color={contrast} variant={"subtitle2"}
                                className={index === indexInfo.length - 1 ? classes.lastTextLabel : classes.textLabel}>
                        {item}
                    </Typography>
                </Fragment>;
            })}
        </div>
    );
};

export default CalendarLegend;
