import {sensor, unit} from "../constants";
import {getVariableUnit} from "./getVariableUnit";

export const getRange = (data, units) => {
    const temperatureUnit = getVariableUnit(sensor.temperature, units);
    const pressureUnit = getVariableUnit(sensor.pressure, units);
    const co2Unit = getVariableUnit(sensor.co2, units);

    let max = -Infinity;
    let min = Infinity;

    data.forEach(pollutant => {
        if (pollutant.visible !== true) return;
        if (pollutant.units === unit.percentage) {
            max = 100;
            return;
        }
        let maxValue = -Infinity;
        let minValue = Infinity;
        pollutant.y.forEach(value => {
            if (isNaN(value)) return;
            if (value > maxValue) maxValue = value;
            if (value < minValue) minValue = value;
        });
        if ([temperatureUnit, pressureUnit, co2Unit].includes(pollutant.units)) {
            if (minValue < min) min = minValue;
        } else if (maxValue < 2) {
            maxValue = 2;
        }
        if (maxValue > max) max = maxValue;
    });

    if (max === -Infinity) max = 0;
    if (min === Infinity) min = 0;

    return [min, max];
};

export const getPollutantRange = (data, units) => {
    const unitsToFilter = [sensor.temperature, sensor.rh, sensor.pressure].map(
        sensor => getVariableUnit(sensor, units)
    );
    const filteredData = data.filter(pollutant => !unitsToFilter.includes(pollutant.units));
    return getRange(filteredData, units);
};

export const getTemperatureRange = (data, units) => {
    const filteredData = data.filter(pollutant => pollutant.units === getVariableUnit(sensor.temperature, units));
    return getRange(filteredData, units);
};

export const getPressureRange = (data, units) => {
    const filteredData = data.filter(pollutant => pollutant.units === getVariableUnit(sensor.pressure, units));
    return getRange(filteredData, units);
};

export const getSensorDataRanges = (data, units) => {
    const pollutantRange = getPollutantRange(data, units);
    const temperatureRange = getTemperatureRange(data, units);
    const pressureRange = getPressureRange(data, units);
    return {pollutantRange, temperatureRange, pressureRange};
};
