import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CircularProgress, Typography} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {WRITE_ALIAS} from "../../organization/members/UserPermissions";
import useAuth from "../../../../../hooks/useAuth";


const ImageButton = styled(ButtonBase)(({_}) => ({
    position: 'relative',
    height: '100%',
    maxHeight: '80px'
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const ImageBackdrop = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)', // Change this to the color you want
    opacity: 0,
    transition: 'opacity .3s',
});

const Image = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: theme.palette.common.white
}));

export default function StationImageButton({url, defaultUrl, loading, onclick}) {

    const {t} = useTranslation();
    const [buttonUrl, setButtonUrl] = useState(url ?? defaultUrl);
    const [showEditIcon, setShowEditIcon] = useState(!url);
    const [hover, setHover] = useState(false);
    const {hasPermission} = useAuth();

    useEffect(() => {
        setButtonUrl(url ?? defaultUrl);
        setShowEditIcon(!url && !!hasPermission(WRITE_ALIAS));
    }, [defaultUrl, hasPermission, url]);

    const onButtonClick = useCallback(()=>{
        setHover(false);
        onclick();
    },[onclick]);

    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', minWidth: 80, height: 80, width: '100%'}}>
            <ImageButton
                focusRipple
                style={{
                    width: "100%",
                }}
                onClick={onButtonClick}
                disabled={loading}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <ImageSrc style={{backgroundImage: `url(${buttonUrl})`}}/>
                {!loading && hover && <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1
                    }}
                >
                    <Typography style={{
                        color: '#ffffff',
                        textDecoration: 'underline',
                        textDecorationThickness: '2px',
                        textTransform: 'uppercase',
                        textUnderlineOffset: '6px',
                        fontWeight: 600
                    }}>{url ? t("analyticScreen.deviceDescription.view") : t("analyticScreen.deviceDescription.edit")}</Typography>
                </div>}
                {!loading && hover && <ImageBackdrop style={{opacity: 1}}/>}
                {!loading && !hover && <Image>
                        {showEditIcon ? <EditRoundedIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
                </Image>}
                {loading && <CircularProgress size={40} style={{color: "#ffffff"}}/>}
            </ImageButton>
        </Box>
    );
}
