import {webClient} from "../../config/axios";

export const deletePasswordRequest = (list, isOrganizationPass = false) => {

    const url = isOrganizationPass ? '/organization/app-password' : '/user/app-password';

    let deletePromises = [];
    list.forEach((passwordId)=>{
        deletePromises.push(webClient.delete(`${url}/${passwordId}`));
    });
    return deletePromises;
};
