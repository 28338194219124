import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import useRequest from "../../../../../hooks/useRequest";
import {DEFAULT_PERIOD, timeZoneOptions} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {noiseDataRequest} from "../../../../../requests/analytics/noiseDataRequest";
import {useTranslation} from "react-i18next";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import useMessage from "../../../../../hooks/useMessage";
import ChartView from "../../../../common/chart/ChartView";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {AUTO_RESOLUTION} from "../common/ResolutionSelector";

const baseSensorData = {
    type: 'scatter',
};

const initialPlotLayout = {
    autosize: true,
    legend: {x: -0.22, y: 0.9},
    yaxis: {
        title: 'dBA',
        range: [0, 120]
    },
    xaxis: {showgrid: false, domain: [0.01, 1]},
    hovermode: "x unified"
};

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines", "hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo: false
};


const NoiseCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {handleErrorResponse} = useRequest();
    const [{data, loading, error}, updateState] = useState({data: [], loading: true, error: notFoundMessage});
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const [timeZone, setTimeZone] = useState(selectedStationData.position?.locationInfo ? dataTimeZone : timeZoneOptions.UTC);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);

    const resolutionOptions = useMemo(() => [
        {value: AUTO_RESOLUTION, label: t("resolutionSelector.auto")},
        {value: 5, label: t("resolutionSelector.5min")},
        {value: 10, label: t("resolutionSelector.10min")},
        {value: 60, label: t("resolutionSelector.1h")}
    ], [t]);

    const updateData = useCallback(() => {

        updateState(state => {
            return {...state, loading: true, data: [], error: ""};
        });

        noiseDataRequest({
            stationId: selectedStation,
            init: selectedRange[0],
            end: selectedRange[1],
            dataTimeZone: timeZone,
            resolution:selectedResolution
        }, (err, data) => {
            if (!isMounted.current) {
                return;
            }
            if (!err) {
                if (Array.isArray(data) && data.length === 0) {
                    updateState(state => {
                        return {...state, data: [], error: notFoundMessage, loading: false};
                    });
                } else {

                    let fetchData = [{
                        name: "Noise level",
                        x: data.data.x,
                        y: data.data.y,
                        metrics: data.metrics,
                        ...baseSensorData,
                        hovertemplate: `<b>Noise level</b>: %{y} dBA`,
                        hoverlabel: {namelength: 0},
                        marker: {color: '#1A23D0'}
                    }];

                    updateState(state => {
                        return {...state, data: fetchData, loading: false};
                    });
                }
            } else {

                updateState(state => {

                    const error = data.status === 412 ? t("requestErrors.couldNotUpdateUserUTC") : data.status;

                    return {...state, loading: false, error};
                });
                handleErrorResponse(data, response => {
                    setError(response,
                        false,
                        "analyticScreen.ambientNoise.could_not_update");
                });
            }

        });
    }, [selectedStation, selectedRange, timeZone, selectedResolution, isMounted, notFoundMessage, handleErrorResponse, t, setError]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const handleRefresh = () => {
        updateData();
    };

    const handleTimeZoneChange = (event, newAlignment) => {
        setTimeZone(newAlignment);
    };

    const metrics = data[0]?.metrics;
    const rows = useMemo(() => {
        const metricIds = ["lm", "le", "ln", "lden", "la"];
        if (metrics) {
            return metricIds.filter(metric => metrics[metric] !== undefined).map(metric => {
                return {
                    metric: t(`analyticScreen.ambientNoise.metrics.${metric}`),
                    dBA: metrics[metric]
                };
            });
        }
    }, [metrics, t]);

    const noiseTable = useMemo(() => {
        return rows ? (
            <TableContainer component={Paper} sx={{marginTop: 6, maxWidth: 160}}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("analyticScreen.ambientNoise.metric")}</TableCell>
                            <TableCell align="right">{t("analyticScreen.ambientNoise.dba")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.metric}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.metric}
                                </TableCell>
                                <TableCell align="right">{row.dBA}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : null;
    }, [rows, t]);

    const plotLayout = useMemo(() => {
        const locationInfo = selectedStationData.position?.locationInfo;
        return {
            ...initialPlotLayout,
            xaxis: {
                ...initialPlotLayout.xaxis,
                hoverformat: `%-d %b, %Y %H:%M:%S${locationInfo ? '' : ` (${timeZoneOptions.UTC})`}`
            }
        };
    }, [selectedStationData]);

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("analyticScreen.ambientNoise.ambient_noise")}
                    helpText={t("analyticScreen.ambientNoise.en_analytics_noise")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout error={error} loading={loading} emptyData={data.length === 0} height={420}
                         chartStyles={{"& .modebar": {left: "42%"}}} timeZone={timeZone}
                         onTimeZoneChange={selectedStationData.position?.locationInfo && handleTimeZoneChange}
                         period={period} onPeriodChange={setPeriod} dateRange={dateRange}
                         onDateRangeChange={setDateRange}
                         sideContent={selectedStationData.position?.locationInfo && noiseTable}
                         resolution={selectedResolution}
                         resolutionOptions = {resolutionOptions}
                         onResolutionChange={setSelectedResolution}
            >
                <ChartView
                    layout={plotLayout}
                    data={data}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default NoiseCardView;
