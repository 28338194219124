import {webClient} from "../../../config/axios";
import {handleError} from "../../handleRequestError";

export const createAlarmRequest = (body, callback) => {
    const url = `alarm`;
    webClient.post(url, body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};