import React, {useEffect, useState} from 'react';
import {
    Backdrop,
    Button, Card,
    Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import useUnits from "../../../../../hooks/useUnits";
import {getVariableUnitName} from "../../unitsNames";
import {getVariableName} from "../../pollutantNames";
import {getTriggerName} from "../triggerNames";
import useUserPrefTimeZone from "../../../../../hooks/useUserPrefTimeZone";
import {timeZoneOptions} from "../../../../../constants";

const useStyles = makeStyles({
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    title: {
        marginLeft: 30,
        color: "#434343",
        marginBottom: 16
    },
    subTitle: {
        fontSize: 20,
        color: "#434343",
        marginBottom: 4
    },
    value: {
        fontSize: 18,
        color: "#a0a0a0",
        marginBottom: 32
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    popUpContainer: {
        width: 700
    },
    detailContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: 600,
        paddingBottom: 20
    },
    tableContainer: {
        marginTop: 20,
        height: 269,
        width: "100%",
        borderRadius: 6,
        background: "#eaeaea",
    }
});

const AlarmStatePopUp = ({alarm, handleClose}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const [data, updateData] = useState([]);
    const {units, convertToUserUnit} = useUnits();
    const {convertStationDateToUserPrefTz, userTimeZone} = useUserPrefTimeZone();

    useEffect(() => {
        const data = alarm.stations.map(item => {
            const triggered = alarm.state[item]?.triggered === undefined ? null : alarm.state[item]?.triggered;
            const date = alarm.state[item]?.timestamp ? new Date(alarm.state[item]?.timestamp) : null;
            const value = alarm?.config?.variable === undefined ? null : alarm.state[item]?.value ?
                `${convertToUserUnit(alarm.config.variable, alarm.state[item]?.value)} ${getVariableUnitName(alarm.config.variable, units)}` : "--";
            return {
                name: item,
                state: triggered === null ? t("alarmScreen.never_triggered") : triggered ? t("alarmScreen.triggered") : t("alarmScreen.not_triggered"),
                value,
                date
            };
        });
        updateData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alarm, t, units]);


    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.title}>{t("alarmScreen.alarm_state")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.detailContainer}>
                        <Grid item xs={6}>
                            <Typography variant={"h5"}
                                        className={classes.subTitle}>{t("alarmScreen.alarm_name")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{alarm.name}</Typography>
                        </Grid>
                        {alarm.type === "threshold" && (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant={"h5"}
                                                className={classes.subTitle}>{t("alarmScreen.sensor")}</Typography>
                                    <Typography variant={"subtitle1"}
                                                className={classes.value}>{getVariableName(alarm.config.variable)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"h5"}
                                                className={classes.subTitle}>{t("alarmScreen.trigger")}</Typography>
                                    <Typography variant={"subtitle1"}
                                                className={classes.value}>{getTriggerName(alarm.config.thresholdType, t)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"h5"}
                                                className={classes.subTitle}>{t("alarmScreen.threshold")}</Typography>
                                    <Typography variant={"subtitle1"} className={classes.value}>
                                        {`${convertToUserUnit(alarm.config.variable, alarm.config.thresholdValue)}
                                ${getVariableUnitName(alarm.config.variable, units)}`}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {alarm.type === "state" && (
                            <Grid item xs={6}>
                                <Typography variant={"h5"}
                                            className={classes.subTitle}>{t("alarmScreen.trigger")}</Typography>
                                <Typography variant={"subtitle1"}
                                            className={classes.value}>{t("alarmScreen.state")}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                {t("alarmScreen.station")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("alarmScreen.state")}
                                            </TableCell>
                                            {alarm.type === "threshold" && (
                                                <TableCell align="left">
                                                    {t("alarmScreen.notifications.value")}
                                                </TableCell>
                                            )}
                                            <TableCell align="left">
                                                {`${t("alarmScreen.notifications.date")} ${userTimeZone === "UTC" ? "(UTC)" : ""}`}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item) => (
                                            <TableRow key={item.name}>
                                                <TableCell align="left">
                                                    {item.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.state}
                                                </TableCell>
                                                {alarm.type === "threshold" && (
                                                    <TableCell align="left">
                                                        {item.value}
                                                    </TableCell>
                                                )}
                                                <TableCell align="left">
                                                    {convertStationDateToUserPrefTz(item.name, item.date, userTimeZone !== timeZoneOptions.UTC, false)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleClose}>{t("ok")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default AlarmStatePopUp;
