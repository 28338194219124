import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {pollutantChartCategoryColor} from "../../analytics/pollutant_rose/chart/PollutantRoseChart";
import {cloneDeep} from "lodash";
import {unitsMap} from "../../unitsNames";

const getLegendData = (data) => {
    const copy = cloneDeep(data.data);
    return copy.map((item) => {
        return {
            name: `${item.name} ${unitsMap.get(data.unit)}`,
            color: pollutantChartCategoryColor[item.category]
        };
    });
};

const PollutantRoseLegend = ({data}) => {

    const [legendData, updateLegendData] = useState(null);

    useEffect(() => {
        updateLegendData(data ? getLegendData(data) : null);
    }, [data]);

    return legendData && (
        <div>
            {legendData.map(item =>
                <div key={item.name} style={{width: "100%", display: "flex", flexDirection: "row",alignItems:"center"}}>
                    <div style={
                        {
                            width: 16,
                            height: 12,
                            background: item.color,
                            marginRight: 10,
                            borderRadius:2
                        }}/>
                    <div>
                        <Typography>{item.name}</Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PollutantRoseLegend;
