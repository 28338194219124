import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import {Backdrop, Button, Grid, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DatePickerField from "../../../../common/DatePickerField";
import TimePickerField from "../../../../common/TimePickerField";
import useDateTime from "../../../../../hooks/useDateTime";
import {getStationOptions, stationSelectorFilter} from "../../../../../utils/stationUtil";
import ListCustomItem from "../../../../common/multipleSelector/ListCustomItem";
import ListCustomItemText from "../../../../common/multipleSelector/ListCustomItemText";

const getTimezoneOffsetMilliseconds = (date) => {
    return new Date(date).getTimezoneOffset() * 60 * 1000;
};

const localToUTCDate = (date) => {
    return new Date(new Date(date).getTime() - getTimezoneOffsetMilliseconds(date));
};

const AddLogPopup = ({cancel, confirm, open, activeStation}) => {
    const {t} = useTranslation();
    const options = useSelector(state => getStationOptions(state.dashboardUI.stations), isEqual);
    const [selectedStation, setSelectedStation] = useState(null);
    const {selectedDate, setSelectedDate, selectedTime, setSelectedTime, selectedDateTime} = useDateTime();
    const [logDescription, setLogDescription] = useState("");

    const onStationChange = (event, newValue) => {
        setSelectedStation(newValue);
    };

    const clearFields = useCallback(() => {
        setSelectedDate(new Date());
        setSelectedTime(new Date());
        setSelectedStation(activeStation);
        setLogDescription("");
    }, [activeStation, setSelectedDate, setSelectedTime]);

    const handleSave = async () => {
        await confirm({
            station: selectedStation.id,
            date: localToUTCDate(selectedDateTime),
            description: logDescription
        });
        clearFields();
    };

    const handleCancel = () => {
        clearFields();
        cancel();
    };

    useEffect(() => {
        setSelectedStation(activeStation);
    }, [activeStation]);

    const saveEnabled = !!(selectedStation && selectedDate && selectedTime && logDescription?.length);

    return (
        <Backdrop open={open} style={{zIndex: 1299}}>
            <Grid container data-testid="add-log-popup"
                  style={{backgroundColor: "#fff", maxWidth: 800, padding: 30, paddingBottom: 20}}>
                <Grid item container xs={12} style={{marginBottom: 20}} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={6}>
                        <Autocomplete
                            onChange={onStationChange}
                            value={selectedStation ?? null}
                            disablePortal
                            options={options}
                            size="small"
                            fullWidth
                            data-testid="station-select"
                            filterOptions={stationSelectorFilter}
                            renderOption={(props, option) => (
                                <ListCustomItem {...props} key={option.id}>
                                    <ListCustomItemText option={option} isStation={true}/>
                                </ListCustomItem>
                            )}
                            renderInput={(params) =>
                                <TextField label={t("dashboard.select_station")} {...params}/>}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerField label={t("analyticScreen.deviceLog.date")}
                                         value={selectedDate}
                                         onChange={setSelectedDate}
                                         size="small"/>
                    </Grid>
                    <Grid item xs={3}>
                        <TimePickerField label={t("common.time")}
                                         value={selectedTime}
                                         onChange={setSelectedTime}
                                         size="small"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={t("common.description")}
                                   fullWidth
                                   multiline
                                   value={logDescription}
                                   onChange={(event) => setLogDescription(event.target.value)}
                                   rows={4}
                                   data-testid="description-input"/>
                    </Grid>
                </Grid>
                <Grid item container xs={12} style={{justifyContent: "flex-end"}}>
                    <Button onClick={handleCancel} color="primary" >
                        {t("cancel")}
                    </Button>
                    <Button
                        data-testid="save-log-button"
                        onClick={handleSave} color="primary"
                        disabled={!saveEnabled}>
                        {t("action.save")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default AddLogPopup;
