import {getWeekDaySeries} from "../week/weekChart";

export const getHourlyChartLayout = (unit,title) => {
    return {
        xaxis8: {
            anchor: `y8`,
            showgrid: true,
            range: [0, 23],
            domain: [0, 0.3],
            side: "bottom",
            mirror: true,
            ticklen: 8,
            title: {
                text:title
            },
            linecolor: '#000000',
            linewidth: 1,
            dtick: 6,
            autotick: true
        },
        yaxis8: {
            anchor: `x8`,
            domain: [0, 0.5],
            mirror:  true,
            showline: true,
            linecolor: '#000000',
            linewidth: 1,
            ticklen: 8,
            title: {text:unit ,standoff:10},
            zeroline:false
        }
    };
};

const HOUR_CHART_INDEX = 8;

export const getHourlyData = (pollutant, unit, dayOfWeekData) =>{
    const mean = getWeekDaySeries(pollutant,unit,"mean", dayOfWeekData, HOUR_CHART_INDEX);
    const min = getWeekDaySeries(pollutant,unit,"min", dayOfWeekData, HOUR_CHART_INDEX);
    const max = getWeekDaySeries(pollutant,unit,"max", dayOfWeekData, HOUR_CHART_INDEX);
    const p25 = getWeekDaySeries(pollutant,unit,"p25", dayOfWeekData, HOUR_CHART_INDEX);
    const p75 = getWeekDaySeries(pollutant,unit,"p75", dayOfWeekData, HOUR_CHART_INDEX);
    return [mean, min, p25, p75, max];
};
