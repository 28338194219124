import { useState, createContext, useContext } from "react";

const DashboardContext = createContext({});

export const DashboardContextProvider = ({ children }) => {
    const [dashboardRef, setDashboardRef] = useState(null);

    return (
        <DashboardContext.Provider value={{ dashboardRef, setDashboardRef }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboardContext = () => useContext(DashboardContext);
