import React from 'react';
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../../../../../utils/dateUtil";

const CalendarMonthSelector = ({className,date,handleDateChange,includeCurrentMonth = true,disable = false}) => {

    const {t} = useTranslation();
    const currentDate = new Date();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
        <DatePicker
            disabled={disable}
            className={className}
            views={['month','year']}
            label={t("analyticScreen.calendar.select_date")}
            minDate={new Date("2019-12-01")}
            maxDate={new Date(currentDate.getFullYear(),
                includeCurrentMonth ? currentDate.getMonth()+1:currentDate.getMonth(), 0)}
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    helperText={null}
                    variant="standard"
                />
            )}
        />
        </LocalizationProvider>
    );
};

export default CalendarMonthSelector;
