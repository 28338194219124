import React from "react";
import {useMessageDialog} from "./useMessageDialog";
import {handleSentryError} from "../handleSentryError";
import {useTranslation} from "react-i18next";

const useTryWebGL = () => {
    const {t} = useTranslation();
    const {showDialog} = useMessageDialog();

    const tryWebGL = (initFunc) => {
        try {
            initFunc();
            return false;
        } catch (e) {
            if (e.message?.startsWith("Failed to initialize WebGL")) {
                showDialog({
                    title: t("dashboard.webgl_disabled_error_title"),
                    message: <>
                        <p>{t("dashboard.webgl_disabled_error_message")}</p>
                        <p>
                            {t("dashboard.webgl_disabled_error_message2")}{": "}
                            <a href="https://get.webgl.org/">
                                https://get.webgl.org
                            </a>
                        </p>
                        <p>
                            {t("dashboard.webgl_disabled_error_contact")}{" "}
                            <a href="mailto:support@bettaircities.com">
                                support@bettaircities.com
                            </a>
                            .
                        </p>
                    </>
                });
            } else {
                handleSentryError(e);
            }
            return true;
        }
    };

    return {tryWebGL};
};

export default useTryWebGL;
