import type from "./action_types/type";

const initialState={
    reportList:[],
    reportProcessingProgressList:[]
};

export const reportReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.setReportListAction:
            return {...state,reportList:action.payload };
        case type.deleteReportAction:
            return {...state,reportList:state.reportList.filter(item => !action.payload.includes(item.id))};
        case type.createReportAction:
            return {...state,reportList: state.reportList.find(item => item.id === action.payload.id) !== undefined ?
                    state.reportList : [...state.reportList,action.payload]};
        case type.updateReportStateAction:
            return {...state,reportList:state.reportList.map(item => {return item.id !== action.payload.id ? item :
                    action.payload.state !== "completed" ? {...item,status:{state:action.payload.state}} :
                        {...item,status:{state:action.payload.state, size:action.payload.size}};})};

        //progress
        case type.setReportProcessingProgressListAction:
            return {...state,reportProcessingProgressList: action.payload};
        case type.removeReportProcessingProgressListAction:
            return {...state,reportProcessingProgressList: state.reportProcessingProgressList.filter(item => !action.payload.includes(item.id))};
        case type.updateReportProcessingProgressListAction:
            return {...state,reportProcessingProgressList: state.reportProcessingProgressList.find(item => item.id === action.payload.id) === undefined ?
                    [...state.reportProcessingProgressList, action.payload] :
                    state.reportProcessingProgressList.map(item => {return item.id !== action.payload.id ?
                        item : {...item,progress:action.payload.progress};})};

        default:
            return state;
    }
};



//Report list actions
export const setReportListAction = (reportList) =>({
    type:type.setReportListAction,
    payload:reportList
});

export const updateReportStateAction = (payload) =>({
    type:type.updateReportStateAction,
    payload:payload
});

export const deleteReportAction = (payload) => ({
    type:type.deleteReportAction,
    payload:payload
});

export const createReportAction = (payload)=>({
    type:type.createReportAction,
    payload:payload
});

//Report generation progress actions
export const setReportProcessingProgressListAction = (progressList) =>({
    type:type.setReportProcessingProgressListAction,
    payload:progressList
});

export const removeReportProcessingProgressListAction = (reportList) =>({
    type:type.removeReportProcessingProgressListAction,
    payload:reportList
});

export const updateReportProcessingProgressListAction = (reportList) =>({
    type:type.updateReportProcessingProgressListAction,
    payload:reportList
});
