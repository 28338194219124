import type from "./action_types/type";


const initialState={
    loading:false,
    error:null,
    success:null
};

export const requestUIReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.startLoading:
            return {...state,loading:true};
        case type.stopLoading:
            return {...state,loading:false};
        case type.setRequestError:
            return {...state,error: action.payload.message};
        case type.removeRequestError:
            return {...state,error: null};
        case type.setInfoMessage:
            return {...state,infoMessage: action.payload.message};
        case type.removeInfoMessage:
            return {...state,infoMessage: null};
        default:
            return state;
    }
};

export const startLoadingAction = () =>({
    type:type.startLoading
});

export const stopLoadingAction = () =>({
    type:type.stopLoading
});

export const setRequestError = (message) =>({
    type:type.setRequestError,
    payload:{message}
});

export const removeRequestErrorAction = () =>({
    type:type.removeRequestError
});
