const gridFilter = (rows, filterModel) => {
    if (!filterModel?.items?.length) {
        return rows;
    }

    return rows.filter(row => {
        return filterModel.items.some(item => {
            const operator = item.operatorValue;
            const value = item.value?.toString().toLowerCase() ?? "";
            const column = item.columnField;
            const rowValue = row[column]?.toString().toLowerCase();
            switch (operator) {
                case "contains":
                    return rowValue?.includes(value);
                case "startsWith":
                    return rowValue?.startsWith(value);
                case "endsWith":
                    return rowValue?.endsWith(value);
                case "equals":
                    return rowValue === value;
                case "notEquals":
                    return rowValue !== value;
                case "isEmpty":
                    return rowValue === "";
                case "isNotEmpty":
                    return rowValue !== "";
                case "isAnyOf":
                    return value?.split(",").includes(rowValue);
                default:
                    return true;
            }
        });
    });
};

export default gridFilter;
