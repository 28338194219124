import React, {useEffect, useMemo, useState} from "react";
import {Grid, Typography, Pagination} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import gridFilter from "./gridFilter";
import DataNotFound from "../DataNotFound";

const useStyle = makeStyles({
    pagination: {
        display: "flex",
        justifyContent: "center"
    }
});

const DataGridComponent = ({
                               className, tableClassName, rows, rowCount, page, onPageChange, filterMode, filterModel,
                               onFilterModelChange, selectionModel, pageSize = 100, ...otherProps
                           }) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const [pageIndex, setPageIndex] = useState(0);
    const pageNumber = pageIndex + 1;
    const [localFilterModel, setLocalFilterModel] = useState({items: []});

    useEffect(() => {
        setPageIndex(page ?? 0);
    }, [page]);

    useEffect(() => {
        setLocalFilterModel(filterModel);
    }, [filterModel]);

    useEffect(() => {
        if (localFilterModel?.items?.length) {
            onPageChange?.(0);
            setPageIndex(0);
        }
    }, [localFilterModel, onPageChange]);

    const handlePageChange = (event, value) => {
        const newPageIndex = value - 1;
        onPageChange?.(newPageIndex);
        setPageIndex(newPageIndex);
    };

    const handleFilterModelChange = (model) => {
        onFilterModelChange?.(model);
        setLocalFilterModel(model);
    };

    const filteredRows = useMemo(() => {
        if (filterMode !== "server") {
            return gridFilter(rows, localFilterModel);
        } else {
            return rows;
        }
    }, [rows, filterMode, localFilterModel]);

    const count = rowCount ?? filteredRows.length;

    return (
        <Grid container className={className}>
            <Grid item xs={12}>
                <DataGrid
                    className={tableClassName}
                    rows={filteredRows}
                    page={pageIndex}
                    pageSize={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    rowCount={count}
                    hideFooter={true}
                    filterMode="server"
                    filterModel={localFilterModel}
                    onFilterModelChange={handleFilterModelChange}
                    selectionModel={selectionModel}
                    components={{
                        NoRowsOverlay: () => (
                            <DataNotFound/>
                        ),
                        NoResultsOverlay: () => (
                            <DataNotFound/>
                        )
                    }}
                    {...otherProps}
                />
            </Grid>
            <Grid item xs={3} lg={2} marginTop={4}>
                {selectionModel && (
                    <Typography>
                        {t("selector.selected_elements", {count: selectionModel.length})}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={6} lg={8} marginTop={2} className={classes.pagination}>
                <Pagination
                    page={pageNumber}
                    onChange={handlePageChange}
                    count={Math.ceil(count / pageSize)}
                    variant="outlined"
                    shape="rounded"
                    color={"primary"}
                    style={{marginTop: "10px"}}
                />
            </Grid>
        </Grid>
    );
};

export default DataGridComponent;