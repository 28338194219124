import {Box, Chip, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";
import {stationState} from "../../../constants";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    label: {
        alignSelf: "stretch",
        lineHeight: 1.25,
        fontWeight: 500,
        marginBottom: 2
    },
    subLabel: {
        alignSelf: "stretch",
        lineHeight: 1.25
    },
    chip: {
        marginLeft: 5,
        height: 16,
        verticalAlign: "top"
    }
});

const ListCustomItemText = ({option, isStation = false}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const withoutLocation = !option.position;
    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body" color="text.primary" className={classes.label}>
                {option.label} {option.state === stationState.offline && isStation && (
                    <Chip className={classes.chip} label={t("offline")} color="error" size="small" />
                )}
                {withoutLocation && isStation && (
                    <Chip className={classes.chip} label={t("common.without_location")} color="warning" size="small" />
                )}
            </Typography>
            {option.isStation && <>
                <Typography variant="caption" color="text.secondary" className={classes.subLabel}>
                    ID: {option.id}
                </Typography>
                {option.serial !== undefined && (
                    <Typography variant="caption" color="text.secondary" className={classes.subLabel}>
                        Serial: {option.serial}
                    </Typography>
                )}
            </>}
        </Box>
    );
};

export default ListCustomItemText;
