import React, {useEffect, useState} from 'react';
import {contrastColor} from "contrast-color";
import {TEXT_COLOR, TEXT_COLOR_CONTRAST} from "./colors";
import {Grid, Typography} from "@mui/material";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";
import {makeStyles} from "@mui/styles";
import {airQualityIndexColors} from "../../../../../constants";
import {useTranslation} from "react-i18next";
import {formatIndexRange, getIndexRange} from "../../../../../utils/airQualityIndexUtil";

const useStyles = makeStyles({
    value: {
        textAlign: "left",
        minWidth: 55,
        fontWeight: "bold",
        marginRight: 8,
        marginLeft: 8
    },
    description: {
        textAlign: "left",
        fontWeight: "bold",
        paddingLeft: 8
    },
    item: {
        marginBottom: 0
    }
});

const AirQualityIndexLegend = ({indexType}) => {

    const {i18n } = useTranslation();

    const [colors, setColors] = useState([]);
    const [indexInfo, setIndexInfo] = useState([]);
    const [rangeInfo, setRangeInfo] = useState([]);
    const [locale, setLocale] = useState(null);

    useEffect(() => {
        setLocale(i18n.language);
    }, [i18n.language]);


    useEffect(() => {
        if(indexType && locale !== null) {
            setColors(airQualityIndexColors[indexType]);
            setRangeInfo(formatIndexRange(getIndexRange(indexType)));
            setIndexInfo(getAQILegend(indexType, locale));
        }
    }, [indexType, locale]);

    const classes = useStyles();

    return (<>
            {indexInfo.length > 0 ? indexInfo.map((item, index) => {
                const contrast = contrastColor({
                    bgColor: colors[index],
                    fgDarkColor: TEXT_COLOR,
                    fgLightColor: TEXT_COLOR_CONTRAST
                });
                return (
                    <Grid item xs={12} container className={classes.item} backgroundColor={colors[index]} key={index}>
                        { rangeInfo &&
                            <Typography variant={"subtitle2"} className={classes.value} color={contrast}>
                                {rangeInfo[index]}
                            </Typography>
                        }
                        <Typography variant={"subtitle2"} className={classes.description} color={contrast}>
                            {item}
                        </Typography>
                    </Grid>
                );
            }) : <Grid item xs={12}/>}
        </>
    )
        ;
};

export default AirQualityIndexLegend;
