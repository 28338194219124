import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {getFormattedDateTime} from "../../../../../utils/getFormattedDateTime";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    container: {
        maxHeight: 200
    },
    emptyMessage: {
        width: "100%",
        marginTop: 65,
        color: "#e0e0e0",
        textAlign: "center"
    }
});

const LogTabView = ({ className, log }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid item xs={12} className={className}>
            {log.length > 0 && (
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="event table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{t("analyticScreen.deviceLog.date")}</TableCell>
                                <TableCell align="left">{t("analyticScreen.deviceLog.event")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {log.slice(0, 5).map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell
                                        align="left">{getFormattedDateTime(item.date)}</TableCell>
                                    <TableCell  align="left">{item.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {log.length === 0 && (
                <Typography data-testid={"empty-message"} variant={"h6"} className={classes.emptyMessage}>
                    {t("analyticScreen.deviceLog.empty")}
                </Typography>
            )}
        </Grid>
    );
};

export default LogTabView;
