import { useCallback } from "react";
import { useSelector } from "react-redux";
import { convertValueToUnit } from "../utils/unitsConvertUtil";

const useUnits = () => {
    const { units } = useSelector( state => state.auth );

    /**
     * @param variable {string} - variable type
     * @param value {number} - value to convert
     * @return {number} return converted value
     */
    const convertToUserUnit = useCallback((variable, value) => {
        return convertValueToUnit(variable, value, units, false);
    }, [units]);

    /**
     * @param variable {string} - variable type
     * @param value {number} - value to convert
     * @return {number} return converted value
     */
    const convertToServerUnit = useCallback((variable, value) => {
        return convertValueToUnit(variable, value, units, true);
    }, [units]);

    return { units, convertToUserUnit, convertToServerUnit };
};

export default useUnits;
