import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const getValidatedPeriodsRequest = ({station}, callback) => {
    webClient.get(`/validate/calendar/${station}`)
        .then(response => {
            callback(false, response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};