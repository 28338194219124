import React from 'react';
import {Grid} from "@mui/material";
import SelectControl from "../../../../common/SelectControl";
import PollutantSelect from "../../../../common/chart/PollutantSelect";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
    }
});

const ScatterPlotSideContent = ({
                                    loading,
                                    selectedPollutant,
                                    onPollutantSelect,
                                    pollutantList,
                                    handleStationChange,
                                    selectedStation,
                                    stationOptions
                                }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (<Grid container style={{display: "flex", marginTop: "12px"}} spacing={1}>

        <Grid item xs={12}>
            <SelectControl
                className={classes.formControl}
                label={t("compareScreen.scatter.refStation")}
                disabled={loading}
                value={selectedStation}
                onChange={event => handleStationChange(event.target.value)}
                variant="standard"
                options={stationOptions}
                margin="dense"
            />
        </Grid>
        <Grid item xs={12}>
            <PollutantSelect
                formControlClass={classes.formControl}
                disabled={loading}
                onPollutantSelect={onPollutantSelect}
                selectedPollutant={selectedPollutant}
                pollutantList={pollutantList}/>
        </Grid>
    </Grid>);
};



export default ScatterPlotSideContent;
