import {useCallback, useEffect, useRef, useState} from "react";

const useDimensions = () => {
    const dimensionRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const onResize = useCallback(() => {
        if (dimensionRef.current) {
            setWidth(dimensionRef.current.clientWidth);
            setHeight(dimensionRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        if (!dimensionRef.current) return;
        const resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(dimensionRef.current);
        return () => resizeObserver.disconnect();
    }, [onResize]);

    return {dimensionRef, width, height};
};

export default useDimensions;
