import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const getNotificationsRequest = () => {
    return new Promise((resolve, reject) => {
        webClient.get("/user/notifications")
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                handleError(err, reject);
            });
    });
};

export const putNotificationsRequest = (body) => {
    return new Promise((resolve, reject) => {
        webClient.put("/user/notifications", body, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                handleError(err, reject);
            });
    });
};