import React, {useCallback, useEffect} from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider, TextField,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {shallowEqual, useSelector} from "react-redux";
import {getVariableUnitName} from "../../../unitsNames";
import {useTranslation} from "react-i18next";
import {getVariableMaxValue, getVariableMinValue} from "../../alarmsMaxThresholdValues";
import {getAvailableSensors} from "../../../../../../utils/stationUtil";
import {pollutantNames} from "../../../pollutantNames";
import {triggerTypes} from "../../alarmConfigs";
import {getTriggerName} from "../../triggerNames";
import {DEFAULT_POPUP_WIDTH, sensor as sensors} from "../../../../../../constants";
import Autocomplete from "@mui/material/Autocomplete";

const hourOptions = Array.from(Array(24).keys()).map(value => {return {value: Number(value), label: `${value}`};});


const useStyles = makeStyles({
    container:{
        maxWidth: DEFAULT_POPUP_WIDTH
    },
    formControl:{
        marginBottom: 50
    },
    pollutants:{
        width:620
    },
    trigger:{
        marginTop:40,
        marginBottom:40
    },
    triggerValue:{
        marginBottom:30
    },
    name:{
        marginBottom:40
    }
});

const AlarmConfigStep2 = ({
                              selectedPollutant, pollutantCallback,
                              trigger, triggerCallback,
                              value, handleInputChange,
                              handleSliderChange, handleBlur,
                              organizationUnits,
                              validPeriod,
                              validPeriodCallback,
                              allDayChecked,
                              allDayCheckedCallback
                          }) => {

    const {t} = useTranslation();

    const stationSensors = useSelector(state => (
        getAvailableSensors(state.dashboardUI.stations).map(sensor => sensor.id)
    ), shallowEqual);

    const pollutantOptions = [
        ...stationSensors?.filter(item => item !== sensors.wind).map(value => ({value, label: pollutantNames.get(value)})),
        {value: "battery", label: t("analyticScreen.deviceDescription.battery_level")}
    ];

    useEffect(()=>{
            const maxValue = getVariableMaxValue(selectedPollutant);
            if (value > maxValue) {
                handleInputChange({target: {value: maxValue}});
            }

            const minValue = getVariableMinValue(selectedPollutant);
            if (value < minValue) {
                handleInputChange({target: {value: minValue}});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[selectedPollutant]);


    useEffect(()=>{
        const maxValue = getVariableMaxValue(selectedPollutant);
        if (value > maxValue) {
            handleInputChange({target: {value: maxValue}});
        }

        const minValue = getVariableMinValue(selectedPollutant);
        if (value < minValue) {
            handleInputChange({target: {value: minValue}});
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[selectedPollutant]);


    const onSelectAllDayChange = useCallback((event) => {
        const checked = event.target.checked;
        allDayCheckedCallback(checked);
        if (checked) validPeriodCallback([0, 0]);
    }, [allDayCheckedCallback, validPeriodCallback]);

    const handleFromChanged = useCallback((value) => {
        validPeriodCallback([value ? Number(value) : 0 , validPeriod[1]]);
    }, [validPeriod, validPeriodCallback]);

    const handleToChanged = useCallback((value) => {
        validPeriodCallback([validPeriod[0] ?? 0, value ? Number(value): 0]);
    }, [validPeriod, validPeriodCallback]);


    const classes = useStyles();

    return (<>
        <Grid container className={classes.container} spacing={4}>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel>{t("alarmScreen.sensor")}</InputLabel>
                        <Select
                            value={selectedPollutant}
                            onChange={pollutantCallback}
                            label={t("alarmScreen.sensor")}
                        >
                            {pollutantOptions?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container xs={12} alignItems="center" className={classes.formControl}>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={allDayChecked}
                                                                     onChange={onSelectAllDayChange}/>}
                                                  label={t("alarmScreen.popUp.allDayValidPeriod")}/>
                            </FormGroup>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={hourOptions[validPeriod[0]]}
                                    disabled={allDayChecked}
                                    disablePortal
                                    getOptionLabel={ (option) => option.label}
                                    id="combo-box-from"
                                    onInputChange={(event, newInputValue) => {
                                        handleFromChanged(newInputValue);
                                    }}
                                    options={hourOptions}
                                    sx={{width: 90}}
                                    renderInput={(params) => <TextField {...params} variant="standard" label={t("alarmScreen.popUp.from")}/>}
                                    ListboxProps={{style:{ maxHeight: '250px'}}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={hourOptions[validPeriod[1]]}
                                    disabled={allDayChecked}
                                    disablePortal
                                    getOptionLabel={ (option) => option.label}
                                    onInputChange={(event, newInputValue) => {
                                        handleToChanged(newInputValue);
                                    }}
                                    id="combo-box-to"
                                    options={hourOptions}
                                    sx={{width: 90}}
                                    renderInput={(params) => <TextField {...params} variant="standard" label={t("alarmScreen.popUp.to")}/>}
                                    ListboxProps={{style:{ maxHeight: '250px'}}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>
            <Grid container item xs={6} className={classes.formControl}>
                <Grid item xs={12} className={classes.formControl}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{t("alarmScreen.trigger")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={trigger}
                            onChange={triggerCallback}
                            label={t("alarmScreen.trigger")}
                        >
                            <MenuItem value={triggerTypes.gt}>{getTriggerName(triggerTypes.gt, t)}</MenuItem>
                            <MenuItem value={triggerTypes.gte}>{getTriggerName(triggerTypes.gte, t)}</MenuItem>
                            <MenuItem value={triggerTypes.lt}>{getTriggerName(triggerTypes.lt, t)}</MenuItem>
                            <MenuItem value={triggerTypes.lte}>{getTriggerName(triggerTypes.lte, t)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container xs={12} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} gutterBottom>
                            {t("alarmScreen.threshold")}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Slider
                            step={0.1}
                            value={typeof value === 'number' ? value : 0}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            max={getVariableMaxValue(selectedPollutant)}
                            min={getVariableMinValue(selectedPollutant)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            value={typeof value === 'number' ? value : 0}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 0.1,
                                min: 0,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                                "data-testid": "add-alarm-value"
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {!organizationUnits.pollutants !== undefined &&
                            !organizationUnits.temperature !== undefined && <Typography>{
                                getVariableUnitName(selectedPollutant, organizationUnits)}
                            </Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
};

export default AlarmConfigStep2;
