import React from 'react';
import {makeStyles} from "@mui/styles";
import {Grid, Link, Typography} from "@mui/material";
import {getFormattedDateTime} from "../../../../utils/getFormattedDateTime";
import {useTranslation} from "react-i18next";
import {airQualityIndexColors, MODEL_EXTERNAL_PREFIX, stationState} from "../../../../constants";
import clsx from "clsx";
import {CATEGORICAL_INDEXES, getIndexRange, getValueColorPosition} from "../../../../utils/airQualityIndexUtil";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";

const useStyles = makeStyles({
    box: {
        width:320,
        padding:6
    },
    mainTitle:{
        color:"#696969",
        fontWeight:"bold"
    },
    title:{
        color:"#696969",
    },
    value:{
        marginLeft:4,
        color:"#9f9f9f"
    },
    active:{
        color:"#26D13D"
    },
    inactive:{
        color:"#c61218"
    },
    link:{
        textAlign:"right",
        marginRight:16
    }
});

const NodeDetail = ({index, device, deviceSelectedCallback, dataTimeZone}) => {

    const { t, i18n } = useTranslation();

    const handleOnClick  = ()=>{
        deviceSelectedCallback(device.id);
    };

    const classes = useStyles();

    const isExternal = device.model.startsWith(MODEL_EXTERNAL_PREFIX);

    const rangeInfo = getAQILegend(index, i18n.language);
    const rangeValues = getIndexRange(index);
    const valueColorPosition = getValueColorPosition(device.index, rangeValues);

    const timeZone = device.position?.locationInfo?.timeZone;

    return (
        <Grid container className={classes.box}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={classes.mainTitle}>{device.alias}</Typography>
            </Grid>
            {(device.battery !== undefined || !isExternal) && (
                <Grid item container xs={12} className={classes.card}>
                    <Typography variant={"subtitle1"} className={classes.title}>{t("home.battery")}</Typography>
                    <Typography variant={"subtitle1"} className={classes.value}>{`${device.battery}%`}</Typography>
                </Grid>
            )}
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"subtitle1"} className={classes.title}>{index || "error"}</Typography>
                <Typography variant={"subtitle1"} className={classes.value}
                            style={{fontWeight: "bold", color: airQualityIndexColors[index][valueColorPosition]}}>{
                    device.index === undefined ? "N/A" :
                    CATEGORICAL_INDEXES.includes(index) ? rangeInfo[device.index] : device.index
                }</Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"body2"}>
                    <span className={classes.title}>{t("analyticScreen.calendar.predominant_pollutant")}:</span>
                    <span className={classes.value}>{device.predominantPollutant ?? "N/A"}</span>
                </Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"body2"}>
                    <span className={classes.title}>{t("analyticScreen.deviceDescription.last_data")}:</span>
                    <span className={classes.value}>
                        {getFormattedDateTime(device.lastData, {
                            timeZone, includeTimeZone: true, timeZoneConfig: dataTimeZone
                        }) || "N/A"}
                    </span>
                </Typography>
            </Grid>
            {isExternal === false && <>
                <Grid item container xs={12} className={classes.card}>
                    <Typography variant={"body2"}>
                        <span className={classes.title}>{t("home.last_connection")}</span>
                        <span className={classes.value}>
                            {getFormattedDateTime(device.lastConnection, {
                                timeZone, includeTimeZone: true, timeZoneConfig: dataTimeZone
                            }) || "N/A"}
                        </span>
                    </Typography>
                </Grid>
                <Grid item container xs={12} className={classes.card}>
                    <Typography variant={"body2"}>
                        <span className={classes.title}>{t("home.state")}</span>
                        <span className={
                            clsx(
                                classes.value,
                                device.state === stationState.online || device.state === stationState.active && classes.active,
                                device.state === stationState.offline && classes.inactive
                            )
                        }>
                            {device.state ?? "N/A"}
                        </span>
                    </Typography>
                </Grid>
            </>}
            <Grid item className={classes.link} xs={12}>
                <Link component="button" underline="always" onClick={handleOnClick} variant="body2">
                    {t("home.more_detail")}
                </Link>
            </Grid>
        </Grid>
    );
};

export default NodeDetail;
