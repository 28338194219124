import {webClient} from "../../../config/axios";
import {handleError} from "../../handleRequestError";

export const notificationRequest = (limit, offset, callback) => {
    webClient.get(`/alarm/event?limit=${limit}${offset ? `&offset=${offset}` : ''}`)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};