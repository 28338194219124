import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {
    AppBar,
    Box,
    Divider,
    Drawer, Grid,
    IconButton,
    List,
    ListItem,
    Toolbar,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from 'clsx';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {DrawerListItems} from "./DrawerListItems";
import {PrivateRoutesTree} from "../PrivateRoutesTree";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    updateDashboardDrawerAction,
} from "../../../../reducers/dashboardUIReducer";
import {logoutAction} from "../../../../reducers/authReducer";
import SettingMenu from "./SettingMenu";
import * as Sentry from "@sentry/react";
import {
    ALARM_ROUTE, DEFAULT_MAP_POSITION,
    DOWNLOAD_ROUTE, ERROR_500,
    HOME_ROUTE, LOGS_ROUTE,
    ORGANIZATION_ROUTE,
    PROFILE_ROUTE, REPORT_ROUTE, VALIDATION_ROUTE
} from "../../../../constants";
import {logoutRequest} from "../../../../requests/auth/authRequests";
import AnalyticsStationSelector from "./AnalyticsStationSelector";
import CompareStationSelectorButton from "./compareSelector/CompareStationSelectorButton";
import CompareStationSelectorPopUp from "./compareSelector/CompareStationSelectorPopUp";
import DownloadWidgetGroup from "./download_progress/DownloadWidgetGroup";
import {removeAllDownloadProcessAction} from "../../../../reducers/downloadReducer";
import {useTranslation} from "react-i18next";
import styles from './Dashboard.module.scss';
import useLoading from "../../../../hooks/useLoading";
import useMessage from "../../../../hooks/useMessage";
import {useDashboardContext} from "./DashboardContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ToolbarBettairIcon from "./ToolbarBettairIcon";
import preproductionEnv from "../../../../getPreEnviroment";

export const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor:  process.env.REACT_APP_ENVIRONMENT !== "production" || preproductionEnv ? "#2b5525" : "#3333FF"
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarError: {
        backgroundColor: "#FFAB00",
        color: "#2a2a2a"
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        "& .MuiListItemIcon-root": {
            minWidth: 40
        },
    },
    drawerPaperClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    versionContainer: {
        marginTop: "auto"
    }
}));

export const Dashboard = (props) => {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {setLoading} = useLoading();
    const {setError, removeError} = useMessage();
    const dashboardRef = useRef(null);
    const {setDashboardRef} = useDashboardContext();
    const {downloadProcessList} = useSelector(state => state.download);
    const defaultSensorId = useSelector(state => state.dashboardUI.stations[0]?.id);
    const selectCompareStationIds = useSelector(state =>
        state.dashboardUI.selectCompareStations.map(item => item.id), shallowEqual);
    const defaultMapPosition = useSelector(state => state.auth.organization.map);
    const {email, name, lastName} = useSelector(state => state.auth);
    const drawerOpen = useSelector(state => state.dashboardUI.drawerOpen);
    const [anchorEl, setAnchorEl] = useState(null);
    const [{notFoundRouteValue, openCompareSelector}, updateState] =
        useState({notFoundRouteValue: false, openCompareSelector: false});

    const version = process.env.REACT_APP_VERSION;

    const classes = useStyles();

    useEffect(() => {
        setDashboardRef(dashboardRef);
    }, [setDashboardRef]);

    useEffect(() => {
        if (downloadProcessList.length > 0) {
            window.onbeforeunload = function () {
                return true;
            };
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [downloadProcessList]);

    const handleOpenCompareSelectorCallback = useCallback(() => {
        updateState(state => ({...state, openCompareSelector: true}));
    }, [updateState]);

    const handleCloseCompareSelectorCallback = useCallback(() => {
        updateState(state => ({...state, openCompareSelector: false}));
    }, [updateState]);

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    useEffect(() => {
            if (!props.isAuthenticated) {
                downloadProcessList.forEach(item => {
                    item.source.cancel();
                });
                dispatch(removeAllDownloadProcessAction());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.isAuthenticated]);


    const logout = useCallback(() => {
        localStorage.removeItem('lastPath');
        setLoading(true);
        removeError();
        logoutRequest((err, resp) => {
            setLoading(false);
            if (err) {
                switch (resp.status) {
                    case 401:
                        dispatch(logoutAction());
                        break;
                    case 500:
                        setError(ERROR_500, true);
                        break;
                    default:
                        setError({message: t('dashboard.error_logout')});
                        break;
                }
            } else {
                dispatch(logoutAction());
            }
        });
    }, [t, dispatch, setLoading, setError, removeError]);


    const logoutSession = useCallback(() => {
        logout();
    }, [logout]);

    const openProfile = useCallback(() => {
        history.push(PROFILE_ROUTE);
    }, [history]);

    const openOrganization = useCallback(() => {
        history.push(ORGANIZATION_ROUTE);
    }, [history]);

    const selectedPageCallback = useCallback((position) => {
        const lastStation = localStorage.getItem('lastStation');
        switch (position) {
            case 0:
                if (location.pathname !== HOME_ROUTE) {
                    history.push(HOME_ROUTE);
                } else {
                    const {lat, lon} = defaultMapPosition?.center || DEFAULT_MAP_POSITION;
                    const {zoom} = defaultMapPosition || DEFAULT_MAP_POSITION;
                    history.push(`${HOME_ROUTE}?lat=${lat}&long=${lon}&zoom=${zoom}`);
                }
                break;
            case 1:
                history.push(`/analytics/${lastStation ? lastStation : defaultSensorId}`);
                break;
            case 2:
                let query = "";
                selectCompareStationIds.forEach((stationId, index) => {
                    if (index === 0) {
                        query = `${query}?id=${stationId}`;
                    } else {
                        query = `${query}&id=${stationId}`;
                    }
                });
                history.push(`/compare/${query}`);
                break;
            case 3:
                history.push(ALARM_ROUTE);
                break;
            case 4:
                history.push(DOWNLOAD_ROUTE);
                break;
            case 5:
                history.push(REPORT_ROUTE);
                break;
            case 6:
                history.push(LOGS_ROUTE);
                break;
            case 7:
                history.push(VALIDATION_ROUTE);
                break;
            case 8:
                const eventId = Sentry.captureMessage('User send feedback', {
                    level: 'info',
                    tags: {userAction: 'sendFeedback'},
                });
                Sentry.showReportDialog({
                    eventId: eventId,
                    title: t('dashboard.sentry_send_suggestion'),
                    subtitle: t('dashboard.sentry_opinion'),
                    subtitle2: "",
                    user: {email: email, name: `${name} ${lastName}`},
                    labelSubmit: t('dashboard.sentry_send'),
                    labelComments: t('dashboard.sentry_something_to_say')
                });
                break;
            default:
                break;
        }
    }, [t, name, email, lastName, history, location, defaultMapPosition, defaultSensorId, selectCompareStationIds]);

    const handleDrawerOpen = () => {
        dispatch(updateDashboardDrawerAction(true));
    };
    const handleDrawerClose = () => {
        dispatch(updateDashboardDrawerAction(false));
    };

    const updateNotFoundRouteCallback = useCallback((notFound) => {
        updateState(previousState => ({...previousState, notFoundRouteValue: notFound}));
    }, []);

    return (
        <div className={classes.root}>
            <DownloadWidgetGroup/>
            {!notFoundRouteValue &&
                <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            {t('dashboard.bettair_platform')}
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        {(location.pathname.includes("/analytics") || location.pathname.includes("/home")) &&
                            <AnalyticsStationSelector/>}
                        {(location.pathname.includes("/compare")) &&
                            <CompareStationSelectorButton onButtonPressCallback={handleOpenCompareSelectorCallback}/>}
                        <IconButton
                            data-testid={"dashboard-setting"}
                            color="inherit" onClick={e => setAnchorEl(e.currentTarget)}>
                            <SettingsIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>}

            {!notFoundRouteValue && <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                }}
                open={drawerOpen}>
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <DrawerListItems selectedPageCallback={selectedPageCallback} drawerOpen={drawerOpen}/>
                </List>
                <Box className={classes.versionContainer}>
                    <ListItem dense>
                        <ListItemIcon>
                            <ToolbarBettairIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('dashboard.bettair_platform')}
                                      secondary={version && `${t("common.version")} ${version}`}/>
                    </ListItem>
                </Box>
            </Drawer>}

            <main className={clsx(styles.main, location.pathname.includes("/home") && styles.home)} ref={dashboardRef}>
                {!notFoundRouteValue && <div className={classes.appBarSpacer}/>}
                <Grid container direction={"row"} alignItems={"flex-start"} justifyContent={"center"}>
                    <Grid item xs={12} className={styles.container}>
                        <PrivateRoutesTree isAuthenticated={props.isAuthenticated}
                                           updateNotFound={updateNotFoundRouteCallback}
                        />
                    </Grid>
                </Grid>
            </main>
            <SettingMenu anchorEl={anchorEl}
                         closeMenu={closeMenu}
                         logoutSession={logoutSession}
                         openProfile={openProfile}
                         openOrganization={openOrganization}/>
            {openCompareSelector && <CompareStationSelectorPopUp open={openCompareSelector}
                                                                 handleClose={handleCloseCompareSelectorCallback}/>}
        </div>
    );
};

export default Dashboard;
