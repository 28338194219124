import {READ_LOG, WRITE_LOG} from "../organization/members/UserPermissions";
import {Grid, Link, Typography} from "@mui/material";
import DataNotFound from "../../../common/DataNotFound";
import DataGrid from "../../../common/dataGrid/DataGridComponent";
import useAuth from "../../../../hooks/useAuth";
import {makeStyles} from "@mui/styles";
import {useCallback, useEffect, useRef, useState} from "react";
import {getFormattedDateTime} from "../../../../utils/getFormattedDateTime";
import {getGridStringOperators} from "@mui/x-data-grid";
import {organizationRequest} from "../../../../requests/organization/organizationRequests";
import {LOGS_ROUTE} from "../../../../constants";
import {isEqual} from "lodash";
import DetailPopup from "./popups/DetailPopup";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import useMessage from "../../../../hooks/useMessage";
import {useTranslation} from "react-i18next";
import {styles} from "./styles";
import useRequest from "../../../../hooks/useRequest";

export const LIST_PAGE_SIZE = 8;

const useStyles = makeStyles(styles);

const isEqualText = (text1, text2) => {
    const normalizeText = (text) => text?.toLowerCase().trim();
    return normalizeText(text1) === normalizeText(text2);
};

const LogsTable = ({loading, setLoading, tableQueryData, reloadData, selectionModel, onSelectionModelChange}) => {
    const classes = useStyles();
    const history = useHistory();
    const {hasPermission} = useAuth();
    const {setError} = useMessage();
    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {rows, rowCount, page, setPage, setFilterModel, setSortModel, loadingData} = tableQueryData;
    const [detailData, setDetailData] = useState(null);
    const [organizationUsers, setOrganizationUsers] = useState([]);
    const [localFilterModel, setLocalFilterModel] = useState({items: []});
    const stations = useSelector(state => state.dashboardUI.stations.map((item) => ({
        id: item.id,
        alias: item.alias
    })), isEqual);
    const {stationId} = useParams();
    const [selectedStationId] = useState(stationId);
    const readyToLoad = useRef(false);
    const reloadedUsers = useRef(false);

    const getUsersData = useCallback(async () => {
        try {
            const {users} = await organizationRequest();
            return users.map(user => ({id: user.id, name: user.name}));
        } catch (err) {
            handleErrorResponse(err, response => {
                setError(response,
                    false,
                    "organizationPreferences.could_not_fetch_organization_preference");
            });
            return null;
        }
    }, [handleErrorResponse, setError]);

    const loadOrganizationUsers = useCallback(async () => {
        setLoading(true);
        const users = await getUsersData();
        if (users) {
            setOrganizationUsers(users);
        }
        reloadedUsers.current = true;
        setLoading(false);
    }, [getUsersData, setLoading]);

    useEffect(() => {
        const usersNotLoaded = rows.filter(row => !organizationUsers.find(user => user.id === row.user));
        if (usersNotLoaded.length !== 0 && reloadedUsers.current === false) {
            loadOrganizationUsers();
        }
    }, [organizationUsers, rows, loadOrganizationUsers]);

    const setFilterByStationId = useCallback((stationId) => {
        const stationAlias = stations.find(station => station.id === stationId)?.alias ?? null;
        if (stationAlias !== null) {
            setLocalFilterModel((oldModel) => {
                const newModel = {
                    ...oldModel,
                    items: [
                        ...oldModel.items.filter(item => item.columnField !== "station"),
                        {
                            columnField: "station",
                            operatorValue: "equals",
                            value: stationAlias
                        }
                    ]
                };
                if (isEqual(newModel, oldModel)) {
                    return oldModel;
                }
                return newModel;
            });
        }
    }, [stations]);

    useEffect(() => {
        if (selectedStationId) {
            setFilterByStationId(selectedStationId);
        } else {
            readyToLoad.current = true;
        }
    }, [selectedStationId, setFilterByStationId]);

    useEffect(() => {
        const stationFilter = localFilterModel.items.find(item => item.columnField === "station");
        const stationAlias = stationFilter?.value ?? null;
        const stationId = stations.find(station => isEqualText(station.alias, stationAlias))?.id ?? null;
        const convertedModel = {
            ...localFilterModel,
            items: localFilterModel.items.map(item => {
                const value = item.value === undefined ? "" : item.value;
                if (item.columnField === "user") {
                    return {
                        ...item,
                        value: value === "" ? "" : organizationUsers.find(user => isEqualText(user.name, value))?.id ?? null
                    };
                }
                if (item.columnField === "station") {
                    return {
                        ...item,
                        value: value === "" ? "" : stationId
                    };
                }
                return item;
            })
        };

        if (readyToLoad.current) {
            const newUrl = `${LOGS_ROUTE}${stationId ? `/${stationId}` : ""}`;
            if (history.location.pathname !== newUrl) {
                history.push(newUrl);
            }
        } else if (stationId) {
            readyToLoad.current = true;
        }

        setFilterModel((oldModel) => {
            if (isEqual(convertedModel, oldModel)) {
                return oldModel;
            }
            return convertedModel;
        });
    }, [localFilterModel, organizationUsers, stations, setFilterModel, history]);

    useEffect(() => {
        if (hasPermission(READ_LOG) && readyToLoad.current) {
            reloadData();
        }
    }, [hasPermission, reloadData]);

    const handleCloseDetailCallback = useCallback(() => {
        setDetailData(null);
    },[]);

    const columns = [
        {
            field: 'date', headerName: t("analyticScreen.deviceLog.date"), width: 220, filterable: false,
            renderCell: (params) => params.value ? (
                <Typography>{getFormattedDateTime(new Date(params.value))}</Typography>
            ) : null
        },
        {
            field: 'station', headerName: t("common.station"), width: 150,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals'),
            renderCell: (params) => {
                const station = stations.find(station => station.id === params.value);
                if (station) {
                    return stationId === undefined ? (
                        <Link component="button"
                              underline="hover"
                              className={classes.itemLink}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  setFilterByStationId(station.id);
                              }}>
                            {station.alias}
                        </Link>
                    ) : (
                        <Typography>{station.alias}</Typography>
                    );
                }
                return null;
            }
        },
        { field: 'user', headerName: t("common.user"), width: 150,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals'),
            renderCell: (params) => (
                <Typography>
                    {organizationUsers.find(user => user.id === params.value)?.name ?? t("common.oldUser")}
                </Typography>
            )
        },
        {
            field: 'description',
            headerName: t("common.description"),
            minWidth: 360,
            flex: 1,
            filterable: false,
            sortable: false
        },
        { field: 'id', headerName: t("downloadScreen.detail"), width: 120, disableClickEventBubbling: true,
            filterable: false, sortable: false, renderCell: (params) => (
                <Link component="button"
                      underline="hover"
                      className={classes.itemLink}
                      onClick={(event) => {
                          event.stopPropagation();
                          setDetailData(params.row);
                      }}>
                    {t("downloadScreen.view_detail")}
                </Link>
            )
        }
    ];

    return <>
        {!hasPermission(READ_LOG) ? (
            <Grid item xs={12}>
                <DataNotFound/>
            </Grid>
        ) : <>
            <Grid item xs={12} className={classes.rowTable}>
                <DataGrid
                    tableClassName={classes.table}
                    rows={rows}
                    columns={columns}
                    columnBuffer={8}
                    paginationMode="server"
                    page={page}
                    onPageChange={setPage}
                    pageSize={LIST_PAGE_SIZE}
                    rowsPerPageOptions={[LIST_PAGE_SIZE]}
                    rowCount={rowCount}
                    filterMode="server"
                    filterModel={localFilterModel}
                    onFilterModelChange={setLocalFilterModel}
                    sortingMode="server"
                    onSortModelChange={setSortModel}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    checkboxSelection={hasPermission(WRITE_LOG)}
                    density={"standard"}
                    loading={loading || loadingData}
                    data-testid="logs-table"
                />
            </Grid>
        </>}
        <DetailPopup logData={detailData} close={handleCloseDetailCallback} stations={stations}
                     organizationUsers={organizationUsers}/>
    </>;
};

export default LogsTable;
