import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import SelectControl from "../../../../common/SelectControl";


export const AUTO_RESOLUTION = -1;

const ResolutionSelector = ({formControlClass, loading, resolution, resolutionOptions, handleSelectorChange,
                            variant = "outlined", margin = "normal", fullWidth}) => {

    const {t} = useTranslation();

    const options = useMemo(() => resolutionOptions ?? [
        {value: AUTO_RESOLUTION, label: t("resolutionSelector.auto")},
        {value: 60, label: t("resolutionSelector.1h")},
        {value: 360, label: t("resolutionSelector.6h")},
        {value: 480, label: t("resolutionSelector.8h")},
        {value: 720, label: t("resolutionSelector.12h")},
        {value: 1440, label: t("resolutionSelector.24h")}
    ], [resolutionOptions, t]);

    return (
        <SelectControl
            className={formControlClass}
            label={t("resolutionSelector.resolution")}
            disabled={loading}
            options={options}
            value={resolution}
            onChange={handleSelectorChange}
            variant={variant}
            margin={margin}
            fullWidth={fullWidth}
        />
    );
};

export default ResolutionSelector;
