import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";
import {AUTO_RESOLUTION} from "../../components/routes/private/analytics/common/ResolutionSelector";

/**
 *
 * @param temp {string}
 * @param pollutantUnit {string}
 * @param stationId {string}
 * @param init {string}
 * @param end {string | undefined}
 * @param dataTimeZone {string}
 * @param useValidationFilter {boolean | undefined}
 * @param resolution
 * @param callback {function}
 */
export const sensorDataRequest = ({temp,pollutantUnit,stationId, init, end, dataTimeZone, useValidationFilter, resolution = AUTO_RESOLUTION}, callback) => {

    let params = `?temperature=${temp}&pollutants=${pollutantUnit}&dataTimeZone=${dataTimeZone}&time=${init}`;

    if(useValidationFilter !== undefined)
        params += `&useValidationFilter=${useValidationFilter}`;

    if(end)
        params += `&endtime=${end}`;

    if(resolution !== AUTO_RESOLUTION)
        params += `&resolution=${resolution}`;

    let url = `/station-data/${stationId}/sensor-data/${params}`;

    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};
