import React from 'react';
import {getFormattedDateTime} from "../../utils/getFormattedDateTime";
import {Divider, Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const LastUpdate = ({date, updateAvailable, handleRefreshCallback}) => {

    const {t} = useTranslation();

    return (
        <div style={{display:"flex", flexDirection:"row", alignItems:"center",justifyContent:"flex-end"}}>
            {updateAvailable &&
                <>
                    <Typography style={{marginRight:8}}>{t("data_updated")}</Typography>
                    <Link
                        style={{marginRight:6, cursor:"pointer"}}
                        onClick={_ => {
                            handleRefreshCallback();
                        }
                        }
                    >{t("refresh")}</Link>
                    <Divider
                        style={{marginRight:8}}
                        orientation={"vertical"} variant="middle" flexItem/>
                </>
            }
            {date && <Typography
                variant={"body2"}
                style={
                    {
                        textAlign: "right",
                        color: "#a2a2a2",
                    }}>{`${t("alarmScreen.last_update")} ${getFormattedDateTime(date)}`}</Typography>}
        </div>
    );
};

export default LastUpdate;
