import {ToggleButton, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyle = makeStyles({
    toggleButtonText: {
        display: "inline-block",
        marginLeft: 20,
        marginRight: 20
    }
});

const CustomToggleButton = ({classname, label, IconComp, iconPosition = "left", compactMode, ...props}) => {
    const classes = useStyle();

    const icon = <IconComp style={{pointerEvents: "none"}}/>;
    const button = (
        <ToggleButton className={classname} {...props}>
            {compactMode ? icon : (
                <>
                    {iconPosition === "left" && icon}
                    <span className={classes.toggleButtonText}>{label}</span>
                    {iconPosition === "right" && icon}
                </>
            )}
        </ToggleButton>
    );

    return compactMode ? (
        <Tooltip arrow title={label} PopperProps={{modifiers: [{name: 'offset', options: {offset: [0, -10]}}]}}>
            {button}
        </Tooltip>
    ) : button;
};

export default CustomToggleButton;