import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";
import {AUTO_RESOLUTION} from "../../components/routes/private/analytics/common/ResolutionSelector";

export const noiseDataRequest = ({stationId,init,end, dataTimeZone, resolution = AUTO_RESOLUTION},callback) => {

    let params = `?dataTimeZone=${dataTimeZone}&time=${init}`;
    if(end)
        params += `&endtime=${end}`;

    if(resolution !== AUTO_RESOLUTION)
        params += `&resolution=${resolution}`;

    let url = `/station-data/${stationId}/noise/${params}`;
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};
