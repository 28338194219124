import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const scatterPlotRequest = (temp, pollutantUnit, stationId, sensorX, sensorY, init, end, callback) => {
    let params =
        `?x=${sensorX}&y=${sensorY}&temperature=${temp}&pollutants=${pollutantUnit}&time=${init}${end ? `&endtime=${end}` : ''}`;
    let url = `/station-data/${stationId}/scatter-plot/${params}`;
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};