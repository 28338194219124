import React, {useCallback, useEffect, useState} from "react";
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import HelpPopup from "../../../../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PublicStationsPopup from "./PublicStationsPopup";
import {updatePublicMapRequest} from "../../../../../requests/organization/organizationRequests";
import useMessage from "../../../../../hooks/useMessage";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {openLinkInNewTab} from "../../../../../utils/linkUtil";
import DataNotFound from "../../../../common/DataNotFound";
import LoadingButton from "../../../../common/buttons/LoadingButton";
import {useMessageDialog} from "../../../../../hooks/useMessageDialog";


const PUBLIC_BASE_URL = "https://public.bettair.city/";

const useStyles = makeStyles({
    form: {
        width: "100%",
    },
    title: {
        fontWeight: "bold"
    },
    grip: {
        marginTop: 12,
    },
    button: {
        color: "#ffffff",
    },
    helpButton: {
        color: "black"
    }
});


const PublicConfig = ({className, publicConfig}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const {setError, setSuccess} = useMessage();
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {showDialog} = useMessageDialog();

    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [publicEnabled, setPublicEnabled] = useState(false);
    const [publicStations, setPublicStations] = useState([]);
    const [publicUrl, setPublicUrl] = useState("");
    const [saving, setSaving] = useState(false);

    const updateRequestCallback = useCallback((resetValues) => (error, data) => {
        setLoading(false);
        setSaving(false);
        if (error) {
            setError(data,
                false,
                "organizationPreferences.error_updating_preferences");
            setPublicEnabled(resetValues.enabled ?? publicEnabled);
            setPublicStations(resetValues.stations ?? publicStations);
        } else {
            setPublicEnabled(data.enabled);
            setPublicStations(data.stations);
            setPublicUrl(data.url);
        }
    }, [setError, publicEnabled, publicStations]);

    useEffect(() => {
        if (publicConfig) {
            setLoading(false);
            setLoadingError(false);
            setPublicEnabled(!!publicConfig.enabled);
            setPublicStations(publicConfig.stations);
            setPublicUrl(publicConfig.url);
        } else {
            setLoading(publicConfig !== null);
            setLoadingError(publicConfig === null);
        }
    }, [publicConfig]);

    const handlePopupOpen = () => {
        setPopupOpen(true);
    };

    const handlePopupClose = () => {
        setPopupOpen(false);
    };

    const handlePublicEnabledChange = async (event) => {
        const enabled = event.target.checked;
        updatePublicMapRequest({enabled}, updateRequestCallback({enabled: publicEnabled}));
        setPublicEnabled(enabled);
        setSaving(true);
        if (enabled) {
            await showDialog({message: t("organizationPublic.disclaimer_info")});
        }
    };

    const handlePublicStationsChange = (stations) => {
        updatePublicMapRequest({stations}, updateRequestCallback({stations: publicStations}));
        setPublicStations(stations);
        setSaving(true);
    };

    const link = publicUrl ? PUBLIC_BASE_URL + publicUrl : "";
    const onLinkFocusHandler = (event) => event.target.select();
    const copyLinkHandler = () => {
        navigator.clipboard.writeText(link);
        setSuccess("organizationPublic.link_copied");
    };
    const openLinkHandler = () => openLinkInNewTab(link);

    return (
        <>
            <Card className={className}>
                {loading && <CardViewLoading/>}
                {loadingError && <DataNotFound/>}
                {!loading && !loadingError && (
                    <div style={{position:"relative",width:"100%"}}>
                        <form noValidate className={classes.form}>
                            <Grid className={classes.grip} container direction={"row"} spacing={2}>
                                <Grid item container xs={12}>
                                    <Typography className={classes.title} variant={"h4"}>
                                        {t("organizationPublic.title")}
                                    </Typography>
                                    <IconButton className={classes.helpButton} aria-label="help"
                                                onClick={e => setAnchorEl(e.currentTarget)}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item container xs={12} spacing={8}>
                                    <Grid item xs={3}>
                                        <FormControlLabel label={t("organizationPublic.enable_public_page")}
                                                          control={<Checkbox checked={publicEnabled}
                                                                             onChange={handlePublicEnabledChange}
                                                                             data-testid="publicEnabledCheckbox" />}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="URL"
                                            id="outlined-size-small"
                                            size="small"
                                            fullWidth
                                            disabled={!publicEnabled}
                                            value={publicEnabled ? link : ""}
                                            onFocus={onLinkFocusHandler}
                                            InputProps={(publicUrl && publicEnabled) ? {
                                                endAdornment: (
                                                    <>
                                                        <InputAdornment position="end">
                                                            <Tooltip title={t("organizationPublic.copy_link")}>
                                                                <IconButton
                                                                    aria-label="copy url"
                                                                    onClick={copyLinkHandler}
                                                                    edge="end"
                                                                >
                                                                    <ContentCopyIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                        <InputAdornment position="end">
                                                            <Tooltip title={t("organizationPublic.open_link")}>
                                                                <IconButton
                                                                    aria-label="open url"
                                                                    onClick={openLinkHandler}
                                                                    edge="end"
                                                                >
                                                                    <OpenInNewIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    </>
                                                )
                                            } : null}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <LoadingButton className={classes.button} fullWidth onClick={handlePopupOpen}
                                                       disabled={!publicEnabled || saving}
                                                       loading={publicEnabled && saving}
                                                       data-testid="selectStationsButton">
                                            {t("organizationPublic.select_stations")}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                )}
            </Card>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("organizationPublic.help")}/>
            <PublicStationsPopup open={popupOpen} initialSelection={publicStations}
                                 saveCallback={handlePublicStationsChange} closeCallback={handlePopupClose}/>
        </>
    );
};

export default PublicConfig;
