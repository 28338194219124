import {webClient} from "../../config/axios";
import {AUTO_RESOLUTION} from "../../components/routes/private/analytics/common/ResolutionSelector";

export const windDataRequest = (windUnits, stationId, init, end, resolution = AUTO_RESOLUTION) => {
    let params = end ? `?windSpeed=${windUnits}&time=${init}&endtime=${end}` : `?windSpeed=${windUnits}&time=${init}`;

    if (resolution !== AUTO_RESOLUTION)
        params += `&resolution=${resolution}`;

    const url = `/station-data/${stationId}/wind-data/${params}`;

    return webClient.get(url).then(response => {
        return response.data;
    }).catch(error => {
        if (error.request?.status === 404) return null;
        throw error;
    });
};
