const processPollutantRoseData = (requestData) => {
    const {data, total, units} = requestData;
    if (data === null || data === undefined) return [];
    const roseData = [];
    const buckets = Object.keys(data);
    buckets.forEach((bucket, index) => {
        if (data[bucket]) {
            const r = [];
            for (let i = 0; i < 16; i++) {
                r[i] = data[bucket][`dir${i}`] / total || 0;
            }
            const roseItem = {
                category: index,
                name: index === buckets.length - 1 ? `> ${bucket}` : `${bucket} - ${buckets[index + 1]}`,
                r: r.map(value => (value * 100).toFixed(2))
            };
            roseData.push(roseItem);
        }
    });
    return {
        unit: units,
        data: roseData
    };
};

export default processPollutantRoseData;