import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const stationInfoRequest = (stationId,callback) => {
    webClient.get(`/stations/${stationId}`)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateAliasRequest = (body,callback) => {
    webClient.put('/stations/alias', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const getStationImageRequest = (stationId)=>{
    return webClient.get(`/stations/image/${stationId}`,{
        responseType: 'blob'
    }).then(response => {
        return URL.createObjectURL(response.data);
    }).catch(err => {
            if (err.response.status === 404)
                return null;
            throw err;
        }
    );
};

export const deleteImageRequest = (stationId) => {
    return webClient.delete(`/stations/image/${stationId}`).catch(err => {
            if (err.response.status === 404)
                return null;
            throw err;
        }
    );
};

export const uploadImageRequest = (stationId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    return webClient.post(`/stations/image/${stationId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
