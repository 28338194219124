import React, {useCallback, useEffect, useState} from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControlLabel,
    Switch
} from "@mui/material";
import {getFormattedDateTimeWithNavigatorTimeZone} from "../../../../../utils/getFormattedDateTime";
import {isCurrentPeriodValid, YESTERDAY} from "./ReportConfigPopUp";
import {useTranslation} from "react-i18next";
import DatePickerField from "../../../../common/DatePickerField";
import {appModules, indexTypes} from "../../../../../constants";
import AutoReportsFrequencySelector from "../autoReportControls/frequency/AutoReportsFrequencySelector";
import AutoReportsDurationSelector from "../autoReportControls/duration/AutoReportsDurationSelector";
import useModules from "../../../../../hooks/useModules";
import {getAqiIndexNameAndRegionText} from "../../../../../utils/aqiUtil";

const ReportParamsConfig = ({
                                isAutoReport,
                                name,
                                nameCallback,
                                title,
                                titleCallback,
                                titleEnabled,
                                titleEnabledCallback,
                                initialDate,
                                updateInitialDate,
                                finalDate,
                                updateFinalDate,
                                pollutantUnit,
                                pollutantUnitsCallback,
                                tempUnits,
                                tempoCallback,
                                index,
                                indexCallback,
                                windSpeedUnit,
                                windSpeedCallback,
                                frequency,
                                frequencyCallback,
                                duration,
                                durationCallback,
                                weekDay,
                                monthFrequency,
                                numberOfDays,
                                numberOfMonths,
                                weekDayCallback,
                                monthFrequencyCallback,
                                numberOfDaysCallback,
                                numberOfMonthsCallback,
                                excludeInvalidData,
                                excludeInvalidDataCallback
                            }) => {

    const {t} = useTranslation();
    const {hasModule} = useModules();

    const getReadablePeriod = useCallback((date, finalDate) => {
        if (isCurrentPeriodValid(date, finalDate)) {
            return `${t("reportScreen.reportConfig.report_date_range")}  ${getFormattedDateTimeWithNavigatorTimeZone(date)} - 
        ${getFormattedDateTimeWithNavigatorTimeZone(finalDate)}`;
        } else {
            return t("reportScreen.reportConfig.invalid_period");
        }
    }, [t]);

    const [readablePeriod, setReadablePeriod] = useState(getReadablePeriod(initialDate, finalDate));

    useEffect(() => {
        setReadablePeriod(getReadablePeriod(initialDate, finalDate));
    }, [getReadablePeriod, initialDate, finalDate]);

    useEffect(() => {
        if (!titleEnabled) {
            titleCallback({target: {value: ""}});
        }
    }, [titleEnabled, titleCallback]);


    return (<>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <TextField inputProps={{"data-testid": "report-param-text"}}
                               onChange={nameCallback}
                               value={name}
                               label={t("reportScreen.reportConfig.report_name")}
                               size="small"
                               multiline
                               rows={1}/>
                </FormControl>
            </Grid>
            {!isAutoReport && <>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <DatePickerField label={t("common.start_date")}
                                         value={initialDate}
                                         onChange={updateInitialDate}
                                         maxDate={YESTERDAY}
                                         size="small"/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <DatePickerField label={t("common.end_date")}
                                         value={finalDate}
                                         onChange={updateFinalDate}
                                         maxDate={YESTERDAY}
                                         size="small"/>
                    </FormControl>
                </Grid>
                <Grid item container xs={12}
                      style={{display: "flex", alignItems: "flex-start", paddingTop: 0, justifyContent: "flex-end"}}>
                    <Typography style={{
                        textAlign: "right",
                        fontSize: 14,
                        color: isCurrentPeriodValid(initialDate, finalDate) ? "#a1a1a1" : "#d72f2f"
                    }}>{readablePeriod}</Typography>
                </Grid>
            </>
            }{
            isAutoReport &&
            <Grid item container xs={12} justifyContent="center" alignItems="flex-start"
                  style={{marginTop: 12, marginBottom: 12}}>
                <Grid item xs={12} sm={7}>
                    <AutoReportsFrequencySelector
                        weekDay={weekDay} monthFrequency={monthFrequency} weekDayCallback={weekDayCallback}
                        monthFrequencyCallback={monthFrequencyCallback}
                        selectedFrequency={frequency} handleFrequencyChange={frequencyCallback}/>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <AutoReportsDurationSelector selectedDuration={duration} handleDurationChange={durationCallback}
                                                 numberOfDays={numberOfDays} numberOfDaysCallback={numberOfDaysCallback}
                                                 numberOfMonths={numberOfMonths}
                                                 numberOfMonthsCallback={numberOfMonthsCallback}/>
                </Grid>
            </Grid>
        }


            <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth size="small" margin="dense">
                    <InputLabel>{t("units.pollutant_index")}</InputLabel>
                    <Select
                        onChange={indexCallback}
                        label={t("units.pollutant_index")}
                        value={index}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        {Object.values(indexTypes).map(item =>
                            <MenuItem key={item} value={item}>{getAqiIndexNameAndRegionText(item, t)}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
                <FormControl variant="outlined" fullWidth size="small" margin="dense">
                    <InputLabel>{t("units.concentration_units")}</InputLabel>
                    <Select
                        label={t("units.concentration_units")}
                        onChange={pollutantUnitsCallback}
                        value={pollutantUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                        <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth size="small" margin="dense">
                    <InputLabel>{t("units.temperature_units")}</InputLabel>
                    <Select
                        label={t("units.temperature_units")}
                        onChange={tempoCallback}
                        value={tempUnits}
                    >
                        <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                        <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
                <FormControl variant="outlined" fullWidth size="small" margin="dense">
                    <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                    <Select
                        label={t("units.wind_speed_unit")}
                        onChange={windSpeedCallback}
                        value={windSpeedUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                        <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                        <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <FormControlLabel control={<Switch defaultChecked/>} label={t("common.custom_title")}
                                  checked={titleEnabled} onChange={titleEnabledCallback}/>
            </Grid>
            <Grid item xs={12} sm={7}>
                <FormControl variant="outlined" fullWidth margin="dense"
                             style={{visibility: titleEnabled ? "visible" : "hidden"}}>
                    <TextField inputProps={{"data-testid": "report-param-title"}}
                               onChange={titleCallback}
                               value={title}
                               disabled={!titleEnabled}
                               label={t("common.title")}
                               size="small"
                               multiline
                               rows={1}/>
                </FormControl>
            </Grid>
            {hasModule(appModules.dataValidation) &&
                <Grid item xs={12}>
                    <FormControlLabel control={<Switch defaultChecked/>}
                                      label={t("reportScreen.reportConfig.only_validated_data")}
                                      checked={excludeInvalidData} onChange={excludeInvalidDataCallback}/>
                </Grid>
            }

        </Grid>
    </>);
};

export default ReportParamsConfig;
