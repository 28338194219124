import {handleSentryError} from "../handleSentryError";

export const getSavedLayerState = (storageName,userId) => {
    const savedLayerState = localStorage.getItem(`${storageName}-${userId}`);
    if (savedLayerState) {
        let decodedString;
        try {
            decodedString = atob(savedLayerState);
        } catch (e) {
            handleSentryError(e);
            return {};
        }
        try {
            return JSON.parse(decodedString);
        } catch (e) {
            handleSentryError(e);
            return {};
        }
    }
    return {};
};
